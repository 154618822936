import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import VerificationInput from "react-verification-input";
import { useAuth } from "../../behavioral";
import { BaseButton, HeaderLogo, ROUTEPATHS } from "../../libs";

import { Col, Row, Spinner } from "react-bootstrap";
import * as Styled from "./RouteConfirmContainer.styled";

export const RouteConfirmContainer: React.FC = () => {
  const { search } = useLocation();
  const { confirmAccount } = useAuth();
  const navigate = useNavigate();
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const [showConfirmationError, setShowConfirmationError] =
    React.useState(false);
  const [isSubmitting, setIsSubmittig] = React.useState(false);
  const query = new URLSearchParams(search);
  const code = query.get("confirmation_code");
  const email = query.get("email");

  const handleAccounConfirmation = React.useCallback(async () => {
    setIsSubmittig(true);
    try {
      await confirmAccount(email!, code!);
      setIsConfirmed(true);
      setIsSubmittig(false);
    } catch (err) {
      setIsSubmittig(false);
      setShowConfirmationError(false);
    }
  }, [confirmAccount, email, code]);

  const navigateToLogin = React.useCallback(() => {
    navigate({ pathname: `${ROUTEPATHS.LOGIN}` }, { replace: true });
  }, [navigate]);

  return (
    <>
      <Styled.ConfirmationHeader>
        <HeaderLogo />
      </Styled.ConfirmationHeader>
      {isConfirmed ? (
        <Styled.ConfirmationWrapper fluid>
          <Styled.Title>
            {showConfirmationError ? "Verification Failed" : "Congratulations"}
          </Styled.Title>
          <Styled.ConfirmationBody>
            {showConfirmationError
              ? `There is an error while verifying the account. Please reach to our customer support to help resolving the issue.`
              : `Your account is verified successfully. Please login to access the
            account and enjoy all the features of Cemboo.`}
          </Styled.ConfirmationBody>
          {!showConfirmationError && (
            <Row>
              <Col md={12}>
                <BaseButton onClick={navigateToLogin}>
                  Continue to Login
                </BaseButton>
              </Col>
            </Row>
          )}
        </Styled.ConfirmationWrapper>
      ) : (
        <Styled.ConfirmationInputWrapper fluid>
          <Styled.Title>Verify Your Account</Styled.Title>
          <Styled.ConfirmationBody>
            Please click on submit to confirm the email:
            <span> {email}</span>
          </Styled.ConfirmationBody>

          <VerificationInput
            length={6}
            placeholder=""
            value={code ?? ""}
            classNames={{
              container: "verify-account-modal-container",
              character: "verify-account-modal-container character",
              characterInactive:
                "verify-account-modal-container character--inactive",
              characterSelected:
                "verify-account-modal-container character--selected",
            }}
          />

          <Row>
            <Col md={12}>
              <BaseButton onClick={handleAccounConfirmation}>
                {" "}
                {isSubmitting ? (
                  <>
                    <Spinner size="sm" /> Verfying
                  </>
                ) : (
                  <>Submit</>
                )}
              </BaseButton>
            </Col>
          </Row>
        </Styled.ConfirmationInputWrapper>
      )}
    </>
  );
};
