import { BlockContainer } from "../../components";
import * as Styled from "./block-page.styled";

export const BlockPage = () => {
    return (
        <Styled.BlockPageWrapper>
            <BlockContainer />
        </Styled.BlockPageWrapper>
    );
};
