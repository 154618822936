import { Col, Container, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { Button } from "../../ui";
import { colors, types } from "./../../../libs";

export const LiveChannelsDetailsContainer = styled(Container)`
  padding: 0;
  margin-bottom: 50px;
`;

export const HeaderRow = styled(Row)`
  margin-bottom: 20px;
  align-items: center;
`;

export const Title = styled.h1`
  ${types.headers.secondary};
  background: ${colors.text.textGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 10px;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80vh;
  color: ${colors.background.green};
  & > .spinner-border {
    width: 5rem;
    height: 5rem;
  }
`;

export const DetailRow = styled(Row)`
  margin-top: 20px;
`;

export const DetailLabel = styled(Form.Label)`
  display: block;
  ${types.headers.small};
`;

export const DetailLabelHeader = styled(Form.Label)`
  display: block;
  margin-top: 20px;
  ${types.headers.section};
`;

export const DetailText = styled(Form.Text)`
  color: ${colors.text.mediumWhite};
  display: block;
`;

export const StreamDetailRow = styled(Row)`
  margin-top: 30px;
`;

export const StreamTitle = styled.h1`
  ${types.headers.section};
  margin-bottom: 10px;
`;

export const PlayerWrapper = styled(Col)`
  position: relative;
`;

export const Player = styled.video`
  margin-top: 20px;
  width: 70%;
  border: 1px solid ${colors.text.mediumWhite};
  height: 500px;
  position: relative;
`;

export const PlayBackUrlRow = styled(Row)`
  margin-top: 20px;
`;

export const IconWrapper = styled.div`
  position: absolute;
  height: 60px;
  width: 80px;
  border: 1px solid ${colors.text.mediumWhite};
  border-radius: 10px;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
  background: ${colors.background.darkGradient};
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PlayIcon = styled.i`
  font-size: 25px;
`;

export const EditButton = styled(Button)`
  background: ${colors.background.lightWhite};
`;
