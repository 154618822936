export const colors = {
  background: {
    black: "#0B0B0B",
    lightBlack: "#121419",
    formField: "#1E2025",
    green: "#B7F84D",
    darkGreen: "#48602A",
    disabled: "#414347",
    active: "#B7F84D",
    darkGradient:
      "linear-gradient(103.07deg, rgba(183, 248, 77, 0.2) -38.19%, rgba(141, 198, 63, 0.2) 102.21%), #0B0B0B",
    linearGradient:
      "linear-gradient(103.07deg, rgba(183, 248, 77, 0.2) -38.19%, rgba(141, 198, 63, 0.2) 102.21%)",
    lightWhite: "rgba(255, 255, 255, 0.2)",
    brown: "#303030",
    error: "#FF3D3D33"
  },
  text: {
    green: "#B7F84D",
    lightGreen: "#E4FFDB",
    white: "#FFFFFF",
    lightWhite: "rgba(255, 255, 255, 0.2)",
    mediumWhite: "#F1F1F1",
    disabled: "rgba(255, 255, 255, 0.5)",
    gradient: "linear-gradient(270.35deg, #B7F84D 0.39%, #FFFFFF 102.68%)",
    textGradient:
      "-webkit-linear-gradient(270.35deg,#b7f84d 0.39%,#ffffff 102.68%);",
    black: "#121419",
    error: "#FF3D3D",
  },
};
