import styled from "styled-components";
import { colors, types } from "../../../../libs";
import { Row, Spinner, Image } from "react-bootstrap";
import { BaseButton } from "../../../../libs";

export const AdServerComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  background: #0b0b0b;
  margin: 20px 0 50px 0;
`;

export const RequiredText = styled.p`
  ${types.body.small};
  color: ${colors.text.green};
  margin: 5px 0;
`;

export const FormFieldRow = styled(Row)`
  margin-bottom: 15px;
`;

export const LoadingSpinner = styled(Spinner)`
  margin-right: 10px;
`;

export const ImagePreview = styled(Image)`
  height: 250px;
  object-fit: contain;
`;

export const ErrorText = styled.p`
  ${types.body.small};
  color: #ff7f50;
  margin: 5px auto;
`;

export const UpdateButton = styled(BaseButton)`
  margin-top: 20px;
`;
