import styled, { keyframes } from "styled-components";
import { NavLink } from "react-router-dom";

const animateTop = keyframes`
  from {
    top:-100px;
    opacity:0;
  }
  to {
    top:0;
    opacity:1;
  }
`;

export const MobieMenuWrapper = styled.div<any>`
  position: relative;
  height: 100vh;
  display: ${(props) =>
    props.show ? "block" : "none"}; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 0px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  /*overflow: auto;*/ /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.8); /* Black w/ opacity */
  background: rgba(11, 11, 11, 0.9);
`;

export const MobileMenuContent = styled.div`
  position: relative;
  background-color: rgba(18, 20, 25, 0.3);
  backdrop-filter: blur(10px);
  margin: auto;
  width: 33%;
  -webkit-animation-name: ${animateTop};
  -webkit-animation-duration: 0.3s;
  animation-name: ${animateTop};
  animation-duration: 0.3s;
  padding: 0.5rem 0.75rem;
  width: 100%;
  height: 100%;
`;

export const MobileMenuCloseIcon = styled.i`
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;

export const MobileMenuLinksWrapper = styled.ul`
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  height: 100%;
`;

export const MobileMenuLinkItem = styled.li`
  margin: 0.5rem 0;
  list-style: none;
  display: block;
`;

export const MobileMenuLink = styled(NavLink)<any>`
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  border: 2px solid transparent;
  padding: 10px 10px 10px 20px;

  &.active {
    border: 2px solid rgba(183, 248, 77, 1);
  }
`;

export const LogoLink = styled.a`
  min-width: 230px;
  padding: 20px 20px 20px 20px;
  display: inline-block;
`;

export const LogoImage = styled.img`
    cursor: pointer;
    width: 121px;
`;
