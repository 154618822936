import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

import { colors, OuterContainer } from "../../libs";

export const RegistrationPageContainer = styled(OuterContainer)`
  background-color: ${colors.background.black};
  padding: 0 15px;
  min-height: 100vh;
`;

export const RegistrationPageRow = styled(Row)``;

export const PackageInfoCol = styled(Col)``;

export const RegistrationHeader = styled.div`
  padding: 20px;
`;

export const RegistrationCol = styled(Col)`
  background-color: ${colors.background.lightBlack};
  min-height: 100vh;
  padding-top: 100px;
`;
