import axios from "axios";
import { API_BASE_PATHS, API_ROUTES, IUser, IUserRequest, LiveStreamLibraryConfig, MediaLibraryConfig, LibraryType } from "../libs";
import { buildRequestConfig } from "./buildRequestConfig";

class UserService {
  async getUserByEmail(email: string): Promise<IUser> {
    const headers = {
      "content-type": "application/json",
    };

    const { data: userData } = await axios.get<IUser>(
      `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.USERS_API}/${API_ROUTES.GET_USER_BY_EMAIL}?email=${email}`,
      { headers }
    );

    return userData;
  }

  async getCurrentUser(): Promise<IUser> {
    const reqConfig = await buildRequestConfig();
    const { data: userData } = await axios.get(
      `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.USERS_API}/${API_ROUTES.GET_CURRENT_USER}`,
      reqConfig
    );

    return userData.data;
  }

  async registerUser(userData: IUserRequest): Promise<IUser> {
    const headers = {
      "content-type": "application/json",
    };

    const { data: userDataResponse } = await axios.post(
      `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.USERS_API}/${API_ROUTES.REGISTER_USER}`,
      { ...userData },
      { headers }
    );

    return userDataResponse.data;
  }

  async createUserSubscription(reqData: any): Promise<any> {
    const headers = {
      "content-type": "application/json",
    };

    const { data: subscription } = await axios.post(
      `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.USERS_API}/${API_ROUTES.CREATE_SUBSCRIPTION}`,
      { ...reqData },
      { headers }
    );

    return subscription.data;
  }

  async getPromoCodeId(promoCode: string): Promise<string> {
    const headers = {
      "content-type": "application/json",
    };

    const { data: promoCodeData } = await axios.get(
      `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.USERS_API}/${API_ROUTES.GET_PROMO_CODE_ID}/${promoCode}`,
      { headers }
    );

    return promoCodeData.data;
  }

  async confirmSubscription(reqData: any): Promise<any> {
    const headers = {
      "content-type": "application/json",
    };

    const { data: subscription } = await axios.post(
      `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.USERS_API}/${API_ROUTES.COMPLETE_SUBSCRIPTION}`,
      { ...reqData },
      { headers }
    );

    return subscription.data;
  }

  async getLibraryConfig(libraryId: LibraryType): Promise<any> {
    const config = await buildRequestConfig();

    const params = {
      libraryId: libraryId
    };

    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.USERS_API}/${API_ROUTES.GET_LIBRARY_CONFIG}`,
      { ...config, params }
    );
    return response.data?.data ? response.data.data : {};
  }

  async setLibraryConfig(data: MediaLibraryConfig | LiveStreamLibraryConfig): Promise<any> {
    const config = await buildRequestConfig();

    const response = await axios.post(
      `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.USERS_API}/${API_ROUTES.GET_LIBRARY_CONFIG}`,
      data,
      { ...config }
    );
    return response.data;
  }
}

export const userService = new UserService();
