import React from "react";
import { Col, Spinner } from "react-bootstrap";
import {
    PaymentElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { BaseButton } from "../../../../libs";
import * as Styled from "./PaymentElementLocal.styled";
import { StripeError } from "@stripe/stripe-js";

export const PaymentElementLocal: React.FC<{
    clientSecret: string;
    finishProcessing: (error: StripeError | undefined) => void;
}> = ({ clientSecret, finishProcessing }) => {
    const [loading, setLoading] = React.useState(false);
    const stripe = useStripe();
    const elements = useElements();

    const onPaymentSubmit = async () => {
        setLoading(true);
        if (!stripe || !elements) {
            return;
        }
        const { error: submitError } = await elements.submit();

        if (submitError) {
            finishProcessing(submitError);
            return;
        }

        const { error } = await stripe.confirmPayment({
            elements,
            clientSecret: clientSecret,
            confirmParams: {
                return_url: `https://www.cemboo.com/login`,
            },
            redirect: "if_required",
        });

        if (error) {
            setLoading(false);
            finishProcessing(error);
            return;
        }
        finishProcessing(undefined);
    };

    return (
        <Styled.RegistrationForm>
            <Styled.RegisterFormRow>
                <Col md={6}>
                    <PaymentElement
                        options={{
                            layout: {
                                type: "accordion",
                                defaultCollapsed: false,
                                radios: true,
                                spacedAccordionItems: true,
                            },
                        }}
                    />
                </Col>
            </Styled.RegisterFormRow>
            <Styled.RegisterFormRow>
                <Col md={6}>
                    <BaseButton
                        type="button"
                        variant={"primary"}
                        onClick={onPaymentSubmit}
                        disabled={loading}
                    >
                        {loading ? (
                            <>
                                <Spinner size="sm" /> Processing
                            </>
                        ) : (
                            "Continue"
                        )}
                    </BaseButton>
                </Col>
            </Styled.RegisterFormRow>
        </Styled.RegistrationForm>
    );
};
