import React from "react";
import { v4 as uuid } from "uuid";

import {
    FileWithPreview,
    IUploadCategory,
    PublishStatus,
    IFilmFestivalCastDetails,
    IFilmFestivalDetails,
    IFilmFestivalLanguageDetails,
    IFilmFestivalSponsorsDetails,
} from "../../../../libs";
import { ContentTypes } from "../../types";
import { UploadPreview } from "../upload-preview";
import { CastDetails } from "./cast";
import { DetailsSection } from "./details";
import { LanguageDetails } from "./language-and-cc";
import { SponsorDetails } from "./sponsors";
import { VisibilityDetails } from "./visibility";

import * as Styled from "./FilmFestivalModalBody.styled";
import { useGlobalState } from "../../../../behavioral";

interface IFilmFestivalModalBodyProps {
    contentType?: ContentTypes;
    uploadedFile: FileWithPreview;
    onContinueToNextSection: () => void;
    clientId: string;
    onUploadComplete: () => void;
}

export const FilmFestivalModalBody: React.FC<IFilmFestivalModalBodyProps> = ({
    contentType = ContentTypes.UPLOAD_DETAILS,
    uploadedFile,
    clientId,
    onUploadComplete,
    onContinueToNextSection,
}) => {
    // Film Festival each section information
    const [uploadId, setUploadId] = React.useState("");
    const [details, setDetails] = React.useState<IFilmFestivalDetails>();
    const [languageDetails, setLanguageDetails] =
        React.useState<IFilmFestivalLanguageDetails>();
    const [castDetails, setCastDetails] =
        React.useState<IFilmFestivalCastDetails[]>();
    const [sponsorDetails, setSponsorDetails] =
        React.useState<IFilmFestivalSponsorsDetails[]>();
    const [visibilityStatus, setVisibilityStatus] =
        React.useState<PublishStatus>(PublishStatus.DRAFT);

    const [filesToUpload, setFilesToUpload] = React.useState<
        {
            category: string;
            files: File[];
        }[]
    >([]);

    const { state, dispatch, actions } = useGlobalState();

    React.useEffect(() => {
        if (uploadedFile) {
            const existingVideoFileIndex = filesToUpload.findIndex(
                (elem) => elem.category === "video",
            );
            const newVideo = {
                category: "video",
                files: [uploadedFile.file],
            };

            if (existingVideoFileIndex >= 0) {
                filesToUpload.splice(existingVideoFileIndex, 1, newVideo);
                setFilesToUpload([...filesToUpload]);
            } else {
                setFilesToUpload([...filesToUpload, newVideo]);
            }
        }
        setUploadId(uuid());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDetailsSubmit = React.useCallback(
        (details: IFilmFestivalDetails) => {
            setDetails(details);
            // Add any static assets here to list of files to upload
            if (details.thumbnails) {
                const thumbnailFile = details.thumbnails?.file;
                const newThumbnail = {
                    category: "thumbnails",
                    files: [thumbnailFile],
                };
                const existingThumbnailFileIndex = filesToUpload.findIndex(
                    (elem) => elem.category === "thumbnails",
                );
                if (existingThumbnailFileIndex >= 0) {
                    filesToUpload.splice(
                        existingThumbnailFileIndex,
                        1,
                        newThumbnail,
                    );
                    setFilesToUpload([...filesToUpload]);
                } else {
                    setFilesToUpload([...filesToUpload, newThumbnail]);
                }
            }
            onContinueToNextSection();
        },
        [onContinueToNextSection, filesToUpload],
    );

    const handleLanguageDetailsSubmit = React.useCallback(
        (languageDetails: IFilmFestivalLanguageDetails) => {
            setLanguageDetails(languageDetails);
            if (languageDetails.subtitles) {
                var subtitleFiles = languageDetails.subtitles
                    .filter((item) => item && item.file)
                    .map((item) => item.file)
                    .map((el) => el?.file) as File[];

                subtitleFiles = subtitleFiles.filter((el) => el !== undefined);

                const newSubtitles = {
                    category: "subtitles",
                    files: subtitleFiles,
                };
                const existingSubtitleFileIndex = filesToUpload.findIndex(
                    (elem) => elem.category === "subtitles",
                );
                if (existingSubtitleFileIndex >= 0) {
                    filesToUpload.splice(
                        existingSubtitleFileIndex,
                        1,
                        newSubtitles,
                    );
                    setFilesToUpload([...filesToUpload]);
                } else {
                    setFilesToUpload([...filesToUpload, newSubtitles]);
                }
            }
            onContinueToNextSection();
        },
        [onContinueToNextSection, filesToUpload],
    );

    const handleCastDetailsSubmit = React.useCallback(
        (castDetails: IFilmFestivalCastDetails[]) => {
            setCastDetails(castDetails);
            if (castDetails) {
                const castFiles = castDetails
                    .map((item) => item.file)
                    .map((el) => el?.file);
                let validCastFiles: File[] = [];
                castFiles.forEach((el) => {
                    if (el) {
                        validCastFiles.push(el);
                    }
                });
                if (castFiles) {
                    const existingCastFileIndex = filesToUpload.findIndex(
                        (elem) => elem.category === "cast",
                    );
                    const newCastFiles = {
                        category: "cast",
                        files: validCastFiles,
                    };
                    if (existingCastFileIndex >= 0) {
                        filesToUpload.splice(
                            existingCastFileIndex,
                            1,
                            newCastFiles,
                        );
                        setFilesToUpload([...filesToUpload]);
                    } else {
                        setFilesToUpload([...filesToUpload, newCastFiles]);
                    }
                }
            }
            onContinueToNextSection();
        },
        [onContinueToNextSection, filesToUpload],
    );

    const handleSponsorDetailsSubmit = React.useCallback(
        (sponsorDetails: IFilmFestivalSponsorsDetails[]) => {
            setSponsorDetails(sponsorDetails);
            if (sponsorDetails) {
                const sponsorLogos = sponsorDetails.map(
                    (sponsor) => sponsor.file.file,
                );
                const existingSponsorFileIndex = filesToUpload.findIndex(
                    (elem) => elem.category === "sponsors",
                );
                const newSponsorFiles = {
                    category: "sponsors",
                    files: sponsorLogos,
                };
                if (existingSponsorFileIndex >= 0) {
                    filesToUpload.splice(
                        existingSponsorFileIndex,
                        1,
                        newSponsorFiles,
                    );
                    setFilesToUpload([...filesToUpload]);
                } else {
                    setFilesToUpload([...filesToUpload, newSponsorFiles]);
                }
            }
            onContinueToNextSection();
        },
        [onContinueToNextSection, filesToUpload],
    );

    const handleVisibilityDetailsSubmit = React.useCallback(
        (status: PublishStatus) => {
            setVisibilityStatus(status);
            const totalUploadsCount = filesToUpload.reduce((count, obj) => {
                count += obj.files.length;
                return count;
            }, 0);
            console.log("Total upload files count: ", totalUploadsCount);
            // Start uploading all files
            const mediaId = uuid();

            dispatch({
                type: actions.SET_ONGOING_UPLOADS,
                payload: {
                    type: IUploadCategory.FILM_FESTIVAL,
                    uploadId: uploadId,
                    progress: 0,
                    mediaId: mediaId,
                    done: false,
                    abort: false,
                    formDataFilmFestival: {
                        details: details,
                        languageDetails: languageDetails,
                        castDetails: castDetails,
                        sponsorDetails: sponsorDetails,
                        visibilityStatus: status,
                    },
                    filesToUpload: filesToUpload,
                },
            });
        },
        [
            filesToUpload,
            dispatch,
            actions,
            uploadId,
            details,
            languageDetails,
            castDetails,
            sponsorDetails,
        ],
    );

    const body: { [key in ContentTypes]: React.ReactNode } =
        React.useMemo(() => {
            return {
                [ContentTypes.UPLOAD_DETAILS]: (
                    <DetailsSection
                        onDetailsSubmit={handleDetailsSubmit}
                        details={details}
                    />
                ),
                [ContentTypes.UPLOAD_LANGUAGE_CC]: (
                    <LanguageDetails
                        onLanguageDetailsSubmit={handleLanguageDetailsSubmit}
                        languageDetails={languageDetails}
                    />
                ),
                [ContentTypes.UPLOAD_CAST]: (
                    <CastDetails
                        onCastDetailsSubmit={handleCastDetailsSubmit}
                        castDetails={castDetails}
                    />
                ),
                [ContentTypes.UPLOAD_SPONSORS]: (
                    <SponsorDetails
                        onSponsorsDetailsSubmit={handleSponsorDetailsSubmit}
                        sponsorDetails={sponsorDetails}
                    />
                ),
                [ContentTypes.UPLOAD_VISIBILITY]: (
                    <VisibilityDetails
                        visibilityStatus={visibilityStatus}
                        percentage={
                            state.ongoingUploads.find(
                                (upload) => upload.uploadId === uploadId,
                            )?.progress || 0
                        }
                        onVisibilityDetailSubmit={handleVisibilityDetailsSubmit}
                    />
                ),
            };
        }, [
            castDetails,
            details,
            handleCastDetailsSubmit,
            handleDetailsSubmit,
            handleLanguageDetailsSubmit,
            handleSponsorDetailsSubmit,
            handleVisibilityDetailsSubmit,
            languageDetails,
            sponsorDetails,
            visibilityStatus,
            state.ongoingUploads,
            uploadId,
        ]);

    React.useEffect(() => {
        const currentUpload = state.ongoingUploads.find(
            (upload) => upload.uploadId === uploadId,
        );
        if (currentUpload?.done || currentUpload?.abort) {
            onUploadComplete();
        }
    }, [state.ongoingUploads, onUploadComplete, uploadId]);

    return (
        <Styled.UploadBodyWrapper>
            <Styled.UploadBodyWrapperRow>
                <Styled.UploadFormWrapper lg={4}>
                    {body[contentType]}
                </Styled.UploadFormWrapper>
                <Styled.UploadPreviewWrapper lg={8}>
                    <UploadPreview uploadedFile={uploadedFile} />
                </Styled.UploadPreviewWrapper>
            </Styled.UploadBodyWrapperRow>
        </Styled.UploadBodyWrapper>
    );
};
