import styled, { css } from "styled-components";
import { colors, types } from "../../../libs";
import { Modal, ProgressBar } from "react-bootstrap";
import { CameraReels, XCircleFill } from "react-bootstrap-icons";
import { CheckLg, XLg } from "react-bootstrap-icons";

export const UploadProgressModal = styled(Modal)`
    .modal-width {
        max-width: 35%;
    }
    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
`;

export const UploadProgressModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    background-color: ${colors.background.lightBlack};
    overflow-y: auto;
`;

export const ModalTitle = styled.div`
    ${types.body.secondaryTitle}
    color: ${colors.text.white};
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const UploadProgressBar = styled(ProgressBar)<any>`
    ${({ variant }) => variant === "primary" && css``}
    height: 5px;
`;

export const ItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 10px 0px 10px;
`;

export const ItemBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 10px;
`;

export const CameraReelsIcon = styled(CameraReels)`
    color: ${colors.text.white};
    padding: 5px;
    width: 2rem;
    height: 2rem;
`;

export const ItemTitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    align-items: center;
`;

export const ItemTitle = styled.div`
    ${types.body.label}
    color: ${colors.text.white};
    font-weight: bold;
`;

export const ItemProgress = styled.div`
    ${types.body.label}
    color: ${colors.text.white};
`;

export const CloseIcon = styled(XCircleFill)<{ disabled?: boolean }>`
    color: ${(props) =>
        props.disabled ? colors.text.disabled : colors.text.error};
    cursor: pointer;
    width: 1.3rem;
    height: 1.3rem;
`;

export const CheckIcon = styled(CheckLg)`
    color: ${colors.text.green};
    width: 1.3rem;
    height: 1.3rem;
`;

export const AbortIcon = styled(XLg)`
    color: ${colors.text.error};
    width: 1.3rem;
    height: 1.3rem;
`;
