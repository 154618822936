import React from "react";
import { IAdBannerType } from "../../../libs";
import { AdServerItem } from "./ad-server-item";

export const AdServerItems: React.FC = () => {
    return (
        <>
            <AdServerItem 
                bannerType={IAdBannerType.AD_HORIZONTAL_BANNER}
                bannerIndex={1}
                bannerWidth={1366}
                bannerHeight={448}
                bannerTitle="Horizontal Banner (1366 x 448px)"
            />
            <AdServerItem 
                bannerType={IAdBannerType.AD_SMALL_BANNER}
                bannerIndex={2}
                bannerWidth={528}
                bannerHeight={448}
                bannerTitle="Small Banner (528 x 448px)"
            />
            <AdServerItem 
                bannerType={IAdBannerType.AD_BACKGROUND_BANNER}
                bannerIndex={2}
                bannerWidth={1366}
                bannerHeight={689}
                bannerTitle="Background Banner (1366 x 689px)"
            />
        </>
    );
};
