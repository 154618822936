import * as React from "react";
import { Card } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { ActionMenu, ConfirmModal, IPackage } from "../../../../libs";

import * as Styled from "./PackagesList.styled";

const PackageCard: React.FC<{
  packageItem: IPackage;
  onEditPackageClick: (packageItem: IPackage) => void;
  onDeletePackageClick: (packageId: string) => void;
}> = ({ packageItem, onEditPackageClick, onDeletePackageClick }) => {
  const handleEditPackage = React.useCallback(() => {
    onEditPackageClick(packageItem);
  }, [onEditPackageClick, packageItem]);

  const handleDeletePackage = React.useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            title="Delete Package Confirmation"
            description={[
              {
                text: "Are you sure, you want to delete this package?",
                bold: false,
              },
              {
                text: "This operation is permanent and cannot be reverted.",
                bold: true,
              },
            ]}
            noText="No"
            yesText="Yes, Delete it!"
            onNo={onClose}
            onYes={() => {
              onDeletePackageClick(packageItem.id);
              onClose();
            }}
          ></ConfirmModal>
        );
      },
      closeOnEscape: true,
      closeOnClickOutside: false,
      keyCodeForClose: [8, 32],
    });
  }, [onDeletePackageClick, packageItem.id]);

  return (
    <Styled.PackageCard text="light">
      <Card.Body>
        <Styled.CardTitle>
          {packageItem.name}{" "}
          <ActionMenu
            menu={{
              edit: {
                label: "Edit",
                onClickHandler: () => handleEditPackage(),
              },
              delete: {
                label: "Delete",
                onClickHandler: () => handleDeletePackage(),
              },
            }}
          />
        </Styled.CardTitle>

        <Styled.PriceLabel className="price">
          {+packageItem.monthlyPrice > 0 ? (
            <>
              ${packageItem.monthlyPrice}
              <span>/month</span>
            </>
          ) : (
            "Custom"
          )}
        </Styled.PriceLabel>

        <Card.Text>
          {packageItem.description}
          <Styled.FeaturesList>
            {packageItem.features.map((feature) => (
              <Styled.FeatureListItem key={feature}>
                {feature}
              </Styled.FeatureListItem>
            ))}
          </Styled.FeaturesList>
        </Card.Text>
      </Card.Body>
    </Styled.PackageCard>
  );
};

export const PackagesList: React.FC<{
  packages: IPackage[];
  onCreateNewPackageClick: () => void;
  onEditPackageClick: (packageItem: IPackage) => void;
  onDeletePackageClick: (packageId: string) => void;
}> = ({
  packages,
  onCreateNewPackageClick,
  onEditPackageClick,
  onDeletePackageClick,
}) => {
  return (
    <Styled.PackagesCardGroup>
      {packages.map((item) => (
        <PackageCard
          key={item.id}
          packageItem={item}
          onEditPackageClick={onEditPackageClick}
          onDeletePackageClick={onDeletePackageClick}
        />
      ))}

      {/* Add Card */}

      <Styled.AddPackageCard>
        <Styled.AddIconWrapper onClick={onCreateNewPackageClick}>
          <Styled.AddIconText> + </Styled.AddIconText>
        </Styled.AddIconWrapper>
      </Styled.AddPackageCard>
    </Styled.PackagesCardGroup>
  );
};
