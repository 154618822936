import { useFormik } from "formik";
import * as React from "react";
import { Alert, Col, Form, Spinner } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";

import {
  BaseButton,
  Error,
  FloatingTextInput,
  IPackageRequest,
  TextAreaInput,
} from "../../../../libs";

import * as Styled from "./PackageForm.styled";

interface IProps {
  initialValues: IPackageRequest;
  onCancel: () => void;
  onSubmit: (
    data: IPackageRequest,
    setSubmitting: (result: boolean) => void
  ) => void;
  errorMessage?: string;
}

export const PackageForm: React.FC<IProps> = ({
  initialValues,
  onSubmit,
  onCancel,
  errorMessage,
}) => {
  const [features, setFeatures] = React.useState(
    initialValues.features.join(",")
  );
  const [addAnnualPriceFlag, setAddAnnualPriceFlag] = React.useState(false);
  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter a package name"),
      monthlyPrice: Yup.number().required(
        "Please enter the monthly price for the package"
      ),
      annualPrice: Yup.number().typeError(
        "Please enter valid annual price for the package"
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      onSubmit(
        {
          ...values,
          monthlyPrice: +values.monthlyPrice,
          features: features.split(","),
        },
        setSubmitting
      );
    },
  });

  const onFeaturesChange = React.useCallback((e: any) => {
    setFeatures(e.target.value);
  }, []);

  return (
    <Styled.PackageForm onSubmit={formik.handleSubmit}>
      {errorMessage && <Alert title={errorMessage} />}
      <Styled.PackageFormFieldRow>
        <Col>
          <FloatingTextInput
            label="Package Name"
            type="text"
            placeholder="Package Name"
            controlId="name"
            value={formik.values.name}
            onChangeHandler={formik.handleChange}
            onBlurHandler={formik.handleBlur}
            hasError={formik.touched.name && !!formik.errors.name}
          />
          {formik.touched.name && formik.errors.name && (
            <Error message={formik.errors.name} />
          )}
        </Col>
      </Styled.PackageFormFieldRow>

      <Styled.PackageFormFieldRow>
        <Col>
          <FloatingTextInput
            label="Package Description"
            type="text"
            placeholder="Package Description"
            controlId="description"
            value={formik.values.description}
            onChangeHandler={formik.handleChange}
            onBlurHandler={formik.handleBlur}
            hasError={formik.touched.description && !!formik.errors.description}
          />
          {formik.touched.name && formik.errors.description && (
            <Error message={formik.errors.description} />
          )}
        </Col>
      </Styled.PackageFormFieldRow>

      <Styled.PackageFormFieldRow>
        <Col>
          <FloatingTextInput
            label="Price Per Month"
            type="text"
            placeholder="Price per month"
            controlId="monthlyPrice"
            value={formik.values.monthlyPrice}
            onChangeHandler={formik.handleChange}
            onBlurHandler={formik.handleBlur}
            hasError={
              formik.touched.monthlyPrice && !!formik.errors.monthlyPrice
            }
          />
          {formik.touched.name && formik.errors.monthlyPrice && (
            <Error message={formik.errors.monthlyPrice} />
          )}
        </Col>
      </Styled.PackageFormFieldRow>
      <Styled.PackageFormFieldRow>
        <Col>
          <Form.Label>Add Annual Price</Form.Label>
          <Styled.PackageFormSwitch
            type="switch"
            id="annual-price-control-switch"
            checked={formik.values.annualPrice}
            onChange={(event: any) => {
              setAddAnnualPriceFlag(!addAnnualPriceFlag);
              if (!event.target.checked) {
                formik.setFieldValue("annualPrice", undefined);
              }
            }}
          />
          <p>
            <Form.Text id="annual-price-help-block" muted>
              Please turn on this to add a different annual price.
            </Form.Text>
          </p>
        </Col>
      </Styled.PackageFormFieldRow>

      {addAnnualPriceFlag && (
        <Styled.PackageFormFieldRow>
          <Col>
            <FloatingTextInput
              label="Price Per Year"
              type="text"
              placeholder="Price per year"
              controlId="monthlyPrice"
              value={formik.values.annualPrice}
              onChangeHandler={formik.handleChange}
              onBlurHandler={formik.handleBlur}
              hasError={
                formik.touched.annualPrice && !!formik.errors.annualPrice
              }
            />
            {formik.touched.annualPrice && formik.errors.annualPrice && (
              <Error message={formik.errors.annualPrice} />
            )}
          </Col>
        </Styled.PackageFormFieldRow>
      )}

      <Styled.PackageFormFieldRow>
        <Col>
          <TextAreaInput
            fieldName="features"
            fieldLabel="Features (use comma to separate values)"
            placeholder="Add features offered with the package"
            fieldValue={features}
            onChangeHandler={onFeaturesChange}
          />
        </Col>
      </Styled.PackageFormFieldRow>

      <Styled.PackageFormFieldRow>
        <Col>
          <BaseButton variant="secondary" onClick={onCancel}>
            Cancel
          </BaseButton>
        </Col>
        <Col>
          <BaseButton type="submit">
            Save
            {"  "}
            {formik.isSubmitting && <Spinner size="sm" />}
          </BaseButton>
        </Col>
      </Styled.PackageFormFieldRow>
    </Styled.PackageForm>
  );
};
