import React from "react";
import { FullScreenModal, IPlaylistResponseDto } from "../../../libs";
import { CreateModalHeader } from "./create-modal-header";
import { CreateModalBody } from "./create-modal-body";
import { CreateModalSteps, CreateModalStepsState } from "./types";

interface IProps {
    show: boolean;
    onHide: () => void;
    playlistName: string;
    playlist?: IPlaylistResponseDto;
    editStep?: CreateModalSteps;
}

export const CreateModal: React.FC<IProps> = ({
    show,
    onHide,
    playlistName,
    playlist,
    editStep,
}) => {
    const [steps, setSteps] = React.useState<CreateModalStepsState[]>([]);

    const handleStepClick = React.useCallback(
        (step: CreateModalSteps) => {
            const newSteps = steps.map((stepState) => {
                if (stepState.step === step) {
                    return {
                        ...stepState,
                        inprogress: true,
                    };
                }
                return {
                    ...stepState,
                    inprogress: false,
                };
            });
            setSteps(newSteps);
        },
        [steps],
    );

    React.useEffect(() => {
        console.log("Setting default steps");
        const defaultSteps: CreateModalStepsState[] = [
            {
                step: CreateModalSteps.VIDEO_SELECTION,
                title: "Video Selection",
                inprogress: false,
                completed: false,
                disabled: false,
            },
            {
                step: CreateModalSteps.ORDER_SELECTION,
                title: "Order",
                inprogress: false,
                completed: false,
                disabled: false,
            },
            {
                step: CreateModalSteps.DETAILS,
                title: "Details",
                inprogress: false,
                completed: false,
                disabled: false,
            },
        ];
        if (editStep !== undefined) {
            defaultSteps.find((step) => step.step === editStep)!.inprogress =
                true;
        } else {
            defaultSteps[0].inprogress = true;
        }
        setSteps(defaultSteps);
        console.log(editStep);
    }, [setSteps, show, editStep]);

    const shouldClose = React.useRef<() => void>(() => {});
    const handleOnClose = React.useCallback(() => {
        if (editStep !== undefined) {
            onHide();
        } else {
            shouldClose.current();
        }
    }, [shouldClose, onHide, editStep]);

    return (
        <FullScreenModal
            show={show}
            onHide={handleOnClose}
            header={
                <CreateModalHeader
                    steps={steps}
                    onStepClick={handleStepClick}
                    editMode={editStep !== undefined}
                />
            }
            body={
                <CreateModalBody
                    steps={steps}
                    playlistName={playlistName}
                    shouldClose={shouldClose}
                    hideCallback={onHide}
                    handleNextStep={handleStepClick}
                    editStep={editStep}
                    playlist={playlist}
                />
            }
            children={undefined}
            zIndex={editStep !== undefined ? 2000 : undefined}
        />
    );
};
