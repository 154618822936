import * as React from "react";
import { PackageInfo, Registration } from "../../components";
import {
    HeaderLogo,
    IPackage,
    Loader,
    ROUTEPATHS,
    RegistrationStatus,
} from "../../libs";

import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../behavioral";
import { adminService } from "../../services";
import * as Styled from "./register-page.styled";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? "");

export const RegistrationPage: React.FC = () => {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();

    const { search } = useLocation();
    const ref = new URLSearchParams(search).get("ref");

    const [packageInfo, setPackageInfo] = React.useState<
        IPackage | undefined
    >();
    const [showLoader, setShowLoader] = React.useState(true);
    const [options, setOptions] = React.useState<
        StripeElementsOptions | undefined
    >({
        mode: "subscription",
        amount: 9999,
        currency: "usd",
        appearance: {
            theme: "night",
            variables: {
                fontFamily: "Open Sans, system-ui, sans-serif",
                borderRadius: "0.375rem",
                colorBackground: "#1E2025",
                colorText: "#FFFFFF",
            },
            labels: "floating",
            rules: {
                ".Input": {
                    boxShadow: "none",
                    border: "none",
                    height: "75px",
                },
                ".Input:focus": {
                    outline: "none",
                    boxShadow: "none",
                },
            },
        },
    });

    // Decode query param
    let decoded: any;
    if (ref) {
        try {
            decoded = JSON.parse(window.atob(ref));
        } catch (err) {
            console.log("Unable to parse the search string", err);
        }
    }

    const getSelectedPackage = React.useCallback(async (packageId: string) => {
        const response = await adminService.getPackageById(packageId);
        setPackageInfo(response);
        setShowLoader(false);
    }, []);

    React.useEffect(() => {
        if (decoded?.packageId) {
            getSelectedPackage(decoded.packageId);
        }
    }, [getSelectedPackage, decoded?.packageId]);

    React.useEffect(() => {
        if (packageInfo) {
            setOptions((prev) => ({
                ...prev,
                amount: +packageInfo.monthlyPrice * 100,
            }));
        }
    }, [packageInfo]);

    React.useEffect(() => {
        if (isAuthenticated && decoded?.redirect) {
            navigate("/dashboard", { replace: true });
        }
    }, [isAuthenticated, navigate, decoded?.redirect]);

    if (!ref || (!showLoader && !packageInfo)) {
        return <Navigate to={`${ROUTEPATHS.PRICING}`} replace={true} />;
    }

    if (showLoader) {
        return <Loader />;
    }

    return (
        <Styled.RegistrationPageContainer>
            <Styled.RegistrationPageRow>
                <Styled.PackageInfoCol md={5}>
                    <Styled.RegistrationHeader>
                        <HeaderLogo />
                    </Styled.RegistrationHeader>
                    <PackageInfo selectedPackage={packageInfo!} />
                </Styled.PackageInfoCol>
                <Styled.RegistrationCol md={7}>
                    <Elements stripe={stripe} options={options}>
                        <Registration
                            priceId={
                                packageInfo?.stripeConfig.prices.monthlyPriceId!
                            }
                            packageId={packageInfo?.id!}
                            userId={decoded?.userId}
                            name={decoded?.name}
                            email={decoded?.email}
                            status={
                                decoded?.userId
                                    ? RegistrationStatus.BILLING_INFO
                                    : RegistrationStatus.ACCOUNT_INFO
                            }
                        />
                    </Elements>
                </Styled.RegistrationCol>
            </Styled.RegistrationPageRow>
        </Styled.RegistrationPageContainer>
    );
};
