import {
  Linkedin,
  TwitterX,
  Facebook,
  Instagram,
  EnvelopeFill
} from 'react-bootstrap-icons';
import * as Styled from "./mobile-menu.styled";

import Logo from "./../../../assets/images/logos/logo.png";

export interface IMobileMenuProps {
  show: boolean;
  closeHandler: () => void;
}

export const MobileMenu: React.FC<IMobileMenuProps> = ({
  show,
  closeHandler,
}) => {
  const closeModal = () => {
    closeHandler();
  };
  return (
    <Styled.MobieMenuWrapper show={show}>
      <Styled.MobileMenuContent>
        <div style={{ position: 'relative', marginBottom: '1.5rem' }}>
          <Styled.LogoLink href="/">
            <Styled.LogoImage src={Logo} />
          </Styled.LogoLink>
          <Styled.MobileMenuCloseIcon
            className="fas fa-times"
            onClick={closeModal} 
          />
        </div>
        <Styled.MobileMenuLinksWrapper>
          <Styled.MobileMenuLinkItem>
            <Styled.MobileMenuLink
              to="/about"
              activeclassname="active"
              exact="true"
              onClick={closeModal}
            >
              About
            </Styled.MobileMenuLink>
          </Styled.MobileMenuLinkItem>
          <Styled.MobileMenuLinkItem>
            <Styled.MobileMenuLink
              to="/pricing"
              activeclassname="active"
              exact="true"
              onClick={closeModal}
            >
              Pricing
            </Styled.MobileMenuLink>
          </Styled.MobileMenuLinkItem>
          <Styled.MobileMenuLinkItem>
            <Styled.MobileMenuLink
              to="/roadmap"
              activeclassname="active"
              exact="true"
              onClick={closeModal}
            >
              Roadmap
            </Styled.MobileMenuLink>
          </Styled.MobileMenuLinkItem>
          <Styled.MobileMenuLinkItem>
            <Styled.MobileMenuLink
              to="/blockchain"
              activeclassname="active"
              exact="true"
              onClick={closeModal}
            >
              Blockchain
            </Styled.MobileMenuLink>
          </Styled.MobileMenuLinkItem>
          <Styled.MobileMenuLinkItem>
            <Styled.MobileMenuLink
              to="/team"
              activeclassname="active"
              exact="true"
              onClick={closeModal}
            >
              Team
            </Styled.MobileMenuLink>
          </Styled.MobileMenuLinkItem>
          <Styled.MobileMenuLinkItem>
            <Styled.MobileMenuLink
              to="/contact"
              activeclassname="active"
              exact="true"
              onClick={closeModal}
            >
              Contact Us
            </Styled.MobileMenuLink>
          </Styled.MobileMenuLinkItem>

          <hr style={{ margin: '0.5rem 0' }} />

          <Styled.MobileMenuLinkItem>
            <Styled.MobileMenuLink
              to="/login"
              activeclassname="active"
              exact="true"
              onClick={closeModal}
            >
              Login
            </Styled.MobileMenuLink>
          </Styled.MobileMenuLinkItem>
          <Styled.MobileMenuLinkItem>
            <Styled.MobileMenuLink
              to="/pricing"
              activeclassname="active"
              exact="true"
              onClick={closeModal}
            >
              Get Started
            </Styled.MobileMenuLink>
          </Styled.MobileMenuLinkItem>
        
          <hr style={{ margin: '0.5rem 0' }} />
          
          <Styled.MobileMenuLinkItem>
            <ul className="social-links justify-content-center justify-content-md-end" style={{ paddingLeft: '20px' }}>
              <li>
                  <a
                      href="https://www.linkedin.com/company/cemboo360"
                      target="_blank"
                      title="Cemboo on LinkedIn"><Linkedin /></a>
              </li>
              <li>
                  <a
                      href="https://twitter.com/Cemboo360"
                      target="_blank"
                      title="Cemboo on X"><TwitterX /></a>
              </li>
              <li>
                  <a
                      href="https://www.facebook.com/cemboo360/"
                      target="_blank"
                      title="Cemboo on Facebook"><Facebook /></a>
              </li>
              <li>
                  <a
                      href="https://www.instagram.com/cemboo360/"
                      target="_blank"
                      title="Cemboo on Instagram"><Instagram /></a>
              </li>
              <li>
                  <a
                      href="mailto:support@cemboo.com"
                      target="_blank"
                      title="Support @ Cemboo"><EnvelopeFill /></a>
              </li>
          </ul>
          </Styled.MobileMenuLinkItem>
        </Styled.MobileMenuLinksWrapper>
      </Styled.MobileMenuContent>
    </Styled.MobieMenuWrapper>
  );
};
