import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors, types } from "../../../libs";

export const Subtitle = styled.h3`
  ${types.body.title};
  background: ${colors.text.textGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const DetailRow = styled(Row)`
  margin-top: 20px;
`;

export const CreateButtonRow = styled(Row)`
  margin-top: 20px;
`;

export const PlanDetailsCard = styled.div`
  height: 120px;
  background: #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 10px;
  padding: 20px;
  color: #111;
  position: relative;
  margin-bottom: 20px;

  &.active {
    background: #faf8cd;
  }
`;

export const PlanPrice = styled.p`
  ${types.headers.small}
  margin: 5px 0;
`;

export const HeaderRow = styled(Row)`
  margin: 20px 0;
  padding: 20px 0;
  border-bottom: 2px solid ${colors.text.lightWhite};
  border-top: 2px solid ${colors.text.lightWhite};
`;

export const ListHeaderCol = styled(Col)`
  ${types.headers.small};
`;

export const PlanDescription = styled.p`
  ${types.body.small}
  position: absolute;
  text-align: center;
  bottom: 10px;
`;

export const DataRow = styled(Row)`
  padding: 15px 0;
  margin: 10px 0;
  border-bottom: 2px solid ${colors.text.lightWhite};
  color: ${colors.text.mediumWhite};
`;

export const EditIcon = styled.i`
  font-size: 18px;
  margin-right: 10px;
  cursor: pointer;
`;

export const DeleteIcon = styled.i`
  font-size: 18px;
  margin-right: 10px;
  cursor: pointer;
`;

export const CustomLink = styled(Link)`
  color: ${colors.text.green};
  &:hover {
    text-decoration: underline;
  }
`;

export const PlanCard = styled(Card)`
  margin: 20px 0;
  background: ${colors.background.black};
  padding: 10px 20px;
  border: 1px solid #ccc;

  &.disabled {
    background: ${colors.background.disabled};
  }
`;

export const PlanDetailRow = styled(Row)`
  align-items: flex-start;
`;

export const TitleRow = styled(Row)`
  margin-bottom: 10px;
`;

export const Title = styled.h3`
  ${types.body.secondaryTitle};
  background: ${colors.text.textGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
`;

export const Description = styled(Row)`
  margin-bottom: 10px;
`;

export const EditLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
  color: ${colors.text.green};
  display: inline-block;
  margin-top: 10px;
`;

export const AccessDetail = styled.p`
  & > span {
    color: #dc3545;
  }
  & > span.public {
    color: ${colors.text.green};
  }
`;
