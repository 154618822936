import { FormikProps } from "formik";
import React from "react";
import { Form, Col } from "react-bootstrap";
import Select from "react-select";
import {
    PublishStatus,
    BaseButton,
    Error,
    RadioInput,
    TextAreaInput,
    TextInput,
    FileWithPreview,
    ICategoryType,
    IGeneralFormValues,
} from "../../../../libs";

import * as Styled from "./GeneralUploadForm.styled";
import { ThumbnailImagesSection } from "../../upload-modal-body";

interface IProps {
    formik: FormikProps<IGeneralFormValues>;
    handleThumbnailImageUpload: (thumbnailImage: FileWithPreview) => void;
    handleThumbnailDelete: () => void;
    percentage: number;
    categories: ICategoryType[];
    abortHandler?: () => void;
}

export const GeneralUploadForm: React.FC<IProps> = ({
    formik,
    handleThumbnailImageUpload,
    handleThumbnailDelete,
    percentage,
    categories,
    abortHandler,
}) => {
    const [formattedCategories, setFormattedCategories] = React.useState<
        { value: string; label: string }[]
    >([]);

    React.useEffect(() => {
        setFormattedCategories(
            categories.map((category) => ({
                value: category.categoryId,
                label: category.categoryType,
            })),
        );
    }, [categories]);

    return (
        <Form onSubmit={formik.handleSubmit}>
            <Styled.RequiredText>* indicates required</Styled.RequiredText>
            <Styled.FormFieldRow>
                <Col>
                    <TextInput
                        fieldName="title"
                        fieldLabel="Title *"
                        placeholder="Add a title for the video"
                        fieldValue={formik.values.title}
                        onChangeHandler={formik.handleChange}
                        onBlurHandler={formik.handleBlur}
                        hasError={formik.touched.title && !!formik.errors.title}
                    />
                    {formik.touched.title && formik.errors.title && (
                        <Error message={formik.errors.title} />
                    )}
                </Col>
            </Styled.FormFieldRow>
            <Styled.FormFieldRow>
                <Col>
                    <TextAreaInput
                        fieldName="description"
                        fieldLabel="Description"
                        placeholder="Tell viewers what the video is about"
                        fieldValue={formik.values.description}
                        onChangeHandler={formik.handleChange}
                        onBlurHandler={formik.handleBlur}
                    />
                </Col>
            </Styled.FormFieldRow>
            <Styled.ThumbnailSectionContainer>
                <Styled.FormFieldRow>
                    <Col>
                        <Styled.ThumbnailHeader>
                            Thumbnail Images
                        </Styled.ThumbnailHeader>
                    </Col>
                </Styled.FormFieldRow>
                <Styled.FormFieldRow>
                    <Styled.ThumbnailText>
                        Upload an image that reflects the content of the video.
                        A good thumbnail stands out and attracts the attention
                        of users.
                    </Styled.ThumbnailText>
                </Styled.FormFieldRow>
                <ThumbnailImagesSection
                    onThumbnailDelete={handleThumbnailDelete}
                    onThumbnailUpload={handleThumbnailImageUpload}
                />
            </Styled.ThumbnailSectionContainer>
            <Styled.FormFieldRow>
                <Col>
                    <Styled.ThumbnailHeader>Category *</Styled.ThumbnailHeader>
                </Col>
            </Styled.FormFieldRow>
            <Styled.FormFieldRow>
                <Col>
                    <Select
                        className="custom-select"
                        isMulti
                        styles={{
                            menu: (baseStyles, state) => ({
                                ...baseStyles,
                                background: "#1E2025",
                                color: "#fff",
                            }),
                            option: (baseStyles, state) => ({
                                ...baseStyles,
                                background: state.isFocused
                                    ? "#ccc"
                                    : "#1E2025",
                                color: state.isFocused ? "#1E2025" : "#fff",
                            }),
                            singleValue: (baseStyles, state) => ({
                                ...baseStyles,
                                color: "#fff",
                            }),
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: "50px",
                                background: "#1E2025",
                                color: "#fff",
                                border: formik.errors.categories
                                    ? "1px solid red"
                                    : state.isFocused
                                      ? "1px solid transparent"
                                      : "1px solid transparent",
                            }),
                            multiValueRemove: (baseStyles) => ({
                                ...baseStyles,
                                color: "#000",
                            }),
                        }}
                        placeholder="Select Category"
                        value={formattedCategories.filter((category) =>
                            formik.values.categories.includes(category.value),
                        )}
                        isClearable
                        options={formattedCategories}
                        onChange={(selectedOptions) => {
                            formik.setFieldValue(
                                "categories",
                                selectedOptions.map((option) => option.value),
                            );
                        }}
                    />
                </Col>
            </Styled.FormFieldRow>
            <Styled.FormFieldRow>
                <Col>
                    <Styled.ThumbnailHeader>Visibility</Styled.ThumbnailHeader>
                </Col>
            </Styled.FormFieldRow>
            <Styled.FormFieldRow>
                <Col>
                    <Styled.VisibilityOptionsWrapper
                        direction="vertical"
                        gap={3}
                    >
                        <RadioInput
                            fieldLabel="Draft (Save it for later)"
                            fieldName="status"
                            fieldId="DRAFT"
                            checked={
                                formik.values.status === PublishStatus.DRAFT
                            }
                            fieldValue={PublishStatus.DRAFT}
                            onChangeHandler={formik.handleChange}
                        />
                        <RadioInput
                            fieldValue={PublishStatus.PUBLISHED}
                            fieldLabel="Public (Anyone with the video link can watch you video)"
                            fieldName="status"
                            fieldId="PUBLIC"
                            checked={
                                formik.values.status === PublishStatus.PUBLISHED
                            }
                            onChangeHandler={formik.handleChange}
                        />
                        <RadioInput
                            fieldValue={PublishStatus.SCHEDULED}
                            fieldLabel="Schedule (Select a date to make your video public)"
                            fieldName="status"
                            fieldId="SCHEDULED"
                            checked={
                                formik.values.status === PublishStatus.SCHEDULED
                            }
                            onChangeHandler={formik.handleChange}
                        />
                    </Styled.VisibilityOptionsWrapper>
                </Col>
            </Styled.FormFieldRow>
            <Styled.ActionButtonsWrapper>
                {abortHandler && (
                    <BaseButton
                        type="button"
                        variant={formik.isSubmitting ? "error" : "secondary"}
                        onClick={() => {
                            abortHandler();
                        }}
                        disabled={!formik.isSubmitting}
                    >
                        Abort Upload
                    </BaseButton>
                )}
                <BaseButton
                    type="submit"
                    variant={!formik.isValid ? "secondary" : "primary"}
                    disabled={!formik.isValid}
                >
                    {formik.isSubmitting && (
                        <>
                            <Styled.LoadingSpinner
                                animation="border"
                                size="sm"
                                variant="primary"
                            />
                        </>
                    )}
                    {formik.isSubmitting
                        ? `${percentage}% Uploaded`
                        : percentage === 100
                          ? "Processing"
                          : "Submit"}
                </BaseButton>
            </Styled.ActionButtonsWrapper>
        </Form>
    );
};
