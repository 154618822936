import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import React, { useEffect, useState } from "react";

interface IProps {
  accountId: string;
  children: React.ReactNode;
}

export const StripeWrapper: React.FC<IProps> = ({ accountId, children }) => {
  const [stripeObject, setStripeObject] = useState<any | null>(null);

  // This function will re-run if the accountId prop changes.
  useEffect(() => {
    const fetchStripeObject = async () => {
      // If there is no accountId, do not run the loadStripe function.
      if (accountId) {
        const res = await loadStripe(
          process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? "",
          {
            stripeAccount: accountId,
          }
        );
        // When we have got the Stripe object, pass it into our useState.
        setStripeObject(res);
      }
    };
    fetchStripeObject();
  }, [accountId]);

  // If no Stripe object, do not render the Stripe Element.
  if (!stripeObject) {
    return <p>Loading...</p>;
  }

  // Once we have the Stripe object, load everything.
  return <Elements stripe={stripeObject}>{children}</Elements>;
};

export default StripeWrapper;
