import styled from "styled-components";
import { colors, types } from "../../../libs";

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80vh;
  color: ${colors.background.green};
  & > .spinner-border {
    width: 5rem;
    height: 5rem;
  }
`;

export const Title = styled.h1`
  ${types.headers.secondary};
  background: ${colors.text.textGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 10px;
  margin-top: 0;
`;
