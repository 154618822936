import { useState } from "react";
import { InnerContainer, InnerSection } from "../ui";
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import ChrisSaraceno from './../../assets/images/testimonials/chris-saraceno_to5.png';
import ChuckHolder from './../../assets/images/testimonials/chuck-holder_ec.png';
import HaydenCraddolph from './../../assets/images/testimonials/hayden-craddolph_hfi.png';
import JuanCastro from './../../assets/images/testimonials/juan-castro_aalive.png';
import MichaelMcCray from './../../assets/images/testimonials/michael-mccray_wsff.png';
import RodneyRiley from './../../assets/images/testimonials/rodney-riley_opake.png';

import AalLogo from './../../assets/images/branding/producers/aal_cemboo.png';
import EcLogo from './../../assets/images/branding/producers/ec_cemboo.png';
import HfiLogo from './../../assets/images/branding/producers/hfi_cemboo.png';
import OpakeLogo from './../../assets/images/branding/producers/opake_cemboo.png';
import To5Logo from './../../assets/images/branding/producers/to5_cemboo.png';
import WsffLogo from './../../assets/images/branding/producers/wsff_cemboo.png';

import IconDistribution from './../../assets/images/icons/icons-14.png';
import IconIntegration from './../../assets/images/icons/icons-13.png';
import IconPlatforms from './../../assets/images/icons/icons-11.png';
import IconMonetization from './../../assets/images/icons/icons-09.png';
import IconLiveEvents from './../../assets/images/icons/icons-12.png';
import IconBlockchain from './../../assets/images/icons/icons-10.png';

import IconCustomers from './../../assets/images/icons/icons-07a.png';

import EmpowerHeroImage from './empower-hero.svg';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import './landing.scss';

export const LandingContainer = () => {

    const [producers] = useState([
        {
            name: 'All Abilities Live',
            logo: AalLogo,
            link: null
        }, {
            name: 'Entrepreneurs Connection',
            logo: EcLogo,
            link: null
        }, {
            name: 'Haydenfilms Institute',
            logo: HfiLogo,
            link: null
        }, {
            name: 'O\'Pake Institute',
            logo: OpakeLogo,
            link: null
        }, {
            name: 'Theory of 5',
            logo: To5Logo,
            link: null
        }, {
            name: 'Whistleblower Summit Film Festival',
            logo: WsffLogo,
            link: null
        }
    ]);

    const [testimonials] = useState([
        {
            name: "Juan Castro",
            company: "All Abilities Live",
            position: "Founder",
            quote: "We went from streaming events with fear of not having the quality and consistency we promised our customers, to feeling 100% confident that the viewer experience would be&nbsp;seamless.",
            avatar: JuanCastro
        }, {
            name: "Chuck Holder",
            company: "Entrepreneurs Connection",
            position: "Founding Member",
            quote: "Entrepreneurs Connection's partnership with Cemboo has been great. Their skill in empowering content owners to manage, monetize, and dispense digital media enhanced our online presence. From website reorganization to tailored marketing strategies and a relevant video library, Cemboo delivers superb&nbsp;results.",
            avatar: ChuckHolder
        }, {
            name: "Chris Saraceno",
            company: "The Theory of 5",
            position: "Author",
            quote: "My thanks to the Cemboo team for making The Theory of 5 'Personal Growth Series' a reality. Cemboo allows a space where individuals can grow together. The team's devotion to quality matches our mission to empower and&nbsp;educate.",
            avatar: ChrisSaraceno
        }, {
            name: "Michael McCray",
            company: "Whistleblower Summit & Film&nbsp;Festival",
            position: "Managing Director",
            quote: "Whistleblowers sacrifice their lives or livelihoods for the benefit of others. Cemboo allows our festival the freedom to build/monetize our unique whistleblower content library. It’s distribution our&nbsp;way.",
            avatar: MichaelMcCray
        }, {
            name: "Rodney Ridley",
            company: "O'Pake Institute",
            position: "VP of Research, Economic Development and Innovation&nbsp;COO",
            quote: "Cemboo excels at bringing visions to life through compelling messaging, positioning itself as a leader in video content creation. By collaborating with Cemboo, both Alvernia University and the O'Pake Institute have experienced remarkable benefits, enhancing our audience reach and&nbsp;presence.",
            avatar: RodneyRiley
        }, {
            name: "Hayden Craddolph",
            company: "Haydenfilms Institute",
            position: "Executive Director",
            quote: "Thanks to Cemboo, HFI's festival is a beacon for innovation in film. Seamless, efficient, and engaging—Cemboo has set a new standard for us in the 21st century and&nbsp;beyond.",
            avatar: HaydenCraddolph
        }
    ]);

    const [platformPoints] = useState([
        {
            title: 'Distribution',
            description: 'Embed your unique libraries on your website, and tailor them to your website’s&nbsp;appearance.',
            icon: IconDistribution
        }, {
            title: 'Integration',
            description: 'Our integration feature allows for social network optimization. Cemboo will also allow content owners to create and maintain their own media&nbsp;channels.',
            icon: IconIntegration
        }, {
            title: 'Platforms',
            description: 'Cemboo is cross-platform, running on PC, mobile, and tablet devices which allows you to cater distribution your&nbsp;way!',
            icon: IconPlatforms
        }, {
            title: 'Monetization',
            description: 'Start and manage your own advertising campaigns, and generate traffic by keeping your content on your own website and generating 100% Ad revenue of&nbsp;it.',
            icon: IconMonetization
        }, {
            title: 'Live Events',
            description: 'Cemboo also offers live video streaming of events, including sports, ceremonies, and&nbsp;corporate.',
            icon: IconLiveEvents
        }, {
            title: 'Blockchain',
            description: 'Enhance content security and monetization with Cemboo\'s Web3 features, ensuring seamless transactions and robust intellectual property protection through smart&nbsp;contracts.',
            icon: IconBlockchain
        }
    ]);

    const handleOnSwiper = (swiper) => {
        //console.log('%conSwiper::', 'color:dodgerblue;', swiper);
    };

    const handleOnSlideChange = (swiper) => {
        console.log('%conSlideChange::', 'color:dodgerblue;', 'slide has changed');
    };

    return (
        <InnerSection id="landing" className="home section-bg" style={{ marginTop: '120px', overflow: 'hidden' }}>

            <InnerContainer className="page-hero" style={{ padding: '2rem' }}>

                <div className="meta mx-4">
                    <h1 className="gradient-text" data-aos="fade-up" data-aos-delay="50" data-aos-duration="800">
                        We Empower Content Owners Through&nbsp;Innovation.
                    </h1>
                    <h3 className="px-4 px-md-0" data-aos="zoom-in" data-aos-delay="100" data-aos-duration="750">
                        Control, monetize, and distribute your own digital media content through the values of quality, community,&nbsp;and&nbsp;innovation.
                    </h3>
                </div>

                <div className="empower-hero-media mx-2 mx-md-5 mx-xl-0 media">
                    <div className="empower-hero">
                        <img src={EmpowerHeroImage} alt="Empower Content Creators Media Devices" />
                    </div>
                </div>

            </InnerContainer>

            <div className="featured-producers">
                <h5 className="text-center mx-2 mb-5 px-5" data-aos="zoom-out-down" data-aos-duration="800">
                    Helping producers worldwide to reach&nbsp;further
                </h5>
                <div className="px-3 px-md-0">

                    <div className="mx-auto d-flex flex-wrap align-items-center justify-content-center" style={{ gap: '3rem', maxWidth: '760px' }}>

                        {producers.map((producer, idx) => (
                            <div
                                className="d-flex justify-content-center"
                                data-aos="zoom-in"
                                data-aos-duration="800"
                                data-aos-easing="ease-out-back"
                                key={idx}>
                                <img
                                    className="producer-logo"
                                    src={producer.logo}
                                    alt={producer.name} />
                            </div>
                        ))}

                    </div>

                </div>
            </div>

            <div className="consumer-testimonials">

                <div className="container mb-4">
                    <div className="row">
                        <div className="col-12">
                            <h3 className="mx-4 mx-md-0" data-aos="fade-up" data-aos-offset="100" data-aos-duration="600">
                                <img src={IconCustomers} alt="" /> What our customers&nbsp;say
                            </h3>
                        </div>
                    </div>
                </div>

                <div className="container-fluid px-0">
                    <div className="row">
                        <div className="col">

                            <div className="testimonial-items">
                                <Swiper
                                    modules={[Autoplay, Pagination]}
                                    autoplay={false}
                                    pagination={{ clickable: true }}
                                    centeredSlides={false}
                                    spaceBetween={16}
                                    slidesPerView={'auto'}
                                    slidesOffsetBefore={24}
                                    slidesOffsetAfter={24}
                                    loop={false}
                                    grabCursor={true}
                                    breakpoints={{ 640: { spaceBetween: 24 } }}
                                    onSwiper={handleOnSwiper}
                                    onSlideChange={handleOnSlideChange}
                                    className="testimonials">
                                    {testimonials.map((testament, idx) => (
                                        <SwiperSlide key={idx}>
                                            <div className="testimonial-card card">
                                                <div className="card-body d-flex justify-content-between flex-column">
                                                    <p className="quote card-text mb-5" 
                                                                dangerouslySetInnerHTML={{ __html: testament.quote }}></p>
                                                    <div className="d-flex flex-row flex-nowrap gap-3">
                                                        <div className="image">
                                                            <img
                                                                src={testament.avatar}
                                                                alt={testament.name}
                                                                width="56"
                                                                height="56"
                                                                className="rounded-circle img-thumbnail" />
                                                        </div>
                                                        <div className="meta">
                                                            <h6 className="company card-subtitle mb-1 text-uppercase" dangerouslySetInnerHTML={{ __html: testament.company }}></h6>
                                                            <h5 className="name card-title mb-2">{testament.name}</h5>
                                                            <h6 className="position card-subtitle mb-1 text-muted" 
                                                                dangerouslySetInnerHTML={{ __html: testament.position }}></h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="platform-points">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">

                                <h1
                                    className="gradient-text mb-5"
                                    data-aos="fade-up"
                                    data-aos-offset="200"
                                    data-aos-duration="800">The Easiest Way To Control, Monetize and Distribute.</h1>

                                <ul className="points-list">

                                    {platformPoints.map((point, idx) => (
                                        <li
                                            className="points-list--item"
                                            key={idx}>
                                            <div className="point-card card">
                                                <div className="card-body text-center">
                                                    <div
                                                        className="icon"
                                                        data-aos="zoom-in"
                                                        data-aos-duration="600">
                                                        <img
                                                            src={point.icon}
                                                            alt={point.title} />
                                                    </div>
                                                    <div className="meta">
                                                        <h5
                                                            className="card-title"
                                                            data-aos="fade-up"
                                                            data-aos-duration="300"
                                                            data-aos-delay="100">{point.title}</h5>
                                                        <p
                                                            className="card-text"
                                                            data-aos="fade"
                                                            data-aos-duration="300"
                                                            data-aos-delay="200" 
                                                            dangerouslySetInnerHTML={{ __html: point.description }}></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}

                                </ul>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </InnerSection>
    );
};

