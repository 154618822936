import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../libs";

export const PlayerWrapper = styled(Col)`
  position: relative;
`;

export const Player = styled.video`
  margin-top: 20px;
  width: 70%;
  border: 1px solid ${colors.text.mediumWhite};
  height: 500px;
  position: relative;
  cursor: pointer;

  &::-webkit-media-controls-timeline,
  &::-webkit-media-controls-current-time-display {
    display: none;
  }
`;

export const PlayBackUrlRow = styled(Row)`
  margin-top: 20px;
`;

export const IconWrapper = styled.div`
  position: absolute;
  height: 60px;
  width: 80px;
  border: 1px solid ${colors.text.mediumWhite};
  border-radius: 10px;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
  background: ${colors.background.darkGradient};
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PlayIcon = styled.i`
  font-size: 25px;
`;
