import { ModalProps } from "react-bootstrap";
import {
    BaseButton,
    Error,
    IPackage,
    IUser,
    SelectInput,
    Switch,
    TextAreaInput,
    TextInput,
} from "../../../../libs";
import * as Styled from "./UpdateManualPaymentModal.styled";
import { useFormik } from "formik";
import { adminService } from "../../../../services";
import React from "react";
import { toast } from "react-toastify";

interface IProps extends ModalProps {
    user: IUser;
}

export const UpdateManualPaymentModal: React.FC<IProps> = ({
    user,
    ...props
}) => {
    const paymentModes = ["ACH", "CHEQUE", "ONLINE"];

    const formatDate = (date: Date): string => {
        const yy = date.getFullYear().toString().slice(-2);
        const mm = (date.getMonth() + 1).toString().padStart(2, "0");
        const dd = date.getDate().toString().padStart(2, "0");
        return `${yy}-${mm}-${dd}`;
    };

    const formik = useFormik({
        initialValues: {
            packageId: "",
            payMode: "",
            accountNumber: "",
            amount: 0,
            description: "",
            payDate: new Date(),
            validityDate: new Date(),
            isConfirmed: false,
        },
        validate: (values) => {
            const errors: any = {};

            if (!values.packageId) {
                errors.packageId = "* Required";
            }

            if (!values.payMode) {
                errors.payMode = "* Required";
            }

            if (!values.accountNumber) {
                errors.accountNumber = "* Required";
            }

            if (!values.amount) {
                errors.amount = "* Required";
            }

            if (!values.description) {
                errors.description = "* Required";
            }

            if (!values.payDate) {
                errors.payDate = "* Required";
            }

            if (!values.validityDate) {
                errors.validityDate = "* Required";
            }

            return errors;
        },
        onSubmit: async (values) => {
            try {
                await adminService.updateManualPayment(user.userId, {
                    packageId: values.packageId,
                    paymode: values.payMode,
                    accno: values.accountNumber,
                    amount: values.amount,
                    description: values.description,
                    paydate: formatDate(values.payDate),
                    validitydate: formatDate(values.validityDate),
                    isConfirmed: values.isConfirmed,
                });

                toast.success("Manual payment successfully");
                props.onHide?.();
            } catch (err: any) {
                console.log(
                    "There is an error while updating the manual payment details",
                    err,
                );
                toast.error(
                    "There is an error while updating the manual payment details",
                );
            }
        },
    });

    const [packages, setPackages] = React.useState<IPackage[]>([]);

    const fetchPackages = React.useCallback(async () => {
        const packages = await adminService.getAllPackages();
        console.log(packages);
        setPackages(packages);
    }, [setPackages]);

    React.useEffect(() => {
        fetchPackages();
    }, [fetchPackages]);

    return (
        <Styled.GenerateModal
            {...props}
            size="lg"
            dialogClassName="modal-width"
            keyboard={false}
        >
            <Styled.ModalWrapper>
                <Styled.ModalTitle>Update Manual Payment</Styled.ModalTitle>
                <Styled.Spacer />
                <Styled.ModalSubtitle>
                    Update the manual payment details for the user
                </Styled.ModalSubtitle>
                <Styled.Spacer />
                {formik.errors.packageId && (
                    <Error message={formik.errors.packageId} />
                )}
                <SelectInput
                    fieldValue={formik.values.packageId}
                    fieldName="packageId"
                    fieldLabel="Package *"
                    options={packages.map((p) => ({
                        id: p.id,
                        value: p.id,
                        label: p.name + " - " + p.monthlyPrice,
                    }))}
                    placeholder="Select package"
                    onChangeHandler={formik.handleChange}
                    hasError={Boolean(formik.errors.packageId)}
                />
                <Styled.Spacer />
                {formik.errors.payMode && (
                    <Error message={formik.errors.payMode} />
                )}
                <SelectInput
                    fieldValue={formik.values.payMode}
                    fieldName="payMode"
                    fieldLabel="Payment Mode *"
                    options={paymentModes.map((p) => ({
                        id: p,
                        value: p,
                        label: p,
                    }))}
                    placeholder="Select payment mode"
                    onChangeHandler={formik.handleChange}
                    hasError={Boolean(formik.errors.payMode)}
                />
                <Styled.Spacer />
                {formik.errors.accountNumber && (
                    <Error message={formik.errors.accountNumber} />
                )}
                <TextInput
                    fieldValue={formik.values.accountNumber}
                    fieldName="accountNumber"
                    fieldLabel="Account Number / Cheque Number *"
                    placeholder="Enter account number / cheque number"
                    onChangeHandler={formik.handleChange}
                    hasError={Boolean(formik.errors.accountNumber)}
                />
                <Styled.Spacer />
                {formik.errors.amount && (
                    <Error message={formik.errors.amount} />
                )}
                <TextInput
                    fieldValue={String(formik.values.amount)}
                    fieldName="amount"
                    fieldLabel="Amount *"
                    placeholder="Enter amount"
                    onChangeHandler={formik.handleChange}
                    hasError={Boolean(formik.errors.amount)}
                />
                <Styled.Spacer />
                {formik.errors.description && (
                    <Error message={formik.errors.description} />
                )}
                <TextAreaInput
                    fieldValue={formik.values.description}
                    fieldName="description"
                    fieldLabel="Description *"
                    placeholder="Enter description"
                    onChangeHandler={formik.handleChange}
                    hasError={Boolean(formik.errors.description)}
                />
                <Styled.Spacer />
                <Styled.DiscountPeriodWrapper>
                    <Styled.DatePickerWrapper>
                        <Styled.DatePickerLabel>
                            Pay Date *
                        </Styled.DatePickerLabel>

                        <Styled.CustomDatePicker
                            selected={formik.values.payDate}
                            onChange={(date: Date) => {
                                formik.setFieldValue("payDate", date);
                            }}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Select date"
                        />
                    </Styled.DatePickerWrapper>
                    <Styled.DatePickerWrapper>
                        <Styled.DatePickerLabel>
                            Validity Date *
                        </Styled.DatePickerLabel>

                        <Styled.CustomDatePicker
                            selected={formik.values.validityDate}
                            onChange={(date: Date) => {
                                formik.setFieldValue("validityDate", date);
                            }}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Select date"
                        />
                    </Styled.DatePickerWrapper>
                </Styled.DiscountPeriodWrapper>
                <Styled.Spacer />
                <Styled.ConfirmWrapper>
                    <Styled.ConfirmText>Is Confirmed *</Styled.ConfirmText>
                    <Switch
                        checked={formik.values.isConfirmed}
                        onChange={(event) =>
                            formik.setFieldValue(
                                "isConfirmed",
                                event.target.checked,
                            )
                        }
                    />
                </Styled.ConfirmWrapper>
                <Styled.Spacer />
                <BaseButton onClick={formik.handleSubmit}>
                    Update Manual Payment
                </BaseButton>
            </Styled.ModalWrapper>
        </Styled.GenerateModal>
    );
};
