import React from "react";
import { Col, Container, Stack } from "react-bootstrap";

import { NavLink } from "react-router-dom";
import { ILiveChannel, ROUTEPATHS } from "../../libs";
import * as Styled from "./LiveChannelsList.styled";

const LiveChannelsListHeader: React.FC = () => {
  return (
    <Container fluid>
      <Styled.HeaderRow>
        <Styled.ListHeaderCol xl={2} md={2}>
          Channel ID
        </Styled.ListHeaderCol>
        <Styled.ListHeaderCol xl={3} md={2}>
          Channel Name
        </Styled.ListHeaderCol>
        <Styled.ListHeaderCol xl={1} md={1}>
          Type
        </Styled.ListHeaderCol>
        <Styled.ListHeaderCol xl={6} md={7}>
          Stream Information
        </Styled.ListHeaderCol>
      </Styled.HeaderRow>
    </Container>
  );
};

export const LiveChannelsList: React.FC<{ channelsList: ILiveChannel[] }> = ({
  channelsList,
}) => {
  const [copiedText, setCopiedText] = React.useState("");

  React.useEffect(() => {
    if (copiedText) {
      setTimeout(() => {
        setCopiedText("");
      }, 2000);
    }
  }, [copiedText]);
  return (
    <>
      <LiveChannelsListHeader />
      <Container fluid>
        {channelsList.map((item) => (
          <Styled.DataRow key={item.id}>
            <Col xl={2} md={2}>
              <NavLink to={`${ROUTEPATHS.LIVE_CHANNELS}/${item.id}`}>
                <Styled.ChannelId>{item.id}</Styled.ChannelId>
              </NavLink>
            </Col>
            <Col xl={3} md={2}>
              {item.channelName}
            </Col>
            <Col xl={1} md={1}>
              {item.isPrivate ? "Private" : "Public"}
            </Col>
            <Col xl={6} md={7}>
              <Stack gap={2}>
                <Styled.IngestEndpoint>
                  <strong>RTMPS:</strong>
                  <br />
                  {`rtmps://${item.ingestEndpoint}:443/app/`}
                </Styled.IngestEndpoint>
                <Styled.IngestEndpoint>
                  <strong>RTMP:</strong> <br />
                  {`rtmp://${item.ingestEndpoint}/app/`}
                </Styled.IngestEndpoint>
                <Styled.StreamKey>
                  <Styled.StreamKeyText>{item.streamKey}</Styled.StreamKeyText>
                  <Styled.CopyBadge
                    pill
                    bg={copiedText === item.streamKey ? "success" : "secondary"}
                    onClick={() => {
                      navigator.clipboard.writeText(item.streamKey);
                      setCopiedText(item.streamKey);
                    }}
                  >
                    {copiedText === item.streamKey ? "Copied" : "Copy"}
                  </Styled.CopyBadge>
                </Styled.StreamKey>
              </Stack>
            </Col>
          </Styled.DataRow>
        ))}
      </Container>
    </>
  );
};
