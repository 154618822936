import * as React from "react";

import { IPackage } from "../../libs";
import { adminService } from "../../services";
import { InnerContainer, InnerSection } from "../ui";

import Icon03 from "./../../assets/images/icons/icons-03.png";
import Icon04 from "./../../assets/images/icons/icons-04.png";
import Icon05 from "./../../assets/images/icons/icons-05.png";
import Icon06 from "./../../assets/images/icons/icons-06.png";
import Icon07 from "./../../assets/images/icons/icons-07.png";
import Icon08 from "./../../assets/images/icons/icons-08.png";

//import * as Styled from "./pricing.styled";

import "./pricing.scss";

export const PricingContainer: React.FC<{
  userId?: string;
  name?: string;
  email?: string;
}> = ({ userId, name, email }) => {
  const [packages, setPackages] = React.useState<IPackage[]>([]);
  const getAllPackages = React.useCallback(async () => {
    const response = await adminService.getAllPackages();
    setPackages(response);
  }, []);

  React.useEffect(() => {
    getAllPackages();
  }, [getAllPackages]);

  const getPackageIds = React.useMemo(() => {
    return packages.map((item) => ({ id: item.id, name: item.name }));
  }, [packages]);

  return (
    <InnerSection
      id="pricing"
      className="pricing"
      style={{ marginTop: "120px", overflow: "hidden" }}
    >
      <InnerContainer className="page-hero">
        <div className="meta mx-4">
          <h1
            className="gradient-text"
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="800"
          >
            Browserless Pricing
          </h1>
          <h3
            className="px-4 px-md-0"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="750"
          >
            Cemboo’s subscription models, Premium, Professional and Enterprise,
            are unlike any other video streaming platform due to their
            customized approach that caters to each video content owner’s
            distribution&nbsp;needs.
          </h3>
        </div>
      </InnerContainer>

      <div className="container-fluid container-lg">
        <div className="row package-container m-0 p-0">
          <div className="col-12">
            <section className="platform-packages">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex flex-column flex-md-row flex-nowrap justify-content-center align-items-center align-items-md-stretch align-items gap-3 gap-md-1">
                    <div className="card package-card">
                      <div className="card-body">
                        <h2 className="card-title">Premium</h2>
                        <p className="card-text mb-4 mb-md-5">
                          Drive significant growth with your video&nbsp;content
                        </p>
                        <h3 className="price mb-4 mb-md-5">
                          $75.00<span>/month</span>
                        </h3>
                        <ul className="notes">
                          <li>7 TB of Storage</li>
                          <li>100% AD Revenue</li>
                          <li>Standard analytics</li>
                        </ul>
                      </div>
                      <div className="card-footer d-grid">
                        <a
                          href={`/register?ref=${window.btoa(
                            JSON.stringify({
                              userId,
                              name,
                              email,
                              packageId: getPackageIds.find(
                                (item) => item.name.toLowerCase() === "premium"
                              )?.id,
                              redirect: false,
                            })
                          )}`}
                          className="btn btn-primary"
                        >
                          Start with Premium
                        </a>
                      </div>
                    </div>

                    <div className="card package-card popular">
                      <div className="popular-choice text-center text-uppercase">
                        <span>Most Popular</span>
                      </div>
                      <div className="card-body">
                        <h2 className="card-title">Professional</h2>
                        <p className="card-text mb-4 mb-md-5">
                          Scale your success with exceptional streaming&nbsp;solutions
                        </p>
                        <h3 className="price mb-4 mb-md-5">
                          $250.00<span>/month</span>
                        </h3>
                        <ul className="notes">
                          <li>15 TB of Storage</li>
                          <li>100% AD Revenue</li>
                          <li>2 Concurrent Live Streams</li>
                          <li>Custom analytics</li>
                        </ul>
                      </div>
                      <div className="card-footer d-grid">
                        <a
                          href={`/register?ref=${window.btoa(
                            JSON.stringify({
                              userId,
                              name,
                              email,
                              redirect: false,
                              packageId: getPackageIds.find(
                                (item) =>
                                  item.name.toLowerCase() === "professional"
                              )?.id,
                            })
                          )}`}
                          className="btn btn-primary"
                        >
                          Start with Professional
                        </a>
                      </div>
                    </div>

                    <div className="card package-card">
                      <div className="card-body">
                        <h2 className="card-title">Enterprise</h2>
                        <p className="card-text mb-4 mb-md-5">
                          Maximize your influence with custom media&nbsp;management
                        </p>
                        <h3 className="price mb-4 mb-md-5">Custom</h3>
                        <ul className="notes">
                          <li>Unlimited Storage</li>
                          <li>100% AD Revenue</li>
                          <li>Up to 50 concurrent live streams</li>
                          <li>Fully customizable analytics</li>
                          <li>Digital rights management</li>
                          <li>Custom website</li>
                        </ul>
                      </div>
                      <div className="card-footer d-grid">
                        <a href="/contact" className="btn btn-primary">
                          Contact Us
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="row advantages-container py-3 py-md-5">
          <div className="col-12">
            <section>
              <h2 className="gradient-text d-inline-block mb-3 mb-md-4">
                Our Advantages
              </h2>
              <p className="section-description mb-4 mb-md-5">
                With the goal of driving traffic to a content owner’s
                specialized video library, Cemboo is an engine for managing,
                distributing, and monetizing video content through contemporary
                technologies such as&nbsp;blockchain.
              </p>

              <div className="advantage-items">
                <div className="advantage-item">
                  <img src={Icon03} className="mb-2" alt="" />
                  <h3 className="mb-2">Scalable options</h3>
                  <p>
                    Cemboo provides scalable options depending on the library
                    owner’s&nbsp;budget.
                  </p>
                </div>

                <div className="advantage-item">
                  <img src={Icon05} className="mb-2" alt="" />
                  <h3 className="mb-2">Distribute content</h3>
                  <p>
                    Control and distribute content, from independent content
                    creators, to the next big film festival, to educational
                    libraries of universities across the&nbsp;globe.
                  </p>
                </div>

                <div className="advantage-item">
                  <img src={Icon08} className="mb-2" alt="" />
                  <h3 className="mb-2">Cloud technology</h3>
                  <p>
                    Our white label software allows you to control, monetize,
                    and maintain your library, utilizing the latest
                    cloud&nbsp;technology.
                  </p>
                </div>

                <div className="advantage-item">
                  <img src={Icon07} className="mb-2" alt="" />
                  <h3 className="mb-2">All-inclusive service</h3>
                  <p>
                    Through Cemboo’s all-inclusive service, library content
                    owners can cut down on development and maintenance costs,
                    and receive 100% revenue share for ad&nbsp;campaigns.
                  </p>
                </div>

                <div className="advantage-item">
                  <img src={Icon04} className="mb-2" alt="" />
                  <h3 className="mb-2">Monetizing</h3>
                  <p>
                    Cemboo is a premier solution to managing and monetizing your
                    digital content&nbsp;library.
                  </p>
                </div>

                <div className="advantage-item">
                  <img src={Icon06} className="mb-2" alt="" />
                  <h3 className="mb-2">Blockchain</h3>
                  <p>
                  Enhance content security and monetization with Cemboo's Web3 
                  features, ensuring seamless transactions and robust intellectual 
                  property protection through smart&nbsp;contracts.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="row contact-container my-3 my-md-5 py-3 py-md-5">
          <div className="col-12">
            <section className="contact-cta">
              <div className="meta">
                <h2 className="gradient-text text-center d-inline-block mb-4 mb-md-5">
                  Need Something Custom <br />or Help Getting&nbsp;Started?
                </h2>
                <div className="">
                  <a className="btn btn-primary" href="/contact">
                    Contact Us
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </InnerSection>
  );
};
