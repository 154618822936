export enum IAdBannerStatus {
    AD_ACTIVE = 1,
    AD_INACTIVE = 0
}

export enum IAdBannerType {
    AD_HORIZONTAL_BANNER = 0,
    AD_SMALL_BANNER = 1,
    AD_BACKGROUND_BANNER = 2
}

export interface IAdServerBannerRequestDto {
    adId?: string;
    adFileName: string;
    s3Key: string;
    adPosition: IAdBannerType;
    adImageAlttext: string;
    adImageUrl: string;
    adStatus: IAdBannerStatus;
}
