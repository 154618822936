import axios from "axios";

class RssFeedService {

    async getAllRssFeeds(): Promise<any> {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_RSS_FEED_URL}`, {
                params: {
                    limit: 15
                }
            }
            );
            return res.data;
        } catch (err: any) {
            console.log('There was an error while fetching all RSS feeds.', err);
            throw new Error(err);
        }
    }

}

export const rssFeedService = new RssFeedService();