import React from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./confirm-modal.css";

interface ConfirmModalProps {
    title?: string;
    description?: {
        text?: string;
        bold?: boolean;
    }[];
    customContent?: React.ReactNode;
    noText?: string;
    yesText?: string;
    danger?: boolean;
    onNo?: () => void;
    onYes: () => void;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
    title,
    description,
    noText,
    yesText,
    danger = false,
    onNo,
    onYes,
    customContent,
}) => {
    const [disableAll, setDisableAll] = React.useState(false);

    const callUserFunction = React.useCallback(
        (func) => {
            setDisableAll(true);
            func();
            setDisableAll(false);
        },
        [setDisableAll],
    );

    return (
        <div className="cmb-confirm-dialog">
            <div className="cmb-confirm-dialog--inner">
                <h1
                    style={{
                        color: danger
                            ? "var(--bs-danger)"
                            : "var(--color-primary)",
                    }}
                >
                    {title ? title : "Confirm Action"}
                </h1>

                <p>
                    {description ? (
                        description.map((item, index) => {
                            return (
                                <span key={index}>
                                    {item.bold ? (
                                        <strong>{item.text}</strong>
                                    ) : (
                                        item.text
                                    )}
                                    {index !== description.length - 1 ? (
                                        <br />
                                    ) : null}
                                </span>
                            );
                        })
                    ) : (
                        <span>Are you sure, you want to proceed?</span>
                    )}
                </p>
                {customContent && <div>{customContent}</div>}
                <div className="cmb-confirm-dialog--controls">
                    {onNo && (
                        <button
                            className="cmb-confirm-dialog--btn no btn btn-secondary"
                            onClick={() => callUserFunction(onNo)}
                            disabled={disableAll}
                        >
                            {noText ? noText : "No"}
                        </button>
                    )}
                    <button
                        className={`cmb-confirm-dialog--btn btn ${danger ? "btn-danger yes-danger" : "btn-primary yes"}`}
                        onClick={() => callUserFunction(onYes)}
                        disabled={disableAll}
                    >
                        {yesText ? yesText : "Yes"}
                    </button>
                </div>
            </div>
        </div>
    );
};
