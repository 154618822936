import { Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { colors, types } from "../../../libs";

export const FormFieldRow = styled(Row)`
  margin: 15px;
`;

export const DetailRow = styled(Row)`
  margin: 15px;
`;

export const DetailLabel = styled(Form.Label)`
  display: block;
  ${types.body.small};
`;

export const DetailLabelHeader = styled(Form.Label)`
  display: block;
  margin-top: 20px;
  ${types.headers.small};
`;

export const DetailText = styled(Form.Text)`
  color: ${colors.text.mediumWhite};
  ${types.body.label};
`;

export const AccountFormFieldRow = styled(Row)`
  margin: 20px;
  padding: 0;
`;
