import * as React from "react";
import { Container } from "react-bootstrap";

import { ILiveChannelRequest } from "../../../libs";
import { liveChannelService } from "../../../services";
import { LiveChannelForm } from "../live-channel-form/LiveChannelForm";
import * as Styled from "./CreateChannel.styled";

export const CreateChannel: React.FC<{
  onCancel: () => void;
  onCreateComplete: () => void;
}> = ({ onCancel, onCreateComplete }) => {
  const [createChannelError, setCreateChannelError] = React.useState("");

  const initialValues = {
    name: "",
    isPrivate: false,
    eventName: "",
    eventStartDate: "",
    eventEndDate: "",
    autoRecordEnabled: true,
  };

  const handleSubmit = React.useCallback(
    async (data: ILiveChannelRequest, setSubmitting) => {
      try {
        const channelId = await liveChannelService.createLiveChannel(data);
        if (channelId) {
          setSubmitting(false);
          onCreateComplete();
        }
      } catch (err) {
        console.log("There is an error while creating a channel", err);
        setCreateChannelError(
          "There is an error while creating the channel. Please try again."
        );
        setSubmitting(false);
      }
    },
    [onCreateComplete]
  );

  return (
    <Container fluid>
      <Styled.CreateChannelHeader>Create Channel</Styled.CreateChannelHeader>
      <LiveChannelForm
        initialValues={initialValues}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        errorMessage={createChannelError}
      />
    </Container>
  );
};
