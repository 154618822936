import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import App from "./App";

import "@aws-amplify/ui-react/styles.css";

// import aws amplify
import { Amplify } from "aws-amplify";
import { AwsConfigAuth } from "./config";
import { AuthProvider, StateProvider } from "./context";

// configure amplify
Amplify.configure({ Auth: AwsConfigAuth });

declare global {
    interface Window {
        LeaderLine?: any;
    }
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <AuthProvider>
                <StateProvider>
                    <App />
                </StateProvider>
            </AuthProvider>
        </BrowserRouter>
    </React.StrictMode>
);
