import React from "react";
import { useGlobalState } from "../../../behavioral";
import { StorageGuage } from "./storage-guage";
import * as Styled from "./StorageInsights.styled";
import LiveStreamIcon from "../../../assets/images/icons/icon-live-stream.svg";
import VODItemIcon from "../../../assets/images/icons/icon-vod-item.svg";

import {
    bytesToHumanReadableSize,
    IMediaResponseDto,
    IRecording,
} from "../../../libs";

interface IProps {
    mediaItems: IMediaResponseDto[];
    liveStreamRecordings: IRecording[];
}

export const StorageInsights: React.FC<IProps> = ({
    mediaItems,
    liveStreamRecordings,
}) => {
    const { state } = useGlobalState();
    console.log(state);

    const highlightColors = {
        first: "#B7F84D",
        second: "#7852FB",
        third: "#EA6B25",
        rest: "#43474F",
    };

    const [storageState, setStorageState] = React.useState({
        filmFestival: {
            used: 0,
            count: 0,
        },
        general: {
            used: 0,
            count: 0,
        },
        liveStream: {
            used: 0,
            count: 0,
        },
    });

    React.useEffect(() => {
        setStorageState({
            filmFestival: {
                used: state.storage.category.vod.filmFestival,
                count: mediaItems.filter(
                    (item) => item.category === "FILM_FESTIVAL",
                ).length,
            },
            general: {
                used: state.storage.category.vod.general,
                count: mediaItems.filter((item) => item.category === "GENERAL")
                    .length,
            },
            liveStream: {
                used: state.storage.category.liveStream,
                count: liveStreamRecordings.length,
            },
        });
    }, [mediaItems, liveStreamRecordings, state]);

    return (
        <Styled.StorageInsightsWrapper>
            <Styled.StorageInsightsCol md="3">
                <Styled.StorageInsightsCard highlight={highlightColors.first}>
                    <Styled.StorageInsightsCardRow>
                        <Styled.StorageInsightsCardUsage
                            highlight={highlightColors.first}
                        >
                            {bytesToHumanReadableSize(
                                storageState.filmFestival.used,
                            )}
                        </Styled.StorageInsightsCardUsage>
                        <Styled.StorageInsightsCardCount>
                            {storageState.filmFestival.count > 1
                                ? `${storageState.filmFestival.count} items`
                                : `${storageState.filmFestival.count} item`}
                        </Styled.StorageInsightsCardCount>
                    </Styled.StorageInsightsCardRow>
                    <Styled.StorageInsightsCardRow>
                        <Styled.StorageInsightsCardCategory>
                            Film Festival
                        </Styled.StorageInsightsCardCategory>
                        <Styled.StorageInsightsCardIcon src={VODItemIcon} />
                    </Styled.StorageInsightsCardRow>
                </Styled.StorageInsightsCard>
            </Styled.StorageInsightsCol>
            <Styled.StorageInsightsCol md="3">
                <Styled.StorageInsightsCard highlight={highlightColors.second}>
                    <Styled.StorageInsightsCardRow>
                        <Styled.StorageInsightsCardUsage
                            highlight={highlightColors.second}
                        >
                            {bytesToHumanReadableSize(
                                storageState.general.used,
                            )}
                        </Styled.StorageInsightsCardUsage>
                        <Styled.StorageInsightsCardCount>
                            {storageState.general.count > 1
                                ? `${storageState.general.count} items`
                                : `${storageState.general.count} item`}
                        </Styled.StorageInsightsCardCount>
                    </Styled.StorageInsightsCardRow>
                    <Styled.StorageInsightsCardRow>
                        <Styled.StorageInsightsCardCategory>
                            General Uploads
                        </Styled.StorageInsightsCardCategory>
                        <Styled.StorageInsightsCardIcon src={VODItemIcon} />
                    </Styled.StorageInsightsCardRow>
                </Styled.StorageInsightsCard>
            </Styled.StorageInsightsCol>
            <Styled.StorageInsightsCol md="3">
                <Styled.StorageInsightsCard highlight={highlightColors.third}>
                    <Styled.StorageInsightsCardRow>
                        <Styled.StorageInsightsCardUsage
                            highlight={highlightColors.third}
                        >
                            {bytesToHumanReadableSize(
                                storageState.liveStream.used,
                            )}
                        </Styled.StorageInsightsCardUsage>
                        <Styled.StorageInsightsCardCount>
                            {storageState.liveStream.count > 1
                                ? `${storageState.liveStream.count} items`
                                : `${storageState.liveStream.count} item`}
                        </Styled.StorageInsightsCardCount>
                    </Styled.StorageInsightsCardRow>
                    <Styled.StorageInsightsCardRow>
                        <Styled.StorageInsightsCardCategory>
                            Live Stream Recordings
                        </Styled.StorageInsightsCardCategory>
                        <Styled.StorageInsightsCardIcon src={LiveStreamIcon} />
                    </Styled.StorageInsightsCardRow>
                </Styled.StorageInsightsCard>
            </Styled.StorageInsightsCol>
            <Styled.StorageInsightsCol md="3">
                <StorageGuage
                    percentFilled={Math.min(state.storage.used / state.storage.limit, 1)}
                    text={bytesToHumanReadableSize(state.storage.used)}
                    labelText={`${bytesToHumanReadableSize(state.storage.limit - state.storage.used)} of ${bytesToHumanReadableSize(state.storage.limit)} Free`}
                />
            </Styled.StorageInsightsCol>
        </Styled.StorageInsightsWrapper>
    );
};
