import { ModalProps } from "react-bootstrap";
import {
    BaseButton,
    Error,
    IPackage,
    IUser,
    SelectInput,
    TextInput,
} from "../../../../libs";
import * as Styled from "./GeneratePaymentLinkModal.styled";
import { useFormik } from "formik";
import { adminService } from "../../../../services";
import React from "react";
import { toast } from "react-toastify";

interface IProps extends ModalProps {
    user: IUser;
}

export const GeneratePaymentLinkModal: React.FC<IProps> = ({
    user,
    ...props
}) => {
    const formik = useFormik({
        initialValues: {
            email: user.email,
            name: user.name,
            packageId: "",
            discountCode: "",
            discountPercentage: 0,
            startDate: new Date().toISOString(),
            fullPriceStartDate: new Date().toISOString(),
            discountPeriodStartDate: new Date().toISOString(),
            discountPeriodEndDate: new Date().toISOString(),
        },
        validate: (values) => {
            const errors: any = {};

            if (!values.email) {
                errors.email = "* Required";
            }

            if (!values.name) {
                errors.name = "* Required";
            }

            if (!values.packageId) {
                errors.packageId = "* Required";
            }

            if (!values.discountCode) {
                errors.discountCode = "* Required";
            }

            if (!values.discountPercentage) {
                errors.discountPercentage = "* Required";
            }

            if (!values.startDate) {
                errors.startDate = "* Required";
            }

            if (!values.fullPriceStartDate) {
                errors.fullPriceStartDate = "* Required";
            }

            if (!values.discountPeriodStartDate) {
                errors.discountPeriodStartDate = "* Required";
            }

            if (!values.discountPeriodEndDate) {
                errors.discountPeriodEndDate = "* Required";
            }

            return errors;
        },
        onSubmit: async (values) => {
            const options = {
                year: "numeric",
                month: "long",
                day: "numeric",
            };

            const formatter = new Intl.DateTimeFormat("en-US", options as any);

            try {
                await adminService.generatePaymentLink({
                    recipient: values.email,
                    packageId: values.packageId,
                    userId: user.userId,
                    name: values.name,
                    startdate: formatter.format(new Date(values.startDate)),
                    fullpricestartdate: formatter.format(
                        new Date(values.fullPriceStartDate),
                    ),
                    discountperiod: `${formatter.format(new Date(values.discountPeriodStartDate))} - ${formatter.format(new Date(values.discountPeriodEndDate))}`,
                    discountpercentage: values.discountPercentage,
                    discountcode: values.discountCode,
                });
                toast.success("Payment link generated and sent successfully");
                props.onHide?.();
            } catch (err: any) {
                console.log(
                    "There is an error while generating the payment link",
                    err,
                );
                toast.error(
                    "There is an error while generating the payment link",
                );
            }
        },
    });

    const [packages, setPackages] = React.useState<IPackage[]>([]);

    const fetchPackages = React.useCallback(async () => {
        const packages = await adminService.getAllPackages();
        console.log(packages);
        setPackages(packages);
    }, [setPackages]);

    React.useEffect(() => {
        fetchPackages();
    }, [fetchPackages]);

    return (
        <Styled.GenerateModal
            {...props}
            size="lg"
            dialogClassName="modal-width"
            keyboard={false}
        >
            <Styled.GenerateModalWrapper>
                <Styled.ModalTitle>Generate Payment Link</Styled.ModalTitle>
                <Styled.Spacer />
                <Styled.ModalSubtitle>
                    Generate a payment link for the user
                </Styled.ModalSubtitle>
                <Styled.Spacer />
                {formik.errors.email && <Error message={formik.errors.email} />}
                <TextInput
                    fieldValue={formik.values.email}
                    fieldName="email"
                    fieldLabel="Email *"
                    placeholder="Enter email ID of the user"
                    onChangeHandler={formik.handleChange}
                />
                <Styled.Spacer />
                {formik.errors.name && <Error message={formik.errors.name} />}
                <TextInput
                    fieldValue={formik.values.name}
                    fieldName="name"
                    fieldLabel="Name *"
                    placeholder="Enter name of the user"
                    onChangeHandler={formik.handleChange}
                />
                <Styled.Spacer />
                {formik.errors.packageId && (
                    <Error message={formik.errors.packageId} />
                )}
                <SelectInput
                    fieldValue={formik.values.packageId}
                    fieldName="packageId"
                    fieldLabel="Package *"
                    options={packages.map((p) => ({
                        id: p.id,
                        value: p.id,
                        label: p.name + " - " + p.monthlyPrice,
                    }))}
                    placeholder="Select package"
                    onChangeHandler={formik.handleChange}
                />
                <Styled.Spacer />
                <Styled.DiscountCodeWrapper>
                    <Styled.DiscountCodeColumn>
                        {formik.errors.discountCode && (
                            <Error message={formik.errors.discountCode} />
                        )}
                        <TextInput
                            fieldValue={formik.values.discountCode}
                            fieldName="discountCode"
                            fieldLabel="Discount Code *"
                            placeholder="Enter discount code"
                            onChangeHandler={formik.handleChange}
                        />
                    </Styled.DiscountCodeColumn>
                    <Styled.DiscountCodeColumn>
                        {formik.errors.discountPercentage && (
                            <Error message={formik.errors.discountPercentage} />
                        )}
                        <TextInput
                            fieldValue={String(
                                formik.values.discountPercentage,
                            )}
                            fieldName="discountPercentage"
                            fieldLabel="Discount Percentage *"
                            placeholder="Enter discount percentage"
                            onChangeHandler={formik.handleChange}
                        />
                    </Styled.DiscountCodeColumn>
                </Styled.DiscountCodeWrapper>
                <Styled.Spacer />
                {formik.errors.startDate && (
                    <Error message={formik.errors.startDate} />
                )}
                <Styled.DatePickerWrapper>
                    <Styled.DatePickerLabel>Start Date *</Styled.DatePickerLabel>

                    <Styled.CustomDatePicker
                        selected={new Date(formik.values.startDate)}
                        onChange={(date: Date) => {
                            formik.setFieldValue(
                                "startDate",
                                date?.toISOString() || new Date().toISOString(),
                            );
                        }}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select date"
                    />
                </Styled.DatePickerWrapper>
                <Styled.Spacer />
                {formik.errors.fullPriceStartDate && (
                    <Error message={formik.errors.fullPriceStartDate} />
                )}
                <Styled.DatePickerWrapper>
                    <Styled.DatePickerLabel>
                        Full price start Date *
                    </Styled.DatePickerLabel>

                    <Styled.CustomDatePicker
                        selected={new Date(formik.values.fullPriceStartDate)}
                        onChange={(date: Date) => {
                            formik.setFieldValue(
                                "fullPriceStartDate",
                                date?.toISOString() || new Date().toISOString(),
                            );
                        }}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select date"
                    />
                </Styled.DatePickerWrapper>
                <Styled.Spacer />
                <Styled.DiscountPeriodWrapper>
                    <Styled.DiscountCodeColumn>
                        {formik.errors.discountPeriodStartDate && (
                            <Error
                                message={formik.errors.discountPeriodStartDate}
                            />
                        )}
                        <Styled.DatePickerWrapper>
                            <Styled.DatePickerLabel>
                                Discount period start date *
                            </Styled.DatePickerLabel>

                            <Styled.CustomDatePicker
                                selected={
                                    new Date(
                                        formik.values.discountPeriodStartDate,
                                    )
                                }
                                onChange={(date: Date) => {
                                    formik.setFieldValue(
                                        "discountPeriodStartDate",
                                        date?.toISOString() ||
                                            new Date().toISOString(),
                                    );
                                }}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Select date"
                            />
                        </Styled.DatePickerWrapper>
                    </Styled.DiscountCodeColumn>
                    <Styled.DiscountCodeColumn>
                        {formik.errors.discountPeriodEndDate && (
                            <Error
                                message={formik.errors.discountPeriodEndDate}
                            />
                        )}
                        <Styled.DatePickerWrapper>
                            <Styled.DatePickerLabel>
                                Discount period end date *
                            </Styled.DatePickerLabel>

                            <Styled.CustomDatePicker
                                selected={
                                    new Date(
                                        formik.values.discountPeriodEndDate,
                                    )
                                }
                                onChange={(date: Date) => {
                                    formik.setFieldValue(
                                        "discountPeriodEndDate",
                                        date?.toISOString() ||
                                            new Date().toISOString(),
                                    );
                                }}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Select date"
                            />
                        </Styled.DatePickerWrapper>
                    </Styled.DiscountCodeColumn>
                </Styled.DiscountPeriodWrapper>
                <Styled.Spacer />
                <BaseButton onClick={formik.handleSubmit}>
                    Generate Payment Link
                </BaseButton>
                <Styled.Spacer />
            </Styled.GenerateModalWrapper>
        </Styled.GenerateModal>
    );
};
