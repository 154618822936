import axios from "axios";
import { API_BASE_PATHS, API_ROUTES } from "../libs";

import { buildRequestConfig } from "./buildRequestConfig";

class UploaderService {
  async initializeMultipartUpload(params: {
    fileName: string;
    mediaId: string;
    contentType: string;
    category: string;
  }) {
    const config = await buildRequestConfig();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.VOD_API}/${API_ROUTES.INITIALIZE_MULTIPART_UPLOAD}`,
        {
          fileName: params.fileName,
          mediaId: params.mediaId,
          contentType: params.contentType,
          category: params.category,
        },
        { ...config }
      );

      return response.data.data;
    } catch (err: any) {
      console.log("There is an error while initializing multipart upload", err);
      throw new Error(err);
    }
  }

  async createMultipartPresignedUrls(params: {
    uploadId: string;
    fileKey: string;
    contentType: string;
    parts: number;
  }) {
    try {
      const config = await buildRequestConfig();
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.VOD_API}/${API_ROUTES.GET_MULTIPART_PRESIGNED_URLS}`,
        {
          uploadId: params.uploadId,
          fileKey: params.fileKey,
          parts: params.parts,
          contentType: params.contentType,
        },
        { ...config }
      );

      return response.data.data;
    } catch (err: any) {
      console.log(
        "There is an error while creating multipart presigned urls",
        err
      );
      throw new Error(err);
    }
  }

  async finalizeMultipartUpload(params: {
    uploadId: string;
    fileKey: string;
    contentType: string;
    parts: { PartNumber: number; ETag: string }[];
  }) {
    try {
      const config = await buildRequestConfig();
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.VOD_API}/${API_ROUTES.FINALIZE_MULTIPART_UPLOAD}`,
        {
          uploadId: params.uploadId,
          fileKey: params.fileKey,
          parts: params.parts,
          contentType: params.contentType,
        },
        { ...config }
      );

      return response.data.data;
    } catch (err: any) {
      console.log("There is an error while finalizing multipart upload", err);
      throw new Error(err);
    }
  }
}

export const uploaderService = new UploaderService();
