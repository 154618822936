import { useFormik } from "formik";
import React from "react";
import { Spinner } from "react-bootstrap";
import * as Yup from "yup";

import { useAuth } from "../../behavioral";
import { BaseButton, FloatingTextInput as TextInput } from "../../libs";

import { useNavigate } from "react-router-dom";
import * as Styled from "./ResetPassword.styled";

export const ResetPassword: React.FC<{ email: string }> = ({ email }) => {
  const navigate = useNavigate();
  const { confirmForgotPassword, signIn } = useAuth();
  const formik = useFormik({
    initialValues: {
      email: email,
      confirmationCode: "",
      newPassword: "",
    },
    validationSchema: Yup.object({
      confirmationCode: Yup.string()
        .max(6, "Please provide a valid confirmation code")
        .required("Please enter the confirmation code"),
      newPassword: Yup.string()
        .min(8, "Password should contain minmum of 8 characters.")
        .required("Please enter a password"),
      email: Yup.string()
        .email("Please provide a valid email address")
        .required("Please enter an email address"),
    }),
    onSubmit: (values) => {
      
      handleResetPasswordSubmit(values);
    },
  });

  const handleResetPasswordSubmit = React.useCallback(
    async (values) => {
      try {
        await confirmForgotPassword(
          values.email,
          values.confirmationCode,
          values.newPassword
        );
        await signIn({ email: values.email, password: values.newPassword });
        navigate("/dashboard", { replace: true });
      } catch (err) {
        console.log(err);
      }
    },
    [confirmForgotPassword, navigate, signIn]
  );
  return (
    <>
      <Styled.ResetPasswordPageHeader>
        Reset Password
      </Styled.ResetPasswordPageHeader>

      <Styled.ConfirmCodeText>
        Please enter the confirmation code sent to the provided email address.
      </Styled.ConfirmCodeText>

      <Styled.ResetPasswordForm onSubmit={formik.handleSubmit}>
        <Styled.ResetPasswordFormRow>
          <Styled.ResetPasswordFormCol>
            <TextInput
              label="Email Address"
              type="email"
              placeholder="Email Address"
              controlId="email"
              value={formik.values.email}
              onChangeHandler={formik.handleChange}
              onBlurHandler={formik.handleBlur}
              readOnly={true}
            />
          </Styled.ResetPasswordFormCol>
        </Styled.ResetPasswordFormRow>
        <Styled.ResetPasswordFormRow>
          <Styled.ResetPasswordFormCol>
            <TextInput
              label="Confirmation Code"
              type="text"
              placeholder="Confirmation Code"
              controlId="confirmationCode"
              value={formik.values.confirmationCode}
              onChangeHandler={formik.handleChange}
              onBlurHandler={formik.handleBlur}
              hasError={
                formik.touched.newPassword && !!formik.errors.newPassword
              }
            />
          </Styled.ResetPasswordFormCol>
        </Styled.ResetPasswordFormRow>
        <Styled.ResetPasswordFormRow>
          <Styled.ResetPasswordFormCol>
            <TextInput
              label="New Password"
              type="password"
              placeholder="New Password"
              controlId="newPassword"
              value={formik.values.newPassword}
              onChangeHandler={formik.handleChange}
              onBlurHandler={formik.handleBlur}
              hasError={
                formik.touched.newPassword && !!formik.errors.newPassword
              }
            />
          </Styled.ResetPasswordFormCol>
        </Styled.ResetPasswordFormRow>

        <Styled.ResetPasswordFormRow>
          <Styled.ResetPasswordFormCol md={4}>
            <BaseButton type="submit" variant="primary" disabled={false}>
              {formik.isSubmitting && <Spinner size="sm" />}
              Reset Password
            </BaseButton>
          </Styled.ResetPasswordFormCol>
        </Styled.ResetPasswordFormRow>
      </Styled.ResetPasswordForm>
    </>
  );
};
