import * as React from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { DashboardHeader, DashboardSideMenu } from "../dashboard-components";

import { useAuth, useGlobalState } from "../../behavioral";
import { ConfirmModal, FileWithPreview, shouldAllowAccess } from "../../libs";
import { ROUTEPATHS } from "../../libs/common/constants";
import { userService } from "../../services";
import { DragAndDropZone } from "../drag-drop-zone";
import { IUploadType, UploadModal, UploadProgressModal } from "../upload-modal";

import { confirmAlert } from "react-confirm-alert";
import { Button, Loader } from "../ui";
import * as Styled from "./SidebarLayout.styled";
import { toast } from "react-toastify";

export const SidebarLayout: React.FC = () => {
    const authInfo = useAuth();
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const {
        dispatch,
        actions,
        state: { currentUser, ongoingUploads },
    } = useGlobalState();
    const [showSubscriptionAlertModal, setShowSubscriptionAlertModal] =
        React.useState(false);
    const [showDragAndDropZone, setShowDragAndDropZone] = React.useState(false);
    const [uploadedFiles, setUploadedFiles] = React.useState<FileWithPreview[]>(
        [],
    );
    const [showUploadModal, setShowUploadModal] = React.useState(false);
    const [uploadType, setUploadType] = React.useState<IUploadType>(
        IUploadType.FILM_FESTIVAL_VIDEO,
    );
    const [showOngoingUploads, setShowOngoingUploads] = React.useState(false);

    const onHideHandler = React.useCallback(() => {
        setShowUploadModal(false);
        setUploadedFiles([]);
        setShowDragAndDropZone(false);
    }, []);

    const handleOnDrop = React.useCallback(
        (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
                setShowUploadModal(true);
            }
        },
        [uploadedFiles],
    );

    const handleInvalidFormat = React.useCallback(() => {
        alert("Supported formats: .mp4, .ogv, .webm");
    }, []);

    React.useEffect(() => {
        setShowDragAndDropZone(false);
    }, [pathname]);

    // Get Logged In user on Sidebard Load, show loader until we fetch the userInfo
    const getLoggedInUser = React.useCallback(async () => {
        const currentUser = await userService.getCurrentUser();
        console.log("Current User: ", currentUser);
        dispatch({ type: actions.SET_USER, payload: currentUser });
        dispatch({ type: actions.SET_IS_ADMIN, payload: currentUser.isAdmin });
        dispatch({ type: actions.SET_STORAGE, payload: currentUser.storage });
        dispatch({
            type: actions.SET_ALLOW_ACCESS,
            payload: shouldAllowAccess(currentUser),
        });
    }, [dispatch, actions]);

    React.useEffect(() => {
        if (currentUser && !shouldAllowAccess(currentUser)) {
            setShowSubscriptionAlertModal(true);
        }
    }, [currentUser]);

    React.useEffect(() => {
        getLoggedInUser();
    }, [getLoggedInUser]);

    const handleUploadComplete = React.useCallback(() => {
        // Navigate to VOD page
        getLoggedInUser();
        onHideHandler();
        navigate(ROUTEPATHS.DASHBOARD);
    }, [navigate, onHideHandler, getLoggedInUser]);

    const onContinueToSubscription = React.useCallback(() => {
        navigate(
            `${ROUTEPATHS.PRICING}?ref=${window.btoa(
                JSON.stringify({
                    userId: currentUser!.userId,
                    name: currentUser!.name,
                    email: currentUser!.email,
                }),
            )}`,
        );
    }, [navigate, currentUser]);

    const uploadVideoHandler = React.useCallback(
        (uploadType: IUploadType) => {
            if (currentUser && shouldAllowAccess(currentUser)) {
                setUploadType(uploadType);
                setShowDragAndDropZone(true);
            } else {
                setShowSubscriptionAlertModal(true);
            }
        },
        [currentUser],
    );

    React.useEffect(() => {
        if (showSubscriptionAlertModal) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <ConfirmModal
                            title="Subscribe Today!"
                            description={[
                                {
                                    text: "Ready to take your experience to the next level? Subscribe now and enjoy a host of exclusive benefits:",
                                    bold: false,
                                },
                            ]}
                            noText="Later"
                            yesText="Let's do it!"
                            onNo={() => {
                                setShowSubscriptionAlertModal(false);
                                onClose();
                            }}
                            onYes={() => {
                                onContinueToSubscription();
                                onClose();
                            }}
                        ></ConfirmModal>
                    );
                },
                closeOnEscape: true,
                closeOnClickOutside: false,
                keyCodeForClose: [8, 32],
            });
        }
    }, [onContinueToSubscription, showSubscriptionAlertModal]);

    const handleBeforeUnload = React.useCallback((event) => {
        event.preventDefault();
        event.returnValue = "Uploads will be cancelled if you leave this page.";
        return "Uploads will be cancelled if you leave this page.";
    }, []);

    React.useEffect(() => {
        const uploadsInProgress = ongoingUploads.filter(
            (upload) => !upload.done && !upload.abort,
        );
        if (uploadsInProgress.length > 0) {
            window.removeEventListener("beforeunload", handleBeforeUnload);

            toast.info("Upload(s) in progress...", {
                autoClose: false,
                toastId: "uploads-in-progress",
                onClick: () => {
                    setShowOngoingUploads(true);
                },
            });
            window.addEventListener("beforeunload", handleBeforeUnload);
        } else {
            window.removeEventListener("beforeunload", handleBeforeUnload);

            toast.dismiss("uploads-in-progress");
        }
    }, [ongoingUploads, setShowOngoingUploads, handleBeforeUnload]);

    if (!authInfo || !authInfo.isAuthenticated) {
        return (
            <Navigate
                to={ROUTEPATHS.LOGIN}
                replace
                state={{ redirect: `${pathname}${search}` }}
            />
        );
    }

    if (!currentUser) {
        return <Loader />;
    }

    return (
        <Styled.DashboardPageWrapper>
            <DashboardHeader />
            <Styled.DashboardContainer fluid>
                <Styled.DashboardSectionWrapper>
                    <Styled.DashboardSectionSideMenuWrapper
                        md={3}
                        sm={3}
                        xl={2}
                    >
                        <DashboardSideMenu
                            onClickUploadVideo={uploadVideoHandler}
                        />
                    </Styled.DashboardSectionSideMenuWrapper>

                    <Styled.DashboardSectionMainWrapper
                        md={9}
                        sm={9}
                        xl={10}
                        className="offset-md-3 offset-sm-3 offset-xl-2"
                    >
                        {showDragAndDropZone ? (
                            <>
                                <Styled.WelcomeHeader>
                                    <Styled.Title>
                                        Upload New Video
                                    </Styled.Title>
                                    <Button
                                        buttonText="Cancel"
                                        clickHandler={() =>
                                            setShowDragAndDropZone(false)
                                        }
                                    />
                                </Styled.WelcomeHeader>
                                <DragAndDropZone
                                    acceptedFileType="video/*"
                                    onDropHandler={handleOnDrop}
                                    direction="vertical"
                                    centerContent
                                    dropMessageText="Drag and drop or click to upload a video"
                                    onInvalidFormatError={handleInvalidFormat}
                                />
                            </>
                        ) : (
                            <Outlet />
                        )}
                    </Styled.DashboardSectionMainWrapper>
                </Styled.DashboardSectionWrapper>
            </Styled.DashboardContainer>
            {uploadedFiles.length > 0 && (
                <UploadModal
                    show={showUploadModal}
                    onHide={onHideHandler}
                    clientId={authInfo?.user?.username}
                    uploadedFile={uploadedFiles[0]}
                    uploadType={uploadType}
                    onUploadComplete={handleUploadComplete}
                />
            )}
            <UploadProgressModal
                show={showOngoingUploads}
                centered
                onHide={() => {
                    setShowOngoingUploads(false);
                }}
            ></UploadProgressModal>
        </Styled.DashboardPageWrapper>
    );
};
