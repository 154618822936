import * as React from "react";
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { InnerContainer, InnerSection } from "../ui";

import moment from 'moment';

import BlockchainHero from './../../assets/images/block/blockchain.png';
import ApiImage0 from './../../assets/images/block/API_graphic_1.png';
import ApiImage1 from './../../assets/images/block/API_graphic_2.png';

//import * as Styled from "./block.styled";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import './block.scss';

import { rssFeedService } from "../../services";
import { IRssFeed } from "../../libs/models";

export const BlockContainer = () => {

    const [rssFeeds, setRssFeeds] = React.useState<IRssFeed[]>([]);

    const getAllRssFeeds = React.useCallback(async () => {

        try {
            const response = await rssFeedService.getAllRssFeeds();

            const rssFeedItems = response.map((item) => ({
                source: item.source,
                title: item.title,
                date: item.pubdate,
                image: item.imagelink,
                description: item.description,
                url: item.pagelink,
            }));

            setRssFeeds(
                rssFeedItems.sort((a, b) =>
                    new Date(a.pubdate).getTime() < new Date(b.pubdate).getTime() ? -1 : 1
                ).slice(0, 10)
            );

        } catch (err: any) {
            console.log(err);
        } finally {

        }

    }, []);

    React.useEffect(() => {
        getAllRssFeeds();
    }, [getAllRssFeeds]);

    const stripTags = (str) => {
        if ((str === null) || (str === '')) {
            return false;
        } else {
            str = str.toString();
        }
        return str.replace(/(<([^>]+)>)/ig, '');
    };

    const handleOnSwiper = (swiper) => {
        //console.log('%conSwiper::', 'color:dodgerblue;', swiper);
    };

    const handleOnSlideChange = (swiper) => {
        //console.log('%conSlideChange::', 'color:dodgerblue;', 'slide has changed');
    };

    return (

        <InnerSection id="blockchain" className="blockchain" style={{ marginTop: '120px', overflow: 'hidden' }}>

            <InnerContainer className="container">
                <InnerContainer className="row">
                    <InnerContainer className="col-12">

                        <div className="cemboo-blockchain">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">

                                        <div className="row">
                                            <div className="col-12 col-md-5">
                                                <div className="intro my-3 my-md-5">
                                                    <h1 className="d-inline-block gradient-text text-start mx-0 mb-4" data-aos="fade-up"
                                                        data-aos-delay="50" data-aos-duration="800">APIs<br />
                                                        for a Vibrant<br />
                                                        Decentralized<br />
                                                        Future</h1>

                                                    <p className="mb-5" data-aos="zoom-in" data-aos-delay="100" data-aos-duration="750">The Cemboo
                                                        Blockchain is an indexing protocol for querying networks like
                                                        Ethereum and IPFS. Anyone can build and publish open APIs, called subgraphs, making data
                                                        easily accessible.</p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-7">
                                                <div className="d-flex justify-content-center">
                                                    <img className="bc-image" src={BlockchainHero} alt=""
                                                        data-aos="fade-left" data-aos-delay="200" data-aos-duration="750" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="row news-container">
                                    <div className="col-12">

                                        <section className="latest-ecosystem-news">
                                            <div className="section-header">
                                                <h3 className="gradient-text">Latest Ecosystem News</h3>
                                            </div>
                                            <div className="latest-news-items">

                                                <Swiper
                                                    modules={[Autoplay, Pagination]}
                                                    autoplay={{ delay: 6000, pauseOnMouseEnter: true }}
                                                    pagination={{ clickable: true }}
                                                    centeredSlides={false}
                                                    spaceBetween={16}
                                                    slidesPerView={'auto'}
                                                    slidesOffsetBefore={24}
                                                    slidesOffsetAfter={24}
                                                    loop={false}
                                                    grabCursor={true}
                                                    breakpoints={{ 640: { spaceBetween: 24 } }}
                                                    onSwiper={handleOnSwiper}
                                                    onSlideChange={handleOnSlideChange}
                                                    className="blockchain-feed">
                                                    {rssFeeds.map((item, idx) => (
                                                        <SwiperSlide key={idx}>
                                                            <div className="latest-news-item card">
                                                                <a href={item.url} className="card-body--link" target="_blank" rel="noreferrer">
                                                                    <div className="card-body">

                                                                        <div className="icon">
                                                                            <img src={item.image} alt="" />
                                                                        </div>
                                                                        <div className="meta">
                                                                            <div className="date">{moment(item.date).format("MMM Do, YYYY")}</div>
                                                                            <h4>{stripTags(item.title || item.description)}</h4>
                                                                        </div>

                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}
                                                </Swiper>

                                            </div>
                                        </section>

                                    </div>
                                </div>

                                <div className="row media-container">
                                    <div className="col-12">

                                        <section className="media-callout">

                                            <div className="media-items">

                                                <div className="media-item" >
                                                    <div className="image d-none d-md-block">
                                                        <img src={ApiImage0} alt="The Cemboo Blockchain is an indexing protocol for querying networks like Ethereum and IPFS. Anyone can build and publish open APIs, called subgraphs, making data easily accessible." />
                                                    </div>
                                                    <div className="meta">
                                                        <h4 className="gradient-text d-inline-block mb-3">Cemboo Governance Portal</h4>
                                                        <h3 className="mb-4">Empowering Cemboo with&nbsp;governance</h3>
                                                        <img src={ApiImage0} alt="" className="d-block d-md-none mb-3" />
                                                        <p>Experience the pinnacle of innovation as Cemboo's cutting-edge governance portal puts power in your hands, granting you direct influence over Cemboo's pioneering evolution using CMB&nbsp;tokens.</p>
                                                    </div>
                                                </div>

                                                <div className="media-item" >
                                                    <div className="image d-none d-md-block">
                                                        <img src={ApiImage1} alt="The Cemboo Blockchain is an indexing protocol for querying networks like Ethereum and IPFS. Anyone can build and publish open APIs, called subgraphs, making data easily accessible." />
                                                    </div>
                                                    <div className="meta">
                                                        <h4 className="gradient-text d-inline-block mb-3">Cemboo Voting Mechanism</h4>
                                                        <h3 className="mb-4">Revolutionary voting solution through DLT (distributed ledger&nbsp;technology)</h3>
                                                        <img src={ApiImage1} alt="" className="d-block d-md-none mb-3" />
                                                        <p>Embrace the future of user-driven content curation with our cutting-edge blockchain voting solution, providing authentic and robust results for film festivals, contests, or rating&nbsp;systems.</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </section>

                                    </div>
                                </div>

                                <div className="row rewards-container my-3 my-md-5 py-3 py-md-5 g-md-5 d-none">
                                    <div className="col-12 col-md-6 mb-4 mb-md-0">
                                        STAKE _CEMBOO
                                    </div>
                                    <div className="col-12 col-md-6">
                                        REWARDS_CALCULATOR
                                    </div>
                                </div>

                                <div className="row contact-container my-3 my-md-5 py-3 py-md-5">
                                    <div className="col-12">

                                        <section className="contact-cta">
                                            <div className="meta">
                                                <h2 className="gradient-text text-center d-inline-block mb-4 mb-md-5">Need Something Custom <br />or Help Getting&nbsp;Started?</h2>
                                                <div className="">
                                                    <a className="btn btn-primary" href="/contact">Contact Us</a>
                                                </div>
                                            </div>
                                        </section>

                                    </div>
                                </div>
                            </div>
                        </div>



                    </InnerContainer>
                </InnerContainer>
            </InnerContainer>

        </InnerSection>

    );
};
