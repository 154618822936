import { Auth } from "aws-amplify";

export const buildRequestConfig = async () => {
  return {
    headers: {
      "content-type": "application/json",
      Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    },
  };
};
