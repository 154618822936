import * as React from "react";
import { Col, Dropdown, Modal, Row, Spinner } from "react-bootstrap";

import { CembooPlayer } from "@haydenfilms-institute/cemboo-player";
import { confirmAlert } from "react-confirm-alert";

import { IRecording } from "../../../libs";
import { liveChannelService } from "../../../services";
import OptionsIcon from "../../../assets/images/icons/icon-options.svg";
import MoveToVodIcon from "../../../assets/images/icons/icon-move-to-vod.svg";
import DeleteBinIcon from "../../../assets/images/icons/icon-delete-bin.svg";
import DownloadIcon from "../../../assets/images/icons/icon-download.svg";

import * as Styled from "./Recordings.styled";
import { RecordingsModal } from "./recordings-modal";
import { ConfirmModal } from "../../../libs";
import { toast } from "react-toastify";

export const Recordings: React.FC = () => {
    const [recordings, setRecordings] = React.useState<IRecording[]>([]);
    const [showLoader, setShowLoader] = React.useState(true);
    //const [showPlayer, setShowPlayer] = React.useState(false);
    const [currentPlayingMedia, setCurrentPlayingMedia] =
        React.useState<IRecording>();

    const [moveToVodMedia, setMoveToVodMedia] = React.useState<IRecording>();

    const getRecordings = React.useCallback(async () => {
        try {
            const response = await liveChannelService.getRecordings();
            setRecordings(response.recordings);
            setShowLoader(false);
        } catch (err) {
            console.log(err);
            setShowLoader(false);
        }
    }, []);

    React.useEffect(() => {
        getRecordings();
    }, [getRecordings]);

    const handlePlayRecording = React.useCallback((recording: IRecording) => {
        setCurrentPlayingMedia(recording);
    }, []);

    const hidePlayer = React.useCallback(() => {
        setCurrentPlayingMedia(undefined);
    }, []);

    const handleRecordingDownload = React.useCallback(
        async (recording: IRecording) => {
            try {
                const response =
                    await liveChannelService.getDownloadLinkForRecording(
                        recording.id,
                        recording.channelId,
                    );

                const link = document.createElement("a");
                link.href = response.downloadUrl;
                link.setAttribute("download", `${recording.event.name}`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                toast.success("Recording download will start soon.");
            } catch (err) {
                console.log(
                    "There is an error while downloading the recording",
                    err,
                );
            }
        },
        [],
    );

    const handleRecordingDelete = React.useCallback(
        async (recording: IRecording) => {
            try {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <ConfirmModal
                                title="Delete Recording"
                                description={[
                                    {
                                        text: `Are you sure you want to delete the recording of the event "${recording.event.name}"?`,
                                    },
                                ]}
                                onYes={async () => {
                                    try {
                                        await liveChannelService.deleteRecording(
                                            recording.id,
                                            recording.channelId,
                                        );
                                        getRecordings();
                                        onClose();
                                        toast.success(
                                            "Recording deleted successfully.",
                                        );
                                    } catch (err) {
                                        console.log(
                                            "There is an error while deleting the recording",
                                            err,
                                        );
                                    }
                                }}
                                onNo={onClose}
                                danger
                            />
                        );
                    },
                });
            } catch (err) {
                console.log(
                    "There is an error while deleting the recording",
                    err,
                );
            }
        },
        [getRecordings],
    );

    type CustomToggleProps = {
        onClick: (e: React.MouseEvent<HTMLImageElement>) => void;
    };

    const generateRandomKey = React.useCallback(() => {
        return Math.random().toString(36).substring(7);
    }, []);

    const RecordingDropdownToggle = React.forwardRef<
        HTMLImageElement,
        CustomToggleProps
    >(({ onClick }, ref) => (
        <Styled.ShowRecordingOptions
            src={OptionsIcon}
            ref={ref}
            alt="Options"
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        />
    ));

    if (showLoader) {
        return (
            <Styled.SpinnerWrapper>
                <Spinner animation="border">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                <p>Fetching the live channels...</p>
            </Styled.SpinnerWrapper>
        );
    }

    if (currentPlayingMedia) {
        return (
            <Modal show={true} fullscreen={true} onHide={hidePlayer}>
                <Styled.PlayerModalHeader closeButton closeVariant="white">
                    <Modal.Title>
                        {currentPlayingMedia.event.name || "Test"}
                    </Modal.Title>
                </Styled.PlayerModalHeader>
                <Styled.PlayerModalBody>
                    <CembooPlayer
                        url={currentPlayingMedia.video}
                        showThumbnail={true}
                    />
                </Styled.PlayerModalBody>
            </Modal>
        );
    }

    return (
        <Styled.RecordingsContainer fluid>
            <RecordingsModal
                show={!!moveToVodMedia}
                onHide={() => {
                    setMoveToVodMedia(undefined);
                }}
                moveToVodMedia={moveToVodMedia}
                centered
            />

            <Styled.HeaderRow>
                <Col md={10}>
                    <Styled.Title>Recordings</Styled.Title>
                </Col>
            </Styled.HeaderRow>
            {recordings.length === 0 ? (
                <>There are no recorings in your account at this time.</>
            ) : (
                <Row>
                    {recordings.map((item) => (
                        <Col key={generateRandomKey()} lg={3} md={4}>
                            <Styled.RecordingCard>
                                <Styled.RecordingCardDropdown>
                                    <Dropdown.Toggle
                                        as={RecordingDropdownToggle}
                                    />
                                    <Styled.RecorsingCardDropdownMenu>
                                        <Styled.RecordingCardDropdownItem
                                            onClick={() => {
                                                setMoveToVodMedia(item);
                                            }}
                                        >
                                            <Styled.RecordingCardDropdownIcon
                                                src={MoveToVodIcon}
                                            />
                                            Move to VOD
                                        </Styled.RecordingCardDropdownItem>
                                        <Styled.RecordingCardDropdownItem
                                            onClick={() =>
                                                handleRecordingDownload(item)
                                            }
                                        >
                                            <Styled.RecordingCardDropdownIcon
                                                src={DownloadIcon}
                                            />
                                            Download
                                        </Styled.RecordingCardDropdownItem>
                                        <Styled.RecordingCardDropdownItem
                                            danger="true"
                                            onClick={() => {
                                                handleRecordingDelete(item);
                                            }}
                                        >
                                            <Styled.RecordingCardDropdownIcon
                                                src={DeleteBinIcon}
                                            />
                                            Delete
                                        </Styled.RecordingCardDropdownItem>
                                    </Styled.RecorsingCardDropdownMenu>
                                </Styled.RecordingCardDropdown>
                                <Styled.RecordingCardImage
                                    variant="top"
                                    src={item.thumbnail}
                                    onClick={() => handlePlayRecording(item)}
                                />
                                <Styled.RecordingCardBody>
                                    <Styled.RecordingDetails>
                                        <Styled.RecordingCardTitle>
                                            {item.event.name || "Recording One"}
                                        </Styled.RecordingCardTitle>
                                        <Styled.RecordingCardText>
                                            Created:{" "}
                                            {new Date(
                                                item.event.date,
                                            ).toLocaleDateString("en-us", {
                                                year: "numeric",
                                                month: "short",
                                                day: "numeric",
                                            })}
                                        </Styled.RecordingCardText>
                                    </Styled.RecordingDetails>
                                </Styled.RecordingCardBody>
                            </Styled.RecordingCard>
                        </Col>
                    ))}
                </Row>
            )}
        </Styled.RecordingsContainer>
    );
};
