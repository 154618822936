import React from "react";
import { CreateSteps } from "./create-steps";
import * as Styled from "./CreateModalHeader.styled";
import { HeaderLogo } from "../../../../libs";
import { Row, Col } from "react-bootstrap";
import { CreateModalSteps, CreateModalStepsState } from "../types";

interface IProps {
    steps: CreateModalStepsState[];
    onStepClick: (step: CreateModalSteps) => void;
    editMode: boolean;
}

export const CreateModalHeader: React.FC<IProps> = ({
    steps,
    onStepClick,
    editMode,
}) => {
    const [localSteps, setLocalSteps] = React.useState<CreateModalStepsState[]>(
        [],
    );

    React.useEffect(() => {
        setLocalSteps(steps);
    }, [steps]);

    return (
        <Styled.ModalHeader closeButton closeVariant="white">
            <Styled.ModalTitle>
                <Styled.CreateModalHeaderWrapper>
                    <Row>
                        <Col md={1}>
                            <HeaderLogo />
                        </Col>
                        <Col md={10}>
                            {editMode ? (
                                <Styled.EditHeader>
                                    {
                                        localSteps.find(
                                            (step) => step.inprogress,
                                        )?.title
                                    }
                                </Styled.EditHeader>
                            ) : (
                                <CreateSteps
                                    steps={localSteps}
                                    onStepClick={onStepClick}
                                />
                            )}
                        </Col>
                    </Row>
                </Styled.CreateModalHeaderWrapper>
            </Styled.ModalTitle>
        </Styled.ModalHeader>
    );
};
