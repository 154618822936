import { ContactContainer } from "../../components";
import * as Styled from "./contact-page.styled";

export const ContactPage = () => {
    return (
        <Styled.ContactPageWrapper>
            <ContactContainer />
        </Styled.ContactPageWrapper>
    );
};
