import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

import * as Styled from "./ActionMenu.styled";

import ThreeDotSvgIcon from "./../../../assets/images/icons/three-dots-icon.svg";

type ActionMentItem = {
  label: string;
  onClickHandler: () => void;
};

interface IProps {
  menu: {
    [key in string]: ActionMentItem;
  };
}

const ActionIcon = React.forwardRef<
  HTMLImageElement,
  React.HTMLProps<HTMLImageElement>
>((props, ref) => (
  <img
    src={ThreeDotSvgIcon}
    alt=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      props.onClick!(e);
    }}
  />
));

export const ActionMenu: React.FC<IProps> = ({ menu }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle
        as={ActionIcon}
        id="dropdown-custom-components"
      ></Dropdown.Toggle>

      <Styled.DropdownMenu>
        {Object.values(menu).map((value) => (
          <Dropdown.Item
            key={value.label}
            onClick={value.onClickHandler}
            eventKey="1"
          >
            {value.label}
          </Dropdown.Item>
        ))}
      </Styled.DropdownMenu>
    </Dropdown>
  );
};
