import React from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth, useGlobalState } from "../../behavioral";
import { DashboardContent } from "../../components";
import { ICategoryType, IMediaResponseDto } from "../../libs";
import { vodService } from "../../services";

import * as Styled from "./dashboard-page.styled";

export const DashboardPage = () => {
    const navigate = useNavigate();
    const auth = useAuth();

    const [clientId, setClientId] = React.useState("");
    const [showLoader, setShowLoader] = React.useState(true);
    const [mediaItems, setMediaItems] = React.useState<IMediaResponseDto[]>([]);
    const [categories, setCategories] = React.useState<ICategoryType[]>([]);
    const [
        shouldListenToMediaConvertStatus,
        setShouldListenToMediaConvertStatus,
    ] = React.useState(false);
    // eslint-disable-next-line
    const [progressItems, setProgressItems] = React.useState<{
        [key in string]: { percentage: number; currentPhase: string };
    }>({});

    const { state, actions, dispatch } = useGlobalState();

    const getUserUploads = React.useCallback(async () => {
        dispatch({
            type: actions.SET_REFRESH_ON_UPLOAD,
            payload: false,
        });
        const results = await vodService.getAllMediaItems();
        const categories_l = await vodService.getCategories();
        setCategories(categories_l);
        if (results) {
            setMediaItems(results);
            setShowLoader(false);
            if (
                results.filter((item: IMediaResponseDto) => !item.processed)
                    .length > 0
            ) {
                setShouldListenToMediaConvertStatus(true);
            } else {
                setShouldListenToMediaConvertStatus(false);
            }
        }
    }, [dispatch, actions]);

    React.useEffect(() => {
        if (state.refreshOnUpload) getUserUploads();
    }, [state.refreshOnUpload, getUserUploads]);

    const listenForMediaConvertStatus = React.useCallback(() => {
        const websocket = new WebSocket(
            `${process.env.REACT_APP_VOD_STATUS_WEBSOCKET_URL}?clientId=${auth.user?.username}`,
        );

        websocket.onopen = (event) => {
            console.log("Connected to Socket");
            websocket.send(
                JSON.stringify({
                    action: "getConnectionId",
                    clientId: auth.user.username,
                }),
            );
        };

        websocket.onmessage = (event) => {
            const data = JSON.parse(event.data);

            if (data.type === "initial") {
                localStorage.setItem("connectionId", data.connectionId);
            } else if (data.type === "status") {
                // Set media convert status here
                if (data.mediaInfo) {
                    // setProgressItems({
                    //   ...progressItems,
                    //   [data.mediaInfo.id]: {
                    //     currentPhase: data.mediaInfo.progress.currentPhase,
                    //     percentage: data.mediaInfo.progress.percentage,
                    //   },
                    // });
                }
            } else {
                if (data.type === "complete") {
                    // set media convert ouputs here
                    // let updatedProgressItems = {};
                    // Object.keys(progressItems).forEach((key) => {
                    //   if (key !== data.mediaInfo.id) {
                    //     updatedProgressItems = {
                    //       [key]: progressItems,
                    //     };
                    //   }
                    // });

                    // setProgressItems({ ...updatedProgressItems });
                    console.log("Fetched uploads again");
                    getUserUploads();
                }
            }
        };

        websocket.onclose = (event) => {
            localStorage.removeItem("connectionId");
        };

        websocket.onerror = (event) => {
            console.log(
                "There is an error while connecting to websocket",
                event,
            );
        };
    }, [auth.user?.username, getUserUploads]);

    React.useEffect(() => {
        if (auth.isAuthenticated) {
            setClientId(auth.user.username);
            getUserUploads();
        } else {
            navigate("/login", { replace: true });
        }
    }, [getUserUploads, navigate, auth.isAuthenticated, auth.user]);

    const handleUploadsUpdate = React.useCallback(() => {
        getUserUploads();
    }, [getUserUploads]);

    // Check if any video has isProgressing then create a websocket connection and listen
    // const shouldListenToMediaConvertStatus = React.useMemo(() => {
    //   return mediaItems && mediaItems.length > 0
    //     ? mediaItems.filter((item) => !item.processed).length > 0
    //     : false;
    // }, [mediaItems]);

    React.useEffect(() => {
        if (shouldListenToMediaConvertStatus) {
            listenForMediaConvertStatus();
        }
    }, [listenForMediaConvertStatus, shouldListenToMediaConvertStatus]);

    return (
        <>
            {showLoader ? (
                <Styled.SpinnerWrapper>
                    <Spinner animation="border">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    <p>Fetching the uploads...</p>
                </Styled.SpinnerWrapper>
            ) : (
                <DashboardContent
                    clientId={clientId}
                    mediaItems={mediaItems}
                    categories={categories}
                    onUpdateUploads={handleUploadsUpdate}
                    progressItems={progressItems}
                />
            )}
        </>
    );
};
