import styled from "styled-components";
import { colors, types } from "../../../libs";

export const AnalyticsCardsTitle = styled.h3`
    ${types.headers.item};
    padding-bottom: 10px;
`;

export const AnalyticsCardsTitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
`;

export const AnalyticsCardsDurationWrapper = styled.div`
    display: flex;
    border: 2px solid ${colors.background.lightWhite};
    border-radius: 5px;
`;

export const AnalyticsCardsDuration = styled.div<{
    selected: boolean;
}>`
    padding: 5px 10px;
    border-radius: 2px;
    background-color: ${({ selected }) =>
        selected ? colors.background.lightWhite : "transparent"};
    &:hover {
        cursor: pointer;
        background-color: ${colors.background.lightWhite};
    }
`;
