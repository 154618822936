import { Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { colors, types } from "../../../libs";

export const Subtitle = styled.h3`
  ${types.body.title};
  background: ${colors.text.textGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const DetailRow = styled(Row)`
  margin-top: 20px;
`;

export const DetailLabel = styled(Form.Label)`
  display: block;
  ${types.headers.small};
`;

export const DetailLabelHeader = styled(Form.Label)`
  display: block;
  margin-top: 20px;
  ${types.headers.section};
`;

export const DetailText = styled(Form.Text)`
  color: ${colors.text.mediumWhite};
`;
