import { TeamContainer } from "../../components";
import * as Styled from "./team-page.styled";

export const TeamPage = () => {
    return (
        <Styled.TeamPageWrapper>
            <TeamContainer />
        </Styled.TeamPageWrapper>
    );
};
