export interface MediaLibraryConfig {
    libraryId: 1;
    fetchByDate?: boolean;
    filterControls?: boolean;
    ratingControls?: boolean;
    shareControls?: boolean;
    mediaPerPage?: number;
    enabled?: boolean;
}

export interface LiveStreamLibraryConfig {
    libraryId: 2;
    tabControls?: boolean;
    shareControls?: boolean;
    enabled?: boolean;
}

export enum LibraryType {
    MediaLibrary = 1,
    LiveStreamLibrary = 2,
}