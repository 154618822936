import { useFormik } from "formik";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import {
  BaseButton,
  Error,
  FloatingTextInput as TextInput,
} from "./../../libs";

import { Spinner } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { useAuth } from "../../behavioral";
import { ROUTEPATHS } from "../../libs/common/constants";
import { userService } from "../../services";
import { ResetPassword } from "../reset-password";
import { Loader } from "../ui";
import { VerifyAccountModal } from "../verify-account-modal";
import * as Styled from "./LoginForm.styled";
import { ChangePassword } from "./change-password";

export const LoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation() as any;
  const [showNotConfirmedError, setShowNotConfirmedError] =
    React.useState(false);
  const [loginFailureMessage, setLoginFailureMessage] = React.useState("");
  const [resetPasswordEmailRequiredError, setResetPasswordEmailRequiredError] =
    React.useState(false);
  const [
    noEmailFoundForpasswordResetError,
    setNoEmailFoundForpasswordResetError,
  ] = React.useState("");
  const [showChangePassword, setShowChangePassword] = React.useState(false);
  const [cognitoUser, setCognitoUser] = React.useState();
  const {
    signIn,
    isAuthenticated,
    isAuthenticating,
    resetPassword,
    resendConfirmationCode,
    confirmAccount,
  } = useAuth();

  const [showResetPasswordForm, setShowResetPasswordForm] =
    React.useState(false);

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate(
        `${location.state}`
          ? `${location.state?.redirect}`
          : `${ROUTEPATHS.DASHBOARD}`,
        { replace: true }
      );
    }
  }, [isAuthenticated, location.state, navigate]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Password should contain minimum of 8 characters.")
        .required("Please enter a password"),
      email: Yup.string()
        .email("Please provide a valid email address")
        .required("Please enter an email address"),
    }),
    onSubmit: async (values) => {
      setLoginFailureMessage("");
      await handleUserLoginSubmit(values.email, values.password);
    },
  });

  const resetAllErrors = React.useCallback(() => {
    formik.setErrors({ email: "", password: "" });
    setNoEmailFoundForpasswordResetError("");
    setLoginFailureMessage("");
    setResetPasswordEmailRequiredError(false);
    setShowNotConfirmedError(false);
  }, [formik]);

  const handleUserLoginSubmit = React.useCallback(
    async (email: string, password: string) => {
      try {
        const currentUser = await signIn({
          email,
          password,
        });
        console.log(currentUser);
        if (currentUser.challengeName === "NEW_PASSWORD_REQUIRED") {
          // TODO: handle logic to submit new password
          setCognitoUser(currentUser);
          setShowChangePassword(true);
        } else {
          navigate("/dashboard", { replace: true });
        }
      } catch (err: any) {
        console.log(err.code);
        switch (err.code) {
          case "UserNotFoundException":
            setLoginFailureMessage(
              "There is no account with the provided email address"
            );
            break;
          case "NotAuthorizedException":
            setLoginFailureMessage("Incorrect username or password");
            break;
          case "UserNotConfirmedException":
            setShowNotConfirmedError(true);
            setLoginFailureMessage(
              `Your account has not been confirmed yet. To complete the confirmation process, please click below link to receive a confirmation code:`
            );
            break;
        }
      }
    },
    [navigate, signIn]
  );

  const handleBlur = React.useCallback(
    (event) => {
      resetAllErrors();
      formik.handleBlur(event);
    },
    [formik, resetAllErrors]
  );

  const handlePasswordResetClick = React.useCallback(async () => {
    resetAllErrors();
    if (formik.values.email) {
      // send request to check if email is valid
      const user = await userService.getUserByEmail(formik.values.email);
      if (user) {
        setShowResetPasswordForm(true);
        await resetPassword(formik.values.email);
      } else {
        setNoEmailFoundForpasswordResetError(
          "There is no account with provided email address"
        );
      }
    } else {
      setResetPasswordEmailRequiredError(true);
    }
  }, [formik, resetPassword, resetAllErrors]);

  const isDisabled = React.useMemo(() => {
    // disable when submitting is true
    if (formik.isSubmitting) {
      return true;
    }

    if (!formik.values.email || !formik.values.password) {
      return true;
    }

    if (!formik.isValid) {
      return true;
    }
  }, [formik]);

  const handleConfirmSuccess = React.useCallback(
    (onClose: () => void) => {
      // TODO: Login the user and redirect to dashboard
      resetAllErrors();
      onClose();
    },
    [resetAllErrors]
  );

  const handleVerifyAccountShowModal = React.useCallback(() => {
    confirmAlert({
      childrenElement: () => <div />,
      customUI: ({ onClose }) => {
        return (
          <VerifyAccountModal
            onClose={onClose}
            unverifiedEmail={formik.values.email}
            onConfirmAccount={confirmAccount}
            onConfirmSuccessContinue={() => handleConfirmSuccess(onClose)}
            onResendCode={resendConfirmationCode}
          />
        );
      },
      closeOnClickOutside: false,
    });
  }, [
    formik.values.email,
    confirmAccount,
    handleConfirmSuccess,
    resendConfirmationCode,
  ]);

  const onResendCodeClick = React.useCallback(async () => {
    // TODO: send the confirmation code using email provided in form
    try {
      await resendConfirmationCode(formik.values.email);
      // show account verify modal
      handleVerifyAccountShowModal();
    } catch (err) {
      console.log(err);
    }
  }, [
    formik.values.email,
    resendConfirmationCode,
    handleVerifyAccountShowModal,
  ]);

  if (isAuthenticating || isAuthenticated) {
    return <Loader />;
  }

  return (
    <Styled.LoginPageWrapper>
      {showResetPasswordForm ? (
        <ResetPassword email={formik.values.email} />
      ) : showChangePassword ? (
        <ChangePassword
          currentUser={cognitoUser}
          email={formik.values.email}
          temporaryPassword={formik.values.password}
        />
      ) : (
        <>
          <Styled.LoginPageHeader>Sign in to Cemboo</Styled.LoginPageHeader>
          <Styled.LoginForm onSubmit={formik.handleSubmit}>
            {loginFailureMessage && <Error message={loginFailureMessage} />}
            {showNotConfirmedError && (
              <Styled.ResendLink onClick={onResendCodeClick}>
                Resend Confirmation code
              </Styled.ResendLink>
            )}
            {noEmailFoundForpasswordResetError && (
              <Error message={noEmailFoundForpasswordResetError} />
            )}
            <Styled.LoginFormRow>
              <Styled.LoginFormCol>
                <TextInput
                  label="Email Address"
                  type="email"
                  placeholder="Email Address"
                  controlId="email"
                  value={formik.values.email}
                  onChangeHandler={formik.handleChange}
                  onBlurHandler={handleBlur}
                  hasError={
                    resetPasswordEmailRequiredError ||
                    !!noEmailFoundForpasswordResetError ||
                    (formik.touched.email && !!formik.errors.email)
                  }
                />
                {formik.touched.email && formik.errors.email && (
                  <Error message={formik.errors.email} />
                )}
              </Styled.LoginFormCol>
            </Styled.LoginFormRow>
            <Styled.LoginFormRow>
              <Styled.LoginFormCol>
                <TextInput
                  label="Password"
                  type="password"
                  placeholder="Password"
                  controlId="password"
                  value={formik.values.password}
                  onChangeHandler={formik.handleChange}
                  onBlurHandler={formik.handleBlur}
                  hasError={formik.touched.password && !!formik.errors.password}
                />
              </Styled.LoginFormCol>
              {formik.touched.password && formik.errors.password && (
                <Error message={formik.errors.password} />
              )}
            </Styled.LoginFormRow>
            <Styled.LoginFormRow className="justify-content-end" sm={3}>
              <Styled.LoginFormCol>
                <Styled.ForgotPasswordText onClick={handlePasswordResetClick}>
                  Forgot your password?
                </Styled.ForgotPasswordText>
              </Styled.LoginFormCol>
            </Styled.LoginFormRow>

            <Styled.LoginFormRow>
              <Styled.LoginFormCol md={4}>
                <BaseButton
                  type="submit"
                  variant={!isDisabled ? "primary" : "secondary"}
                  disabled={isDisabled}
                >
                  {formik.isSubmitting ? (
                    <>
                      <Spinner size="sm" /> Signing In
                    </>
                  ) : (
                    "Sign In"
                  )}
                </BaseButton>
              </Styled.LoginFormCol>
            </Styled.LoginFormRow>
          </Styled.LoginForm>
        </>
      )}
    </Styled.LoginPageWrapper>
  );
};
