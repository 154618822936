import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../libs";

export const NavigationWrapper = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const LogoWrapper = styled.div`
    padding: 10px;
    transition: all 0.3s ease-out;

    @media only screen and (min-width: 768px) {
        min-width: 230px;
        padding: 20px;
        transition: all 0.3s ease-out;
    }
`;

export const LogoLink = styled.a``;

export const LogoImage = styled.img`
    cursor: pointer;
    width: 121px;
`;

export const NavLeftLinksWrapper = styled.ul`
    margin: 0;
    padding: 0;
    display: none;
    list-style: none;
    align-items: center;
    transition: all .3s ease-out;

    @media (min-width: 992px) {
        display: flex;
    }
`;

export const NavLinkItem = styled.li`
    position: relative;
`;

export const StyledNavLink = styled(NavLink) <any>`
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.5;
    text-shadow: 0px 0px 0px rgba(255,255,255, 0);
    color: rgba(255,255,255, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem 0.938rem;
    white-space: nowrap;
    transition: all 0.3s ease-out;

    &.active {
        color: rgba(183, 248, 77, 1);
        transition: all 0.3s ease-out;

        @media(hover: hover) {
            &:hover {
                text-shadow: 0 0 1rem rgba(255,255,255, 0.6);
                color: rgba(183, 248, 77, 1);
                transition: all 0.3s ease-out;
            }
        }
    }

    @media(hover: hover) {
        &:hover {
            text-shadow: 0 0 1rem rgba(255,255,255, 0.6);
            color: rgba(255,255,255, 1);
            transition: all 0.3s ease-out;
        }
    }
`;

export const NavRightLinksWrapper = styled.ul`
    min-width: 230px;
    padding: 10px 20px;
    display: flex;
    flex-flow: row;
    justify-content: end;
    gap: 1.5rem;
    
    @media only screen and (max-width: 600px) {
        padding: 0;
    }

    & > li {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & .auth-link {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.5;
        text-shadow: rgba(255, 255, 255, 0) 0px 0px 0px;
        color: rgb(255, 255, 255);
    }
`;

export const MobileMenuIconWrapper = styled.div`
    line-height: 0;
    opacity: 0.6;
    display: block;
    transition: all 0.5s ease-out;

    @media (min-width: 992px) {
        display: none;
    }

    @media(hover: hover) {
        &:hover {
            opacity: 1;
            cursor: pointer;
            transition: all 0.5s ease-out;
        }
    }
`;

export const SearchBarWrapper = styled.div`
    position: relative;
    width: 500px;
`;

export const SearchInput = styled.input`
    width: 100%;
    border-radius: 20px;
    padding: 8px 50px;
    background: #0c0c0c;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.2);

    &:focus {
        outline: none;
    }
`;

export const SearchIcon = styled.img`
  position: absolute;
  left: 25px;
  top: 14px;
`;

export const UserMenuIconsWrapper = styled.div``;

export const NotificationsIcon = styled.i`
  color: ${colors.text.green};
`;

export const ProfileIcon = styled.img``;
