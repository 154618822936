import React from "react";
import { Accordion, Row, Col, Card } from "react-bootstrap";
import { Switch, TextInput, BaseButton, MediaLibraryConfig, LiveStreamLibraryConfig } from "../../../libs";
import * as Styled from "./ManageLibrary.styled";

export const ManageLibrary: React.FC<{
    title: string;
    controls: {
        title: string;
        key: string;
        type: string;
    }[];
    config: MediaLibraryConfig | LiveStreamLibraryConfig;
    onSave: (config: MediaLibraryConfig | LiveStreamLibraryConfig) => void;
}> = ({ title, controls, config, onSave }) => {
    const [localConfig, setLocalConfig] = React.useState(config);


    const handleUpdateText = React.useCallback((event: any, key: string, isNumber: boolean) => {
        let value = event.target.value;
        value = isNumber ? parseInt(value) : value;
        if (isNaN(value)) value = "";
        setLocalConfig({ ...localConfig, [key]: value });
    }, [setLocalConfig, localConfig]);

    const disbaleSave = React.useMemo(() => {
        const isDifferent = controls.some((control) => config[control.key] !== localConfig[control.key]);
        return isDifferent;
    }, [controls, config, localConfig]);

    React.useEffect(() => {
        setLocalConfig(config);
    }, [config]);

    const handleEnable = React.useCallback(() => {
        const configL = { ...localConfig, enabled: !localConfig.enabled };
        setLocalConfig(configL);
        onSave(configL)
    }, [setLocalConfig, localConfig, onSave]);


    return (<Accordion activeKey={localConfig.enabled ? "0" : ""}>
        <Styled.Wrapper>
            <Card.Header>
                <Row>
                    <Styled.ManageTitle>
                        {title}
                        <Switch
                            checked={localConfig.enabled}
                            onChange={handleEnable}
                        />
                    </Styled.ManageTitle>
                </Row>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
                <Card.Body>
                    <Row>
                        {controls.map((control, index) => {
                            return (
                                <Col md={6} key={control.title}>
                                    <Styled.ControlBody>
                                        <Col xs={6}>
                                            <Styled.ControlTitle>
                                                {control.title}
                                            </Styled.ControlTitle>
                                        </Col>
                                        <Col xs={6} style={control.type === "switch" ? { paddingTop: "11px", paddingBottom: "11px" } : {}}>
                                            {control.type === "switch" ? (
                                                <Switch
                                                    checked={localConfig[control.key]}
                                                    onChange={() => setLocalConfig({ ...localConfig, [control.key]: !localConfig[control.key] })}
                                                />
                                            ) : (
                                                <TextInput
                                                    fieldName={`text-input-${index}`}
                                                    placeholder={control.title}
                                                    fieldValue={localConfig[control.key]}
                                                    fieldType={control.type === "input-number" ? "number" : "text"}
                                                    onChangeHandler={(e) =>
                                                        handleUpdateText(e, control.key, control.type === "input-number")
                                                    }
                                                />
                                            )}
                                        </Col>
                                    </Styled.ControlBody>
                                </Col>
                            );
                        })}
                    </Row>
                    <Row>
                        <Col md={3} style={{ marginLeft: 'auto' }}>
                            <BaseButton
                                onClick={() => onSave(localConfig)}
                                variant={disbaleSave ? "primary" : "secondary"}
                                disabled={!disbaleSave}
                            >
                                Save
                            </BaseButton>
                        </Col>
                    </Row>
                </Card.Body>
            </Accordion.Collapse>
        </Styled.Wrapper>
    </Accordion>);
};