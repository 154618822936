import { Badge, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { colors, types } from "../../libs";

export const HeaderRow = styled(Row)`
  margin: 20px 0;
  padding: 20px 0;
  border-bottom: 2px solid ${colors.text.lightWhite};
  border-top: 2px solid ${colors.text.lightWhite};
`;

export const ListHeaderCol = styled(Col)`
  ${types.headers.small};
`;

export const DataRow = styled(Row)`
  padding: 15px 0;
  margin: 10px 0;
  border-bottom: 2px solid ${colors.text.lightWhite};
  color: ${colors.text.mediumWhite};
`;

export const ChannelId = styled.p`
  ${types.body.normal};
  padding-bottom: 1px;
  text-decoration: underline;
  color: ${colors.text.mediumWhite};
  &:hover {
    color: ${colors.text.lightGreen};
  }
  cursor: pointer;
`;

export const IngestEndpoint = styled.p`
  ${types.body.normal};
`;

export const StreamKey = styled.p`
  ${types.body.small};
  color: ${colors.text.black};
  background: #eeeee4;
  padding: 5px;
  border-radius: 10px;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const StreamKeyText = styled.span`
  width: 80%;
`;

export const CopyBadge = styled(Badge)`
  cursor: pointer;
`;
