import React from "react";
import * as Styled from "./PlaylistDetails.styled";
import {
    TextInput,
    TextAreaInput,
    Error,
    BaseButton,
    IMediaResponseDto,
    ConfirmModal,
    FileWithPreview,
    MultiPartUploader,
    PlaylistPublishStatus,
    PlaylistStatus,
    IPlaylistResponseDto,
} from "../../../../../libs";
import UploadIcon from "../../../../../assets/images/upload_icon.svg";
import { RadioInput, IPlaylistRequestDto } from "../../../../../libs";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import { confirmAlert } from "react-confirm-alert";
import { useAuth } from "../../../../../behavioral";
import { Spinner } from "react-bootstrap";
import { playlistsService } from "../../../../../services";
import IconArrowRight from "../../../../../assets/images/icons/icon-arrow-right.svg";
import { CreateModalSteps } from "../../types";

enum Visibility {
    Public = 0,
    Private = 1,
    Unlisted = 2,
}

enum Audience {
    G = 0,
    PG = 1,
    PG13 = 2,
    R = 3,
    NC17 = 4,
}

interface IProps {
    playlistName: string;
    mediaItems: IMediaResponseDto[];
    selectedVideos: string[];
    playlist?: IPlaylistResponseDto;
    handleNextStep: (step: CreateModalSteps) => void;
    hideCallback: () => void;
}

export const PlaylistDetails: React.FC<IProps> = ({
    playlistName,
    mediaItems,
    selectedVideos,
    handleNextStep,
    hideCallback,
    playlist,
}) => {
    const { user } = useAuth();

    const formik = useFormik({
        initialValues: {
            playlistTitle: playlist?.title || playlistName,
            playlistDescription: playlist?.description || "",
            Visibility: playlist ? playlist.visibility : Visibility.Private,
            Audience: playlist?.audience || Audience.G,
        },
        validationSchema: Yup.object({
            playlistTitle: Yup.string()
                .max(100, "Must be 100 characters or less")
                .required("Required"),
            playlistDescription: Yup.string().max(
                500,
                "Must be 500 characters or less",
            ),
        }),
        onSubmit: async (values) => {
            setIsSubmitting(true);
            try {
                if (selectedThumbnailVideoID === "userSelectedThumbnail") {
                    if (userSelectedThumbnail) {
                        await uploadThumbnailToS3();
                    } else {
                        if (playlist) {
                            await createPlaylistCallback(
                                playlist.thumbnails.userUploaded?.s3Key || "",
                            );
                        }
                    }
                } else {
                    var s3Prefix =
                        mediaItems.find(
                            (media) =>
                                media.mediaId === selectedThumbnailVideoID,
                        )?.thumbnailsSrc || "";

                    await createPlaylistCallback(s3Prefix, true);
                }
            } catch (e) {
                console.log(e);
            }
        },
    });

    const [selectedThumbnailVideoID, setSelectedThumbnailVideoID] =
        React.useState<string>(mediaItems[0]?.mediaId || "");
    const [userSelectedThumbnail, setUserSelectedThumbnail] =
        React.useState<FileWithPreview>();
    const [existingUserThumbnail, setExistingUserThumbnail] =
        React.useState<string>("");
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const [percentage, setPercentage] = React.useState<number>(0);

    const { open } = useDropzone({
        noClick: true,
        noKeyboard: true,
        maxFiles: 1,
        accept: "image/*",
        onDropRejected: (files) => {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <ConfirmModal
                            title={
                                files.length > 1
                                    ? "Too many files"
                                    : "Invalid file type"
                            }
                            description={[
                                {
                                    text:
                                        files.length > 1
                                            ? "You can only upload one file"
                                            : "You can only upload image files",
                                    bold: false,
                                },
                            ]}
                            noText="Cancel"
                            yesText="OK"
                            onYes={() => {
                                onClose();
                            }}
                            onNo={() => {
                                onClose();
                            }}
                        ></ConfirmModal>
                    );
                },
            });
        },
        onDropAccepted: (files) => {
            setUserSelectedThumbnail({
                file: files[0],
                previewUrl: URL.createObjectURL(files[0]),
            });
            setSelectedThumbnailVideoID("userSelectedThumbnail");
        },
    });

    const createPlaylistCallback = React.useCallback(
        async (s3Key: string, existing: boolean = false) => {
            const request: IPlaylistRequestDto = {
                title: formik.values.playlistTitle,
                description: formik.values.playlistDescription,
                visibility: formik.values.Visibility,
                audience: formik.values.Audience,
                thumbnails: existing
                    ? { existing: { url: s3Key } }
                    : {
                          userUploaded: {
                              fileName: userSelectedThumbnail?.file.name
                                  ? userSelectedThumbnail?.file.name
                                  : playlist
                                    ? playlist.thumbnails.userUploaded
                                          ?.fileName || "unknown"
                                    : "unknown",
                              s3Key: s3Key,
                          },
                      },
                mediaItems: selectedVideos,
                steps: PlaylistStatus.PUBLISHED,
                playliststatus: PlaylistPublishStatus.PUBLISHED,
                category: "",
            };
            console.log(request);
            if (playlist)
                await playlistsService.updatePlaylist(
                    request,
                    playlist.playlistId,
                );
            else await playlistsService.createPlaylist(request);
            setIsSubmitting(false);
            hideCallback();
        },
        [
            formik,
            userSelectedThumbnail,
            selectedVideos,
            setIsSubmitting,
            hideCallback,
            playlist,
        ],
    );

    const uploadThumbnailToS3 = React.useCallback(async () => {
        if (userSelectedThumbnail) {
            const uploader = new MultiPartUploader({
                fileName: userSelectedThumbnail.file.name,
                file: userSelectedThumbnail.file,
                clientId: user.username,
                mediaId: "playlist",
                category: "thumbnails",
            });

            uploader
                .getOnProgress((progress) => setPercentage(progress))
                .getOnComplete(async (response) => {
                    await createPlaylistCallback(response.s3Key);
                });
            await uploader.start();
        }
    }, [userSelectedThumbnail, user, setPercentage, createPlaylistCallback]);

    const isFormValid = React.useMemo(() => {
        return formik.isValid && selectedThumbnailVideoID;
    }, [formik.isValid, selectedThumbnailVideoID]);

    const updateThumbnailSelection = React.useCallback(() => {
        if (playlist) {
            if (playlist.thumbnails.existing) {
                const mediaId = mediaItems.find(
                    (media) =>
                        media.thumbnailsSrc ===
                        playlist.thumbnails.existing?.url,
                )?.mediaId;
                setSelectedThumbnailVideoID(mediaId || "");
            } else if (playlist.thumbnails.userUploaded) {
                setSelectedThumbnailVideoID("userSelectedThumbnail");
                setExistingUserThumbnail(
                    `${playlist.thumbnails.userUploaded.cdnPath}/${playlist.thumbnails.userUploaded.s3Key}`,
                );
            }
        }
    }, [playlist, mediaItems]);

    React.useEffect(() => {
        updateThumbnailSelection();
    }, [updateThumbnailSelection]);

    return (
        <Styled.PlaylistSettingsWrapper>
            <Styled.PlaylistSettingsRow>
                <Styled.PlaylistSettingsCol md={6} className="mx-auto">
                    <Styled.PlaylistSettingsRow>
                        <Styled.BackIconContainerCol
                            onClick={() =>
                                handleNextStep(CreateModalSteps.ORDER_SELECTION)
                            }
                        >
                            <Styled.BackIcon src={IconArrowRight} alt="" />
                            Back
                        </Styled.BackIconContainerCol>
                    </Styled.PlaylistSettingsRow>
                    <Styled.PlaylistSettingsRow>
                        <Styled.PlaylistSettingsCol>
                            <Styled.PlaylistSettingsTitle>
                                Playlist Details
                            </Styled.PlaylistSettingsTitle>
                        </Styled.PlaylistSettingsCol>
                    </Styled.PlaylistSettingsRow>
                    <Styled.PlaylistSettingsRow className="mt-3">
                        <Styled.PlaylistSettingsCol>
                            <TextInput
                                fieldName="playlistTitle"
                                fieldLabel="Playlist Title"
                                placeholder="Add an exciting title to your playlist"
                                inputLimit={100}
                                fieldValue={formik.values.playlistTitle}
                                onChangeHandler={formik.handleChange}
                                hasError={
                                    formik.touched.playlistTitle &&
                                    formik.errors.playlistTitle
                                        ? true
                                        : false
                                }
                                onBlurHandler={formik.handleBlur}
                            />
                            {formik.touched.playlistTitle &&
                                formik.errors.playlistTitle && (
                                    <Error
                                        message={formik.errors.playlistTitle}
                                    />
                                )}
                        </Styled.PlaylistSettingsCol>
                    </Styled.PlaylistSettingsRow>
                    <Styled.PlaylistSettingsRow className="mt-3">
                        <Styled.PlaylistSettingsCol>
                            <TextAreaInput
                                fieldName="playlistDescription"
                                fieldLabel="Description (Optional)"
                                placeholder="Tell viewers about your playlist"
                                rows={5}
                                fieldValue={formik.values.playlistDescription}
                                onChangeHandler={formik.handleChange}
                                hasError={
                                    formik.touched.playlistDescription &&
                                    formik.errors.playlistDescription
                                        ? true
                                        : false
                                }
                                onBlurHandler={formik.handleBlur}
                            />
                            {formik.touched.playlistDescription &&
                                formik.errors.playlistDescription && (
                                    <Error
                                        message={
                                            formik.errors.playlistDescription
                                        }
                                    />
                                )}
                        </Styled.PlaylistSettingsCol>
                    </Styled.PlaylistSettingsRow>
                    <Styled.PlaylistSettingsRow className="mt-5">
                        <Styled.PlaylistSettingsCol>
                            <Styled.PlaylistSettingsSection>
                                Thumbnail
                            </Styled.PlaylistSettingsSection>
                            <Styled.PlaylistSettingsSubtitle>
                                Select or upload an image that reflects the
                                content of the playlist. A good thumbnail stands
                                out and attracts the attention of users.
                            </Styled.PlaylistSettingsSubtitle>
                            {(!userSelectedThumbnail ||
                                existingUserThumbnail) && (
                                <Error message="* Upload a thumbnail" />
                            )}
                        </Styled.PlaylistSettingsCol>
                    </Styled.PlaylistSettingsRow>
                    <Styled.PlaylistSettingsRow className="mt-3">
                        <Styled.PlaylistSettingsCol>
                            <Styled.ThumbnailGridRow>
                                <Styled.ThumbnailGridCol md={3}>
                                    <Styled.ThumbnailGridUpload onClick={open}>
                                        <img
                                            src={UploadIcon}
                                            alt="Upload Thumbnail"
                                        />
                                        Upload Thumbnail
                                    </Styled.ThumbnailGridUpload>
                                </Styled.ThumbnailGridCol>
                                {userSelectedThumbnail ||
                                existingUserThumbnail ? (
                                    <Styled.ThumbnailGridCol
                                        md={3}
                                        selected={
                                            selectedThumbnailVideoID ===
                                            "userSelectedThumbnail"
                                        }
                                        onClick={() =>
                                            setSelectedThumbnailVideoID(
                                                "userSelectedThumbnail",
                                            )
                                        }
                                    >
                                        {userSelectedThumbnail ? (
                                            <Styled.ThumbnailGridImg
                                                src={
                                                    userSelectedThumbnail.previewUrl
                                                }
                                                alt="User selected thumbnail"
                                            />
                                        ) : existingUserThumbnail ? (
                                            <Styled.ThumbnailGridImg
                                                src={existingUserThumbnail}
                                                alt="User selected thumbnail"
                                            />
                                        ) : null}
                                    </Styled.ThumbnailGridCol>
                                ) : null}
                                {mediaItems.map((media, index) => {
                                    if (
                                        selectedVideos.includes(media.mediaId)
                                    ) {
                                        return (
                                            <Styled.ThumbnailGridCol
                                                md={3}
                                                key={index}
                                                selected={
                                                    selectedThumbnailVideoID ===
                                                    media.mediaId
                                                }
                                                onClick={() =>
                                                    setSelectedThumbnailVideoID(
                                                        media.mediaId,
                                                    )
                                                }
                                            >
                                                <Styled.ThumbnailGridImg
                                                    src={media.thumbnailsSrc}
                                                    alt={media.title}
                                                />
                                            </Styled.ThumbnailGridCol>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </Styled.ThumbnailGridRow>
                        </Styled.PlaylistSettingsCol>
                    </Styled.PlaylistSettingsRow>
                    <Styled.PlaylistSettingsRow className="mt-5">
                        <Styled.PlaylistSettingsCol>
                            <Styled.PlaylistSettingsSection>
                                Visibility
                            </Styled.PlaylistSettingsSection>
                            <Styled.PlaylistSettingsSubtitle>
                                Choose when to publish and who can see your
                                playlist. Don't worry you'll be able to change
                                this afterwords.
                            </Styled.PlaylistSettingsSubtitle>
                        </Styled.PlaylistSettingsCol>
                    </Styled.PlaylistSettingsRow>
                    <Styled.PlaylistSettingsRow className="mt-2">
                        <Styled.PlaylistSettingsCol>
                            <Styled.VisibilitySection gap={3}>
                                <RadioInput
                                    fieldName="Visibility"
                                    fieldLabel="Private"
                                    fieldId="visibilityPrivate"
                                    fieldValue="1"
                                    description="Only you and people you choose can watch your video."
                                    checked={
                                        formik.values.Visibility ===
                                        Visibility.Private
                                    }
                                    onChangeHandler={() => {
                                        formik.setFieldValue(
                                            "Visibility",
                                            Visibility.Private,
                                        );
                                    }}
                                />
                                <RadioInput
                                    fieldName="Visibility"
                                    fieldLabel="Unlisted"
                                    fieldId="visibilityUnlisted"
                                    fieldValue="2"
                                    description="Anyone with the video link can watch you video"
                                    checked={
                                        formik.values.Visibility ===
                                        Visibility.Unlisted
                                    }
                                    onChangeHandler={() => {
                                        formik.setFieldValue(
                                            "Visibility",
                                            Visibility.Unlisted,
                                        );
                                    }}
                                />
                                <RadioInput
                                    fieldName="Visibility"
                                    fieldLabel="Public"
                                    fieldId="visibilityPublic"
                                    fieldValue="0"
                                    description="Everyone can watch your video"
                                    checked={
                                        formik.values.Visibility ===
                                        Visibility.Public
                                    }
                                    onChangeHandler={() => {
                                        formik.setFieldValue(
                                            "Visibility",
                                            Visibility.Public,
                                        );
                                    }}
                                />
                            </Styled.VisibilitySection>
                        </Styled.PlaylistSettingsCol>
                    </Styled.PlaylistSettingsRow>
                    <Styled.PlaylistSettingsRow className="mt-5">
                        <Styled.PlaylistSettingsCol>
                            <Styled.PlaylistSettingsSection>
                                Audience
                            </Styled.PlaylistSettingsSection>
                            <Styled.PlaylistSettingsSubtitle>
                                This is automatically selected based on the
                                content of the playlist but you can change it if
                                needed.
                            </Styled.PlaylistSettingsSubtitle>
                        </Styled.PlaylistSettingsCol>
                    </Styled.PlaylistSettingsRow>
                    <Styled.PlaylistSettingsRow className="mt-2">
                        <Styled.PlaylistSettingsCol>
                            <Styled.VisibilitySection gap={3}>
                                <RadioInput
                                    fieldName="Audience"
                                    fieldLabel="G: General Audiences"
                                    fieldId="AudienceG"
                                    fieldValue="0"
                                    checked={
                                        formik.values.Audience === Audience.G
                                    }
                                    onChangeHandler={() => {
                                        formik.setFieldValue(
                                            "Audience",
                                            Audience.G,
                                        );
                                    }}
                                />
                                <RadioInput
                                    fieldName="Audience"
                                    fieldLabel="PG: Parental Guidance Suggested"
                                    fieldId="AudiencePG"
                                    fieldValue="1"
                                    checked={
                                        formik.values.Audience === Audience.PG
                                    }
                                    onChangeHandler={() => {
                                        formik.setFieldValue(
                                            "Audience",
                                            Audience.PG,
                                        );
                                    }}
                                />
                                <RadioInput
                                    fieldName="Audience"
                                    fieldLabel="PG-13: Parental Strongly Cautioned"
                                    fieldId="AudiencePG13"
                                    fieldValue="2"
                                    checked={
                                        formik.values.Audience === Audience.PG13
                                    }
                                    onChangeHandler={() => {
                                        formik.setFieldValue(
                                            "Audience",
                                            Audience.PG13,
                                        );
                                    }}
                                />
                                <RadioInput
                                    fieldName="Audience"
                                    fieldLabel="R: Restricted"
                                    fieldId="AudienceR"
                                    fieldValue="3"
                                    checked={
                                        formik.values.Audience === Audience.R
                                    }
                                    onChangeHandler={() => {
                                        formik.setFieldValue(
                                            "Audience",
                                            Audience.R,
                                        );
                                    }}
                                />
                                <RadioInput
                                    fieldName="Audience"
                                    fieldLabel="NC-17: Clearly Adult"
                                    fieldId="AudienceNC17"
                                    fieldValue="4"
                                    checked={
                                        formik.values.Audience === Audience.NC17
                                    }
                                    onChangeHandler={() => {
                                        formik.setFieldValue(
                                            "Audience",
                                            Audience.NC17,
                                        );
                                    }}
                                />
                            </Styled.VisibilitySection>
                        </Styled.PlaylistSettingsCol>
                    </Styled.PlaylistSettingsRow>
                    <Styled.PlaylistSettingsRowSticky className="mt-5">
                        <Styled.PlaylistSettingsCol>
                            <BaseButton
                                variant={isFormValid ? "primary" : "secondary"}
                                disabled={!isFormValid || isSubmitting}
                                type="submit"
                                onClick={formik.submitForm}
                            >
                                {isSubmitting && (
                                    <Spinner
                                        variant="primary"
                                        animation="border"
                                        size="sm"
                                    />
                                )}
                                {isSubmitting && `${percentage}%`}
                                {playlist ? "Save" : "Publish Playlist"}
                            </BaseButton>
                        </Styled.PlaylistSettingsCol>
                    </Styled.PlaylistSettingsRowSticky>
                </Styled.PlaylistSettingsCol>
            </Styled.PlaylistSettingsRow>
        </Styled.PlaylistSettingsWrapper>
    );
};
