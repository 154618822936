import { Card, Container, Dropdown, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import { colors, types } from "../../../libs";

export const RecordingsContainer = styled(Container)``;

export const HeaderRow = styled(Row)`
  margin-bottom: 20px;
  align-items: center;
`;

export const Title = styled.h1`
  ${types.headers.secondary};
  background: ${colors.text.textGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 10px;
  margin-top: 0;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80vh;
  color: ${colors.background.green};
  & > .spinner-border {
    width: 5rem;
    height: 5rem;
  }
`;

export const RecordingCard = styled(Card)`
  margin-bottom: 20px;
  cursor: pointer;
  background: #121419;
  position: relative;
  border: 1px solid ${colors.text.lightWhite};
  border-radius: 0;
`;

export const RecordingCardImage = styled(Card.Img)`
  object-fit: cover;
  max-height: 215px;
  height: 200px;
  border-radius: 0;
  border-bottom: 1px solid ${colors.text.lightWhite};
`;

export const RecordingCardBody = styled(Card.Body)`
  padding: 5px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RecordingCardTitle = styled(Card.Title)`
  ${types.body.subtitle};
  color: ${colors.text.mediumWhite};
  margin-bottom: 2px;
`;

export const RecordingCardText = styled(Card.Text)`
  ${types.body.small};
`;

export const PlayerModalHeader = styled(Modal.Header)`
  background: ${colors.background.black};
  color: ${colors.text.mediumWhite};
`;

export const PlayerModalBody = styled(Modal.Body)`
  background: ${colors.background.black};
  padding: 30px 10px;
`;

export const RecordingDetails = styled.div``;

export const ShowRecordingOptions = styled.img`
  height: 20px;
  cursor: pointer;
`;

export const RecordingCardDropdown = styled(Dropdown)`
  position: absolute;
  z-index: 100;
  right: 10px;
  top: 10px;
`;

export const RecorsingCardDropdownMenu = styled(Dropdown.Menu)`
  background: ${colors.background.formField};
  color: ${colors.text.mediumWhite};
`;

export const RecordingCardDropdownItem = styled(Dropdown.Item) <{ danger?: boolean }>`
  color: ${({ danger }) => (danger ? colors.text.error : colors.text.mediumWhite)};
  display: flex;
  align-items: center;
`;

export const RecordingCardDropdownIcon = styled.img`
  height: 1rem;
  margin-right: 0.5rem;
`;
