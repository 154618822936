import React from "react";
import * as Styled from "./NewPlaylistAlert.styled";
import { TextInput } from "../../../libs";
import { BaseButton } from "../../../libs";
import { useFormik } from "formik";
import * as Yup from "yup";

export const NewPlaylistAlert: React.FC<{
    onClose: () => void;
    handleCreatePlaylist: (name: string) => void;
}> = ({ onClose, handleCreatePlaylist }) => {
    const formik = useFormik({
        initialValues: {
            playlist_name: ""
        },
        validationSchema: Yup.object({
            playlist_name: Yup.string()
                .required("This is a mandatory field. Please complete it before continuing.")
                .test("len", "Must be less than 100 characters", (val) => val?.length <= 100)
        }),
        onSubmit: (values) => {
            handleCreatePlaylist(values.playlist_name);
            onClose();
        }
    });
    return (<Styled.NewPlaylistAlertContainer>
        <Styled.NewPlaylistAlertTitle>New Playlist</Styled.NewPlaylistAlertTitle>
        <TextInput
            fieldName="playlist_name"
            hasError={!!formik.errors.playlist_name}
            fieldLabel="Title*"
            placeholder="Playlist Name"
            inputLimit={100}
            fieldValue={formik.values.playlist_name}
            onChangeHandler={formik.handleChange}
        />
        <Styled.ErrorText>{formik.errors.playlist_name}</Styled.ErrorText>
        <Styled.ButtonsContainer>
            <BaseButton size="sm" variant="secondary" onClick={onClose}>Cancel</BaseButton>
            <BaseButton size="sm" variant="primary" onClick={formik.submitForm}>Create</BaseButton>
        </Styled.ButtonsContainer>
    </Styled.NewPlaylistAlertContainer>);
}