import React from "react";
import * as Styled from "./button.styled";

interface IButtonProps extends React.ButtonHTMLAttributes<any> {
    buttonText: string;
    clickHandler: () => void;
    className?: string;
}

export const Button: React.FC<IButtonProps> = ({
    buttonText,
    clickHandler,
    className
}) => {
    return (
        <Styled.StyledButton onClick={clickHandler} className={className}>
            {buttonText}
        </Styled.StyledButton>
    );
};
