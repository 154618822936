import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import {
    useMaterialReactTable,
    type MRT_ColumnDef,
    type MRT_Row,
    MRT_Table as Table,
} from "material-react-table";
import DragRows from "../../../../../assets/images/icons/icon-drag-rows.svg";
import IconUndo from "../../../../../assets/images/icons/icon-undo.svg";
import IconRedo from "../../../../../assets/images/icons/icon-redo.svg";
import IconArrowRight from "../../../../../assets/images/icons/icon-arrow-right.svg";
import DefaultThumbnailImage from "../../../../../assets/images/default_thumbnail.jpeg";
import {
    BaseButton,
    IMediaResponseDto,
    IUploadCategory,
} from "../../../../../libs";

import * as Styled from "./OrderSelection.styled";
import { CreateModalSteps } from "../../types";

interface IProps {
    mediaItems: IMediaResponseDto[];
    selectedVideos: string[];
    editMode: boolean;
    setSelectedVideos: (ids: string[]) => void;
    handleNextStep: (
        step: CreateModalSteps,
        latestSelectedVideos?: string[],
    ) => void;
}

export const OrderSelection: React.FC<IProps> = ({
    mediaItems,
    selectedVideos,
    setSelectedVideos,
    handleNextStep,
    editMode,
}) => {
    const columns = React.useMemo<MRT_ColumnDef<IMediaResponseDto>[]>(() => {
        return [
            {
                header: "#",
                accessorKey: "order",
                size: 20,
            },
            {
                header: "Title",
                accessorKey: "title",
                Cell: ({ renderedCellValue, row }) => (
                    <Styled.TitleCellWrapper>
                        <img
                            src={
                                row.original.thumbnailsSrc
                                    ? row.original.thumbnailsSrc
                                    : DefaultThumbnailImage
                            }
                            alt="Title"
                        />
                        <div>{renderedCellValue}</div>
                    </Styled.TitleCellWrapper>
                ),
            },
            {
                header: "Date",
                Cell: ({ row }) =>
                    new Date(row.original.createdAt).toLocaleDateString(
                        "en-US",
                        {
                            timeZone: "UTC",
                        },
                    ),
            },
            {
                header: "Category",
                Cell: ({ row }) => {
                    if (row.original.category === IUploadCategory.GENERAL) {
                        return "General";
                    }
                    if (
                        row.original.category === IUploadCategory.FILM_FESTIVAL
                    ) {
                        return "Film Festival";
                    }
                },
            },
        ];
    }, []);
    const [data, setData] = React.useState<IMediaResponseDto[]>([]);

    React.useEffect(() => {
        const op = selectedVideos.map((id, index) => {
            const media = mediaItems.find((media) => media.mediaId === id);
            return {
                ...media,
                order: index + 1,
            };
        });
        setData(
            op.filter((media) => media !== undefined) as IMediaResponseDto[],
        );
    }, [selectedVideos, mediaItems]);

    const table = useMaterialReactTable({
        autoResetPageIndex: false,
        columns,
        data,
        enableRowOrdering: true,
        enableSorting: true,
        enableColumnActions: false,
        muiRowDragHandleProps: ({ table }) => ({
            onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                    data.splice(
                        (hoveredRow as MRT_Row<IMediaResponseDto>).index,
                        0,
                        data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                }
            },
        }),
        icons: {
            DragHandleIcon: () => {
                return (
                    <img
                        src={DragRows}
                        alt="Drag Rows"
                        style={{ zIndex: "-1" }}
                    />
                );
            },
        },
        mrtTheme: {
            baseBackgroundColor: "#0F1013",
            draggingBorderColor: "#b6f84d",
        },
    });

    const handleConfirmOrder = React.useCallback(() => {
        const orderedMedia = data.map((media) => media.mediaId);
        if (orderedMedia.length > 0) setSelectedVideos(orderedMedia);
        setTimeout(() => {
            handleNextStep(CreateModalSteps.DETAILS, orderedMedia);
        }, 500);
    }, [data, setSelectedVideos, handleNextStep]);

    const theme = createTheme({
        palette: {
            mode: "dark",
        },
    });
    return (
        <Styled.OrderSelectionWrapper>
            <ThemeProvider theme={theme}>
                <Styled.OrderSelectionRow>
                    <Styled.OrderSelectionCol md={9} className="mx-auto">
                        <Styled.OrderSelectionHeader>
                            <Styled.OrderSelectionHeaderSide>
                                {!editMode && (
                                    <Styled.OrderSelectionHeaderBackButton
                                        onClick={() =>
                                            handleNextStep(
                                                CreateModalSteps.VIDEO_SELECTION,
                                            )
                                        }
                                    >
                                        <Styled.OrderSelectionHeaderBackIcon
                                            src={IconArrowRight}
                                            alt=""
                                        />
                                        Back
                                    </Styled.OrderSelectionHeaderBackButton>
                                )}
                                <Styled.OrderSelectionHeaderText>
                                    Select Video Order
                                </Styled.OrderSelectionHeaderText>
                                <Styled.OrderSelectionHeaderSubText>
                                    Drag to re-organize the videos order of
                                    appearance.
                                </Styled.OrderSelectionHeaderSubText>
                            </Styled.OrderSelectionHeaderSide>
                            <Styled.OrderSelectionHeaderActions>
                                <BaseButton variant="secondary">
                                    <Styled.OrderSelectionActionButtonInside>
                                        <Styled.OrderSelectionActionButtonIcon
                                            src={IconUndo}
                                            alt="Undo"
                                        />
                                        Undo
                                    </Styled.OrderSelectionActionButtonInside>
                                </BaseButton>
                                <BaseButton variant="secondary">
                                    <Styled.OrderSelectionActionButtonInside>
                                        Redo
                                        <Styled.OrderSelectionActionButtonIcon
                                            src={IconRedo}
                                            alt="Redo"
                                        />
                                    </Styled.OrderSelectionActionButtonInside>
                                </BaseButton>
                            </Styled.OrderSelectionHeaderActions>
                        </Styled.OrderSelectionHeader>
                    </Styled.OrderSelectionCol>
                </Styled.OrderSelectionRow>
                <Styled.OrderSelectionRow className="mt-4">
                    <Styled.OrderSelectionCol md={9} className="mx-auto">
                        <Table table={table} />
                    </Styled.OrderSelectionCol>
                </Styled.OrderSelectionRow>
                <Styled.OrderSelectionRowSticky className="mt-4">
                    <Styled.OrderSelectionCol md={4} className="mx-auto">
                        <BaseButton
                            variant="primary"
                            onClick={handleConfirmOrder}
                        >
                            {" "}
                            Confirm Order
                        </BaseButton>
                    </Styled.OrderSelectionCol>
                </Styled.OrderSelectionRowSticky>
            </ThemeProvider>
        </Styled.OrderSelectionWrapper>
    );
};
