import React from "react";
import { IPlaylistResponseDto, BaseButton } from "../../../libs";
import * as Styled from "./ShowPlaylists.styled";
import { Plus } from "react-bootstrap-icons";
import { NewPlaylistAlert } from "../new-playlist-alert";
import { confirmAlert } from "react-confirm-alert";
import { ShowPlaylistsModal } from "./show-playlists-modal";
import OptionsIcon from "../../../assets/images/icons/icon-options.svg";
import ListViewIcon from "../../../assets/images/icons/icon-list-view.svg";
import GridViewIcon from "../../../assets/images/icons/icon-grid-view.svg";

interface IProps {
    playlists: IPlaylistResponseDto[];
    createPlaylist: (name: string) => void;
    refreshPlaylists: () => void;
}

export const ShowPlaylists: React.FC<IProps> = ({
    playlists,
    createPlaylist,
    refreshPlaylists,
}) => {
    const [showModal, setShowModal] = React.useState(true);
    const [selectedPlaylist, setSelectedPlaylist] =
        React.useState<IPlaylistResponseDto>();
    const [listView, setListView] = React.useState(false);

    const hideModal = React.useCallback(() => {
        setShowModal(false);
    }, []);

    const handleCreatePlaylist = React.useCallback(
        (name: string) => {
            createPlaylist(name);
        },
        [createPlaylist],
    );

    const showAlertModal = React.useCallback(() => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <NewPlaylistAlert
                        onClose={onClose}
                        handleCreatePlaylist={handleCreatePlaylist}
                    />
                );
            },
            closeOnEscape: true,
            closeOnClickOutside: false,
        });
    }, [handleCreatePlaylist]);

    const handlePlaylistClick = React.useCallback(
        (playlist: IPlaylistResponseDto) => {
            setSelectedPlaylist(playlist);
            setShowModal(true);
        },
        [setSelectedPlaylist, setShowModal],
    );

    const handleViewSwitch = React.useCallback(() => {
        setListView(!listView);
    }, [listView, setListView]);

    const generateListView = React.useCallback(() => {
        return (
            <Styled.ShowPlaylistHorizontalCol>
                {playlists.map((playlist, index) => (
                    <Styled.ShowPlaylistHorizontalRow
                        key={`playlist_${index}`}
                        onClick={() => {
                            handlePlaylistClick(playlist);
                        }}
                    >
                        <Styled.ShowPlaylistHorizontalCol md={1}>
                            <Styled.ShowPlaylistHorizontalThumbnail
                                src={
                                    playlist.thumbnails.userUploaded
                                        ? `${playlist.thumbnails.userUploaded.cdnPath}/${playlist.thumbnails.userUploaded.s3Key}`
                                        : playlist.thumbnails.existing
                                          ? playlist.thumbnails.existing.url
                                          : ""
                                }
                            />
                        </Styled.ShowPlaylistHorizontalCol>
                        <Styled.ShowPlaylistHorizontalCol md={11}>
                            <Styled.ShowPlaylistHorizontalContent>
                                <Styled.ShowPlaylistHorizontalText>
                                    <Styled.ShowPlaylistHorizontalTitle>
                                        {playlist.title}
                                    </Styled.ShowPlaylistHorizontalTitle>
                                    <Styled.ShowPlaylistHorizontalDescription>
                                        {playlist.description}
                                    </Styled.ShowPlaylistHorizontalDescription>
                                </Styled.ShowPlaylistHorizontalText>
                                <Styled.ShowPlaylistHorizontalOptionsWrapper>
                                    <Styled.ShowPlaylistHorizontalOptions
                                        src={OptionsIcon}
                                        alt="Options"
                                    />
                                </Styled.ShowPlaylistHorizontalOptionsWrapper>
                            </Styled.ShowPlaylistHorizontalContent>
                        </Styled.ShowPlaylistHorizontalCol>
                    </Styled.ShowPlaylistHorizontalRow>
                ))}
            </Styled.ShowPlaylistHorizontalCol>
        );
    }, [playlists, handlePlaylistClick]);

    return (
        <>
            {selectedPlaylist && (
                <ShowPlaylistsModal
                    show={showModal}
                    onHide={hideModal}
                    playlist={selectedPlaylist}
                    refreshPlaylists={refreshPlaylists}
                />
            )}
            <Styled.ShowPlaylistsWrapper>
                <Styled.ShowPlaylistsHeader>
                    <Styled.ShowPlaylistsTitle>
                        Your Playlists
                    </Styled.ShowPlaylistsTitle>
                    <Styled.ShowPlaylistsButtons>
                        <BaseButton onClick={showAlertModal}>
                            <Styled.ShowPlaylistAddButtonContainer>
                                <Plus size={28} />
                                New Playlist
                            </Styled.ShowPlaylistAddButtonContainer>
                        </BaseButton>
                        <Styled.SwitchViewButton onClick={handleViewSwitch}>
                            <Styled.SwitchViewIcon
                                src={listView ? ListViewIcon : GridViewIcon}
                            />
                        </Styled.SwitchViewButton>
                    </Styled.ShowPlaylistsButtons>
                </Styled.ShowPlaylistsHeader>
            </Styled.ShowPlaylistsWrapper>
            <Styled.ShowPlaylistRow>
                {listView
                    ? generateListView()
                    : playlists.map((playlist, index) => (
                          <Styled.ShowPlaylistCol
                              key={`playlist_${index}`}
                              md={3}
                              onClick={() => {
                                  handlePlaylistClick(playlist);
                              }}
                          >
                              <Styled.ShowPlaylistColInner>
                                  <Styled.ShowPlaylistOptions
                                      src={OptionsIcon}
                                      alt="Options"
                                  />
                                  <Styled.ShowPlaylistWrapper>
                                      <Styled.ShowPlaylistThumbnail
                                          src={
                                              playlist.thumbnails.userUploaded
                                                  ? `${playlist.thumbnails.userUploaded.cdnPath}/${playlist.thumbnails.userUploaded.s3Key}`
                                                  : playlist.thumbnails.existing
                                                    ? playlist.thumbnails
                                                          .existing.url
                                                    : ""
                                          }
                                          alt={playlist.title}
                                      />
                                  </Styled.ShowPlaylistWrapper>
                                  <Styled.ShowPlaylistTitle>
                                      {playlist.title}
                                  </Styled.ShowPlaylistTitle>
                                  <Styled.ShowPlaylistDescription>
                                      <span>Published: Feb 2023</span>
                                      <span>102 Minutes</span>
                                  </Styled.ShowPlaylistDescription>
                              </Styled.ShowPlaylistColInner>
                          </Styled.ShowPlaylistCol>
                      ))}
            </Styled.ShowPlaylistRow>
        </>
    );
};
