import { uploaderService } from "../../services";

export class MultiPartUploader {
  file: File;
  fileName: string;
  uniqueUploadedFileName: string = "";
  chunkSize: number;
  threadsQuantity: number;
  aborted = false;
  uploadedSize = 0;
  progressCache: any = {};
  activeConnections: any = {};
  parts: any[] = [];
  uploadedParts: any = [];
  uploadId: string = "";
  fileKey: string = "";
  clientId: string;
  mediaId: string;
  contentType: string;
  category: string;
  getProgressFunction: ((params: any) => void) | undefined;
  getErrorFunction: ((error: any) => void) | undefined;
  getCompleteFunction: ((params: any) => void) | undefined;

  constructor(options: any) {
    this.file = options.file;
    this.contentType = options.file.type;
    this.fileName = options.fileName;
    this.chunkSize = options.chunkSize || 1024 * 1024 * 5;
    this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15);
    this.clientId = options.clientId;
    this.mediaId = options.mediaId;
    this.category = options.category;
  }

  // starting the multipart upload request
  async start() {
    await this.initialize();
  }

  async initialize() {
    try {
      // adding the the file extension (if present) to fileName
      // const ext = this.file.name.split(".").pop();

      // initializing the multipart request
      const multipartUploadInitializationParams = {
        fileName: this.fileName,
        mediaId: this.mediaId,
        contentType: this.contentType,
        category: this.category,
      };

      const initializeRespone = await uploaderService.initializeMultipartUpload(
        multipartUploadInitializationParams
      );

      this.uploadId = initializeRespone.uploadId;
      this.fileKey = initializeRespone.fileKey;
      this.uniqueUploadedFileName = initializeRespone.fileName;

      // retrieving the pre-signed URLs
      const numberOfParts = Math.ceil(this.file.size / this.chunkSize);

      const multipartPresignedUrlParams = {
        uploadId: this.uploadId,
        fileKey: this.fileKey,
        parts: numberOfParts,
        contentType: this.contentType,
      };

      const presignedUrlResponse =
        await uploaderService.createMultipartPresignedUrls(
          multipartPresignedUrlParams
        );

      this.parts.push(...presignedUrlResponse);

      this.sendNext();
    } catch (err) {
      console.log("Error", err);
    }
  }

  sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length;
    if (activeConnections >= this.threadsQuantity) {
      return;
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete();
      }

      return;
    }

    const part = this.parts.pop();

    if (this.file && part) {
      const sentSize = (part.PartNumber - 1) * this.chunkSize;
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);

      const sendChunkStarted = () => {
        this.sendNext();
      };

      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext();
        })
        .catch((error) => {
          this.parts.push(part);

          this.complete(error);
        });
    }
  }

  sendChunk(
    chunk: Blob,
    part: any,
    sendChunkStarted: () => void
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error("Failed chunk upload"));
            return;
          }

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  upload(chunk: Blob, part: any, sendChunkStarted: () => void): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.uploadId && this.fileKey) {
        const xhr = (this.activeConnections[part.PartNumber - 1] =
          new XMLHttpRequest());

        sendChunkStarted();

        const progressListener = this.handleProgress.bind(
          this,
          part.PartNumber - 1
        );

        xhr.upload.addEventListener("progress", progressListener);
        xhr.addEventListener("error", progressListener);
        xhr.addEventListener("abort", progressListener);
        xhr.addEventListener("loadend", progressListener);

        xhr.open("PUT", part.signedUrl);

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            // retrieving the ETag parameter from the HTTP headers
            const ETag = xhr.getResponseHeader("ETag");

            if (ETag) {
              const uploadedPart = {
                PartNumber: part.PartNumber,
                // removing the " enclosing carachters from
                // the raw ETag
                ETag: ETag.replaceAll('"', ""),
              };

              this.uploadedParts.push(uploadedPart);

              resolve(xhr.status);
              delete this.activeConnections[part.PartNumber - 1];
            }
          }
        };

        xhr.onerror = (error) => {
          reject(error);
          delete this.activeConnections[part.PartNumber - 1];
        };

        xhr.onabort = () => {
          reject(new Error("Upload canceled by user"));
          delete this.activeConnections[part.PartNumber - 1];
        };

        xhr.send(chunk);
      }
    });
  }

  handleProgress(part: any, event: any) {
    if (this.file) {
      if (
        event.type === "progress" ||
        event.type === "error" ||
        event.type === "abort"
      ) {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === "uploaded") {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);

      const total = this.file.size;

      const percentage = Math.round((sent / total) * 100);

      if (this.getProgressFunction) {
        this.getProgressFunction({
          sent: sent,
          total: total,
          percentage: percentage,
        });
      } else {
        this.onProgress({ sent: sent, total: total, percentage: percentage });
      }
    }
  }

  async complete(error?: any) {
    if (error && !this.aborted) {
      if (this.getErrorFunction) {
        this.getErrorFunction(error);
      } else {
        this.onError(error);
      }

      return;
    }

    if (error) {
      if (this.getErrorFunction) {
        this.getErrorFunction(error);
      } else {
        this.onError(error);
      }
      return;
    }

    try {
      await this.sendCompleteRequest();
    } catch (error) {
      if (this.getErrorFunction) {
        this.getErrorFunction(error);
      } else {
        this.onError(error);
      }
    }
  }

  // finalizing the multipart upload request on success by calling
  // the finalization API
  async sendCompleteRequest() {
    if (this.uploadId && this.fileKey) {
      const finalizeMultipartUploadParams = {
        uploadId: this.uploadId,
        fileKey: this.fileKey,
        parts: this.uploadedParts,
        contentType: this.contentType,
      };

      await uploaderService.finalizeMultipartUpload(
        finalizeMultipartUploadParams
      );
      if (this.getCompleteFunction) {
        this.getCompleteFunction({
          s3Key: this.uniqueUploadedFileName,
          fileName: this.fileName,
          mediaId: this.mediaId,
        });
      }
    }
  }

  abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort();
      });

    this.aborted = true;
  }

  onProgress(params: { sent: number; total: number; percentage: number }) {
    console.log(
      `Progress --- sent: ${params.sent}, total: ${params.total} and percentage: ${params.percentage} `
    );
  }

  onError(error: any) {
    console.log("Error", error);
  }

  getOnProgress(getProgress: (params: any) => void) {
    this.getProgressFunction = getProgress;
    return this;
  }

  getOnError(getError: (error: any) => void) {
    this.getErrorFunction = getError;
    return this;
  }

  getOnComplete(getComplete: (params: any) => void) {
    this.getCompleteFunction = getComplete;
    return this;
  }
}
