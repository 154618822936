import React from "react";
import { ShowPlaylistsModalBody } from "./show-playlists-modal-body";
import { ShowPlaylistsModalHeader } from "./show-playlists-modal-header";
import { FullScreenModal, IPlaylistResponseDto } from "../../../../libs";
import { playlistsService } from "../../../../services";
import * as Styled from "./ShowPlaylistsModal.styled";
import { Spinner } from "react-bootstrap";

interface IProps {
    show: boolean;
    onHide: () => void;
    refreshPlaylists: () => void;
    playlist: IPlaylistResponseDto;
}

export const ShowPlaylistsModal: React.FC<IProps> = ({
    show,
    onHide,
    refreshPlaylists,
    playlist,
}) => {
    const [playlistData, setPlaylistData] =
        React.useState<IPlaylistResponseDto>(playlist);
    const [showSpinner, setShowSpinner] = React.useState(true);
    const [potentialChanges, setPotentialChanges] =
        React.useState<Boolean>(false);

    const fetchPlaylist = React.useCallback(
        async (deleted: boolean = false) => {
            if (deleted) {
                refreshPlaylists();
                onHide();
                return;
            }
            setShowSpinner(true);
            const response = await playlistsService.getPlaylistById(
                playlist.playlistId,
            );
            setPlaylistData(response);
            setShowSpinner(false);
        },
        [playlist, setPlaylistData, setShowSpinner, onHide, refreshPlaylists],
    );

    React.useEffect(() => {
        fetchPlaylist();
    }, [fetchPlaylist]);

    const onHideWrapper = React.useCallback(() => {
        if (potentialChanges) {
            refreshPlaylists();
            setPotentialChanges(false);
        }
        onHide();
    }, [potentialChanges, refreshPlaylists, onHide]);

    return playlist ? (
        <FullScreenModal
            show={show}
            onHide={onHideWrapper}
            header={
                showSpinner ? (
                    <></>
                ) : (
                    <ShowPlaylistsModalHeader
                        playlist={playlistData}
                        onHide={onHideWrapper}
                        refreshPlaylist={(deleted: boolean = false) => {
                            fetchPlaylist(deleted);
                            setPotentialChanges(true);
                        }}
                    />
                )
            }
            body={
                showSpinner ? (
                    <Styled.SpinnerWrapper>
                        <Spinner variant="primary" animation="border" />
                    </Styled.SpinnerWrapper>
                ) : (
                    <ShowPlaylistsModalBody
                        playlist={playlistData}
                        refreshPlaylist={() => {
                            fetchPlaylist();
                            setPotentialChanges(true);
                        }}
                    />
                )
            }
            children={null}
        />
    ) : (
        <></>
    );
};
