import React from "react";
import { List } from "react-bootstrap-icons";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "./../../assets/images/icons/search.svg";
import Logo from "./../../assets/images/logos/logo.png";

import { MobileMenu } from "./mobile-menu";

import { useAuth } from "../../behavioral";
import { ROUTEPATHS, isComingSoon, replaceLocation } from "../../libs";
import { Modal } from "../ui";
import { Button } from "../ui/button/button";
import * as Styled from "./navigation.styled";

export const Navigation = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const location = useLocation();
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);
  const [showSignInModal, setShowSignInModal] = React.useState(false);
  const showNotifyMe = isComingSoon;

  const shouldDisplayMenuLinks =
    location.pathname !== ROUTEPATHS.REGISTER &&
    location.pathname !== ROUTEPATHS.LOGIN;
  const isDashboardPage = location.pathname === ROUTEPATHS.DASHBOARD;

  const handleGetStartedClick = () => {
    replaceLocation(ROUTEPATHS.PRICING);
  };

  const onDashboardClick = React.useCallback(() => {
    replaceLocation(ROUTEPATHS.DASHBOARD);
  }, []);

  const handleSignout = React.useCallback(async () => {
    try {
      await auth.signOut();
      navigate("/", { replace: true });
    } catch (err) {
      console.log(err);
    }
  }, [auth, navigate]);

  return (
    <Styled.NavigationWrapper id="navbar" className="navbar flex-nowrap">
      <Styled.LogoWrapper className="logo branding me-4">
        <Styled.LogoLink href="/">
          <Styled.LogoImage src={Logo} />
        </Styled.LogoLink>
      </Styled.LogoWrapper>

      {shouldDisplayMenuLinks && !isDashboardPage && (
        <Styled.NavLeftLinksWrapper className="nav-primary">
          <Styled.NavLinkItem>
            <Styled.StyledNavLink
              to="/about"
              activeclassname="active"
              exact="true"
            >
              About
            </Styled.StyledNavLink>
          </Styled.NavLinkItem>

          <Styled.NavLinkItem>
            <Styled.StyledNavLink
              to="/pricing"
              activeclassname="active"
              exact="true"
            >
              Pricing
            </Styled.StyledNavLink>
          </Styled.NavLinkItem>

          <Styled.NavLinkItem>
            <Styled.StyledNavLink
              to="/roadmap"
              activeclassname="active"
              exact="true"
            >
              Roadmap
            </Styled.StyledNavLink>
          </Styled.NavLinkItem>

          <Styled.NavLinkItem>
            <Styled.StyledNavLink
              to="/blockchain"
              activeclassname="active"
              exact="true"
            >
              Blockchain
            </Styled.StyledNavLink>
          </Styled.NavLinkItem>

          <Styled.NavLinkItem>
            <Styled.StyledNavLink
              to="/team"
              activeclassname="active"
              exact="true"
            >
              Team
            </Styled.StyledNavLink>
          </Styled.NavLinkItem>
        </Styled.NavLeftLinksWrapper>
      )}

      {shouldDisplayMenuLinks && !isDashboardPage && (
        <Styled.NavRightLinksWrapper className="nav-secondary d-none d-lg-flex obi-wan">
          <li>
            {auth.isAuthenticated ? (
              <Link
                to="/"
                onClick={handleSignout}
                className="auth-link"
                style={{ color: "rgba(255,255,255,1)" }}
              >
                Logout
              </Link>
            ) : (
              <NavLink
                to={ROUTEPATHS.LOGIN}
                className="auth-link"
                style={{ color: "rgba(255,255,255,1)" }}
              >
                Login
              </NavLink>
            )}
          </li>
          <li>
            {auth.isAuthenticated ? (
              <Button
                buttonText="Dashboard"
                className={`btn btn-primary`}
                clickHandler={onDashboardClick}
              />
            ) : showNotifyMe ? (
              <Button
                buttonText="Notify Me"
                className={`btn btn-primary`}
                clickHandler={() => setShowSignInModal(true)}
              />
            ) : (
              <Button
                buttonText="Get Started"
                className={`btn btn-primary`}
                clickHandler={handleGetStartedClick}
              />
            )}
          </li>
        </Styled.NavRightLinksWrapper>
      )}

      {isDashboardPage && (
        <>
          <Styled.SearchBarWrapper className="nav-search">
            <Styled.SearchInput
              name="search"
              type="text"
              placeholder="Search your library"
            />
            <Styled.SearchIcon src={SearchIcon} />
          </Styled.SearchBarWrapper>
          <Styled.UserMenuIconsWrapper>
            <Styled.NotificationsIcon className="fas fa-bell"></Styled.NotificationsIcon>
            <Styled.ProfileIcon />
          </Styled.UserMenuIconsWrapper>
        </>
      )}

      <Styled.MobileMenuIconWrapper 
        className="mobile-nav-toggle ms-auto" 
        onClick={() => setShowMobileMenu(true)}>
        <List color="rgba(255,255,255, 1)" size={32} className=""></List>
      </Styled.MobileMenuIconWrapper>

      <MobileMenu
        show={showMobileMenu}
        closeHandler={() => setShowMobileMenu(false)}
      />

      <Modal
        closeHandler={() => setShowSignInModal(false)}
        show={showSignInModal}
      />
    </Styled.NavigationWrapper>
  );
};
