import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth, useGlobalState } from "../../../behavioral";
import { BaseButton, ICategoryType, IMediaResponseDto, ROUTEPATHS } from "../../../libs";
import { userService, vodService } from "../../../services";
import { UploadsContent } from "../uploads-content";
import * as Styled from "./DashboardContent.styled";
import { AnalyticsCards } from "../analytics-cards";

export const DashboardContent: React.FC<{
    mediaItems: IMediaResponseDto[];
    categories: ICategoryType[];
    clientId: string;
    onUpdateUploads?: () => void;
    progressItems?: {
        [key in string]: { percentage: number; currentPhase: string };
    };
}> = ({ mediaItems, onUpdateUploads, categories, clientId, progressItems }) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const { dispatch, actions, state } = useGlobalState();

    const fetchStorage = React.useCallback(async () => {
        const email = auth.user?.attributes?.email;
        if (email) {
            const userInfo = await userService.getUserByEmail(email);
            dispatch({ type: actions.SET_STORAGE, payload: userInfo.storage });
        }
    }, [auth.user, dispatch, actions]);

    const onContinueToSubscription = React.useCallback(() => {
        // Add different logic if user already has subsv
        navigate(
            `${ROUTEPATHS.PRICING}?ref=${window.btoa(
                JSON.stringify({
                    userId: auth.user?.attributes?.sub,
                    name: auth.user?.attributes?.name,
                    email: auth.user?.attributes?.email,
                }),
            )}`,
        );
    }, [navigate, auth.user]);

    const handleOnDeleteUpload = React.useCallback(
        async (mediaId: string) => {
            const response = await vodService.deleteMediaItem(mediaId);

            if (!response.error && onUpdateUploads) {
                fetchStorage();
                onUpdateUploads();
            }
        },
        [onUpdateUploads, fetchStorage],
    );

    const handleOnUpdateUploads = React.useCallback(() => {
        onUpdateUploads && onUpdateUploads();
    }, [onUpdateUploads]);

    return (
        <>
            <Styled.WelcomeHeaderWrapper>
                <Styled.WelcomeHeader>
                    Welcome {auth.user?.attributes.name}
                </Styled.WelcomeHeader>
            </Styled.WelcomeHeaderWrapper>
            {!state.allowAccess && (
                <>
                    <Row>
                        <Col md={6}>
                            Upgrade Now and Elevate Your Experience! Click to
                            explore our premium subscription plans and unlock a
                            world of exclusive features.
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={2}>
                            <BaseButton onClick={onContinueToSubscription}>
                                Explore Now
                            </BaseButton>
                        </Col>
                    </Row>
                </>
            )}
            <br />
            {state.allowAccess && (
                <>
                    {mediaItems.length === 0 ? (
                        <p>
                            There are no uploads available in your account at
                            this time. Start uploading!!
                        </p>
                    ) : (
                        <>
                            <UploadsContent
                                mediaItems={mediaItems}
                                categories={categories}
                                progressItems={progressItems}
                                onDeleteUpload={handleOnDeleteUpload}
                                onUpdateUploads={handleOnUpdateUploads}
                                mediaCount={4}
                            />
                            <AnalyticsCards />
                        </>
                    )}
                </>
            )}
        </>
    );
};
