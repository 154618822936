import React from "react";
import * as Styled from "./SelectedVideos.styled";
import { BaseButton } from "../../../../../../libs";
import DeselectIcon from "./../../../../../../assets/images/icons/icon-deselect-cross.svg";
import { CreateModalSteps } from "../../../types";

interface IProps {
    playlistName: string;
    mediaItems: any[];
    selectedVideos: string[];
    setSelectedVideos: (videos: string[]) => void;
    handleNextStep: (step: CreateModalSteps) => void;
    editMode: boolean;
}

export const SelectedVideos: React.FC<IProps> = ({
    playlistName,
    mediaItems,
    selectedVideos,
    setSelectedVideos,
    handleNextStep,
    editMode,
}) => {
    // TODO: Use spinner
    const [showSpinner, setShowSpinner] = React.useState(false); // eslint-disable-line

    const handleClearAll = React.useCallback(() => {
        setSelectedVideos(selectedVideos);
    }, [setSelectedVideos, selectedVideos]);

    const handleRemoveVideo = React.useCallback(
        (id: string) => {
            setSelectedVideos([id]);
        },
        [setSelectedVideos],
    );

    const generatedVideoRows = React.useMemo(() => {
        const videoRows: React.ReactElement[] = [];
        mediaItems?.forEach((media, index) => {
            if (!selectedVideos.includes(media.mediaId)) return;
            videoRows.push(
                <Styled.SelectedVideoRow
                    key={`selectedVideoRow_${index}`}
                    onClick={() => handleRemoveVideo(media.mediaId)}
                >
                    <Styled.SelectedVideoCol md={2}>
                        <Styled.SelectedVideoImg src={media.thumbnailsSrc} />
                    </Styled.SelectedVideoCol>
                    <Styled.SelectedVideoCol md={9}>
                        <Styled.SelectedVideoTitle>
                            {media.title}
                        </Styled.SelectedVideoTitle>
                        <Styled.SelectedVideoDescription>
                            {media.category}
                        </Styled.SelectedVideoDescription>
                    </Styled.SelectedVideoCol>
                    <Styled.SelectedVideoCol md={1}>
                        <Styled.SelectedVideoCross src={DeselectIcon} />
                    </Styled.SelectedVideoCol>
                </Styled.SelectedVideoRow>,
            );
        });
        return videoRows;
    }, [mediaItems, selectedVideos, handleRemoveVideo]);

    const isDisabled = React.useMemo(() => {
        return selectedVideos.length === 0;
    }, [selectedVideos]);

    return (
        <Styled.SelectedVideosWrapper>
            <Styled.SelectedVideosHeader>
                <Styled.SelectedVideosHeaderText>
                    {playlistName}
                    <Styled.SelectedVideoHeaderMutedText
                        fontSize="1.5rem"
                        shouldSpace={true}
                    >
                        |
                    </Styled.SelectedVideoHeaderMutedText>
                    <Styled.SelectedVideoHeaderMutedText>
                        PLAYLIST
                    </Styled.SelectedVideoHeaderMutedText>
                </Styled.SelectedVideosHeaderText>
                {showSpinner && (
                    <Styled.SelectedVideosHeaderSpinnerWrapper>
                        <Styled.SelectedVideosHeaderSpinner
                            animation="border"
                            role="status"
                            size="sm"
                        >
                            <span className="visually-hidden">Loading...</span>
                        </Styled.SelectedVideosHeaderSpinner>
                        Saving...
                    </Styled.SelectedVideosHeaderSpinnerWrapper>
                )}
            </Styled.SelectedVideosHeader>
            <Styled.SelectedVideosColumnTitleWrapper>
                <Styled.SelectedVideosColumnTitleMutedText>
                    Playlist Videos
                </Styled.SelectedVideosColumnTitleMutedText>
                <Styled.SelectedVideosColumnClearAllText
                    onClick={handleClearAll}
                >
                    Clear All
                </Styled.SelectedVideosColumnClearAllText>
            </Styled.SelectedVideosColumnTitleWrapper>
            <Styled.SelectedVideosRowsWrapper>
                {generatedVideoRows}
            </Styled.SelectedVideosRowsWrapper>
            <Styled.SelectedVideosButtonWrapper>
                {/* <Styled.SelectedVideosButtonCol md={6}>
                    <BaseButton variant="secondary">Skip</BaseButton>
                </Styled.SelectedVideosButtonCol> */}
                <Styled.SelectedVideosButtonCol>
                    <BaseButton
                        variant={isDisabled ? "secondary" : "primary"}
                        onClick={() =>
                            handleNextStep(CreateModalSteps.ORDER_SELECTION)
                        }
                        disabled={isDisabled}
                    >
                        {editMode ? "Save" : "Save and Continue"}
                    </BaseButton>
                </Styled.SelectedVideosButtonCol>
            </Styled.SelectedVideosButtonWrapper>
        </Styled.SelectedVideosWrapper>
    );
};
