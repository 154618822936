import React from "react";
import { Stack } from "react-bootstrap";
import { StepIndicator } from "./../../../../../libs";
import { CreateModalSteps, CreateModalStepsState } from "../../types";

import * as Styled from "./CreateSteps.styled";

interface IProps {
  steps: CreateModalStepsState[];
  onStepClick: (step: CreateModalSteps) => void;
}

export const CreateSteps: React.FC<IProps> = ({ steps, onStepClick }) => {
  return (
    <Stack direction="horizontal" gap={5} className="justify-content-center">
      {
        steps.map((step, index) => {
          return (
            <StepIndicator
              key={"create_steps_" + index}
              stepName={step.title}
              stepNumber={index + 1}
              isActive={step.inprogress}
              direction="row"
              isCompleted={step.completed}
              onStepClick={() => onStepClick(step.step)}
            >
              {index !== steps.length - 1 && <Styled.ConnectorLine />}
            </StepIndicator>
          )
        })
      }


    </Stack>
  );
};
