import * as React from "react";
import { v4 as uuid } from "uuid";
import * as Yup from "yup";

// import { useWebSocket } from "./../../../behavioral";
import { ContentTypes } from "../types";
import {
    FileWithPreview,
    ICategoryType,
    IUploadCategory,
    PublishStatus,
} from "./../../../libs";

import { useFormik } from "formik";
import { UploadPreview } from "../upload-modal-body/upload-preview";
import * as Styled from "./GeneralUploadModalBody.styled";
import { GeneralUploadForm } from "./general-form";
import { useGlobalState } from "../../../behavioral";
import { vodService } from "../../../services";

export const GeneralUploadModalBody: React.FC<{
    onUploadComplete: () => void;
    contentType?: ContentTypes;
    uploadedFile: FileWithPreview;
    clientId: string;
}> = ({ onUploadComplete, uploadedFile, clientId }) => {
    const [uploadId, setUploadId] = React.useState("");

    const { state, dispatch, actions } = useGlobalState();

    // eslint-disable-next-line
    const [thumbnailImage, setThumbnailImage] =
        React.useState<FileWithPreview>();

    const [filesToUpload, setFilesToUpload] = React.useState<
        {
            category: string;
            files: File[];
        }[]
    >([]);

    const [categories, setCategories] = React.useState<ICategoryType[]>([]);

    const populateCategories = React.useCallback(async () => {
        const data = await vodService.getCategories();
        setCategories(data);
    }, []);

    React.useEffect(() => {
        setUploadId(uuid());
        populateCategories();
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (uploadedFile) {
            const existingVideoFileIndex = filesToUpload.findIndex(
                (elem) => elem.category === "video",
            );
            const newVideo = {
                category: "video",
                files: [uploadedFile.file],
            };

            if (existingVideoFileIndex >= 0) {
                filesToUpload.splice(existingVideoFileIndex, 1, newVideo);
                setFilesToUpload([...filesToUpload]);
            } else {
                setFilesToUpload([...filesToUpload, newVideo]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleThumbnailImageUpload = React.useCallback(
        (thumbnailImage: FileWithPreview) => {
            setThumbnailImage(thumbnailImage);
            if (thumbnailImage) {
                const newThumbnail = {
                    category: "thumbnails",
                    files: [thumbnailImage.file],
                };
                const existingThumbnailFileIndex = filesToUpload.findIndex(
                    (elem) => elem.category === "thumbnails",
                );
                if (existingThumbnailFileIndex >= 0) {
                    filesToUpload.splice(
                        existingThumbnailFileIndex,
                        1,
                        newThumbnail,
                    );
                    setFilesToUpload([...filesToUpload]);
                } else {
                    setFilesToUpload([...filesToUpload, newThumbnail]);
                }
            }
        },
        [filesToUpload],
    );

    const handleThumbnailDelete = React.useCallback(() => {
        setThumbnailImage(undefined);
        const existingThumbnailFileIndex = filesToUpload.findIndex(
            (elem) => elem.category === "thumbnails",
        );

        if (existingThumbnailFileIndex >= 0) {
            filesToUpload.splice(existingThumbnailFileIndex, 1);
            setFilesToUpload([...filesToUpload]);
        }
    }, [filesToUpload]);

    const formik = useFormik({
        initialValues: {
            title: "",
            description: "",
            status: PublishStatus.DRAFT,
            categories: [] as string[],
        },

        validationSchema: Yup.object({
            title: Yup.string().required(
                "Please enter a title for the media item",
            ),
        }),

        onSubmit(values) {
            const mediaId = uuid();
            dispatch({
                type: actions.SET_ONGOING_UPLOADS,
                payload: {
                    type: IUploadCategory.GENERAL,
                    uploadId: uploadId,
                    progress: 0,
                    mediaId: mediaId,
                    formData: values,
                    done: false,
                    abort: false,
                    filesToUpload: [...filesToUpload],
                },
            });
            console.log("Upload ID inserted", uploadId);
            // uploadFilesToS3();
        },
    });

    React.useEffect(() => {
        const currentUpload = state.ongoingUploads.find(
            (upload) => upload.uploadId === uploadId,
        );
        if (currentUpload?.done || currentUpload?.abort) {
            onUploadComplete();
        }
    }, [state.ongoingUploads, onUploadComplete, uploadId]);

    const handleAbortUpload = React.useCallback(
        (uploadId: string) => {
            const currentUpload = state.ongoingUploads.find(
                (upload) => upload.uploadId === uploadId,
            );
            currentUpload?.uploaders?.forEach((uploader) => {
                uploader.abort();
            });
            dispatch({
                type: actions.UPDATE_ONGOING_UPLOADS,
                payload: {
                    uploadId,
                    abort: true,
                },
            });
        },
        [actions, dispatch, state.ongoingUploads],
    );

    return (
        <Styled.UploadBodyWrapper>
            <Styled.UploadBodyWrapperRow>
                <Styled.UploadFormWrapper lg={4}>
                    <Styled.DetailsHeader>Details</Styled.DetailsHeader>
                    <GeneralUploadForm
                        formik={formik}
                        handleThumbnailImageUpload={handleThumbnailImageUpload}
                        handleThumbnailDelete={handleThumbnailDelete}
                        percentage={
                            state.ongoingUploads.find(
                                (upload) => upload.uploadId === uploadId,
                            )?.progress || 0
                        }
                        categories={categories}
                        abortHandler={() => handleAbortUpload(uploadId)}
                    />
                </Styled.UploadFormWrapper>
                <Styled.UploadPreviewWrapper lg={8}>
                    <UploadPreview uploadedFile={uploadedFile} />
                </Styled.UploadPreviewWrapper>
            </Styled.UploadBodyWrapperRow>
        </Styled.UploadBodyWrapper>
    );
};
