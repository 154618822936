import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Spinner from 'react-bootstrap/Spinner';
import axios from "axios";

const SF_FORM_API_ENDPOINT = "https://api-dev.cemboo.com/v1/client/salesforce/businesscontact";
const SF_LEAD_SOURCE = "Website Inquiry";

const ContactForm: React.FC = () => {

    const captchaRef = React.useRef();

    const onCaptchaChange = (val: any) => {
        console.log('Captcha value: ', val);
    }

    const [status, setStatus] = useState('');
    const [msg, setMsg] = useState('');

    const [checked, setChecked] = useState(true);

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        company: "",
        category_of_contents__c: "",
        existing_subscription_channel__c: "",
        how_many_subscribers_do_you_have__c: "",
        does_your_content_includes_livestream__c: "",
        Job_title_role_in_company__c: "",
        what_services_are_you_seeking__c: "",
        emailOptIn: 1
    });

    const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = evt.target;
        console.log(name, value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    }

    const handleCheckChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        //const isChecked = (evt.target.checked) ? 1 : 0;
        setChecked(evt.target.checked);
        const { name } = evt.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: (evt.target.checked) ? 1 : 0
        }));
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();

        if (!evt.target.checkValidity()) {
            evt.target.reportValidity();
            return false;
        }

        setStatus('loading');
        setMsg('');

        const postData = {
            lead_source: SF_LEAD_SOURCE,
            firstName: formData.firstName,
            last_name: formData.lastName,
            phone: formData.phone,
            email: formData.email,
            company: formData.company,
            category_of_contents__c: formData.category_of_contents__c,
            existing_subscription_channel__c: formData.existing_subscription_channel__c,
            how_many_subscribers_do_you_have__c: formData.how_many_subscribers_do_you_have__c,
            does_your_content_includes_livestream__c: formData.does_your_content_includes_livestream__c,
            Job_title_role_in_company__c: formData.Job_title_role_in_company__c,
            what_services_are_you_seeking__c: formData.what_services_are_you_seeking__c,
            receive_newsletter__c: (formData.emailOptIn) ? 1 : 0
        };

        axios.post(SF_FORM_API_ENDPOINT, {
            firstName: postData.firstName,
            lastName: postData.last_name,
            company: postData.company,
            email: postData.email,
            phone: postData.phone,
            category_of_contents__c: postData.category_of_contents__c,
            existing_subscription_channel__c: postData.existing_subscription_channel__c,
            how_many_subscribers_do_you_have__c: postData.how_many_subscribers_do_you_have__c,
            does_your_content_includes_livestream__c: postData.does_your_content_includes_livestream__c,
            Job_title_role_in_company__c: postData.Job_title_role_in_company__c,
            what_services_are_you_seeking__c: postData.what_services_are_you_seeking__c,
            receive_newsletter__c: postData.receive_newsletter__c
        }).then((res) => {
            setStatus('success');
            setMsg('<strong class="text-primary">Thank you!</strong> <br />Your submission has been successfully transmitted. We look forward to following up with you on your request.<br />&mdash;<em>Cemboo</em>');
            console.log('res', res);
            console.log('postData:', postData);
        }).catch((err) => {
            setStatus('error');
            setMsg('<strong class="text-primary">' + err.response?.data?.error?.message[0]?.errorCode + '</strong><br />' + err.response?.data?.error?.message[0]?.message);
            console.log('err', err);
            console.log('postData:', postData);
        });

    };

    if (status === 'success') {
        return (
            <>
                <hr className="mb-5" />
                <h3 className="mb-3">We'll be in touch&nbsp;shortly!</h3>
                <p dangerouslySetInnerHTML={{ __html: msg }}></p>
                <p><a href="/">Continue</a></p>
            </>
        );
    }

    if (status === 'error') {
        return (
            <>
                <hr className="mb-5" />
                <h3 className="mb-3">Oops! An unexpected error&nbsp;occurred.</h3>
                <p dangerouslySetInnerHTML={{ __html: msg }}></p>
                <p><a href="/">Continue</a></p>
            </>
        );
    }

    return (

        <form
            id="frmContact"
            name="frmContact"
            className="row g-1 needs-validation"
            onSubmit={handleSubmit}
            method="POST">

            <div className="form-floating mb-3">
                <input onChange={handleInputChange} type="text" className="form-control" id="firstName" name="firstName" placeholder="First Name" required />
                <label htmlFor="firstName">First Name</label>
                <div className="invalid-feedback">Please provide your first name.</div>
            </div>

            <div className="form-floating mb-3">
                <input onChange={handleInputChange} type="text" className="form-control" id="lastName" name="lastName" placeholder="Last Name" required />
                <label htmlFor="lastName">Last Name</label>
                <div className="invalid-feedback">Please provide your last name.</div>
            </div>

            <div className="form-floating mb-3">
                <input onChange={handleInputChange} type="text" className="form-control" id="company" name="company" placeholder="Company" />
                <label htmlFor="company">Company (optional)</label>
            </div>

            <div className="form-floating mb-3">
                <input onChange={handleInputChange} type="email" className="form-control" id="email" name="email" placeholder="name@example.com" aria-describedby="emailHelp" required />
                <label htmlFor="email">Email Address</label>
                <div className="invalid-feedback">Please provide a valid business email address.</div>
            </div>

            <div className="form-floating mb-3">
                <input onChange={handleInputChange} type="text" className="form-control" id="phone" name="phone" placeholder="+X (XXX) XXX-XXXX" required />
                <label htmlFor="phone" className="form-label">Phone Number</label>
                <div className="invalid-feedback">Please provide your phone number.</div>
            </div>

            <div className="form-floating mb-3">
                <select onChange={handleInputChange} className="form-select" id="category_of_contents__c" name="category_of_contents__c" aria-label="Question 1" defaultValue={'DEFAULT'} required>
                    <option value="DEFAULT" disabled>...</option>
                    <option value="Educational">Educational</option>
                    <option value="Entertainment">Entertainment</option>
                    <option value="Sports">Sports</option>
                    <option value="Other">Other</option>
                </select>
                <label htmlFor="category_of_contents__c" className="form-label">What best describes the category of your content?</label>
                <div className="invalid-feedback">Please select an option above.</div>
            </div>

            <div className="form-floating mb-3">
                <select onChange={handleInputChange} className="form-select" id="existing_subscription_channel__c" name="existing_subscription_channel__c" aria-label="Question 2" defaultValue={'DEFAULT'} required>
                    <option value="DEFAULT" disabled>...</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
                <label htmlFor="existing_subscription_channel__c" className="form-label">Do you have an existing subscription channel?</label>
                <div className="invalid-feedback">Please select an option above.</div>
            </div>

            <div className="form-floating mb-3">
                <select onChange={handleInputChange} className="form-select" id="how_many_subscribers_do_you_have__c" name="how_many_subscribers_do_you_have__c" aria-label="Question 3" defaultValue={'DEFAULT'} required>
                    <option value="DEFAULT" disabled>...</option>
                    <option value="under 1,000">Under 1,000</option>
                    <option value="2,000 to 3,000">2,000 to 3,000</option>
                    <option value="3,000 to 4,000">3,000 to 4,000</option>
                    <option value="5,000+">5,000+</option>
                </select>
                <label htmlFor="how_many_subscribers_do_you_have__c" className="form-label">How many subscribers do you have?</label>
                <div className="invalid-feedback">Please select an option above.</div>
            </div>

            <div className="form-floating mb-3">
                <select onChange={handleInputChange} className="form-select" id="does_your_content_includes_livestream__c" name="does_your_content_includes_livestream__c" aria-label="Question 4" defaultValue={'DEFAULT'} required>
                    <option value="DEFAULT" disabled>...</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
                <label htmlFor="does_your_content_includes_livestream__c" className="form-label">Does your content include livestream(s)?</label>
                <div className="invalid-feedback">Please select an option above.</div>
            </div>

            <div className="form-floating mb-3">
                <select onChange={handleInputChange} className="form-select" id="Job_title_role_in_company__c" name="Job_title_role_in_company__c" aria-label="Question 5" defaultValue={'DEFAULT'} required>
                    <option value="DEFAULT" disabled>...</option>
                    <option value="Administration">Administration</option>
                    <option value="Athletic Director">Athletic Director</option>
                    <option value="Business Owner">Business Owner</option>
                    <option value="Executive Leadership / CSuite">Executive Leadership / CSuite</option>
                    <option value="Film Festival Director">Film Festival Director</option>
                    <option value="Nonprofit">Nonprofit</option>
                    <option value="Other Business / Professional / Organizational Role">Other Business / Professional / Organizational Role</option>
                </select>
                <label htmlFor="Job_title_role_in_company__c" className="form-label">What is your role?</label>
                <div className="invalid-feedback">Please select an option above.</div>
            </div>
            <hr className="mt-4 mb-2" />
            <div className="my-4">
                <label htmlFor="what_services_are_you_seeking__c" className="form-label">What services are you seeking?</label>
                <textarea onChange={handleInputChange} className="form-control" id="what_services_are_you_seeking__c" name="what_services_are_you_seeking__c" rows={4}></textarea>
            </div>

            <div className="mb-4 d-flex justify-content-center align-items-center">
                <div className="form-check">
                    <input onChange={handleCheckChange} className="form-check-input" type="checkbox" id="emailOptIn" name="emailOptIn" defaultChecked={checked} />
                    <label className="form-check-label" style={{ color: 'var(--radcheck-border-color)' }} htmlFor="emailOptIn">
                        I agree to receive newsletters, product updates, and exclusive offers from&nbsp;Cemboo.
                    </label>
                </div>
            </div>

            <div className="mb-4 d-flex justify-content-center align-items-center">
                <ReCAPTCHA
                    ref={captchaRef}
                    theme="dark"
                    size="invisible"
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                    onChange={onCaptchaChange} />
            </div>

            {status !== 'loading' ? (
                <div className="d-grid gap-2 mb-3">
                    <button type="submit" className="btn btn-primary">Contact our team</button>
                </div>
            ) : (
                <div className="d-flex flex-column align-items-center gap-2 mb-3">
                    <div className=""><Spinner animation="border" variant="success" /></div>
                </div>
            )}

            <div className="my-4 d-flex justify-content-center align-items-center">
                <div className="recaptcha-disclaimer">
                    This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of Service</a> apply.
                </div>
            </div>
        </form>
    );
}

export default ContactForm;