import { InnerContainer, InnerSection } from "../ui";
//import * as Styled from "./about.styled";

import Customizable360Icon from './../../assets/images/services/360-CUSTOMIZABLE.png';
import CembooDashboard1 from './../../assets/images/services/cemboo-dashboard-1.png';
import CembooDashboard2 from './../../assets/images/services/cemboo-dashboard-2.png';
import CembooDashboard3 from './../../assets/images/services/cemboo-dashboard-3.png';

import IconControl from './../../assets/images/icons/icon-control.svg';
import IconVod from './../../assets/images/icons/icon-vod.svg';
import IconMonetize from './../../assets/images/icons/icon-monetize.svg';
import IconDrm from './../../assets/images/icons/icon-drm.svg';
import IconDistribute from './../../assets/images/icons/icon-distribute.svg';
import IconSubscription from './../../assets/images/icons/icon-subscription.svg';

import './about.scss';

export const AboutContainer = () => {
    return (

        <InnerSection id="about" className="about section-bg" style={{ paddingTop: '120px', overflow: 'hidden' }}>

            <InnerContainer className="page-hero">

                <div className="meta mx-4">
                    <h1 className="gradient-text" data-aos="fade-up" data-aos-delay="50"
                        data-aos-duration="800">Do You Find Video Platforms Way Too&nbsp;Complicated?</h1>
                    <h3 className="px-4 px-md-0" data-aos="zoom-in" data-aos-delay="100"
                        data-aos-duration="750">We are here to help you.</h3>
                </div>

                <div className="services-hero-media mx-2 mx-md-0 mx-xl-0">
                    <div className="services-hero ready">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px" y="0px" width="1388px" height="801.114px" viewBox="537 -105.114 1388 801.114"
                            enable-background="new 537 -105.114 1388 801.114" xmlSpace="preserve">
                            <path fill="rgba(18, 20, 26, 0.6)" d="M675,61h1097c4.418,0,8,3.582,8,8v447c0,4.418-3.582,8-8,8H675c-4.418,0-8-3.582-8-8V69
                                                	C667,64.582,670.582,61,675,61z" />
                            <g opacity="0.1">
                                <path fill="none" d="M543.869,518.086l152.661-88.14c2.135-1.232,5.596-1.232,7.729,0l231.892,133.883
                                                		c2.134,1.231,2.134,3.229,0,4.462l-152.662,88.14c-2.135,1.232-5.595,1.232-7.729,0L543.869,522.549
                                                		C541.734,521.316,541.734,519.318,543.869,518.086z" />
                                <path fill="none" d="M728.895,411.256l152.662-88.14c2.135-1.232,5.595-1.232,7.729,0l231.891,133.883
                                                		c2.135,1.232,2.135,3.229,0,4.462l-152.661,88.14c-2.135,1.232-5.596,1.232-7.73,0L728.895,415.719
                                                		C726.761,414.486,726.761,412.488,728.895,411.256z" />
                                <path fill="none" d="M913.926,304.43l152.662-88.139c2.134-1.232,5.595-1.232,7.729,0l231.891,133.882
                                                		c2.135,1.232,2.135,3.23,0,4.462l-152.662,88.139c-2.134,1.232-5.595,1.232-7.729,0L913.926,308.893
                                                		C911.791,307.66,911.791,305.662,913.926,304.43z" />
                                <path fill="none" d="M1098.955,197.603l152.661-88.139c2.135-1.232,5.596-1.232,7.729,0l231.891,133.882
                                                		c2.135,1.232,2.135,3.23,0,4.463l-152.661,88.139c-2.135,1.232-5.595,1.232-7.729,0l-231.891-133.882
                                                		C1096.82,200.833,1096.82,198.835,1098.955,197.603z" />
                                <path fill="none" d="M1283.984,90.776l152.662-88.139c2.135-1.232,5.595-1.232,7.729,0l231.891,133.882
                                                		c2.135,1.232,2.135,3.23,0,4.462l-152.661,88.139c-2.135,1.232-5.596,1.232-7.729,0L1283.984,95.239
                                                		C1281.851,94.007,1281.851,92.009,1283.984,90.776z" />
                                <path fill="none" d="M1469.015-16.05l152.662-88.139c2.134-1.232,5.595-1.232,7.729,0l231.891,133.882
                                                		c2.135,1.232,2.135,3.23,0,4.463l-152.661,88.139c-2.135,1.232-5.596,1.232-7.73,0L1469.015-11.587
                                                		C1466.881-12.82,1466.881-14.818,1469.015-16.05z" />
                            </g>
                            <g><rect x="845" y="447" fill="#B7F84D" width="15" height="17" /></g>
                            <g><rect x="835" y="534" fill="#B7F84D" width="9" height="18" /></g>
                            <g><rect x="835" y="498" fill="#B7F84D" width="9" height="11" /></g>
                            <g><rect x="835" y="517" fill="#B7F84D" width="9" height="1" /></g>
                            <g><rect x="860" y="410" fill="#B7F84D" width="25" height="4" /></g>
                            <g><rect x="828" y="167" fill="#B7F84D" width="107" height="3" /></g>
                            <g><rect x="958" y="172" fill="#B7F84D" width="31" height="3" /></g>
                            <g><rect x="879" y="173" fill="#B7F84D" width="110" height="2" /></g>
                            <g><rect x="905" y="179" fill="#B7F84D" width="49" height="4" /></g>
                            <g><rect x="787" y="170" fill="#B7F84D" width="148" height="1" /></g>
                            <g><rect x="797" y="169" fill="#B7F84D" width="138" height="1" /></g>
                            <g><rect x="860" y="415" fill="#B7F84D" width="33" height="4" /></g>
                            <g><rect x="860" y="485" fill="#B7F84D" width="56" height="5" /></g>
                            <g><rect x="1147" y="485" fill="#B7F84D" width="19" height="17" /></g>
                            <g><rect x="1506" y="419" fill="#B7F84D" width="12" height="15" /></g>
                            <g><rect x="1549" y="475" fill="#B7F84D" width="9" height="17" /></g>
                            <g><rect x="1542" y="476" fill="#B7F84D" width="7" height="11" /></g>
                            <g><rect x="1533" y="478" fill="#B7F84D" width="9" height="3" /></g>
                            <g><rect x="1533" y="483" fill="#B7F84D" width="9" height="3" /></g>
                            <g><rect x="1503" y="395" fill="#B7F84D" width="15" height="10" /></g>
                            <g><rect x="1410" y="425" fill="#B7F84D" width="22" height="4" /></g>
                            <g><rect x="1408" y="429" fill="#B7F84D" width="9" height="5" /></g>
                            <g><rect x="1401" y="440" fill="#B7F84D" width="6" height="3" /></g>
                            <g><rect x="1143" y="565" fill="#B7F84D" width="8" height="4" /></g>
                            <g><rect x="1143" y="574" fill="#B7F84D" width="8" height="4" /></g>
                            <g><rect x="1143" y="585" fill="#B7F84D" width="8" height="4" /></g>
                            <g><rect x="1144" y="492" fill="#B7F84D" width="3" height="10" /></g>
                            <g><rect x="910" y="549" fill="#B7F84D" width="125" height="2" /></g>
                            <g><rect x="860" y="551" fill="#B7F84D" width="127" height="6" /></g>
                            <g><rect x="860" y="557" fill="#B7F84D" width="56" height="12" /></g>
                            <g><rect x="1090" y="11" fill="#B7F84D" width="51" height="4" /></g>
                            <g><rect x="1359" y="33" fill="#B7F84D" width="25" height="5" /></g>
                            <g><rect x="1287" y="156" fill="#B7F84D" width="135" height="8" /></g>
                            <g><rect x="1485" y="202" fill="#B7F84D" width="176" height="2" /></g>
                            <g><rect x="1639" y="204" fill="#B7F84D" width="22" height="2" /></g>
                            <g><rect x="1280" y="173" fill="#B7F84D" width="14" height="5" /></g>
                            <g><rect x="1336" y="173" fill="#B7F84D" width="25" height="5" /></g>
                            <g><rect x="1405" y="157" fill="#B7F84D" width="25" height="6" /></g>
                            <g><rect x="1414" y="158" fill="#B7F84D" width="25" height="4" /></g>
                            <g><rect x="1394" y="38" fill="#B7F84D" width="6" height="4" /></g>
                            <g><rect x="1649" y="197" fill="#B7F84D" width="4" height="5" /></g>
                            <g><rect x="1639" y="206" fill="#B7F84D" width="7" height="6" /></g>
                            <g><rect x="1646" y="208" fill="#B7F84D" width="7" height="3" /></g>
                            <g><rect x="1780" y="491" fill="#B7F84D" width="9" height="16" /></g>
                            <g><rect x="1789" y="493" fill="#B7F84D" width="9" height="9" /></g>
                            <g><rect x="1831" y="512" fill="#B7F84D" width="9" height="10" /></g>
                            <g><rect x="1839" y="497" fill="#B7F84D" width="3" height="5" /></g>
                            <g><rect x="1839" y="506" fill="#B7F84D" width="3" height="5" /></g>
                            <g><rect x="690" y="410" fill="#B7F84D" width="11" height="4" /></g>
                            <rect x="588" y="482" fill="#565656" width="28" height="5" />
                            <rect x="588" y="488" fill="#565656" width="37" height="5" />
                            <rect x="1631" y="574" fill="#FFFFFF" fill-opacity="0.03" width="294" height="122" />
                            <rect x="802" y="90" fill="#FFFFFF" fill-opacity="0.03" width="303" height="388" />
                            <rect x="537" y="447" fill="#FFFFFF" fill-opacity="0.03" width="158" height="27" />
                            <rect x="1516" y="99" fill="#565656" width="26" height="2" />
                            <rect x="1241" y="71" fill="#565656" width="19" height="2" />
                            <rect x="1241" y="69" fill="#565656" width="19" height="2" />
                            <rect x="1529" y="90" fill="#565656" width="164" height="1" />
                            <rect x="1623" y="179" fill="#565656" width="55" height="1" />
                            <rect x="1539" y="105" fill="#565656" width="82" height="1" />
                            <rect x="1758" y="137" fill="#565656" width="84" height="5" />
                            <rect x="1776" y="147" fill="#565656" width="10" height="9" />
                            <rect x="1824" y="132" fill="#565656" width="5" height="4" />
                            <rect x="1819" y="143" fill="#565656" width="10" height="4" />
                            <rect x="1623" y="179" fill="#565656" width="8" height="3" />
                            <g><rect x="828" y="167" fill="#B7F84D" width="107" height="3" /></g>
                            <g><rect x="958" y="172" fill="#B7F84D" width="31" height="3" /></g>
                            <g><rect x="879" y="173" fill="#B7F84D" width="110" height="2" /></g>
                            <g><rect x="905" y="179" fill="#B7F84D" width="49" height="4" /></g>
                            <g><rect x="787" y="170" fill="#B7F84D" width="148" height="1" /></g>
                            <g><rect x="797" y="169" fill="#B7F84D" width="138" height="1" /></g>
                            <g><rect x="1506" y="419" fill="#B7F84D" width="12" height="15" /></g>
                            <g><rect x="1549" y="475" fill="#B7F84D" width="9" height="17" /></g>
                            <g><rect x="1542" y="476" fill="#B7F84D" width="7" height="11" /></g>
                            <g><rect x="1533" y="478" fill="#B7F84D" width="9" height="3" /></g>
                            <g><rect x="1533" y="483" fill="#B7F84D" width="9" height="3" /></g>
                            <g><rect x="1503" y="395" fill="#B7F84D" width="15" height="10" /></g>
                            <g><rect x="1410" y="425" fill="#B7F84D" width="22" height="4" /></g>
                            <g><rect x="1408" y="429" fill="#B7F84D" width="9" height="5" /></g>
                            <g><rect x="1401" y="440" fill="#B7F84D" width="6" height="3" /></g>
                            <g><rect x="1090" y="11" fill="#B7F84D" width="51" height="4" /></g>
                            <g><rect x="1359" y="33" fill="#B7F84D" width="25" height="5" /></g>
                            <g><rect x="1287" y="156" fill="#B7F84D" width="135" height="8" /></g>
                            <g><rect x="1485" y="202" fill="#B7F84D" width="176" height="2" /></g>
                            <g><rect x="1639" y="204" fill="#B7F84D" width="22" height="2" /></g>
                            <g><rect x="1280" y="173" fill="#B7F84D" width="14" height="5" /></g>
                            <g><rect x="1336" y="173" fill="#B7F84D" width="25" height="5" /></g>
                            <g><rect x="1405" y="157" fill="#B7F84D" width="25" height="6" /></g>
                            <g><rect x="1414" y="158" fill="#B7F84D" width="25" height="4" /></g>
                            <g><rect x="1394" y="38" fill="#B7F84D" width="6" height="4" /></g>
                            <g><rect x="1649" y="197" fill="#B7F84D" width="4" height="5" /></g>
                            <g><rect x="1639" y="206" fill="#B7F84D" width="7" height="6" /></g>
                            <g><rect x="1646" y="208" fill="#B7F84D" width="7" height="3" /></g>
                            <g><rect x="1780" y="491" fill="#B7F84D" width="9" height="16" /></g>
                            <g><rect x="1789" y="493" fill="#B7F84D" width="9" height="9" /></g>
                            <g><rect x="1831" y="512" fill="#B7F84D" width="9" height="10" /></g>
                            <g><rect x="1839" y="497" fill="#B7F84D" width="3" height="5" /></g>
                            <g><rect x="1839" y="506" fill="#B7F84D" width="3" height="5" /></g>
                            <g><rect x="690" y="410" fill="#B7F84D" width="11" height="4" /></g>
                            <rect x="588" y="482" fill="#565656" width="28" height="5" />
                            <rect x="588" y="488" fill="#565656" width="37" height="5" />
                            <rect x="1516" y="99" fill="#565656" width="26" height="2" />
                            <rect x="1241" y="71" fill="#565656" width="19" height="2" />
                            <rect x="1241" y="69" fill="#565656" width="19" height="2" />
                            <rect x="1529" y="90" fill="#565656" width="164" height="1" />
                            <rect x="1623" y="179" fill="#565656" width="55" height="1" />
                            <rect x="1539" y="105" fill="#565656" width="82" height="1" />
                            <rect x="1758" y="137" fill="#565656" width="84" height="5" />
                            <rect x="1776" y="147" fill="#565656" width="10" height="9" />
                            <rect x="1824" y="132" fill="#565656" width="5" height="4" />
                            <rect x="1819" y="143" fill="#565656" width="10" height="4" />
                            <rect x="1623" y="179" fill="#565656" width="8" height="3" />
                            <g><rect x="1009" y="515" fill="#B7F84D" width="19" height="17" /></g>
                            <g><rect x="1136" y="492" fill="#B7F84D" width="3" height="10" /></g>
                            <g><rect x="627" y="238" fill="#B7F84D" width="105" height="18" /></g>
                        </svg>
                    </div>
                </div>

            </InnerContainer>

            <InnerContainer className="container mt-3 mt-md-5 about-container">
                <InnerContainer className="row">
                    <InnerContainer className="col-12">

                        <InnerContainer className="cemboo-services row mb-4 mb-md-5">

                            <div className="col-12 col-md-6 mb-md-4">

                                <div className="services-solve">
                                    <h3 className="gradient-text">
                                        <img src={Customizable360Icon} alt="360° Customizable" />
                                    </h3>
                                    <h2>We solved it <br />with Cemboo</h2>
                                    <p>With the goal of driving traffic to a content owner’s specialized video library, Cemboo
                                        is an
                                        engine for managing, distributing, and monetizing video content through contemporary
                                        technologies such as blockchain.</p>
                                </div>

                            </div>

                            <div className="col-12 col-md-6">

                                <div className="cemboo-service-items" >
                                    <div className="cemboo-service-item">
                                        <div className="icon"><img src={IconControl} alt="" /></div>
                                        <h3>Control</h3>
                                        <p>Take control of your media with diverse features and customizable options for library, playlists, and live channels.</p>
                                    </div>

                                    <div className="cemboo-service-item">
                                        <div className="icon"><img src={IconVod} alt="" /></div>
                                        <h3>VOD &amp; Live Streaming</h3>
                                        <p>Elevate your content game with VOD and live streaming software that delivers seamless viewing experiences.</p>
                                    </div>

                                    <div className="cemboo-service-item">
                                        <div className="icon"><img src={IconMonetize} alt="" /></div>
                                        <h3>Monetize</h3>
                                        <p>Create and manage ad campaigns off of your own website, and keep 100% of the revenue.</p>
                                    </div>

                                    <div className="cemboo-service-item">
                                        <div className="icon"><img src={IconDrm} alt="" /></div>
                                        <h3>Digital Rights Management</h3>
                                        <p>Protect your content and digital rights with ease, using advanced digital rights management software.</p>
                                    </div>

                                    <div className="cemboo-service-item">
                                        <div className="icon"><img src={IconDistribute} alt="" /></div>
                                        <h3>Distribute</h3>
                                        <p>Easily maintain your media channels and embed unique library solutions while keeping branding consistent.</p>
                                    </div>

                                    <div className="cemboo-service-item">
                                        <div className="icon"><img src={IconSubscription} alt="" /></div>
                                        <h3>Subscription Modeling</h3>
                                        <p>Boost customer retention and growth with powerful subscription modeling, resale, and flexible pricing options.</p>
                                    </div>
                                </div>

                            </div>

                        </InnerContainer>

                        <InnerContainer className="cemboo-platform">

                            <div className="container pt-4 pt-md-5">
                                <div className="row mb-3">
                                    <div className="col-12 col-md-6">
                                        <h3 className="d-inline-block gradient-text">Introducing the <br />Cemboo platform</h3>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <p>Cemboo is an engine for managing, distributing, and monetizing video content through contemporary
                                            technologies such as blockchain.</p>
                                    </div>
                                </div>

                                <InnerContainer className="d-flex flex-column" style={{ gap: '1rem' }}>
                                    <div className="w-100">
                                        <img src={CembooDashboard1} alt="" />
                                    </div>
                                    <div className="d-flex flex-row flex-nowrap gap-3">
                                        <div className="flex-shrink-1">
                                            <img src={CembooDashboard2} alt="" />
                                        </div>
                                        <div className="flex-fill">
                                            <img src={CembooDashboard3} alt="" />
                                        </div>
                                    </div>
                                </InnerContainer>
                            </div>
                        </InnerContainer>

                    </InnerContainer>
                </InnerContainer>
            </InnerContainer>

        </InnerSection >

    );
};
