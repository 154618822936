import React from "react";
import { Col, Spinner } from "react-bootstrap";

import { CreateChannel, LiveChannelsList } from "../../components";
import { BaseButton, ILiveChannel } from "../../libs";
import { liveChannelService } from "../../services";

import * as Styled from "./LiveChannelsContainer.styled";

export const LiveChannelsContent = () => {
  const [showLoader, setShowLoader] = React.useState(true);
  const [liveChannels, setLiveChannels] = React.useState<ILiveChannel[]>([]);
  const [showCreateChannelModal, setShowCreateChannelModal] =
    React.useState(false);

  const getLiveChannels = React.useCallback(async () => {
    const response = await liveChannelService.getAllLiveChannels();
    setLiveChannels(response);
    setShowLoader(false);
  }, []);

  const handleCreateChannelClick = React.useCallback(() => {
    setShowCreateChannelModal(true);
  }, []);

  const handleCreateChannelCancel = React.useCallback(() => {
    setShowCreateChannelModal(false);
  }, []);

  const handleCreateComplete = React.useCallback(() => {
    setShowCreateChannelModal(false);
    getLiveChannels();
  }, [getLiveChannels]);

  React.useEffect(() => {
    getLiveChannels();
  }, [getLiveChannels]);

  if (showLoader) {
    return (
      <Styled.SpinnerWrapper>
        <Spinner animation="border">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <p>Fetching the live channels...</p>
      </Styled.SpinnerWrapper>
    );
  }

  return (
    <Styled.LiveChannelsContainer fluid>
      <Styled.HeaderRow>
        <Col xl={10} md={8}>
          <Styled.Title>Live Channels</Styled.Title>
        </Col>

        {!showCreateChannelModal && (
          <Col xl={2} md={4}>
            <BaseButton onClick={handleCreateChannelClick}>
              Create Channel
            </BaseButton>
          </Col>
        )}
      </Styled.HeaderRow>
      {showCreateChannelModal ? (
        <CreateChannel
          onCancel={handleCreateChannelCancel}
          onCreateComplete={handleCreateComplete}
        />
      ) : liveChannels && liveChannels.length > 0 ? (
        <LiveChannelsList channelsList={liveChannels} />
      ) : (
        <>
          There are no channels created in your account at this time. Start
          creating by clicking on Create Channel button
        </>
      )}
    </Styled.LiveChannelsContainer>
  );
};
