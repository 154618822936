export interface IPaymentAccount {
  accountId: string;
  enabled?: boolean;
  currency: string;
  requirements: string[];
  bankAccount: IBankDetails[];
}

export interface IBankDetails {
  accountNumber: string;
  bankName: string;
  routingNumber: string;
  accountHolderName?: string;
}

export interface IProductPlanRequest {
  accountId: string;
  name: string;
  description: string;
  price: number;
  expiryDate?: string;
  isRecurring?: boolean;
  frequency?: string;
}

export interface IProductPlanDetail {
  id: string;
  name: string;
  expiryDate?: string;
  description: string;
  price: number;
  channelId?: string;
  events?: IEvent[];
}

export interface IEvent {
  id: string;
  eventName: string;
  channelName: string;
}

export interface IMemberPlanRequest {
  name: string;
  amount: number;
  description?: string;
  expiration: string; // "NONE || "specific date and time" || "7d"
  isEnabled: boolean;
  isPublic: boolean;
  planType: IPlanType;
  isRecurring?: boolean;
  frequency?: string;
}

export interface IMemberPlan {
  id: string;
  name: string;
  description: string;
  amount: number;
  isRecurring?: boolean;
  isEnabled: boolean;
  planType: IPlanType;
  isPublic: boolean;
  expiration?: string;
  frequency?: string;
  active?: boolean;
}

export enum IPlanType {
  VOD = 1,
  liveStream = 2,
}