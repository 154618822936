import React from "react";
import { Dropdown, Modal, Spinner, Tab } from "react-bootstrap";

import { confirmAlert } from "react-confirm-alert";

import {
    ConfirmModal,
    ICategoryType,
    IMediaResponseDto,
    IUploadCategory,
} from "../../../libs";

import DefaultThumbnailImage from "./../../../assets/images/default_thumbnail.jpeg";
import OptionsIcon from "./../../../assets/images/icons/icon-options.svg";
import DeleteBinIcon from "./../../../assets/images/icons/icon-delete-bin.svg";

import { CembooPlayer } from "@haydenfilms-institute/cemboo-player";
import * as Styled from "./UploadsContent.styled";
import { IUploadType, UploadModal } from "../../upload-modal";
import { useAuth } from "../../../behavioral";
import { Pencil } from "react-bootstrap-icons";

const UploadsContent: React.FC<{
    mediaItems: IMediaResponseDto[];
    categories: ICategoryType[];
    onDeleteUpload: (mediaId: string) => void;
    onUpdateUploads: () => void;
    progressItems?: {
        [key in string]: { percentage: number; currentPhase: string };
    };
    mediaCount?: number;
}> = ({
    mediaItems,
    categories,
    onDeleteUpload,
    onUpdateUploads,
    progressItems,
    mediaCount,
}) => {
    const [showPlayer, setShowPlayer] = React.useState(false);
    const [currentPlayingMedia, setCurrentPlayingMedia] =
        React.useState<IMediaResponseDto>();
    const [deletingItemId, setDeletingItemId] = React.useState("");
    const [showEditModal, setShowEditModal] = React.useState(false);
    const [selectedMedia, setSelectedMedia] =
        React.useState<IMediaResponseDto>();
    const authInfo = useAuth();

    const handlePlayVideo = React.useCallback(
        (selectedMedia: IMediaResponseDto) => {
            setShowPlayer(true);
            setCurrentPlayingMedia(selectedMedia);
        },
        [],
    );

    const hidePlayer = React.useCallback(() => {
        setShowPlayer(false);
        setCurrentPlayingMedia(undefined);
    }, []);

    const handleDeleteUpload = React.useCallback(
        (event, mediaId) => {
            event.stopPropagation();

            // confirm delete action
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <ConfirmModal
                            title="Confirm Item Removal"
                            description={[
                                {
                                    text: "Are you sure, you want to remove this item?",
                                    bold: false,
                                },
                                {
                                    text: "This operation is permanent and cannot be reverted.",
                                    bold: true,
                                },
                            ]}
                            noText="No"
                            yesText="Yes, Delete it!"
                            onNo={onClose}
                            onYes={() => {
                                setDeletingItemId(mediaId);
                                onDeleteUpload(mediaId);
                                onClose();
                            }}
                            danger
                        ></ConfirmModal>
                    );
                },
                closeOnEscape: true,
                closeOnClickOutside: false,
                keyCodeForClose: [8, 32],
            });
        },
        [onDeleteUpload],
    );

    type CustomToggleProps = {
        onClick: (e: React.MouseEvent<HTMLImageElement>) => void;
    };

    const VodDropdownToggle = React.forwardRef<
        HTMLImageElement,
        CustomToggleProps
    >(({ onClick }, ref) => (
        <Styled.ShowVodOptions
            src={OptionsIcon}
            ref={ref}
            alt="Options"
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        />
    ));

    const generateMediaItemCard = (item: IMediaResponseDto) => {
        return (
            <Styled.UploadCol md={4} sm={6} xl={3} key={item.id}>
                <Styled.UploadCard
                    title="View Item"
                    className={
                        !item.processed || item.mediaId === deletingItemId
                            ? "disabled"
                            : ""
                    }
                >
                    <Styled.VodCardDropdown>
                        <Dropdown.Toggle as={VodDropdownToggle} />
                        <Styled.VodCardDropdownMenu>
                            <Styled.VodCardDropdownItem
                                danger="true"
                                onClick={(event) =>
                                    handleDeleteUpload(event, item.mediaId)
                                }
                            >
                                <Styled.VodCardDropdownIcon
                                    src={DeleteBinIcon}
                                />
                                Delete
                            </Styled.VodCardDropdownItem>
                            <Styled.VodCardDropdownItem
                                onClick={() => {
                                    setSelectedMedia(item);
                                    setShowEditModal(true);
                                }}
                            >
                                <Pencil style={{ marginRight: "10px" }} />
                                Edit
                            </Styled.VodCardDropdownItem>
                        </Styled.VodCardDropdownMenu>
                    </Styled.VodCardDropdown>
                    <Styled.ImageContainer
                        onClick={() => handlePlayVideo(item)}
                    >
                        <Styled.UploadCardImage
                            src={
                                item.processed
                                    ? item.thumbnailsSrc
                                    : DefaultThumbnailImage
                            }
                        />
                    </Styled.ImageContainer>
                    <Styled.UploadCardBody
                        onClick={() => handlePlayVideo(item)}
                    >
                        <Styled.UploadCardTitle>
                            {item.title}
                        </Styled.UploadCardTitle>
                        <Styled.UploadCardText>
                            {new Date(item.createdAt).toLocaleDateString(
                                "en-us",
                                {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                },
                            ) + " • "}
                            {item.status}
                            {item.categoryType.length > 0 &&
                                ` • ${
                                    categories.find(
                                        (category) =>
                                            category.categoryId ===
                                            item.categoryType[0],
                                    )?.categoryType
                                }`}
                            {item.categoryType.length > 1 &&
                                ` + ${item.categoryType.length - 1} more`}
                        </Styled.UploadCardText>
                    </Styled.UploadCardBody>
                    {(!item.processed || item.mediaId === deletingItemId) && (
                        <Styled.SpinnerWrapper>
                            <Spinner variant="primary" />
                            {progressItems && progressItems[item.mediaId]
                                ? progressItems[item.mediaId].currentPhase
                                : item.mediaId === deletingItemId
                                  ? "Deleting"
                                  : "Processing"}{" "}
                            {progressItems &&
                                progressItems[item.mediaId] &&
                                `${progressItems[item.mediaId].percentage}%`}
                        </Styled.SpinnerWrapper>
                    )}
                </Styled.UploadCard>
            </Styled.UploadCol>
        );
    };

    const filmFestivalUploadCardCols = mediaItems
        .filter((item) => item.category === IUploadCategory.FILM_FESTIVAL)
        .slice(0, mediaCount ? mediaCount : mediaItems.length)
        .map((item) => generateMediaItemCard(item));

    const generalUploadCardCols = mediaItems
        .filter((item) => item.category === IUploadCategory.GENERAL)
        .slice(0, mediaCount ? mediaCount : mediaItems.length)
        .map((item) => generateMediaItemCard(item));

    return (
        <>
            <Styled.UploadContentWrapper>
                {showPlayer && currentPlayingMedia ? (
                    <Modal
                        show={showPlayer}
                        fullscreen={true}
                        onHide={hidePlayer}
                    >
                        <Styled.PlayerModalHeader
                            closeButton
                            closeVariant="white"
                        >
                            <Modal.Title>
                                {currentPlayingMedia?.title ||
                                    currentPlayingMedia?.title}
                            </Modal.Title>
                        </Styled.PlayerModalHeader>
                        <Styled.PlayerModalBody>
                            <CembooPlayer
                                url={currentPlayingMedia.playbackUrl}
                                showThumbnail={true}
                            />
                        </Styled.PlayerModalBody>
                    </Modal>
                ) : (
                    <>
                        <Styled.UploadsTabs
                            defaultActiveKey="general"
                            id="upload-content-tabs"
                            className="mb-3"
                            fill
                        >
                            <Tab eventKey="general" title="General">
                                {generalUploadCardCols.length > 0 ? (
                                    <Styled.UploadsCategoryWrapper>
                                        <Styled.UploadsRow>
                                            {generalUploadCardCols}
                                        </Styled.UploadsRow>
                                    </Styled.UploadsCategoryWrapper>
                                ) : (
                                    <div>
                                        No Media Here! Get started by uploading
                                        some content
                                    </div>
                                )}
                            </Tab>
                            <Tab eventKey="film-festival" title="Film Festival">
                                {filmFestivalUploadCardCols.length > 0 ? (
                                    <Styled.UploadsCategoryWrapper>
                                        <Styled.UploadsRow>
                                            {filmFestivalUploadCardCols}
                                        </Styled.UploadsRow>
                                    </Styled.UploadsCategoryWrapper>
                                ) : (
                                    <div>
                                        No Media Here! Get started by uploading
                                        some content
                                    </div>
                                )}
                            </Tab>
                        </Styled.UploadsTabs>
                    </>
                )}
                {selectedMedia && (
                    <UploadModal
                        show={showEditModal}
                        onHide={() => setShowEditModal(false)}
                        mediaItem={selectedMedia}
                        uploadType={
                            selectedMedia.category === IUploadCategory.GENERAL
                                ? IUploadType.GENERAL_VIDEO
                                : IUploadType.FILM_FESTIVAL_VIDEO
                        }
                        clientId={authInfo?.user?.clientId}
                        onUploadComplete={() => {
                            setShowEditModal(false);
                            onUpdateUploads();
                        }}
                    />
                )}
            </Styled.UploadContentWrapper>
        </>
    );
};

export { UploadsContent };
