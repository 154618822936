import React from "react";
import {
    IOngoingUploads,
    IStateActionType,
    IStateContextType,
    IUploadCategory,
} from "../libs";
import {
    checkGeneralUploadFiles,
    executeGeneralUpload,
    uploadFilesToS3,
} from "../components/upload-modal/general/GeneralUploadUtils";
import { executeFilmFestivalUpload } from "../components/upload-modal/upload-modal-body/film-festival/FilmFestivalUploadUtils";

const initialState: IStateContextType = {
    storage: {
        used: 0,
        limit: 0,
        category: {
            liveStream: 0,
            vod: {
                general: 0,
                filmFestival: 0,
            },
            other: 0,
        },
    },
    isAdmin: false,
    allowAccess: false,
    currentUser: undefined,
    ongoingUploads: [],
    refreshOnUpload: false,
};

export interface IStateContext {
    state: IStateContextType;
    dispatch: React.Dispatch<any>;
    actions: typeof IStateActionType;
}

export const StateContext = React.createContext<IStateContext | undefined>(
    undefined,
);

const reducer = (state, action) => {
    switch (action.type) {
        case IStateActionType.SET_STORAGE:
            return {
                ...state,
                storage: action.payload,
            };
        case IStateActionType.SET_IS_ADMIN:
            return {
                ...state,
                isAdmin: action.payload,
            };
        case IStateActionType.SET_ALLOW_ACCESS:
            return {
                ...state,
                allowAccess: action.payload,
            };
        case IStateActionType.SET_USER:
            return {
                ...state,
                currentUser: action.payload,
            };
        case IStateActionType.SET_ONGOING_UPLOADS:
            if (
                state.ongoingUploads.some(
                    (upload) => upload.uploadId === action.payload.uploadId,
                )
            ) {
                return state;
            }
            return {
                ...state,
                ongoingUploads: [...state.ongoingUploads, action.payload],
            };
        case IStateActionType.UPDATE_ONGOING_UPLOADS:
            return {
                ...state,
                ongoingUploads: state.ongoingUploads.map((upload) => {
                    if (upload.uploadId === action.payload.uploadId) {
                        return {
                            ...upload,
                            ...action.payload,
                        };
                    }
                    return upload;
                }),
            };
        case IStateActionType.SET_REFRESH_ON_UPLOAD:
            return {
                ...state,
                refreshOnUpload: action.payload,
            };
        default:
            return state;
    }
};

export const StateProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const [uploadsInProgress, setUploadsInProgress] = React.useState<string[]>(
        [],
    );
    const [queuedUploads, setQueuedUploads] = React.useState<string[]>([]);

    const actions = IStateActionType;

    const handleOngoingUploads = React.useCallback(
        async (uploads: IOngoingUploads[]) => {
            for (const upload of uploads) {
                if (!upload.done && !upload.abort) {
                    if (
                        !queuedUploads.some(
                            (item) => item === upload.uploadId,
                        ) &&
                        state.currentUser
                    ) {
                        if (queuedUploads.length < 1) {
                            setQueuedUploads([
                                ...queuedUploads,
                                upload.uploadId,
                            ]);
                            uploadFilesToS3(
                                dispatch,
                                upload,
                                state.currentUser.userId,
                            );
                        }
                    }
                    if (
                        !uploadsInProgress.some(
                            (item) => item === upload.uploadId,
                        )
                    ) {
                        if (checkGeneralUploadFiles(upload))
                            setUploadsInProgress([
                                ...uploadsInProgress,
                                upload.uploadId,
                            ]);
                        else {
                            console.log("Not all files uploaded yet", upload);
                            return;
                        }
                    } else {
                        console.log(
                            "Upload already in progress",
                            upload.uploadId,
                        );
                        return;
                    }
                    console.log("upload type", upload.type);

                    if (upload.type === IUploadCategory.GENERAL) {
                        const uploadOutput = await executeGeneralUpload(upload);
                        if (uploadOutput) {
                            dispatch({
                                type: actions.UPDATE_ONGOING_UPLOADS,
                                payload: {
                                    ...upload,
                                    done: true,
                                },
                            });
                            dispatch({
                                type: actions.SET_REFRESH_ON_UPLOAD,
                                payload: true,
                            });
                        }
                    } else if (upload.type === IUploadCategory.FILM_FESTIVAL) {
                        console.log("started the execute");
                        const uploadOutput =
                            await executeFilmFestivalUpload(upload);
                        if (uploadOutput) {
                            dispatch({
                                type: actions.UPDATE_ONGOING_UPLOADS,
                                payload: {
                                    ...upload,
                                    done: true,
                                },
                            });
                            dispatch({
                                type: actions.SET_REFRESH_ON_UPLOAD,
                                payload: true,
                            });
                        }
                    }
                } else {
                    if (queuedUploads.some((item) => item === upload.uploadId))
                        setQueuedUploads(
                            queuedUploads.filter(
                                (item) => upload.uploadId !== item,
                            ),
                        );
                }
            }
        },
        [actions, uploadsInProgress, queuedUploads, state.currentUser],
    );

    React.useEffect(() => {
        handleOngoingUploads(state.ongoingUploads);
    }, [state.ongoingUploads, handleOngoingUploads]);

    return (
        <StateContext.Provider value={{ state, dispatch, actions }}>
            {children}
        </StateContext.Provider>
    );
};
