import {
  IFilmFestivalUploadRequestDto,
  IGeneralUploadRequestDto,
} from "./upload-request-dto";

export interface IFilmFestivalUpload extends IFilmFestivalUploadRequestDto {
  uploadId: string;
  createdAt: string;
}

export interface IGeneralUpload extends IGeneralUploadRequestDto {
  uploadId: string;
  createdAt: string;
}

export enum IUploadCategory {
  FILM_FESTIVAL = "FILM_FESTIVAL",
  GENERAL = "GENERAL",
}

export interface IUploads {
  [IUploadCategory.GENERAL]: IGeneralUpload[];
  [IUploadCategory.FILM_FESTIVAL]: IFilmFestivalUpload[];
}

export interface IDetails {
  title: string;
  description?: string;
  directors: string;
  producers: string;
  productionYear: string;
  audience: IAudience;
  imdbLink?: string;
  genre?: string;
  playlist?: IPlayList;
  thumbnails?: FileWithPreview;
}

export interface FileWithPreview {
  file: File;
  previewUrl?: string;
}

export interface UploadedFilwWithPreview {
  fileName: string;
  previewUrl: string;
  s3Key?: string;
  s3Prefix?: string;
  cdnPath?: string;
}

export interface ILanguageDetails {
  language: string;
  subtitles?: ISubtitle[];
}

export interface ICast {
  id?: string;
  name: string;
  castImageFile?: FileWithPreview;
  roleName?: string;
}

export interface ISponsor {
  id?: string;
  sponsorLogo: FileWithPreview;
}

export interface ISubtitle {
  id?: string;
  fileName: string;
  fileLanguage?: string;
  subtitleFile?: FileWithPreview;
}

export enum IAudience {
  GENERAL = "GENERAL",
  ADULT = "ADULT",
  PARENTAL_GUIDANCE = "PARENTAL_GUIDANCE",
  STRICT_PARENTAL_GUIDANCE = "STRICT_PARENTAL_GUIDANCE",
  RESTRICTED = "RESTRICTED",
}

export interface IPlayList {
  id: string;
  name: string;
}

export enum PublishStatus {
  DRAFT = "DRAFT",
  SCHEDULED = "SCHEDULED",
  PUBLISHED = "PUBLISHED",
}
