import React from "react";

import * as Styled from "./Input.styled";

interface ITextInputProps {
  label?: string;
  type?: string;
  placeholder?: string;
  controlId?: string;
  value?: string | number;
  onChangeHandler?: (value: string) => void;
  maxLength?: number;
  onBlurHandler?: (e: Event) => void;
  hasError?: boolean;
  readOnly?: boolean;
  onFocusHandler?: (e: Event) => void;
}

export const FloatingTextInput: React.FC<ITextInputProps> = ({
  label,
  type = "text",
  placeholder,
  controlId,
  onChangeHandler,
  value,
  maxLength,
  onBlurHandler,
  hasError,
  readOnly,
  onFocusHandler,
}) => {
  return (
    <Styled.FormGroupFloatingLabel controlId={controlId} label={label}>
      <Styled.FormGroupInput
        className={hasError ? "is-invalid" : ""}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChangeHandler}
        name={controlId}
        autoComplete="off"
        maxLength={maxLength}
        onBlur={onBlurHandler}
        readOnly={readOnly}
        onFocus={onFocusHandler}
      />
    </Styled.FormGroupFloatingLabel>
  );
};
