import styled from "styled-components";
import { Form, Row } from "react-bootstrap";

export const RegistrationForm = styled(Form)`
    margin-top: 20px;
`;

export const RegisterFormRow = styled(Row)`
    justify-content: center;
    margin: 10px 0;
`;
