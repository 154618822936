import styled from "styled-components";
import { colors, OuterContainer } from "../../../../libs";
import { Modal } from "react-bootstrap";

export const CreateModalHeaderWrapper = styled(OuterContainer)`
  padding: 15px;
`;

export const ModalHeader = styled(Modal.Header)`
  background: ${colors.background.black};
  color: ${colors.text.mediumWhite};
  border-bottom: none;
  padding: 0;
  padding-right: 20px;
`;

export const ModalTitle = styled(Modal.Title)`
  flex: 1;
`;

export const EditHeader = styled.div`
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
`;
