import { useStripe } from "@stripe/react-stripe-js";
import { useFormik } from "formik";
import * as React from "react";
import { Col, Form, Spinner } from "react-bootstrap";
import * as Yup from "yup";

import {
  BaseButton,
  Error,
  FloatingTextInput as TextInput,
} from "../../../libs";
import * as Styled from "./AccountForm.styled";

export const AccountForm: React.FC<{
  onSubmit: (
    bankToken: string,
    setSubmitting: (result: boolean) => void
  ) => void;
  onCancel: () => void;
}> = ({ onSubmit, onCancel }) => {
  const stripe = useStripe();

  const formik = useFormik({
    initialValues: {
      country: "US",
      currency: "USD",
      accountHolderName: "",
      accountType: "individual",
      routingNumber: "",
      accountNumber: "",
    },

    validationSchema: Yup.object({
      accountHolderName: Yup.string().required(
        "Please enter a name of account holder."
      ),
      routingNumber: Yup.number().required("Please enter a routing number."),
      accountNumber: Yup.number().required("Please enter the account number."),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      const bankToken = await createBankToken();
      if (bankToken) {
        onSubmit(bankToken, setSubmitting);
      }
    },
  });

  const createBankToken = React.useCallback(async () => {
    if (!stripe) return;
    const result = await stripe.createToken("bank_account", {
      country: "US",
      currency: "USD",
      account_holder_name: formik.values.accountHolderName,
      account_holder_type: "individual",
      routing_number: formik.values.routingNumber,
      account_number: formik.values.accountNumber,
    });

    console.log(result);
    return result.token?.id || "";
  }, [
    formik.values.accountHolderName,
    formik.values.accountNumber,
    formik.values.routingNumber,
    stripe,
  ]);
  return (
    <Form onSubmit={formik.handleSubmit}>
      <Styled.FormFieldRow>
        <Col xl={6} md={8}>
          <TextInput
            controlId="accountHolderName"
            label="Account Holder Name*"
            placeholder="Account Holder Name"
            value={formik.values.accountHolderName}
            onChangeHandler={formik.handleChange}
            onBlurHandler={formik.handleBlur}
            hasError={
              formik.touched.accountHolderName &&
              !!formik.errors.accountHolderName
            }
          />
          {formik.touched.accountHolderName &&
            formik.errors.accountHolderName && (
              <Error message={formik.errors.accountHolderName} />
            )}
        </Col>
      </Styled.FormFieldRow>
      <Styled.FormFieldRow>
        <Col xl={6} md={8}>
          <TextInput
            controlId="routingNumber"
            label="Routing Number*"
            placeholder="Routing Number"
            value={formik.values.routingNumber}
            onChangeHandler={formik.handleChange}
            onBlurHandler={formik.handleBlur}
            hasError={
              formik.touched.routingNumber && !!formik.errors.routingNumber
            }
          />
          {formik.touched.routingNumber && formik.errors.routingNumber && (
            <Error message={formik.errors.routingNumber} />
          )}
        </Col>
      </Styled.FormFieldRow>
      <Styled.FormFieldRow>
        <Col xl={6} md={8}>
          <TextInput
            controlId="accountNumber"
            label="Account Number*"
            placeholder="Account Number"
            value={formik.values.accountNumber}
            onChangeHandler={formik.handleChange}
            onBlurHandler={formik.handleBlur}
            hasError={
              formik.touched.accountNumber && !!formik.errors.accountNumber
            }
          />
          {formik.touched.accountNumber && formik.errors.accountNumber && (
            <Error message={formik.errors.accountNumber} />
          )}
        </Col>
      </Styled.FormFieldRow>

      <Styled.DetailRow>
        <Col md={2}>
          <Styled.DetailLabel>Country</Styled.DetailLabel>
          <Styled.DetailText>{formik.values.country}</Styled.DetailText>
        </Col>
        <Col md={2}>
          <Styled.DetailLabel>Currency</Styled.DetailLabel>
          <Styled.DetailText>{formik.values.currency}</Styled.DetailText>
        </Col>
        <Col md={2}>
          <Styled.DetailLabel>Account Type</Styled.DetailLabel>
          <Styled.DetailText>{formik.values.accountType}</Styled.DetailText>
        </Col>
      </Styled.DetailRow>
      <Styled.AccountFormFieldRow>
        <Col xl={3} md={4}>
          <BaseButton variant="secondary" onClick={onCancel}>
            Cancel
          </BaseButton>
        </Col>
        <Col xl={3} md={4}>
          <BaseButton type="submit">
            {formik.isSubmitting && <Spinner size="sm" />}
            {"  "}
            Save
          </BaseButton>
        </Col>
      </Styled.AccountFormFieldRow>
    </Form>
  );
};
