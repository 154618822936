import * as React from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";

import { BaseButton, IMemberPlan, IPlanType } from "../../../libs";
import * as Styled from "./PlanDetails.styled";

interface IProps {
  plans: IMemberPlan[];
  onCreatePlanClick: () => void;
  onEditPlanClick: (planId: string) => void;
  onDeletePlanClick: (planId: string) => void;
}
export const PlanDetails: React.FC<IProps> = ({
  plans,
  onCreatePlanClick,
  onEditPlanClick,
  onDeletePlanClick,
}) => {
  const options: any = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return (
    <Container fluid>
      <Styled.DetailRow>
        <Col md={6}>
          <Styled.Subtitle>My Plans</Styled.Subtitle>
        </Col>
      </Styled.DetailRow>
      {plans.length === 0 ? (
        <>
          <Styled.CreateButtonRow>
            <Col md={8}>
              There are no plans created, start creating a product plan by
              clicking on the Create Plan button below.
            </Col>
          </Styled.CreateButtonRow>
        </>
      ) : (
        <Container fluid>
          <Row>
            <Col xl={12} md={12}>
              {plans.map((item) => (
                <Styled.PlanCard
                  className={item.active && item.isEnabled ? "" : "disabled"}
                  key={item.id}
                >
                  <Styled.PlanDetailRow>
                    <Col md={10} xl={11}>
                      <Styled.TitleRow>
                        <Col>
                          <Styled.Title>
                            {item.name} {!item.active && "(Expired)"}
                          </Styled.Title>
                        </Col>
                      </Styled.TitleRow>
                      <Styled.Description>
                        <Col>{item.description}</Col>
                      </Styled.Description>

                      <Row>
                        <Col xl={8} md={12}>
                          <Styled.AccessDetail>
                            {item.isRecurring
                              ? "Membership Subscription"
                              : item.expiration && item.expiration !== "NONE"
                              ? isNaN(Date.parse(item.expiration))
                                ? `Access expires ${item.expiration.substring(
                                    0,
                                    item.expiration.length - 1
                                  )} days after purchasing the plan`
                                : `Access expires on ${new Date(
                                    item.expiration
                                  ).toLocaleDateString(
                                    "en-US",
                                    options
                                  )} at ${new Date(
                                    item.expiration
                                  ).toLocaleTimeString()}`
                              : "There is no expiration for the plan."}{" "}
                            {item.active && (
                              <span className={item.isPublic ? "public" : ""}>
                                [{item.isPublic ? "Public" : "Private"}]{" "}
                              </span>
                            )}
                            {item.active && (
                              <span className={item.isEnabled ? "public" : ""}>
                                [{item.isEnabled ? "Enabled" : "Disabled"}]{" "}
                              </span>
                            )}
                            {item.planType && (
                              <span className="public">[{item.planType === IPlanType.VOD ? "VOD" : "Live Stream"}]</span>
                            )}
                          </Styled.AccessDetail>
                        </Col>
                        <Col xl={4} md={3}>
                          $
                          {item.amount
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                        </Col>
                      </Row>
                    </Col>
                    <Col xl={1} md={2}>
                      <Stack>
                        <Styled.EditLink
                          onClick={() => onEditPlanClick(item.id)}
                        >
                          Edit
                        </Styled.EditLink>
                        {(!item.active ||
                          (!item.isEnabled && !item.isPublic)) && (
                          <Styled.EditLink
                            onClick={() => onDeletePlanClick(item.id)}
                          >
                            Delete
                          </Styled.EditLink>
                        )}
                      </Stack>
                    </Col>
                  </Styled.PlanDetailRow>
                </Styled.PlanCard>
              ))}
            </Col>
          </Row>
        </Container>
      )}
      <Styled.CreateButtonRow>
        <Col xl={2} md={4}>
          <BaseButton onClick={onCreatePlanClick}>Create Plan</BaseButton>
        </Col>
      </Styled.CreateButtonRow>
    </Container>
  );
};
