import { IAudience } from "./uploads";

export type IPlaylistMediaItem = {
    id: string;
    mediaId: string;
    title: string;
    description: string;
    publishedDate: string;
    thumbnail: string;
    video: string;
    directors: string;
    producers: string;
    genre: string;
    year: string;
    audience: IAudience;
    language: string;
};

export enum PlaylistPublishStatus {
    DRAFT = 0,
    PUBLISHED = 1,
}

export enum PlaylistStatus {
    VIDEO_SELECTION = 0,
    ORDER_SELECTION = 1,
    SETTINGS = 2,
    PUBLISHED = 3,
}

export interface IPlaylistRequestDtoBase {
    title: string;
    description: string;
    thumbnails: {
        userUploaded?: {
            fileName: string;
            s3Key: string;
        },
        existing?: {
            url: string;
        }
    },
    audience: number;
    visibility: number;
    playliststatus: number;
    steps: number;
    category?: string;
}

export interface IPlaylistRequestDto extends IPlaylistRequestDtoBase {
    mediaItems: string[];
}

export interface IPlaylistResponseDto extends IPlaylistRequestDtoBase {
    playlistId: string;
    thumbnails: {
        userUploaded?: {
            fileName: string;
            s3Key: string;
            cdnPath: string;
            s3Prefix: string;
        },
        existing?: {
            url: string;
        }
    },
    mediaItems?: IPlaylistMediaItem[];
    timestamp?: string;
}
