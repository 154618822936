import axios from "axios";
import {
  API_BASE_PATHS,
  API_ROUTES,
  ICategoryType,
  IFilmFestivalMediaRequestDto,
  IGeneralMediaRequestDto,
  MoveRecordingToVodRequestDto,
} from "../libs";
import { buildRequestConfig } from "./buildRequestConfig";

class VODService {
  async getAllMediaItems() {
    try {
      const config = await buildRequestConfig();
      const response = await axios.get(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.VOD_API}/${API_ROUTES.MEDIA}`,
        { ...config }
      );

      return response.data.data;
    } catch (err: any) {
      console.log("There is an error while fetching all media items", err);
      throw new Error(err);
    }
  }

  async createGeneralMediaItem(generalMediaRequest: IGeneralMediaRequestDto) {
    try {
      const config = await buildRequestConfig();
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.VOD_API}/${API_ROUTES.MEDIA}`,
        generalMediaRequest,
        { ...config }
      );

      return response.data;
    } catch (err: any) {
      console.log("There is an error while creating a general media item", err);
      throw new Error(err);
    }
  }

  async editGeneralMediaItem(generalMediaRequest: Partial<IGeneralMediaRequestDto>) {
    try {
      const config = await buildRequestConfig();
      const { mediaId, ...requestData } = generalMediaRequest;
      const response = await axios.put(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.VOD_API}/${API_ROUTES.MEDIA}/${mediaId}`,
        requestData,
        { ...config }
      );

      return response.data;
    } catch (err: any) {
      console.log("There is an error while creating a general media item", err);
      throw new Error(err);
    }
  }

  async editFilmFestivalMediaItem(filmFestivalMediaRequest: Partial<IFilmFestivalMediaRequestDto>) {
    try {
      const config = await buildRequestConfig();
      const { mediaId, ...requestData } = filmFestivalMediaRequest;
      const response = await axios.put(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.VOD_API}/${API_ROUTES.MEDIA}/${mediaId}`,
        requestData,
        { ...config }
      );

      return response.data;
    } catch (err: any) {
      console.log("There is an error while creating a general media item", err);
      throw new Error(err);
    }
  }

  async createFilmFestivalMediaItem(
    filmFestivalMediaRequest: IFilmFestivalMediaRequestDto
  ) {
    try {
      const config = await buildRequestConfig();
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.VOD_API}/${API_ROUTES.MEDIA}`,
        filmFestivalMediaRequest,
        { ...config }
      );

      return response.data;
    } catch (err: any) {
      console.log(
        "There is an error while creating a film festival media item",
        err
      );
      throw new Error(err);
    }
  }

  async deleteMediaItem(mediaId: string) {
    try {
      const config = await buildRequestConfig();
      const response = await axios.delete(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.VOD_API}/${API_ROUTES.MEDIA}/${mediaId}`,
        { ...config }
      );

      return response.data;
    } catch (err: any) {
      console.log("There is an error while deleting a media item", err);
      throw new Error(err);
    }
  }

  async moveRecordingToVod(moveToVod: MoveRecordingToVodRequestDto) {
    try {
      const config = await buildRequestConfig();
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.VOD_API}/${API_ROUTES.MOVE_TO_VOD}`,
        moveToVod,
        { ...config }
      );

      return response.data;
    } catch (err: any) {
      console.log("There is an error while moving recording to VOD", err);
      throw new Error(err);
    }
  }

  async getCategories(): Promise<ICategoryType[]> {
    try {
      const config = await buildRequestConfig();
      const response = await axios.get(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.VOD_API}/${API_ROUTES.CATEGORY}`,
        { ...config }
      );

      return response.data.data;
    } catch (err: any) {
      console.log("There is an error while fetching categories", err);
      throw new Error(err);
    }
  }
}

export const vodService = new VODService();
