export enum CreateModalSteps {
    VIDEO_SELECTION = 0,
    ORDER_SELECTION = 1,
    DETAILS = 2,
}

export type CreateModalStepsState = {
    step: CreateModalSteps;
    title: string;
    completed: boolean;
    disabled: boolean;
    inprogress: boolean;
};