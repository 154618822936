import React from "react";

import { FileWithPreview } from "./../../../../libs";

import * as Styled from "./UploadPreview.styled";
import { CembooPlayer } from "@haydenfilms-institute/cemboo-player";

export const UploadPreview: React.FC<{
    uploadedFile: FileWithPreview | string;
}> = ({ uploadedFile }) => {
    return (
        <Styled.VideoWrapper>
            {typeof uploadedFile === "string" ? (
                <CembooPlayer
                    url={uploadedFile}
                    enableKeyboardShortcuts={false}
                />
            ) : (
                <Styled.VideoPlayer width="400" controls>
                    <source src={uploadedFile?.previewUrl} id="video_here" />
                    Your browser does not support HTML5 video.
                </Styled.VideoPlayer>
            )}
        </Styled.VideoWrapper>
    );
};
