import React from "react";
import * as Styled from "./CreateModalBody.styled";

import { OrderSelection } from "./order-selection";
import { PlaylistDetails } from "./playlist-details";
import { VideoSelection } from "./video-selection";
import { CreateModalSteps, CreateModalStepsState } from "../types";
import {
    IMediaResponseDto,
    ConfirmModal,
    IPlaylistResponseDto,
    IPlaylistRequestDto,
} from "../../../../libs";
import { playlistsService, vodService } from "../../../../services";
import { confirmAlert } from "react-confirm-alert";

interface IProps {
    steps: CreateModalStepsState[];
    playlistName: string;
    shouldClose: React.MutableRefObject<() => void>;
    hideCallback: () => void;
    handleNextStep: (step: CreateModalSteps) => void;
    playlist?: IPlaylistResponseDto;
    editStep?: CreateModalSteps;
}

export const CreateModalBody: React.FC<IProps> = ({
    steps,
    playlistName,
    shouldClose,
    hideCallback,
    handleNextStep,
    playlist,
    editStep,
}) => {
    const [mediaItems, setMediaItems] = React.useState<IMediaResponseDto[]>([]);
    const [selectedVideos, setSelectedVideos] = React.useState<string[]>([]);

    React.useEffect(() => {
        async function fetchVideos() {
            const response = await vodService.getAllMediaItems();
            setMediaItems(response);
        }
        fetchVideos();
    }, []);

    const handleClose = React.useCallback(() => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ConfirmModal
                        title="Are you sure you want to leave?"
                        description={[
                            {
                                text: `By leaving your playlist wont be published.`,
                                bold: false,
                            },
                        ]}
                        noText="Cancel"
                        yesText="Delete Playlist"
                        onNo={() => {
                            onClose();
                        }}
                        onYes={() => {
                            onClose();
                            hideCallback();
                        }}
                    ></ConfirmModal>
                );
            },
        });
    }, [hideCallback]);

    React.useImperativeHandle(shouldClose, () => handleClose);

    const handleSelectVideo = React.useCallback(
        (ids: string[]) => {
            console.log("handleSelectVideo", ids, selectedVideos);
            ids.forEach((id) => {
                if (selectedVideos.includes(id)) {
                    setSelectedVideos((prev) =>
                        prev.filter((videoId) => videoId !== id),
                    );
                } else {
                    setSelectedVideos((prev) => [...prev, id]);
                }
            });
        },
        [selectedVideos, setSelectedVideos],
    );

    React.useEffect(() => {
        if (playlist) {
            setSelectedVideos(
                playlist.mediaItems?.map((item) => item.mediaId) || [],
            );
            console.log(playlist.mediaItems?.map((item) => item.mediaId) || []);
        }
    }, [playlist]);

    const handleNextStepEditMode = React.useCallback(
        async (step: CreateModalSteps, latestSelectedVideos: string[] = []) => {
            console.log("handleNextStepEditMode", step);
            if (editStep !== undefined && playlist) {
                const request: IPlaylistRequestDto = {
                    title: playlist.title,
                    description: playlist.description,
                    thumbnails: playlist.thumbnails.existing
                        ? {
                              existing: {
                                  url: playlist.thumbnails.existing.url,
                              },
                          }
                        : playlist.thumbnails.userUploaded
                          ? {
                                userUploaded: {
                                    fileName:
                                        playlist.thumbnails.userUploaded
                                            .fileName,
                                    s3Key: playlist.thumbnails.userUploaded
                                        .s3Key,
                                },
                            }
                          : {},
                    audience: playlist.audience,
                    visibility: playlist.visibility,
                    playliststatus: playlist.playliststatus,
                    steps: playlist.steps,
                    category: "",
                    mediaItems:
                        latestSelectedVideos.length > 0
                            ? latestSelectedVideos
                            : selectedVideos,
                };

                await playlistsService.updatePlaylist(
                    request,
                    playlist.playlistId,
                );
                hideCallback();
            } else {
                handleNextStep(step);
            }
        },
        [editStep, playlist, selectedVideos, handleNextStep, hideCallback],
    );

    const activeBody = React.useMemo(() => {
        const activeStep = steps.find((step) => step.inprogress);
        switch (activeStep?.step) {
            case CreateModalSteps.VIDEO_SELECTION:
                return (
                    <VideoSelection
                        mediaItems={mediaItems}
                        playlistName={playlist ? playlist.title : playlistName}
                        selectedVideos={selectedVideos}
                        setSelectedVideos={handleSelectVideo}
                        handleNextStep={handleNextStepEditMode}
                        editMode={editStep !== undefined}
                    />
                );
            case CreateModalSteps.ORDER_SELECTION:
                return (
                    <OrderSelection
                        mediaItems={mediaItems}
                        selectedVideos={selectedVideos}
                        setSelectedVideos={setSelectedVideos}
                        handleNextStep={handleNextStepEditMode}
                        editMode={editStep !== undefined}
                    />
                );
            case CreateModalSteps.DETAILS:
                return (
                    <PlaylistDetails
                        playlistName={playlistName}
                        mediaItems={mediaItems}
                        selectedVideos={selectedVideos}
                        playlist={playlist}
                        handleNextStep={handleNextStepEditMode}
                        hideCallback={hideCallback}
                    />
                );
            default:
                return (
                    <VideoSelection
                        mediaItems={mediaItems}
                        playlistName={playlist ? playlist.title : playlistName}
                        selectedVideos={selectedVideos}
                        setSelectedVideos={handleSelectVideo}
                        handleNextStep={handleNextStepEditMode}
                        editMode={editStep !== undefined}
                    />
                );
        }
    }, [
        steps,
        mediaItems,
        playlistName,
        selectedVideos,
        editStep,
        playlist,
        handleSelectVideo,
        hideCallback,
        handleNextStepEditMode,
    ]);

    return <Styled.BodyContainer>{activeBody}</Styled.BodyContainer>;
};
