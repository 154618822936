import React from "react";
import { Image } from "react-bootstrap";

import { FileWithPreview } from "../../../../../../libs";

import * as Styled from "./ThumbnailImage.styled";

export const ThumbnailImagesSection: React.FC<{
  onThumbnailUpload: (thumbnailImage: FileWithPreview) => void;
  onThumbnailDelete: () => void;
}> = ({ onThumbnailUpload, onThumbnailDelete }) => {
  const [thumbnailImage, setThumbnailImage] = React.useState<FileWithPreview>();

  const handleThumbnailImageUpload = React.useCallback(
    (event) => {
      const newThumbnail: FileWithPreview = {
        file: event.target.files[0],
        previewUrl: URL.createObjectURL(event.target.files[0]),
      };
      setThumbnailImage(newThumbnail);
      if (newThumbnail) {
        onThumbnailUpload(newThumbnail);
      }
    },
    [onThumbnailUpload]
  );

  const handleThumbnailImageDelete = React.useCallback(() => {
    onThumbnailDelete();
    setThumbnailImage(undefined);
  }, [onThumbnailDelete]);

  return (
    <Styled.ThumbnailImagesWrapper direction="horizontal" gap={2}>
      {thumbnailImage ? (
        <Styled.ThumbnailImageWrapper>
          <Image src={thumbnailImage.previewUrl} width="100%" />

          <Styled.ThumbnailImageDeletIcon
            variant="white"
            onClick={handleThumbnailImageDelete}
          />
        </Styled.ThumbnailImageWrapper>
      ) : (
        <>
          <Styled.UploadFromDeviceWrapper>
            <Styled.ThumbnailFileInput
              type="file"
              name="file"
              id="file"
              className="inputfile"
              onChange={handleThumbnailImageUpload}
            />
            <label htmlFor="file">Choose a file</label>
          </Styled.UploadFromDeviceWrapper>
          <Styled.ThumbnailText>
            If no image uploaded, we will add random from video.
          </Styled.ThumbnailText>
          {/* <Styled.OrText>(OR)</Styled.OrText> */}
          {/* <Styled.ChooseFromVideoWrapper>
            <BaseButton variant="primary">Choose from Video</BaseButton>
          </Styled.ChooseFromVideoWrapper> */}
        </>
      )}
    </Styled.ThumbnailImagesWrapper>
  );
};
