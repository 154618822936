import * as React from "react";
import * as Yup from "yup";

// import { useWebSocket } from "./../../../behavioral";
import {
    FileWithPreview,
    ICategoryType,
    IGeneralMediaRequestDto,
    IMediaResponseDto,
    IStaticFilePaths,
    MultiPartUploader,
} from "./../../../../libs";

import { useFormik } from "formik";
import { UploadPreview } from "../../upload-modal-body/upload-preview";
import * as Styled from "./GeneralUploadEditModalBody.styled";
import { GeneralUploadForm } from "./../general-form";
import { buildGeneralEditRequestDto } from "../GeneralUploadUtils";
import { vodService } from "../../../../services";

export const GeneralUploadEditModalBody: React.FC<{
    mediaItem: IMediaResponseDto;
    clientId: string;
    onUploadComplete: () => void;
}> = ({ mediaItem, clientId, onUploadComplete }) => {
    // eslint-disable-next-line
    const [thumbnailImage, setThumbnailImage] =
        React.useState<FileWithPreview>();

    const [percentage, setPercentage] = React.useState(0);

    const [staticFilePaths, setStaticFilePaths] = React.useState<
        IStaticFilePaths[]
    >([]);

    const [filesToUpload, setFilesToUpload] = React.useState<
        {
            category: string;
            files: File[];
        }[]
    >([]);

    const [allUploadStarted, setAllUploadStarted] = React.useState(false);

    const [categories, setCategories] = React.useState<ICategoryType[]>([]);

    const populateCategories = React.useCallback(async () => {
        const data = await vodService.getCategories();
        setCategories(data);
    }, []);

    React.useEffect(() => {
        populateCategories();
        // eslint-disable-next-line
    }, []);

    const handleThumbnailImageUpload = React.useCallback(
        (thumbnailImage: FileWithPreview) => {
            setThumbnailImage(thumbnailImage);
            if (thumbnailImage) {
                const newThumbnail = {
                    category: "thumbnails",
                    files: [thumbnailImage.file],
                };
                const existingThumbnailFileIndex = filesToUpload.findIndex(
                    (elem) => elem.category === "thumbnails",
                );
                if (existingThumbnailFileIndex >= 0) {
                    filesToUpload.splice(
                        existingThumbnailFileIndex,
                        1,
                        newThumbnail,
                    );
                    setFilesToUpload([...filesToUpload]);
                } else {
                    setFilesToUpload([...filesToUpload, newThumbnail]);
                }
            }
        },
        [filesToUpload],
    );

    const handleThumbnailDelete = React.useCallback(() => {
        setThumbnailImage(undefined);
        const existingThumbnailFileIndex = filesToUpload.findIndex(
            (elem) => elem.category === "thumbnails",
        );

        if (existingThumbnailFileIndex >= 0) {
            filesToUpload.splice(existingThumbnailFileIndex, 1);
            setFilesToUpload([...filesToUpload]);
        }
    }, [filesToUpload]);

    const formik = useFormik({
        initialValues: {
            title: mediaItem.title,
            description: mediaItem.description || "",
            status: mediaItem.status,
            categories: mediaItem.categoryType || ([] as string[]),
        },

        validationSchema: Yup.object({
            title: Yup.string().required(
                "Please enter a title for the media item",
            ),
        }),

        onSubmit(values) {
            uploadFilesToS3();
        },
    });

    const uploadFilesToS3 = React.useCallback(() => {
        const mediaId = mediaItem.mediaId;

        let filePaths: {
            category: string;
            paths: { fileName: string; s3Key: string }[];
        }[] = [];

        for (let index = 0; index < filesToUpload.length; index++) {
            const { files, category } = filesToUpload[index];
            console.log(`Uploading ${category} files now`);

            for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
                const file = files[fileIndex];
                const uploader = new MultiPartUploader({
                    fileName: file.name,
                    file: file,
                    clientId: clientId,
                    mediaId,
                    category: category,
                });

                uploader
                    .getOnProgress(function (params: any) {
                        if (category === "video") {
                            setPercentage(params.percentage);
                        }
                    })
                    .getOnComplete(function (params: any) {
                        console.log(`Uploaded ${category} files`);
                        const groupIndex = filePaths.findIndex(
                            (elem) => elem.category === category,
                        );
                        const elem = filePaths[groupIndex];
                        if (elem) {
                            elem.paths.push({
                                s3Key: params.s3Key,
                                fileName: params.fileName,
                            });
                            filePaths[groupIndex] = elem;
                        } else {
                            filePaths.push({
                                category,
                                paths: [
                                    {
                                        s3Key: params.s3Key,
                                        fileName: params.fileName,
                                    },
                                ],
                            });
                        }
                        setStaticFilePaths([...filePaths]);
                    })
                    .getOnError((error: any) => {
                        console.log(error);
                    });

                uploader.start();
            }
        }
        setAllUploadStarted(true);
    }, [clientId, filesToUpload, mediaItem.mediaId]);

    const handleEdit = React.useCallback(
        async (requestDto: Partial<IGeneralMediaRequestDto>) => {
            try {
                await vodService.editGeneralMediaItem(requestDto);
                onUploadComplete();
            } catch (error: any) {
                console.log("Error while editing general media item", error);
            }
        },
        [onUploadComplete],
    );

    React.useEffect(() => {
        if (!allUploadStarted) return;
        const uploadedCount = staticFilePaths.reduce((count, obj) => {
            count += obj.paths.length;
            return count;
        }, 0);

        const actualCountToUpload = filesToUpload.reduce((count, obj) => {
            count += obj.files.length;
            return count;
        }, 0);

        if (uploadedCount === actualCountToUpload) {
            const requestDto = buildGeneralEditRequestDto(
                formik.values,
                staticFilePaths,
                mediaItem.mediaId,
            );
            if (requestDto) {
                handleEdit(requestDto);
            }
        }
    }, [
        staticFilePaths,
        filesToUpload,
        clientId,
        formik.values,
        handleEdit,
        mediaItem.mediaId,
        allUploadStarted,
    ]);

    return (
        <Styled.UploadBodyWrapper>
            <Styled.UploadBodyWrapperRow>
                <Styled.UploadFormWrapper lg={4}>
                    <Styled.DetailsHeader>Details</Styled.DetailsHeader>
                    <GeneralUploadForm
                        formik={formik}
                        handleThumbnailImageUpload={handleThumbnailImageUpload}
                        handleThumbnailDelete={handleThumbnailDelete}
                        percentage={percentage}
                        categories={categories}
                    />
                </Styled.UploadFormWrapper>
                <Styled.UploadPreviewWrapper lg={8}>
                    <UploadPreview uploadedFile={mediaItem.playbackUrl} />
                </Styled.UploadPreviewWrapper>
            </Styled.UploadBodyWrapperRow>
        </Styled.UploadBodyWrapper>
    );
};
