import { useFormik } from "formik";
import React from "react";
import { Spinner } from "react-bootstrap";
import * as Yup from "yup";

import { useAuth } from "../../../behavioral";
import { BaseButton, FloatingTextInput as TextInput } from "../../../libs";

import { useNavigate } from "react-router-dom";
import * as Styled from "./ChangePassword.styled";

export const ChangePassword: React.FC<{
  email: string;
  currentUser: any;
  temporaryPassword?: string;
}> = ({ currentUser, email, temporaryPassword }) => {
  const navigate = useNavigate();
  const { changePassword } = useAuth();
  const formik = useFormik({
    initialValues: {
      email: email,
      temporaryPassword: temporaryPassword,
      newPassword: "",
    },
    validationSchema: Yup.object({
      temporaryPassword: Yup.string()
        .min(8, "Please provide a valid temporary password")
        .required("Please enter the temporary password"),
      newPassword: Yup.string()
        .min(8, "Password should contain minmum of 8 characters.")
        .required("Please enter a password"),
      email: Yup.string()
        .email("Please provide a valid email address")
        .required("Please enter an email address"),
    }),
    onSubmit: (values) => {
      handleChangePasswordSubmit(values);
    },
  });

  const handleChangePasswordSubmit = React.useCallback(
    async (values: any) => {
      try {
        await changePassword(
          currentUser,
          values.temporaryPassword,
          values.newPassword
        );

        navigate("/dashboard", { replace: true });
      } catch (err) {
        console.log(err);
      }
    },
    [changePassword, currentUser, navigate]
  );
  return (
    <>
      <Styled.ChangePasswordPageHeader>
        Change Password
      </Styled.ChangePasswordPageHeader>

      <Styled.ConfirmCodeText>
        Please enter the temporary password received in the email.
      </Styled.ConfirmCodeText>

      <Styled.ChangePasswordForm onSubmit={formik.handleSubmit}>
        <Styled.ChangePasswordFormRow>
          <Styled.ChangePasswordFormCol>
            <TextInput
              label="Email Address"
              type="email"
              placeholder="Email Address"
              controlId="email"
              value={formik.values.email}
              onChangeHandler={formik.handleChange}
              onBlurHandler={formik.handleBlur}
              readOnly={true}
            />
          </Styled.ChangePasswordFormCol>
        </Styled.ChangePasswordFormRow>
        <Styled.ChangePasswordFormRow>
          <Styled.ChangePasswordFormCol>
            <TextInput
              label="Temporary Password"
              type="text"
              placeholder="Temporary Password"
              controlId="temporaryPassword"
              value={formik.values.temporaryPassword}
              onChangeHandler={formik.handleChange}
              onBlurHandler={formik.handleBlur}
              hasError={
                formik.touched.temporaryPassword &&
                !!formik.errors.temporaryPassword
              }
            />
          </Styled.ChangePasswordFormCol>
        </Styled.ChangePasswordFormRow>
        <Styled.ChangePasswordFormRow>
          <Styled.ChangePasswordFormCol>
            <TextInput
              label="New Password"
              type="password"
              placeholder="New Password"
              controlId="newPassword"
              value={formik.values.newPassword}
              onChangeHandler={formik.handleChange}
              onBlurHandler={formik.handleBlur}
              hasError={
                formik.touched.newPassword && !!formik.errors.newPassword
              }
            />
          </Styled.ChangePasswordFormCol>
        </Styled.ChangePasswordFormRow>

        <Styled.ChangePasswordFormRow>
          <Styled.ChangePasswordFormCol md={4}>
            <BaseButton type="submit" variant="primary" disabled={false}>
              {formik.isSubmitting && <Spinner size="sm" />}
              Change Password
            </BaseButton>
          </Styled.ChangePasswordFormCol>
        </Styled.ChangePasswordFormRow>
      </Styled.ChangePasswordForm>
    </>
  );
};
