import React from "react";
import { Image } from "react-bootstrap";

import * as Styled from "./MenuItem.styled";

import MenuIcon from "../../../../assets/images/icons/menu_icon.png";
import ActiveMenuIcon from "../../../../assets/images/icons/menu_icon_active.png";

interface IMenuItemProps {
  menuItemText: string;
  isActive?: boolean;
}

export const MenuItem: React.FC<IMenuItemProps> = ({
  menuItemText,
  isActive = false,
}) => {
  return (
    <Styled.MenuItemContainer className={isActive ? "active" : ""}>
      <Styled.MenuItemRow className="align-items-center">
        {
          <Styled.MenuItemIconWrapper md={3}>
            <Image src={isActive ? ActiveMenuIcon : MenuIcon} />
          </Styled.MenuItemIconWrapper>
        }
        <Styled.MenuItemTextWrapper md={9}>
          <Styled.MenuItemText className={isActive ? "active" : ""}>
            {menuItemText}
          </Styled.MenuItemText>
        </Styled.MenuItemTextWrapper>
      </Styled.MenuItemRow>
    </Styled.MenuItemContainer>
  );
};
