import axios from "axios";
import { API_BASE_PATHS, API_ROUTES } from "../libs";
import { buildRequestConfig } from "./buildRequestConfig";
import { IAnalyticsEvent, IAnalyticsRecord } from "../libs/models";

class AnalyticsService {
    async getSummarizedAnalytics(startDate?: Date, endDate?: Date): Promise<IAnalyticsRecord[]> {
        try {
            const config = await buildRequestConfig();
            const response = await axios.get(
                `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.ANALYTICS_API}/${API_ROUTES.METRICES_SUMMARY}${startDate && endDate ? `?startDate=${startDate.toISOString().slice(0, 10)}&endDate=${endDate.toISOString().slice(0, 10)}` : ""}`,
                { ...config }
            );

            return response.data.data;
        } catch (err: any) {
            console.log(
                "There is an error while fetching summarized analytics",
                err
            );
            throw new Error(err);
        }
    }

    async getAnalytics(): Promise<IAnalyticsEvent[]> {
        try {
            const config = await buildRequestConfig();
            const response = await axios.get(
                `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.ANALYTICS_API}/${API_ROUTES.METRICES}`,
                { ...config }
            );

            return response.data.data;
        } catch (err: any) {
            console.log("There is an error while fetching analytics", err);
            throw new Error(err);
        }
    }
}

export const analyticsService = new AnalyticsService();