import React from "react";
import { AnalyticsCardLine } from "../analytics-card-line";
import { Container, Row, Col } from "react-bootstrap";
import * as Styled from "./AnalyticsCards.styled";
import { analyticsService } from "../../../services";
import { IAnalyticsRecord } from "../../../libs/models";

interface ProcessedData {
    date: string;
    totalViews: number;
    totalSecondsWatched: number;
    uniqueViews: number;
    avgWatchDuration: number;
    completedCount: number;
    engagementRate: number;
    rewindSkips: number;
}

enum Duration {
    "7D",
    "30D",
    "3M",
    "6M",
    "1Y",
}

export const AnalyticsCards = () => {
    // Keeping this as raw processed data and can be used down the line
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [processedData, setProcessedData] = React.useState<ProcessedData[]>([
        {
            date: "2021-09-01",
            totalViews: 0,
            totalSecondsWatched: 0,
            uniqueViews: 0,
            avgWatchDuration: 0,
            completedCount: 0,
            engagementRate: 0,
            rewindSkips: 0,
        },
    ]);

    const [individualData, setIndividualData] = React.useState<ProcessedData[]>(
        [
            {
                date: "2021-09-01",
                totalViews: 0,
                totalSecondsWatched: 0,
                uniqueViews: 0,
                avgWatchDuration: 0,
                completedCount: 0,
                engagementRate: 0,
                rewindSkips: 0,
            },
        ],
    );

    const [selectedDuration, setSelectedDuration] = React.useState<Duration>(
        Duration["30D"],
    );

    const secondsToTime = React.useCallback((seconds: number) => {
        if (seconds < 60) {
            return seconds.toFixed(2);
        }
        const minutes = seconds / 60;
        if (minutes < 60) {
            return minutes.toFixed(2);
        }
        const hours = minutes / 60;
        return hours.toFixed(2);
    }, []);

    const secondsToUnit = React.useCallback((seconds: number | undefined) => {
        if (seconds === undefined) return "";
        if (seconds < 60) {
            return "Sec";
        }
        const minutes = seconds / 60;
        if (minutes < 60) {
            return "Min";
        }
        return "Hrs";
    }, []);

    const CardsInfo = React.useMemo(() => {
        const trimmedData = individualData.slice(0, individualData.length - 1);

        const subtitles = {
            totalViews: "0",
            totalSecondsWatched: "0",
            totalSecondsWatchedUnit: "Sec",
            avgWatchDuration: "0",
            avgWatchDurationUnit: "Sec",
            completedCount: "0",
            uniqueViews: "0",
            engagementRate: "0",
            rewindSkips: "0",
        };

        if (individualData.length > 0) {
            subtitles.totalViews = trimmedData
                .reduce((acc, record) => acc + record.totalViews, 0)
                .toString();

            let totalSecondsWatchedCalc = trimmedData.reduce(
                (acc, record) => acc + record.totalSecondsWatched,
                0,
            );

            subtitles.totalSecondsWatched = secondsToTime(
                totalSecondsWatchedCalc,
            );
            subtitles.totalSecondsWatchedUnit = secondsToUnit(
                totalSecondsWatchedCalc,
            );

            let avgWatchDurationCalc = trimmedData.reduce(
                (acc, record) => acc + record.avgWatchDuration,
                0,
            );

            subtitles.avgWatchDuration = secondsToTime(avgWatchDurationCalc);
            subtitles.avgWatchDurationUnit =
                secondsToUnit(avgWatchDurationCalc);

            subtitles.completedCount = trimmedData
                .reduce((acc, record) => acc + record.completedCount, 0)
                .toString();

            subtitles.uniqueViews = trimmedData
                .reduce((acc, record) => acc + record.uniqueViews, 0)
                .toString();

            subtitles.engagementRate = (
                trimmedData.reduce(
                    (acc, record) => acc + record.engagementRate,
                    0,
                ) / trimmedData.length
            ).toString();

            subtitles.rewindSkips = trimmedData
                .reduce((acc, record) => acc + record.rewindSkips, 0)
                .toString();
        }

        return [
            [
                {
                    title: "Total Views",
                    subtitle: subtitles.totalViews,
                    unit: "Nos",
                    data: trimmedData,
                    dataKey: "totalViews",
                    oldData: individualData[individualData.length - 1],
                },
                {
                    title: "Total Seconds Watched",
                    subtitle: subtitles.totalSecondsWatched,
                    unit: subtitles.totalSecondsWatchedUnit,
                    data: trimmedData,
                    dataKey: "totalSecondsWatched",
                    oldData: individualData[individualData.length - 1],
                },
                {
                    title: "Avg Watch Duration",
                    subtitle: subtitles.avgWatchDuration,
                    unit: subtitles.avgWatchDurationUnit,
                    data: trimmedData,
                    dataKey: "avgWatchDuration",
                    oldData: individualData[individualData.length - 1],
                },
                {
                    title: "Completed Count",
                    subtitle: subtitles.completedCount,
                    unit: "Nos",
                    data: trimmedData,
                    dataKey: "completedCount",
                    oldData: individualData[individualData.length - 1],
                },
            ],
            [
                {
                    title: "Unique Views",
                    subtitle: subtitles.uniqueViews,
                    unit: "Nos",
                    data: trimmedData,
                    dataKey: "uniqueViews",
                    oldData: individualData[individualData.length - 1],
                },
                {
                    title: "Engagement Rate",
                    subtitle: subtitles.engagementRate,
                    unit: "%",
                    data: trimmedData,
                    dataKey: "engagementRate",
                    oldData: individualData[individualData.length - 1],
                },
                {
                    title: "Rewind Skips",
                    subtitle: subtitles.rewindSkips,
                    unit: "Nos",
                    data: trimmedData,
                    dataKey: "rewindSkips",
                    oldData: individualData[individualData.length - 1],
                },
            ],
        ];
    }, [individualData, secondsToTime, secondsToUnit]);

    const [summarizedAnalytics, setSummarizedAnalytics] = React.useState<
        IAnalyticsRecord[]
    >([]);

    const days = React.useMemo(() => {
        return {
            [Duration["7D"]]: 7,
            [Duration["30D"]]: 30,
            [Duration["3M"]]: 90,
            [Duration["6M"]]: 180,
            [Duration["1Y"]]: 365,
        };
    }, []);

    const groupByDate = React.useCallback(
        (records: IAnalyticsRecord[]): [string, IAnalyticsRecord[]][] => {
            const groupsMap = records.reduce((acc, record) => {
                const dateKey = record.timestamp.split("T")[0];
                const group = acc.get(dateKey) || [];
                group.push(record);
                acc.set(dateKey, group);
                return acc;
            }, new Map<string, IAnalyticsRecord[]>());

            const sortedDates = Array.from(groupsMap.keys()).sort(
                (a, b) => new Date(b).getTime() - new Date(a).getTime(),
            );

            return sortedDates.map((date) => [date, groupsMap.get(date)!]);
        },
        [],
    );
    const groupByMediaId = React.useCallback(
        (records: IAnalyticsRecord[]): IAnalyticsRecord[][] => {
            const groups = new Map<string, IAnalyticsRecord[]>();

            records.forEach((record) => {
                if (!groups.has(record.mediaId)) {
                    groups.set(record.mediaId, []);
                }
                groups.get(record.mediaId)?.push(record);
            });

            groups.forEach((group) => {
                group.sort(
                    (a, b) =>
                        new Date(b.timestamp).getTime() -
                        new Date(a.timestamp).getTime(),
                );
            });

            return Array.from(groups.values());
        },
        [],
    );

    const populateAnalyticsData = React.useCallback(() => {
        var finalSorted = groupByDate(summarizedAnalytics);

        const processedDataT = finalSorted.map(([date, records], index) => {
            const mediaGroups = groupByMediaId(records);
            // TODO: Need to verify the avg watch duration calculation
            return {
                date,
                totalViews: mediaGroups.reduce(
                    (acc, record) => acc + record[0].views,
                    0,
                ),
                totalSecondsWatched: mediaGroups.reduce(
                    (acc, record) => acc + record[0].watchTime,
                    0,
                ),
                uniqueViews: mediaGroups.reduce(
                    (acc, record) => acc + record[0].uniqueView,
                    0,
                ),
                avgWatchDuration:
                    mediaGroups.reduce(
                        (acc, record) => acc + record[0].avgWatchDuration,
                        0,
                    ) / mediaGroups.length,
                completedCount: mediaGroups.reduce(
                    (acc, record) => acc + record[0].completedCount,
                    0,
                ),
                engagementRate: mediaGroups.reduce(
                    (acc, record) => acc + record[0].engagementRate,
                    0,
                ),
                rewindSkips: mediaGroups.reduce(
                    (acc, record) => acc + record[0].rewindSkips,
                    0,
                ),
            };
        });

        const individualData = processedDataT.map((record, index) => {
            if (index === processedDataT.length - 1) {
                return {
                    date: record.date,
                    totalViews: record.totalViews,
                    totalSecondsWatched: record.totalSecondsWatched,
                    uniqueViews: record.uniqueViews,
                    avgWatchDuration: record.avgWatchDuration,
                    completedCount: record.completedCount,
                    engagementRate: record.engagementRate,
                    rewindSkips: record.rewindSkips,
                };
            }

            return {
                date: processedDataT[index].date,
                totalViews:
                    processedDataT[index].totalViews -
                    processedDataT[index + 1].totalViews,
                totalSecondsWatched:
                    processedDataT[index].totalSecondsWatched -
                    processedDataT[index + 1].totalSecondsWatched,
                uniqueViews:
                    processedDataT[index].uniqueViews -
                    processedDataT[index + 1].uniqueViews,
                avgWatchDuration:
                    processedDataT[index].avgWatchDuration -
                    processedDataT[index + 1].avgWatchDuration,
                completedCount:
                    processedDataT[index].completedCount -
                    processedDataT[index + 1].completedCount,
                engagementRate:
                    processedDataT[index].engagementRate -
                    processedDataT[index + 1].engagementRate,
                rewindSkips:
                    processedDataT[index].rewindSkips -
                    processedDataT[index + 1].rewindSkips,
            };
        });

        try {
            const lastDate = new Date(
                individualData[individualData.length - 1].date,
            ).getTime();

            let date = new Date();
            date.setDate(date.getDate() - (days[selectedDuration] + 1));
            let formattedDate = date.toISOString().slice(0, 10);
            let expectedLastDate = new Date(formattedDate).getTime();

            if (lastDate !== expectedLastDate) {
                individualData.push({
                    date: "",
                    totalViews: 0,
                    totalSecondsWatched: 0,
                    uniqueViews: 0,
                    avgWatchDuration: 0,
                    completedCount: 0,
                    engagementRate: 0,
                    rewindSkips: 0,
                });
            }
        } catch (e) {}

        setIndividualData(individualData);

        setProcessedData(processedDataT);
    }, [
        summarizedAnalytics,
        groupByDate,
        groupByMediaId,
        setProcessedData,
        days,
        selectedDuration,
    ]);

    const getAnalytics = React.useCallback(
        async (duration: Duration = Duration["30D"]) => {
            const response = await analyticsService.getSummarizedAnalytics(
                new Date(
                    new Date().setDate(
                        new Date().getDate() - (days[duration] + 1),
                    ),
                ),
                new Date(new Date().setDate(new Date().getDate() + 1)),
            );
            setSummarizedAnalytics(response);
            setSelectedDuration(duration);
        },
        [setSummarizedAnalytics, setSelectedDuration, days],
    );

    React.useEffect(() => {
        populateAnalyticsData();
    }, [summarizedAnalytics, populateAnalyticsData]);

    React.useEffect(() => {
        getAnalytics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDurationChange = React.useCallback(
        (duration: Duration) => {
            setSummarizedAnalytics([]);
            getAnalytics(duration);
        },
        [getAnalytics, setSummarizedAnalytics],
    );

    return (
        <>
            {
                <Container fluid>
                    <Styled.AnalyticsCardsTitleWrapper>
                        <Styled.AnalyticsCardsTitle>
                            Analytics
                        </Styled.AnalyticsCardsTitle>
                        <Styled.AnalyticsCardsDurationWrapper>
                            <Styled.AnalyticsCardsDuration
                                selected={selectedDuration === Duration["7D"]}
                                onClick={() =>
                                    handleDurationChange(Duration["7D"])
                                }
                            >
                                7D
                            </Styled.AnalyticsCardsDuration>
                            <Styled.AnalyticsCardsDuration
                                selected={selectedDuration === Duration["30D"]}
                                onClick={() =>
                                    handleDurationChange(Duration["30D"])
                                }
                            >
                                30D
                            </Styled.AnalyticsCardsDuration>
                            <Styled.AnalyticsCardsDuration
                                selected={selectedDuration === Duration["3M"]}
                                onClick={() =>
                                    handleDurationChange(Duration["3M"])
                                }
                            >
                                3M
                            </Styled.AnalyticsCardsDuration>
                            <Styled.AnalyticsCardsDuration
                                selected={selectedDuration === Duration["6M"]}
                                onClick={() =>
                                    handleDurationChange(Duration["6M"])
                                }
                            >
                                6M
                            </Styled.AnalyticsCardsDuration>
                            <Styled.AnalyticsCardsDuration
                                selected={selectedDuration === Duration["1Y"]}
                                onClick={() =>
                                    handleDurationChange(Duration["1Y"])
                                }
                            >
                                1Y
                            </Styled.AnalyticsCardsDuration>
                        </Styled.AnalyticsCardsDurationWrapper>
                    </Styled.AnalyticsCardsTitleWrapper>
                    {CardsInfo.map((row, index) => {
                        return (
                            <Row key={index}>
                                {row.map((card, index) => {
                                    return (
                                        <Col key={index} lg={3} md={6} sm={12}>
                                            <AnalyticsCardLine
                                                title={card.title}
                                                subtitle={card.subtitle}
                                                unit={card.unit}
                                                data={card.data}
                                                dataKey={card.dataKey}
                                                oldData={card.oldData}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        );
                    })}
                </Container>
            }
        </>
    );
};
