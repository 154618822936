import React from "react";

import * as Styled from "./AnalyticsCardLine.styled";
import { LineChart, Line } from "recharts";

const AnalyticsCardLine: React.FC<{
    title: string;
    subtitle: string;
    unit: string;
    data: any;
    dataKey: string;
    oldData: any;
}> = ({ title, subtitle, unit, data, dataKey, oldData }) => {
    const percentageIncrease = React.useMemo(() => {
        try {
            const last = Math.max(...data.map((d: any) => d[dataKey]));

            const first = oldData[dataKey];
            if (first === 0) return "+∞ %";
            const percentage = (((last - first) / first) * 100);
            let preFix = "";
            if (percentage >= 0) preFix = "+";

            return preFix + percentage.toFixed(2) + "%";
        } catch (e) {
            if (data.length === 0) return "+∞ %";
            else console.log(e);
        }
        return "";
    }, [data, dataKey, oldData]);

    return (
        <Styled.ACLCard>
            <Styled.ACLCard.Body>
                <Styled.ALCCardContainerStats>
                    <Styled.ACLCard.Title>{title}</Styled.ACLCard.Title>
                    <Styled.ALCCardContainerStatsEnd>
                        <Styled.ALCCardMetricText>
                            {subtitle}
                        </Styled.ALCCardMetricText>
                        <Styled.ALCCardMetricTextSmall>
                            {" "}
                            {unit}{" "}
                        </Styled.ALCCardMetricTextSmall>
                    </Styled.ALCCardContainerStatsEnd>
                </Styled.ALCCardContainerStats>
                <Styled.ALCCardContainerGraph>
                    <LineChart width={60} height={30} data={data}>
                        <defs>
                            <linearGradient
                                id="paint1_linear_gradient"
                                x1="50"
                                y1="0"
                                x2="0"
                                y2="0"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop offset="0%" stopColor="#A4FF30" />
                                <stop offset="28%" stopColor="#30E6FF" />
                                <stop offset="75%" stopColor="#539EAE" />
                                <stop offset="100%" stopColor="#70366B" />
                            </linearGradient>
                        </defs>
                        <filter id="lineGlow">
                            <feGaussianBlur
                                className="blur"
                                result="coloredBlur"
                                stdDeviation="5"
                            />
                            <feMerge>
                                <feMergeNode in="coloredBlur" />
                                <feMergeNode in="coloredBlur" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                        <Line
                            dataKey={dataKey}
                            stroke="url(#paint1_linear_gradient)"
                            dot={false}
                            strokeWidth={2.5}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            filter="url(#lineGlow)"
                        />
                    </LineChart>
                    <Styled.ALCCardContainerGraphText>
                        {percentageIncrease}
                    </Styled.ALCCardContainerGraphText>
                </Styled.ALCCardContainerGraph>
            </Styled.ACLCard.Body>
        </Styled.ACLCard>
    );
};

export { AnalyticsCardLine };
