import React from "react";
import { Col, Form } from "react-bootstrap";

import {
    BaseButton,
    FileWithPreview,
    IFilmFestivalSponsorsDetails,
} from "../../../../../libs";
import { DragAndDropZone } from "../../../../drag-drop-zone";

import { SponsorsList } from "./sponsors-list";

import * as Styled from "./SponsorDetail.styled";

export const SponsorDetails: React.FC<{
    onSponsorsDetailsSubmit: (sponsors: IFilmFestivalSponsorsDetails[]) => void;
    sponsorDetails?: IFilmFestivalSponsorsDetails[];
}> = ({ onSponsorsDetailsSubmit, sponsorDetails }) => {
    const [sponsors, setSponsors] = React.useState<FileWithPreview[]>(
        sponsorDetails && sponsorDetails.length > 0
            ? sponsorDetails.map((sponsor) => sponsor.file)
            : [],
    );
    const [showDragAndDropZone, setShowDragAndDropZone] = React.useState(true);

    const handleOnDrop = React.useCallback(
        (acceptedFiles: FileWithPreview[]) => {
            setSponsors([...sponsors, ...acceptedFiles]);
        },
        [sponsors],
    );

    const handleSponsorDelete = React.useCallback(
        (index) => {
            const updatedSponsors = [...sponsors];
            updatedSponsors.splice(index, 1);
            setSponsors(updatedSponsors);
        },
        [sponsors],
    );

    React.useEffect(() => {
        if (sponsors.length === 6) {
            setShowDragAndDropZone(false);
        } else {
            setShowDragAndDropZone(true);
        }
    }, [sponsors]);

    const handleSponsorDetailsSubmit = React.useCallback(
        (event) => {
            event.preventDefault();
            const sponsorFiles = sponsors.map((file, index) => {
                return {
                    file,
                    id: index + 1,
                };
            });
            onSponsorsDetailsSubmit(sponsorFiles);
        },
        [onSponsorsDetailsSubmit, sponsors],
    );

    return (
        <>
            <Styled.SponsorsDetailsHeader>
                Sponsors
            </Styled.SponsorsDetailsHeader>
            <Styled.HelpText>
                Upload all the images of the logos corresponding to the sponsors
                you want to appear on top of the video.
            </Styled.HelpText>
            <Form onSubmit={handleSponsorDetailsSubmit}>
                {sponsors.length > 0 && (
                    <Styled.FormFieldRow>
                        <Col>
                            <SponsorsList
                                sponsorsList={sponsors}
                                onDelete={handleSponsorDelete}
                            />
                        </Col>
                    </Styled.FormFieldRow>
                )}
                {showDragAndDropZone && (
                    <Styled.FormFieldRow>
                        <Col>
                            <DragAndDropZone
                                showSmallImage
                                direction="horizontal"
                                textalign="left"
                                dropMessageText="Drag and drop images here or choose files."
                                acceptedFileType="image/jpeg, image/png"
                                onDropHandler={handleOnDrop}
                            />
                        </Col>
                    </Styled.FormFieldRow>
                )}

                <Styled.FormFieldRow>
                    <Col>
                        <BaseButton variant="secondary">Go Back</BaseButton>
                    </Col>
                    <Col>
                        <BaseButton type="submit" variant="primary">
                            Continue
                        </BaseButton>
                    </Col>
                </Styled.FormFieldRow>
            </Form>
        </>
    );
};
