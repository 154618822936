import { Container } from "react-bootstrap";
import styled from "styled-components";
import { colors, types } from "../../libs";

export const ConfirmationWrapper = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${colors.background.black};
`;

export const ConfirmationInputWrapper = styled(ConfirmationWrapper)`
  background-color: ${colors.background.lightBlack};
`;

export const ConfirmationBody = styled.p`
  color: ${colors.text.white};
  ${types.body.label};
  width: 50%;
  text-align: center;

  & span {
    ${types.body.secondaryTitle};
    background: ${colors.text.textGradient};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const ConfirmationHeader = styled.div`
  padding: 20px;
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 100;
`;

export const Title = styled.h3`
  ${types.body.title};
  margin-bottom: 10px;
  background: ${colors.text.textGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
`;
