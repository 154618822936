import { useFormik } from "formik";
import * as React from "react";
import { Alert, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import * as Yup from "yup";

import {
  BaseButton,
  Error,
  FloatingTextInput,
  IMemberPlanRequest,
  IPlanType,
  RadioInput,
} from "../../../libs";

import "react-datepicker/dist/react-datepicker.css";

import * as Styled from "./MemberPlanForm.styled";

interface IProps {
  initialValues: IMemberPlanRequest;
  onCancel: () => void;
  onSubmit: (
    data: IMemberPlanRequest,
    setSubmitting: (result: boolean) => void
  ) => void;
  errorMessage?: string;
  showRecurring?: boolean;
}

export const MemberPlanForm: React.FC<IProps> = ({
  initialValues,
  onSubmit,
  onCancel,
  errorMessage,
  showRecurring,
}) => {
  const [expirationType, setExpirationType] = React.useState(
    initialValues.expiration === "NONE" || !initialValues.expiration
      ? "NONE"
      : isNaN(Date.parse(initialValues.expiration))
      ? "TIME_PERIOD"
      : "DATE_AND_TIME"
  );

  const [planType, setPlanType] = React.useState<IPlanType>(
    initialValues.planType ?? IPlanType.VOD
  );

  const frequencyOptions = [
    { label: "Daily", value: "day" },
    { label: "Weekly", value: "week" },
    { label: "Monthly", value: "month" },
    { label: "Annual", value: "year" },
  ];

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      expiration:
        initialValues.expiration === "NONE" || !initialValues.expiration
          ? "NONE"
          : isNaN(Date.parse(initialValues.expiration))
          ? initialValues.expiration.substring(
              0,
              initialValues.expiration.length - 1
            )
          : initialValues.expiration,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter a name for plan"),
      description: Yup.string().required(
        "Please provide a description for the plan."
      ),
      amount: Yup.number()
        .min(1, "Please enter a price greater than 0")
        .required("Please enter a price for the plan"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      if (values.expiration !== "NONE" && values.expiration === "") {
        formik.setFieldError(
          "expiration",
          "Please provide a value for expiration."
        );
        return;
      }

      if (values.isRecurring && !values.frequency) {
        formik.setFieldError(
          "frequency",
          "Please select a value for interval."
        );
        return;
      }

      // setSubmitting(true);
      const newPlan: IMemberPlanRequest = {
        name: values.name,
        description: values.description,
        amount: +values.amount,
        isRecurring: values.isRecurring ?? false,
        isPublic: values.isPublic,
        isEnabled: values.isEnabled,
        planType: planType,
        expiration:
          expirationType === "TIME_PERIOD"
            ? `${values.expiration}d`
            : values.expiration,
        frequency: values.frequency ?? "",
      };

      onSubmit(newPlan, setSubmitting);
    },
  });

  return (
    <Styled.ProductPlanForm onSubmit={formik.handleSubmit}>
      {errorMessage && <Alert title={errorMessage} />}
      <Styled.ProductPlanFormFieldRow>
        <Styled.ProductPlanFormFieldCol>
          <FloatingTextInput
            label="Plan Name"
            type="text"
            placeholder="Plan Name"
            controlId="name"
            value={formik.values.name}
            onChangeHandler={formik.handleChange}
            onBlurHandler={formik.handleBlur}
            hasError={formik.touched.name && !!formik.errors.name}
          />
          {formik.touched.name && formik.errors.name && (
            <Error message={formik.errors.name} />
          )}
        </Styled.ProductPlanFormFieldCol>
      </Styled.ProductPlanFormFieldRow>

      <Styled.ProductPlanFormFieldRow>
        <Styled.ProductPlanFormFieldCol>
          <FloatingTextInput
            label="Description"
            type="text"
            placeholder="Plan Description"
            controlId="description"
            value={formik.values.description}
            onChangeHandler={formik.handleChange}
            onBlurHandler={formik.handleBlur}
            hasError={formik.touched.description && !!formik.errors.description}
          />
          {formik.touched.description && formik.errors.description && (
            <Error message={formik.errors.description} />
          )}
        </Styled.ProductPlanFormFieldCol>
      </Styled.ProductPlanFormFieldRow>

      {showRecurring && (
        <Styled.ProductPlanFormFieldRow>
          <Styled.ProductPlanFormFieldCol>
            <Form.Label>Recurring</Form.Label>

            <Styled.ProductPlanFormSwitch
              type="switch"
              id="custom-switch"
              checked={formik.values.isRecurring}
              onChange={() => {
                formik.setFieldValue("isRecurring", !formik.values.isRecurring);
              }}
            />
          </Styled.ProductPlanFormFieldCol>
        </Styled.ProductPlanFormFieldRow>
      )}
      {formik.values.isRecurring && (
        <Styled.ProductPlanFormFieldRow>
          <Form.Label>Interval</Form.Label>
          <Styled.ProductPlanFormFieldCol>
            <Select
              isDisabled={!showRecurring}
              className="custom-select"
              styles={{
                menu: (baseStyles, state) => ({
                  ...baseStyles,
                  background: "#1E2025",
                  color: "#fff",
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  background: state.isFocused ? "#ccc" : "#1E2025",
                  color: state.isFocused ? "#1E2025" : "#fff",
                }),
                singleValue: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "#fff",
                }),
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: "50px",
                  background: "#1E2025",
                  color: "#fff",
                  border: formik.errors.frequency
                    ? "1px solid red"
                    : state.isFocused
                    ? "1px solid transparent"
                    : "1px solid transparent",
                }),
              }}
              placeholder="Select Payment Plan"
              isClearable
              options={frequencyOptions}
              defaultValue={frequencyOptions.find(
                (item) => item.value === formik.values.frequency
              )}
              onChange={(option: any) => {
                formik.setFieldValue("frequency", option?.value);
              }}
            />
            {formik.errors.frequency && (
              <Error message={formik.errors.frequency} />
            )}
            <Form.Text>
              This field can not be updated once the plan is created.
            </Form.Text>
          </Styled.ProductPlanFormFieldCol>
        </Styled.ProductPlanFormFieldRow>
      )}

      <Styled.ProductPlanFormFieldRow>
        <Styled.ProductPlanFormFieldCol>
          <FloatingTextInput
            label="Price in USD($)"
            readOnly={!showRecurring}
            type="text"
            placeholder="Plan Price"
            controlId="amount"
            value={formik.values.amount}
            onChangeHandler={formik.handleChange}
            onBlurHandler={formik.handleBlur}
            hasError={formik.touched.amount && !!formik.errors.amount}
          />
          {formik.touched.amount && formik.errors.amount && (
            <Error message={formik.errors.amount} />
          )}
          {(formik.values.isRecurring || !showRecurring) && (
            <Form.Text>
              This field can not be updated once the plan is created.
            </Form.Text>
          )}
        </Styled.ProductPlanFormFieldCol>
      </Styled.ProductPlanFormFieldRow>

      <Styled.ProductPlanFormFieldRow>
        <Styled.ProductPlanFormFieldCol>
          <Form.Label>Enable Plan </Form.Label>
          <br />
          <Styled.ProductPlanFormSwitch
            type="switch"
            id="custom-switch"
            checked={formik.values.isEnabled}
            onChange={(event: any) => {
              formik.setFieldValue("isEnabled", !formik.values.isEnabled);
              formik.setFieldValue(
                "isPublic",
                event.target.checked ? formik.values.isPublic : false
              );
            }}
          />
        </Styled.ProductPlanFormFieldCol>
        <Styled.ProductPlanFormFieldCol>
          <Form.Label>Make Public</Form.Label>
          <br />
          <Styled.ProductPlanFormSwitch
            type="switch"
            id="custom-switch"
            checked={!formik.values.isEnabled ? false : formik.values.isPublic}
            onChange={() => {
              formik.setFieldValue("isPublic", !formik.values.isPublic);
            }}
          />
        </Styled.ProductPlanFormFieldCol>
      </Styled.ProductPlanFormFieldRow>

      <Styled.ProductPlanFormFieldRow>
        <Styled.ProductPlanFormFieldCol>
          <Form.Label>Plan Type</Form.Label>
          <br />
          <Styled.PlanTypeOptionsWrapper direction="vertical" gap={3}>
            <RadioInput
              fieldLabel="VOD"
              fieldName="planType"
              fieldId="VOD"
              checked={planType === IPlanType.VOD}
              fieldValue={"VOD"}
              onChangeHandler={() => {
                setPlanType(IPlanType.VOD);
                formik.setFieldValue("planType", IPlanType.VOD);
              }}
            />
            <RadioInput
              fieldLabel="Live Stream"
              fieldName="planType"
              fieldId="liveStream"
              checked={planType === IPlanType.liveStream}
              fieldValue={"liveStream"}
              onChangeHandler={() => {
                setPlanType(IPlanType.liveStream);
                formik.setFieldValue("planType", IPlanType.liveStream);
              }}
            />
          </Styled.PlanTypeOptionsWrapper>
        </Styled.ProductPlanFormFieldCol>
      </Styled.ProductPlanFormFieldRow>

      {!formik.values.isRecurring && (
        <Styled.ProductPlanFormFieldRow>
          <Styled.ProductPlanFormFieldCol>
            <Form.Label>Expiration</Form.Label>
            <br />
            <Styled.ExpiryOptionsWrapper direction="vertical" gap={3}>
              <RadioInput
                fieldLabel="None"
                fieldName="expirationType"
                fieldId="NONE"
                checked={expirationType === "NONE"}
                fieldValue={"NONE"}
                onChangeHandler={() => {
                  setExpirationType("NONE");
                  formik.setFieldValue("expiration", "NONE");
                }}
              />
              <Styled.ExpirationRadioInput
                fieldLabel="Select Date & Time"
                fieldName="expirationType"
                fieldId="DATE_AND_TIME"
                checked={expirationType === "DATE_AND_TIME"}
                fieldValue={"DATE_AND_TIME"}
                onChangeHandler={() => {
                  setExpirationType("DATE_AND_TIME");
                  formik.setFieldValue("expiration", "");
                }}
              />
              {expirationType === "DATE_AND_TIME" && (
                <>
                  <Styled.CustomDatePicker
                    placeholderText="Expiry Date (MM/dd/yyyy)"
                    className={formik.errors.expiration ? "error" : ""}
                    selected={
                      formik.values.expiration
                        ? new Date(formik.values.expiration)
                        : undefined
                    }
                    showTimeSelect
                    dateFormat="Pp"
                    onChange={(date: Date) => {
                      formik.setFieldValue("expiration", date.toISOString());
                    }}
                  />
                  {formik.touched.expiration && formik.errors.expiration && (
                    <Error message={formik.errors.expiration} />
                  )}
                </>
              )}
              <RadioInput
                fieldLabel="Time period (in days)"
                fieldName="expirationType"
                fieldId="TIME_PERIOD"
                checked={expirationType === "TIME_PERIOD"}
                fieldValue={"TIME_PERIOD"}
                onChangeHandler={() => {
                  setExpirationType("TIME_PERIOD");
                  formik.setFieldValue("expiration", "");
                }}
              />
              {expirationType === "TIME_PERIOD" && (
                <>
                  <FloatingTextInput
                    label="Time period"
                    type="text"
                    placeholder="Eg: 6"
                    controlId="expiration"
                    value={formik.values.expiration}
                    onChangeHandler={formik.handleChange}
                    onBlurHandler={formik.handleBlur}
                    hasError={
                      formik.touched.expiration && !!formik.errors.expiration
                    }
                  />
                  {formik.touched.expiration && formik.errors.expiration && (
                    <Error message={formik.errors.expiration} />
                  )}
                </>
              )}
            </Styled.ExpiryOptionsWrapper>
          </Styled.ProductPlanFormFieldCol>
        </Styled.ProductPlanFormFieldRow>
      )}

      <Styled.ProductPlanFormFieldRow>
        <Styled.ProductPlanFormFieldCol>
          <BaseButton variant="secondary" onClick={onCancel}>
            Cancel
          </BaseButton>
        </Styled.ProductPlanFormFieldCol>
        <Styled.ProductPlanFormFieldCol>
          <BaseButton type="submit">
            {formik.isSubmitting && <Spinner size="sm" />}
            {"  "}
            Save
          </BaseButton>
        </Styled.ProductPlanFormFieldCol>
      </Styled.ProductPlanFormFieldRow>
    </Styled.ProductPlanForm>
  );
};
