import React from "react";
import { Col, Row, Accordion, Card, Spinner } from "react-bootstrap";

import { useAuth } from "../../../../behavioral";

import { DragAndDropZone } from "../../../drag-drop-zone";
import * as Styled from "./AdServerItem.styled";
import { BannerFile } from "../upload-form/BannerFile";

import {
  FileWithPreview,
  IAdBannerStatus,
  IAdBannerType,
  IAdServerBannerRequestDto,
  Switch
} from "../../../../libs";
import { IAdBanner } from "../../types";
import { BannerList } from "../list";
import { adServerService } from "../../../../services";

export const AdServerItem: React.FC<{
  bannerType: IAdBannerType;
  bannerIndex: Number;
  bannerTitle: string;
  bannerWidth: Number;
  bannerHeight: Number;
}> = ({ bannerType, bannerIndex, bannerTitle, bannerWidth, bannerHeight }) => {
  const authInfo = useAuth();

  const [status, setStatus] = React.useState<IAdBannerStatus>(
    IAdBannerStatus.AD_INACTIVE
  );
  const [showBannerFile, setShowBannerFile] = React.useState(false);
  const [uploadedFiles, setUploadedFiles] = React.useState<FileWithPreview>();
  const [banner, setBanner] = React.useState<IAdBanner>();
  const [loading, setLoading] = React.useState(false);

  const getBannerList = React.useCallback(async () => {
    setLoading(true);
    const banners = await adServerService.getAllBanners();
    let setLatestBanner = false;
    for (let i = banners.length - 1; i >= 0; i--) {
      if (banners[i].adPosition.key === bannerType) {
        setBanner({
          ...banners[i],
          adPosition: bannerType,
          adStatus: banners[i].adStatus.key,
        });
        setStatus(banners[i].adStatus.key);
        setLatestBanner = true;
      }
    }
    if (!setLatestBanner) {
      setBanner(undefined);
      setStatus(IAdBannerStatus.AD_ACTIVE);
    }
    setLoading(false);
  }, [bannerType]);

  const handleOnEdit = React.useCallback((banner: IAdBanner) => {
    if (banner.adId === banner?.adId) {
      setShowBannerFile(true);
    }
  }, []);

  const deleteBanner = React.useCallback(
    async (banner: IAdBanner) => {
      setLoading(true);
      if (banner?.adId) {
        await adServerService.deleteBanner(banner.adId, banner.s3Key);
      }
      getBannerList();
    },
    [getBannerList]
  );

  const handleOnSave = React.useCallback((acceptedFiles) => {
    setUploadedFiles(acceptedFiles[0]);
    setShowBannerFile(true);
  }, []);

  const handleStatusToggle = React.useCallback(() => {
    if (banner) {
      const latestStatus =
        status === IAdBannerStatus.AD_ACTIVE
          ? IAdBannerStatus.AD_INACTIVE
          : IAdBannerStatus.AD_ACTIVE;
      setStatus(latestStatus);

      const requestDtol: IAdServerBannerRequestDto = {
        ...banner,
        adStatus: latestStatus,
      };
      adServerService.updateBanner(requestDtol);
    }
  }, [banner, status]);

  React.useEffect(() => {
    getBannerList();
  }, [getBannerList]);

  return (
    <Accordion activeKey={status === IAdBannerStatus.AD_ACTIVE ? "0" : ""}>
      <Styled.AdServerItemWrapper>
        <Card.Header>
          <Row>
            <Col md={1}>
              <Styled.BannerIndex>{bannerIndex.toString()}</Styled.BannerIndex>
            </Col>
            <Col md={8}>
              <Styled.BannerTitle>{bannerTitle}</Styled.BannerTitle>
            </Col>
            <Col md={2}>
              <Switch
                disabled={loading || banner ? false : true}
                id="custom-switch"
                checked={banner ? status === IAdBannerStatus.AD_ACTIVE : false}
                onChange={handleStatusToggle}
              />
            </Col>
          </Row>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="p-0">
            <Styled.BannerTextRow>
              <Col>
                Cemboo banner will be displayed or upload your file here:
              </Col>
            </Styled.BannerTextRow>

            <Styled.BannerTextRow>
              <Col>
                {showBannerFile ? (
                  <BannerFile
                    bannerType={bannerType}
                    file={uploadedFiles}
                    clientId={authInfo?.user?.username}
                    onSave={() => {
                      getBannerList();
                      setShowBannerFile(false);
                      setUploadedFiles(undefined);
                    }}
                    onCancel={() => {
                      setShowBannerFile(false);
                      setUploadedFiles(undefined);
                    }}
                    bannerWidth={bannerWidth}
                    bannerHeight={bannerHeight}
                    banner={banner}
                  ></BannerFile>
                ) : banner ? (
                  <BannerList
                    banner={banner}
                    onEdit={handleOnEdit}
                    onDelete={deleteBanner}
                  ></BannerList>
                ) : (
                  <DragAndDropZone
                    showSmallImage
                    textalign="left"
                    direction="horizontal"
                    acceptedFileType=".png,.jpeg,.jpg"
                    onDropHandler={handleOnSave}
                    dropMessageText="Drag and drop an advt file here or choose them (*.png,*.jpg/jpeg)"
                    onInvalidFormatError={() => alert("Invalid format")}
                  />
                )}
              </Col>
              {loading && (
                <Styled.SpinnerWrapper>
                  <Spinner variant="primary" />
                </Styled.SpinnerWrapper>
              )}
            </Styled.BannerTextRow>
          </Card.Body>
        </Accordion.Collapse>
      </Styled.AdServerItemWrapper>
    </Accordion>
  );
};
