import { Row } from "react-bootstrap";
import styled from "styled-components";

export const NoPlaylistsContainer = styled(Row)`
  background-color: #121419;
  margin: 20px 80px;
`;

export const NoPlaylistsImage = styled.img`
    width: 100%;
`;

export const NoPlaylistsText = styled.div`
    width: 100%;
    position: absolute;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    top: 60%;
`;

export const NoPlaylistsTextSmall = styled.div`
    color: #9F9F9F;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 25px;
`;

export const NoPlaylistsButtonWrap = styled.div`
  width: 200px;
  margin: 0 auto;
`;