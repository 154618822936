import styled from 'styled-components'
import { Row, Col, Stack } from 'react-bootstrap'
import { types, colors } from "../../../../../libs";


export const PlaylistSettingsWrapper = styled.div`
    height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
`

export const PlaylistSettingsRow = styled(Row)``
export const PlaylistSettingsCol = styled(Col)``

export const PlaylistSettingsRowSticky = styled(Row)`
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    background-color: ${colors.background.black};
`

export const PlaylistSettingsTitle = styled.div`
    ${types.headers.section}
    color: ${colors.text.white};
`

export const PlaylistSettingsSection = styled.div`
    ${types.headers.item}
    color: ${colors.text.white};
    margin-bottom: 0.8rem;
`

export const PlaylistSettingsSubtitle = styled.div`
    ${types.headers.small}
    font-weight: normal;
    color: ${colors.text.disabled};
`

export const ThumbnailGridRow = styled(Row)``
export const ThumbnailGridCol = styled(Col)`
    border: ${props => props.selected && `2px solid ${colors.background.green}`};
`
export const ThumbnailGridImg = styled.img``
export const ThumbnailGridUpload = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: ${colors.background.lightBlack};
    border: 1px solid #FFFFFF3D;
    cursor: pointer;
    img {
        margin-bottom: 0.5rem;
        height: 2rem;
    }
`

export const VisibilitySection = styled(Stack)``

export const BackIconContainerCol = styled(Col)`
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 0.5rem;
    margin-bottom: 1rem;
`

export const BackIcon = styled.img``