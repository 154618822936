import React from "react";
import GlyphLogo from "./../../assets/images/branding/cemboo-glyph.svg";
import IconCheck from "./../../assets/images/icons/icon-check.svg";
import IconBuoy from "./../../assets/images/icons/icon-lifebuoy.svg";
import IconLock from "./../../assets/images/icons/icon-lock-v2.svg";

import {
    ArrowRightShort,
    Linkedin,
    TwitterX,
    Facebook,
    Instagram,
    EnvelopeFill
} from 'react-bootstrap-icons';

import './footer.scss';

import * as Styled from "./footer.styled";

export const Footer = () => {
    return (
        <Styled.FooterWrapper className="mt-auto">

            <div className="container-fluid">
                <div className="container">

                    <Styled.FooterUpperWrapper className="footer-upper aos-init aos-animate">

                        <div className="container m-0 p-0">
                            <div className="row">

                                <div className="col-12 col-md-5 col-lg-5 footer-mission">
                                    <h3><img src={GlyphLogo} width="35" height="20" alt="Cemboo" />
                                    </h3>
                                    <p>
                                    Cemboo is an all-in-one B2B SaaS platform enabling publishers to control, monetize, and 
                                    distribute digital content their way. It improves media library management by supporting 
                                    diverse content under one roof, embedding it everywhere, and tracking performance. Plus, 
                                    publishers receive 100% of ad&nbsp;revenue.
                                    </p>
                                </div>

                                <div className="col-12 offset-md-1 col-md-3 col-lg-3 footer-links">
                                    <ul className="footer-links--list">
                                        <li>
                                            <Styled.FooterStyledNavLink
                                                className="nav-link"
                                                to="/about"
                                                activeclassname="active"
                                                exact="true">
                                                <ArrowRightShort size={18} />
                                                <span>About Us</span>
                                            </Styled.FooterStyledNavLink>
                                        </li>
                                        <li>
                                            <Styled.FooterStyledNavLink
                                                className="nav-link"
                                                to="/pricing"
                                                activeclassname="active"
                                                exact="true">
                                                <ArrowRightShort size={18} />
                                                <span>Pricing</span>
                                            </Styled.FooterStyledNavLink>
                                        </li>
                                        <li>
                                            <Styled.FooterStyledNavLink
                                                className="nav-link"
                                                to="/blockchain"
                                                activeclassname="active"
                                                exact="true">
                                                <ArrowRightShort size={18} />
                                                <span>Blockchain</span>
                                            </Styled.FooterStyledNavLink>
                                        </li>
                                        <li>
                                            <Styled.FooterStyledNavLink
                                                className="nav-link"
                                                to="/contact"
                                                activeclassname="active"
                                                exact="true">
                                                <ArrowRightShort size={18} />
                                                <span>Contact Us</span>
                                            </Styled.FooterStyledNavLink>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col-12 col-md-3 col-lg-3 footer-highlights">
                                    <ul className="footer-highlights--list">
                                        <li>
                                            <Styled.FooterListIcon
                                                src={IconCheck}
                                                width={28}
                                                height={28}
                                                alt=""></Styled.FooterListIcon>
                                            <span>30 day money back&nbsp;guarantee</span>
                                        </li>
                                        <li>
                                            <Styled.FooterListIcon
                                                src={IconBuoy}
                                                width={28}
                                                height={28}
                                                alt=""></Styled.FooterListIcon>
                                            <span>Support teams across the&nbsp;world</span>
                                        </li>
                                        <li>
                                            <Styled.FooterListIcon
                                                src={IconLock}
                                                width={28}
                                                height={28}
                                                alt=""></Styled.FooterListIcon>
                                            <span>Secure and safe online&nbsp;payment</span>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>

                    </Styled.FooterUpperWrapper>

                    <Styled.FooterLowerWrapper className="container footer-lower m-0 p-0 pb-4 clearfix aos-init aos-animate">

                        <div className="row">
                            <div className="col-12 col-md-6 pb-3 pb-md-0 order-2">

                                <Styled.CopyRightContent>
                                    &copy; {new Date().getFullYear()} <strong><span>Cemboo</span></strong>. All Rights Reserved.
                                </Styled.CopyRightContent>

                            </div>

                            <div className="col-12 col-md-6 pb-3 pb-md-0 order-2">

                                <ul className="social-links justify-content-center justify-content-md-end">
                                    <li>
                                        <a
                                            href="https://www.linkedin.com/company/cemboo360"
                                            target="_blank"
                                            title="Cemboo on LinkedIn"><Linkedin /></a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://twitter.com/Cemboo360"
                                            target="_blank"
                                            title="Cemboo on X"><TwitterX /></a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.facebook.com/cemboo360/"
                                            target="_blank"
                                            title="Cemboo on Facebook"><Facebook /></a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.instagram.com/cemboo360/"
                                            target="_blank"
                                            title="Cemboo on Instagram"><Instagram /></a>
                                    </li>
                                    <li>
                                        <a
                                            href="mailto:support@cemboo.com"
                                            target="_blank"
                                            title="Support @ Cemboo"><EnvelopeFill /></a>
                                    </li>
                                </ul>

                            </div>
                        </div>

                    </Styled.FooterLowerWrapper>

                </div>
            </div>

        </Styled.FooterWrapper>
    );
};
