import styled from "styled-components";

export const PackageInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
  & .package-card {
    background-color: var(--transparent);
    width: 100%;
    max-width: 312px;

    //&:nth-of-type(odd) {
    &.popular {
      background-color: var(--card-bg);
      background: linear-gradient(var(--card-bg), var(--card-bg)) padding-box,
        linear-gradient(180deg, #b7f84d 0%, rgba(183, 248, 77, 0) 100%)
          border-box;
      border-radius: 0.5rem;
      border: 1px solid transparent;
    }

    &:not(.popular) {
      border-width: 1px;
      border-style: dotted;
      border-color: grey;
      border-image-source: linear-gradient(
        165.72deg,
        rgba(255, 255, 255, 0) -3.66%,
        rgba(255, 255, 255, 0.5) 41.67%,
        rgba(255, 255, 255, 0) 108.07%
      );
      border-image-slice: 1;

      @media (min-width: 576px) {
      }

      @media (min-width: 768px) {
        border: none;
      }

      @media (min-width: 992px) {
      }

      @media (min-width: 1200px) {
      }

      @media (min-width: 1400px) {
      }
    }

    & .card-body {
      padding: 4rem 2.25rem 3rem 2.25rem;
    }

    & .card-title {
      font-size: clamp(1.5rem, 1.3333rem + 0.8889vw, 2rem);
      font-weight: 700;
      color: var(--vt-c-white);
    }

    & .card-text {
      font-size: 1rem;
    }

    & .price {
      font-size: clamp(1.5rem, 1.3333rem + 0.8889vw, 2rem);
      font-weight: 700;
      color: var(--color-primary);

      & span {
        font-size: clamp(1rem, 0.8333rem + 0.8889vw, 1.5rem);
        font-weight: 400;
        opacity: 0.7;
      }
    }

    & .notes {
      list-style-type: square;
      margin: 0 0 0 1.125rem;
      padding: 0 0 0 0;

      & > li {
        font-size: clamp(0.875rem, 0.8333rem + 0.2222vw, 1rem);
        font-weight: 400;
        color: var(--radcheck-border-color);
        margin: 0.5rem 0;
      }
    }

    & .card-footer {
      background-color: transparent;
      padding: 2rem 3.125rem;
    }
  }

  & .popular-choice {
    background: linear-gradient(270.46deg, #b7f84d 100%, #ffffff 133.76%);
    border-radius: 8px 8px 0px 0px;
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 5;
    transform: translateX(-50%);

    & span {
      font-size: 0.875rem;
      font-weight: 600;
      color: var(--slate-dark);
    }
  }
`;
