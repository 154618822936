import { useFormik } from "formik";
import * as React from "react";
import { Alert, Image as BImage, Form, Spinner, Stack } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import * as Yup from "yup";
import { useAuth } from "../../../behavioral";
import DeleteIcon from "./../../../assets/images/delete.svg";
import EditIcon from "./../../../assets/images/edit.svg";

import {
  BaseButton,
  Error,
  FileWithPreview,
  FloatingTextInput,
  ILiveChannelRequest,
  IMemberPlan,
  IPlanType,
  MultiPartUploader,
} from "../../../libs";

import { paymentService } from "../../../services";

import { useDropzone } from "react-dropzone";
import { DragAndDropZone } from "../../drag-drop-zone";
import * as Styled from "./LiveChannelForm.styled";

interface IProps {
  initialValues: {
    name: string;
    isPrivate?: boolean;
    eventName?: string;
    eventStartDate?: string;
    eventEndDate?: string;
    autoRecordEnabled?: boolean;
    domainName?: string;
    planId?: string[];
    sponsorLogo?: string;
  };
  onCancel: () => void;
  onSubmit: (
    data: ILiveChannelRequest,
    setSubmitting: (result: boolean) => void
  ) => void;
  errorMessage?: string;
  saveChannel?: number;
}

export const LiveChannelForm: React.FC<IProps> = ({
  initialValues,
  onSubmit,
  onCancel,
  errorMessage,
  saveChannel,
}) => {
  const authInfo = useAuth();
  const { open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
  });

  const [showLoader, setShowLoader] = React.useState(false);
  const [productPlans, setProductPlans] = React.useState<IMemberPlan[]>([]);
  const [planOptions, setPlanOptions] = React.useState<
    { label: string; value: string }[]
  >([]);

  const [isStripeConnected, setIsStripeConnected] = React.useState(false);
  const [sponsorLogo, setSponsorLogo] = React.useState<FileWithPreview>();
  const [sponsorLogoDeleted, setSponsorLogoDeleted] = React.useState(false);
  const [percentage, setPercentage] = React.useState(0);
  const getStripeAccountDetails = React.useCallback(async () => {
    setShowLoader(true);
    try {
      const response = await paymentService.getStripeCustomerAccountDetails();

      if (response.data && response.data.data?.enabled) {
        setIsStripeConnected(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setShowLoader(false);
    }
  }, []);

  React.useEffect(() => {
    getStripeAccountDetails();
  }, [getStripeAccountDetails]);

  const createNewChannelRequest = React.useCallback(
    (data, sponsorLogoS3Key): ILiveChannelRequest => {
      const newChannel: ILiveChannelRequest = {
        name: data.name,
        isPrivate: !!data.isPrivate,
        autoRecordEnabled: !!data.autoRecordEnabled,
        origin: data.domainName,
        event: {
          name: data.eventName ?? "",
          startDate: data.eventStartDate ?? "",
          endDate: data.eventEndDate ?? "",
        },
        planId: data.planId,
        sponsorLogoS3Key: sponsorLogoS3Key,
      };
      return newChannel;
    },
    []
  );

  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: Yup.object({
      name: Yup.string()
        .transform((value, originalValue) =>
          /\s/.test(originalValue) ? NaN : value
        )
        .typeError("Channel Name should not contain spaces")
        .required("Please enter a channel name"),
      eventName: Yup.string().required("Please enter a event name"),
      eventStartDate: Yup.date()
        .min(new Date(), "Please select a valid start date")
        .required(),
      eventEndDate: Yup.date()
        .min(Yup.ref("eventStartDate"), "Please select a valid end date")
        .required(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);

      const isValid = validateRequest();
      if (!isValid) {
        setSubmitting(false);
        return;
      }

      if (sponsorLogo) {
        uploadFilesToS3();
      } else {
        const newChannel: ILiveChannelRequest = createNewChannelRequest(
          values,
          initialValues?.sponsorLogo && sponsorLogoDeleted ? "" : undefined
        );
        onSubmit(newChannel, setSubmitting);
      }
    },
  });

  const formikSubmitRef = React.useRef(formik.handleSubmit);

  React.useEffect(() => {
    if (saveChannel && saveChannel > 0) {
      formikSubmitRef.current();
    }
  }, [saveChannel]);

  const validateRequest = React.useCallback(() => {
    // check if plan expires before start of event
    let isValid = true;

    formik.values.planId?.forEach((plan) => {
      const expiryDate = productPlans.find(
        (item) => item.id === plan
      )?.expiration;

      if (expiryDate && formik.values.eventStartDate) {
        if (
          new Date(expiryDate).getTime() <
          new Date(formik.values.eventStartDate).getTime()
        ) {
          formik.setFieldError(
            "planId",
            "The plan gets expired before the event starts."
          );
          isValid = false;
        }
      }
    });
    return isValid;
  }, [formik, productPlans]);

  const fetchProductPlans = React.useCallback(async () => {
    setShowLoader(true);
    try {
      const response = await paymentService.getCustomerProductPlans();
      setProductPlans(response);
      setPlanOptions(
        response
          .filter(
            (el) =>
              el.isEnabled &&
              el.isPublic &&
              el.active &&
              (el.planType === IPlanType.liveStream ||
                formik.values.planId?.includes(el.id)) // If a non LiveStream plan is already selected, then show it in the dropdown
          )
          .map((item: IMemberPlan) => ({
            value: item.id,
            label: `${item.name} -  $${item.amount
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`,
          }))
      );
    } catch (err) {
      console.log(err);
    } finally {
      setShowLoader(false);
    }
  }, [formik.values.planId]);

  React.useEffect(() => {
    fetchProductPlans();
  }, [fetchProductPlans]);

  const handlePlanSelect = React.useCallback(
    (option: any) => {
      formik.setFieldError("planId", "");
      if (option) {
        formik.setFieldValue(
          "planId",
          option.map((el: any) => el.value)
        );
      } else {
        formik.setFieldValue("planId", undefined);
      }
    },
    [formik]
  );

  const uploadFilesToS3 = React.useCallback(() => {
    const clientId = authInfo?.user?.username;
    if (sponsorLogo) {
      const uploader = new MultiPartUploader({
        fileName: sponsorLogo.file.name,
        file: sponsorLogo.file,
        clientId: { clientId },
        mediaId: "channel-sponsor-logos",
        category: "image",
      });

      uploader
        .getOnProgress(function (params: any) {
          setPercentage(params.percentage);
        })
        .getOnComplete(function (params: any) {
          const newChannel: ILiveChannelRequest = createNewChannelRequest(
            formik.values,
            params.s3Key
          );
          onSubmit(newChannel, formik.setSubmitting);
        })
        .getOnError((error: any) => {
          console.log(error);
        });

      uploader.start();
    }
  }, [sponsorLogo, formik, authInfo, createNewChannelRequest, onSubmit]);

  const handleOnDrop = React.useCallback((acceptedFiles) => {
    if (acceptedFiles[0]?.previewUrl) {
      var img = new Image();
      img.onload = function () {
        if (img.height !== 150 || img.width !== 150) {
          alert("Please upload an image with dimensions 150x150");
        } else {
          setSponsorLogo(acceptedFiles[0]);
        }
      };
      img.src = acceptedFiles[0]?.previewUrl;
    }
  }, []);

  const handleEditClicked = React.useCallback(() => {
    open();
  }, [open]);

  React.useEffect(() => {
    if (acceptedFiles.length > 0) {
      const fileWithPreview = {
        file: acceptedFiles[0],
        previewUrl: URL.createObjectURL(acceptedFiles[0]),
      };
      handleOnDrop([fileWithPreview]);
    }
  }, [acceptedFiles, handleOnDrop]);

  const handleDeleteClicked = React.useCallback(() => {
    if (initialValues?.sponsorLogo) {
      setSponsorLogoDeleted(true);
    } else {
      setSponsorLogo(undefined);
    }
  }, [initialValues]);

  if (showLoader) {
    return (
      <Styled.SpinnerWrapper>
        <Spinner animation="border">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Styled.SpinnerWrapper>
    );
  }

  return (
    <Styled.LiveChannelForm onSubmit={formik.handleSubmit}>
      {errorMessage && <Alert title={errorMessage} />}
      <Styled.LiveChannelFormFieldRow>
        <Styled.LiveChannelFormFieldCol>
          <FloatingTextInput
            label="Channel Name"
            type="text"
            placeholder="Channel Name"
            controlId="name"
            value={formik.values.name}
            onChangeHandler={formik.handleChange}
            onBlurHandler={formik.handleBlur}
            hasError={formik.touched.name && !!formik.errors.name}
          />
          {formik.touched.name && formik.errors.name && (
            <Error message={formik.errors.name} />
          )}
          <Form.Text id="passwordHelpBlock" muted>
            Maximum length: 128 characters. May include numbers, letters,
            underscores (_) and hyphens (-) but not spaces.
          </Form.Text>
        </Styled.LiveChannelFormFieldCol>
      </Styled.LiveChannelFormFieldRow>

      <Styled.LiveChannelFormFieldRow>
        <Styled.LiveChannelFormFieldCol>
          <Form.Label>Channel Type</Form.Label>
          <p>
            <Form.Text>
              Standard (broadcast and deliver live video up to Full HD, with
              transcoding)
            </Form.Text>
          </p>
        </Styled.LiveChannelFormFieldCol>

        <Styled.LiveChannelFormFieldCol>
          <Form.Label>Latency</Form.Label>
          <p>
            <Form.Text>
              Ultra-low (best for near real-time interactions with viewers)
            </Form.Text>
          </p>
        </Styled.LiveChannelFormFieldCol>
      </Styled.LiveChannelFormFieldRow>
      <Styled.AddEventHeader>Add Event</Styled.AddEventHeader>
      <Styled.LiveChannelFormFieldRow>
        <Styled.LiveChannelFormFieldCol>
          <FloatingTextInput
            label="Event Name"
            type="text"
            placeholder="Event Name"
            controlId="eventName"
            value={formik.values.eventName}
            onChangeHandler={formik.handleChange}
            onBlurHandler={formik.handleBlur}
          />
        </Styled.LiveChannelFormFieldCol>
      </Styled.LiveChannelFormFieldRow>

      <Styled.LiveChannelFormFieldRow>
        <Styled.LiveChannelFormFieldCol md={6}>
          <Form.Label>Start Date & Time</Form.Label>
          <Styled.CustomDatePicker
            placeholderText="MM/dd/yyyy"
            className={
              formik.touched.eventStartDate && formik.errors.eventStartDate
                ? "error"
                : ""
            }
            selected={
              formik.values.eventStartDate
                ? new Date(formik.values.eventStartDate)
                : undefined
            }
            showTimeSelect
            dateFormat="Pp"
            onChange={(date: Date) => {
              formik.setFieldValue("eventStartDate", date.toISOString());
            }}
          />
          {formik.touched.eventStartDate && formik.errors.eventStartDate && (
            <Error message={formik.errors.eventStartDate} />
          )}
        </Styled.LiveChannelFormFieldCol>
        <Styled.LiveChannelFormFieldCol md={6}>
          <Form.Label>End Date & Time</Form.Label>
          <Styled.CustomDatePicker
            placeholderText="MM/dd/yyyy"
            className={
              formik.touched.eventEndDate && formik.errors.eventEndDate
                ? "error"
                : ""
            }
            selected={
              formik.values.eventEndDate
                ? new Date(formik.values.eventEndDate)
                : undefined
            }
            showTimeSelect
            dateFormat="Pp"
            onChange={(date: Date) => {
              formik.setFieldValue("eventEndDate", date.toISOString());
            }}
          />
          {formik.touched.eventEndDate && formik.errors.eventEndDate && (
            <Error message={formik.errors.eventEndDate} />
          )}
        </Styled.LiveChannelFormFieldCol>
      </Styled.LiveChannelFormFieldRow>

      <Styled.LiveChannelFormFieldRow>
        <Styled.LiveChannelFormFieldCol>
          <Form.Label>Restrict Access</Form.Label>
          <Styled.LiveChannelFormSwitch
            type="switch"
            id="custom-switch"
            disabled={!isStripeConnected}
            checked={formik.values.isPrivate}
            onChange={(event: any) => {
              formik.setFieldValue("isPrivate", !formik.values.isPrivate);
              if (!event.target.checked) {
                formik.setFieldValue("planId", "");
              }
            }}
          />
          <br />
          {!isStripeConnected && (
            <Styled.ConnectAccountMessage>
              Please connect your account details to enable private streams.
            </Styled.ConnectAccountMessage>
          )}
        </Styled.LiveChannelFormFieldCol>

        <Styled.LiveChannelFormFieldCol>
          <Form.Label>Auto Record (VOD)</Form.Label>
          <Styled.LiveChannelFormSwitch
            type="switch"
            id="custom-switch"
            checked={formik.values.autoRecordEnabled}
            onChange={() => {
              formik.setFieldValue(
                "autoRecordEnabled",
                !formik.values.autoRecordEnabled
              );
            }}
          />
        </Styled.LiveChannelFormFieldCol>
      </Styled.LiveChannelFormFieldRow>

      <Styled.LiveChannelFormFieldRow>
        <Styled.LiveChannelFormFieldCol>
          <Form.Label>Sponsor Logo (150 x 150px)</Form.Label>
          {!sponsorLogo &&
          (!initialValues?.sponsorLogo || sponsorLogoDeleted) ? (
            <DragAndDropZone
              showSmallImage
              textalign="left"
              direction="horizontal"
              acceptedFileType=".png,.jpeg,.jpg,.gif"
              onDropHandler={handleOnDrop}
              dropMessageText="Drag and drop an sponsor logo or choose it (*.png,*.jpg/jpeg,*.gif)"
              onInvalidFormatError={() => alert("Invalid format")}
            />
          ) : (
            <Styled.SponsorLogoContainer direction="horizontal" gap={3}>
              <BImage
                src={
                  sponsorLogo?.previewUrl
                    ? sponsorLogo?.previewUrl
                    : initialValues
                    ? initialValues?.sponsorLogo
                    : ""
                }
                height={100}
                width={100}
              />
              <Stack direction="horizontal">
                <Styled.IconImage
                  src={DeleteIcon}
                  width={30}
                  onClick={handleDeleteClicked}
                />
                <Styled.IconImage
                  src={EditIcon}
                  width={30}
                  onClick={handleEditClicked}
                />
              </Stack>
            </Styled.SponsorLogoContainer>
          )}
        </Styled.LiveChannelFormFieldCol>
      </Styled.LiveChannelFormFieldRow>

      <Styled.LiveChannelFormFieldRow>
        <Styled.LiveChannelFormFieldCol>
          <FloatingTextInput
            label="Website Domain Name"
            type="text"
            placeholder="Domain Name"
            controlId="domainName"
            value={formik.values.domainName}
            onChangeHandler={formik.handleChange}
            onBlurHandler={formik.handleBlur}
          />
        </Styled.LiveChannelFormFieldCol>
      </Styled.LiveChannelFormFieldRow>
      <Styled.LiveChannelFormFieldRow>
        <Styled.LiveChannelFormFieldCol>
          We recommend to provide the domain name of website to restrict the
          event to be streamed only on your website.
        </Styled.LiveChannelFormFieldCol>
      </Styled.LiveChannelFormFieldRow>

      {formik.values.isPrivate && (
        <Styled.LiveChannelFormFieldRow>
          <Form.Label>Payment Plan</Form.Label>
          <Styled.LiveChannelFormFieldCol>
            <Select
              className="custom-select"
              isMulti
              styles={{
                menu: (baseStyles, state) => ({
                  ...baseStyles,
                  background: "#1E2025",
                  color: "#fff",
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  background: state.isFocused ? "#ccc" : "#1E2025",
                  color: state.isFocused ? "#1E2025" : "#fff",
                }),
                singleValue: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "#fff",
                }),
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: "50px",
                  background: "#1E2025",
                  color: "#fff",
                  border: formik.errors.planId
                    ? "1px solid red"
                    : state.isFocused
                    ? "1px solid transparent"
                    : "1px solid transparent",
                }),
              }}
              placeholder="Select Payment Plan"
              defaultValue={planOptions.filter((el) =>
                formik.values.planId?.includes(el.value)
              )}
              isClearable
              options={planOptions}
              onChange={handlePlanSelect}
            />
            {formik.errors.planId && <Error message={formik.errors.planId} />}
          </Styled.LiveChannelFormFieldCol>
        </Styled.LiveChannelFormFieldRow>
      )}

      <Styled.LiveChannelFormFieldRow>
        <Styled.LiveChannelFormFieldCol>
          <BaseButton variant="secondary" onClick={onCancel}>
            Cancel
          </BaseButton>
        </Styled.LiveChannelFormFieldCol>
        <Styled.LiveChannelFormFieldCol>
          <BaseButton type="submit">
            {formik.isSubmitting ? `${percentage}%` : "Save"}
            {"  "}
            {formik.isSubmitting && <Spinner size="sm" />}
          </BaseButton>
        </Styled.LiveChannelFormFieldCol>
      </Styled.LiveChannelFormFieldRow>
    </Styled.LiveChannelForm>
  );
};
