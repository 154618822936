import styled from 'styled-components';
import { Card, Row } from 'react-bootstrap';
import { colors } from '../../../libs';

export const Wrapper = styled(Card)`
  background: #0b0b0b;
  padding: 20px;
  margin: 20px 0px;
  border: 1px solid ${colors.background.lightWhite};
`;

export const ManageTitle = styled.div`
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
`;

export const ControlBody = styled(Row)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin: 10px 0;
    border: 1px solid ${colors.background.lightWhite};
`;

export const ControlTitle = styled.div`
    font-size: 16px;
    color: #fff;
`;