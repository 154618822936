import React from "react";

import { Image, Row, Col } from "react-bootstrap";

import * as Styled from "./BannerList.styled";
import EditIcon from "../../../../assets/images/edit.svg";
import DeleteIcon from "./../../../../assets/images/delete.svg";
import { IAdBanner } from "../../types";
import { confirmAlert } from "react-confirm-alert";
import { ConfirmModal } from "../../../../libs";

interface IBannerListProps {
  banner: IAdBanner;
  onEdit: (banner: IAdBanner) => void;
  onDelete: (banner: IAdBanner) => void;
}

export const BannerList: React.FC<IBannerListProps> = ({
  banner,
  onEdit,
  onDelete,
}) => {
  const confirmCb = React.useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            title="Confirm Ad Banner Removal"
            description={[
              {
                text: `Are you sure, you want to remove "${banner.adFileName}" ad banner?`,
                bold: false,
              },
              {
                text: "This operation is permanent and cannot be reverted.",
                bold: true,
              },
            ]}
            noText="Cancel"
            yesText="Remove"
            onNo={onClose}
            onYes={() => {
              onDelete(banner);
              onClose();
            }}
          ></ConfirmModal>
        );
      },
    });
  }, [onDelete, banner]);
  return (
    <Row>
      <Col lg={10}>
        <Row>
          <Col lg={3}>
            <Image
              src={banner.adImageSource}
              width={150}
              height={60}
              alt={banner.adImageAlttext}
            />
          </Col>
          <Col lg={8}>
            <Styled.FileName>File Name : {banner.adFileName}</Styled.FileName>
            <Styled.FileName>
              Alt Text : {banner.adImageAlttext}
            </Styled.FileName>
            <Styled.FileName>
              Url :{" "}
              <a href={banner.adImageUrl} target="_blank" rel="noreferrer">
                {banner.adImageUrl}
              </a>
            </Styled.FileName>
          </Col>
        </Row>
      </Col>
      <Col lg={2}>
        <Styled.IconContainer>
          <div className="vr" />
          <Styled.IconImage src={DeleteIcon} width={30} onClick={confirmCb} />
          <Styled.IconImage
            src={EditIcon}
            width={30}
            onClick={() => onEdit(banner)}
          />
        </Styled.IconContainer>
      </Col>
    </Row>
  );
};
