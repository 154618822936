import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const FooterWrapper = styled.footer`
    font-size: 0.875rem;
`;

export const FooterNewsletterWrapper = styled.div`

`;

export const FooterUpperWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const FooterLowerWrapper = styled.div`

`;

export const FooterStyledNavLink = styled(NavLink) <any>`
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.5;
    text-shadow: 0px 0px 0px rgba(255,255,255, 0);
    color: rgba(255,255,255, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem 0.938rem;
    white-space: nowrap;
    transition: all 0.3s ease-out;

    &.active {
        color: rgba(183, 248, 77, 1);
        transition: all 0.3s ease-out;

        @media(hover: hover) {
            &:hover {
                text-shadow: 0 0 1rem rgba(255,255,255, 0.6);
                color: rgba(183, 248, 77, 1);
                transition: all 0.3s ease-out;
            }
        }
    }

    @media(hover: hover) {
        &:hover {
            text-shadow: 0 0 1rem rgba(255,255,255, 0.6);
            color: rgba(255,255,255, 1);
            transition: all 0.3s ease-out;
        }
    }
`;

export const FooterLink = styled.a`
    margin-right: 10px;
    cursor: pointer;
`;

export const FooterListIcon = styled.img`
    width: 28px;
    height: 28px;
`;


export const CopyRightContent = styled.p`
    color: rgba(209, 214, 224, 1.0);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 1rem;
`;

export const LocationContent = styled.p`
    color: rgba(209, 214, 224, 1.0);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    
`;
