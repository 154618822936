import { ProgressBar } from "react-bootstrap";
import styled, { css } from "styled-components";

import { colors, types } from "../../../libs";

export const SideMenuUploadCta = styled.div`
  padding: 15px 20px;
`;

export const SideMenuListWrapper = styled.ul`
  flex: 1;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${colors.background.darkGreen};
    border-radius: 10px;
  }
`;

export const SideMenuLinkItemWrapper = styled.li``;

export const SideMenuUpgradeCard = styled.div`
  background: ${colors.background.brown};
  padding: 15px;
  border-radius: 10px;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
  width: 100%;
`;

export const UpgradeHeader = styled.p`
  ${types.body.secondaryTitle}
`;

export const UsageWrapper = styled.div`
  margin-top: 5px;
`;

export const UsageProgressBar = styled(ProgressBar) <any>`
  ${({ variant }) => variant === "primary" && css``}
  height: 5px;
`;

export const UsageText = styled.p`
  margin-top: 10px;
`;

export const UpgradeCtaLink = styled.a`
  color: ${colors.text.green};
`;
