import React from "react";

import {
    FileWithPreview,
    FullScreenModal,
    IFilmFestivalMediaRequestDto,
    IGeneralMediaRequestDto,
    IMediaResponseDto,
} from "../../libs";

import { GeneralUploadEditModalBody, GeneralUploadModalBody } from "./general";
import { ContentTypes, IUploadType } from "./types";
import { FilmFestivalModalBody } from "./upload-modal-body";
import { UploadModalHeader } from "./upload-modal-header";

import { FilmFestivalEditModalBody } from "./upload-modal-body";

interface IUploadModalProps {
    show?: boolean;
    onHide?: () => void;
    uploadedFile?: FileWithPreview;
    mediaItem?: IMediaResponseDto;
    clientId: string;
    uploadType: IUploadType;
    onUploadComplete: () => void;
}

export const UploadModal: React.FC<IUploadModalProps> = ({
    onHide,
    show,
    uploadedFile,
    mediaItem,
    clientId,
    uploadType = IUploadType.FILM_FESTIVAL_VIDEO,
    onUploadComplete,
}) => {
    // eslint-disable-next-line
    const [filmFestivalUploadRequestDto, setFilmFestivalUploadRequestDto] =
        React.useState<IFilmFestivalMediaRequestDto>();
    // eslint-disable-next-line
    const [generalUploadRequestDto, setGeneralUploadRequestDto] =
        React.useState<IGeneralMediaRequestDto>();

    const [contentType, setContentType] = React.useState<ContentTypes>(
        ContentTypes.UPLOAD_DETAILS,
    );

    const contentTypeChangeHandler = React.useCallback(
        (selectedContentType: ContentTypes) => {
            setContentType(selectedContentType);
        },
        [],
    );

    const handleModalHide = React.useCallback(() => {
        if (onHide) {
            onHide();
        }
        if (uploadType === IUploadType.FILM_FESTIVAL_VIDEO) {
            setContentType(ContentTypes.UPLOAD_DETAILS);
        }
    }, [onHide, uploadType]);

    const handleContinueToNextSection = React.useCallback(() => {
        if (contentType === ContentTypes.UPLOAD_DETAILS) {
            setContentType(ContentTypes.UPLOAD_LANGUAGE_CC);
        } else if (contentType === ContentTypes.UPLOAD_LANGUAGE_CC) {
            setContentType(ContentTypes.UPLOAD_CAST);
        } else if (contentType === ContentTypes.UPLOAD_CAST) {
            setContentType(ContentTypes.UPLOAD_SPONSORS);
        } else if (contentType === ContentTypes.UPLOAD_SPONSORS) {
            setContentType(ContentTypes.UPLOAD_VISIBILITY);
        } else {
            console.log("Final Section");
        }
    }, [contentType]);

    React.useEffect(() => {
        setContentType(ContentTypes.UPLOAD_DETAILS);
    }, []);

    const UPLOAD_MODAL_HEADER: { [key in IUploadType]: React.ReactNode } = {
        [IUploadType.GENERAL_VIDEO]: (
            <UploadModalHeader
                onStepClick={contentTypeChangeHandler}
                contentType={contentType}
                showSteps={false}
            />
        ),

        [IUploadType.FILM_FESTIVAL_VIDEO]: (
            <UploadModalHeader
                onStepClick={contentTypeChangeHandler}
                contentType={contentType}
                showSteps={true}
            />
        ),
    };

    return (
        <>
            <FullScreenModal
                show={show}
                onHide={handleModalHide}
                header={UPLOAD_MODAL_HEADER[uploadType]}
                body={
                    <>
                        {uploadedFile ? (
                            uploadType === IUploadType.FILM_FESTIVAL_VIDEO ? (
                                <FilmFestivalModalBody
                                    contentType={contentType}
                                    uploadedFile={uploadedFile}
                                    clientId={clientId}
                                    onUploadComplete={onUploadComplete}
                                    onContinueToNextSection={
                                        handleContinueToNextSection
                                    }
                                />
                            ) : (
                                <GeneralUploadModalBody
                                    contentType={contentType}
                                    uploadedFile={uploadedFile}
                                    clientId={clientId}
                                    onUploadComplete={onUploadComplete}
                                />
                            )
                        ) : (
                            mediaItem &&
                            (uploadType === IUploadType.GENERAL_VIDEO ? (
                                <GeneralUploadEditModalBody
                                    mediaItem={mediaItem}
                                    clientId={clientId}
                                    onUploadComplete={onUploadComplete}
                                />
                            ) : (
                                <FilmFestivalEditModalBody
                                    mediaItem={mediaItem}
                                    contentType={contentType}
                                    clientId={clientId}
                                    onUploadComplete={onUploadComplete}
                                    onContinueToNextSection={
                                        handleContinueToNextSection
                                    }
                                />
                            ))
                        )}
                    </>
                }
                children={undefined}
            ></FullScreenModal>
        </>
    );
};
