import React from "react";
import * as Styled from "./SelectVideo.styled";
import FilterIcon from "./../../../../../../assets/images/icons/icon-filter.svg";
import SelectIcon from "./../../../../../../assets/images/icons/icon-select-check.svg";
import DeselectIcon from "./../../../../../../assets/images/icons/icon-deselect-cross.svg";
import ListViewIcon from "./../../../../../../assets/images/icons/icon-list-view.svg";
import GridViewIcon from "./../../../../../../assets/images/icons/icon-grid-view.svg";
import { colors } from "../../../../../../libs";
import { Spinner } from "react-bootstrap";

interface IProps {
    mediaItems: any[];
    selectedVideos: string[];
    setSelectedVideos: (videos: string[]) => void;
}

export const SelectVideo: React.FC<IProps> = ({
    mediaItems,
    selectedVideos,
    setSelectedVideos,
}) => {
    const [listView, setListView] = React.useState<boolean>(false);
    const [searchText, setSearchText] = React.useState<string>("");

    const handleSelectVideo = React.useCallback(
        (id: string) => {
            setSelectedVideos([id]);
        },
        [setSelectedVideos],
    );

    const highlightText = React.useCallback(
        (text: string) => {
            const finalText = searchText
                ? text.replace(
                      new RegExp(searchText, "ig"),
                      (match) => `<span>${match}</span>`,
                  )
                : text;
            const splits = finalText.split(/<span>|<\/span>/);
            return splits.map((split, index) => {
                return index % 2 === 0 ? (
                    <>{split}</>
                ) : (
                    <span style={{ color: colors.text.green }}>{split}</span>
                );
            });
        },
        [searchText],
    );

    const generatedVideoCards = React.useMemo(() => {
        const videoCards: React.ReactElement[] = [];
        const filteredMediaItems = mediaItems?.filter((media) =>
            media.title.toLowerCase().includes(searchText.toLowerCase()),
        );
        filteredMediaItems?.forEach((media, index) => {
            videoCards.push(
                listView ? (
                    <Styled.SelectedVideoRow
                        key={index}
                        onClick={() => handleSelectVideo(media.mediaId)}
                    >
                        <Styled.SelectedVideoCol md={2}>
                            <Styled.SelectedVideoImg
                                src={media.thumbnailsSrc}
                            />
                        </Styled.SelectedVideoCol>
                        <Styled.SelectedVideoCol md={9}>
                            <Styled.SelectedVideoTitle>
                                {highlightText(media.title)}
                            </Styled.SelectedVideoTitle>
                            <Styled.SelectedVideoDescription>
                                {media.category}
                            </Styled.SelectedVideoDescription>
                        </Styled.SelectedVideoCol>
                        <Styled.SelectedVideoCol md={1}>
                            <Styled.SelectedVideoCross
                                src={
                                    selectedVideos.includes(media.mediaId)
                                        ? DeselectIcon
                                        : SelectIcon
                                }
                            />
                        </Styled.SelectedVideoCol>
                    </Styled.SelectedVideoRow>
                ) : (
                    <Styled.SelectVideoBodyCol
                        sm={12}
                        md={3}
                        key={index}
                        onClick={() => handleSelectVideo(media.mediaId)}
                    >
                        <Styled.SelectVideoCardContainer>
                            <Styled.SelectVideoCardThumbnailContainer>
                                <Styled.SelectVideoCardThumbnail
                                    src={media.thumbnailsSrc}
                                />
                                <Styled.SelectVideoCardSelect
                                    src={
                                        selectedVideos.includes(media.mediaId)
                                            ? DeselectIcon
                                            : SelectIcon
                                    }
                                />
                                {/* <Styled.SelectVideoCardRuntime></Styled.SelectVideoCardRuntime> */}
                            </Styled.SelectVideoCardThumbnailContainer>
                            <Styled.SelectVideoCardTitle>
                                {highlightText(media.title)}
                            </Styled.SelectVideoCardTitle>
                        </Styled.SelectVideoCardContainer>
                    </Styled.SelectVideoBodyCol>
                ),
            );
        });

        return videoCards;
    }, [
        listView,
        mediaItems,
        selectedVideos,
        handleSelectVideo,
        searchText,
        highlightText,
    ]);

    const hadleChangeView = React.useCallback(() => {
        setListView(!listView);
    }, [listView]);

    return (
        <Styled.SelectVideoWrapper>
            <Styled.SelectVideoHeader>
                Select videos for your playlist
            </Styled.SelectVideoHeader>
            <Styled.SelectVideoSearchBar>
                <Styled.SelectVideoSearchBarInput
                    type="text"
                    placeholder="Search your library"
                    onChange={(value) => setSearchText(value.target.value)}
                />
                <Styled.SideIconsContainer>
                    <Styled.SearchVideoFilter>
                        <Styled.SearchVideoFilterIcon src={FilterIcon} />
                        <Styled.SearchVideoFilterText>
                            Filter
                        </Styled.SearchVideoFilterText>
                    </Styled.SearchVideoFilter>
                    <Styled.ChangeViewContiner onClick={hadleChangeView}>
                        <Styled.ChangeViewIcon
                            src={listView ? GridViewIcon : ListViewIcon}
                        />
                    </Styled.ChangeViewContiner>
                </Styled.SideIconsContainer>
            </Styled.SelectVideoSearchBar>
            <Styled.SelectVideoBody>
                {generatedVideoCards.length ? (
                    generatedVideoCards
                ) : (
                    <Styled.SelectVideoLoading>
                        <Spinner
                            animation="border"
                            variant="primary"
                        />
                    </Styled.SelectVideoLoading>
                )}
            </Styled.SelectVideoBody>
        </Styled.SelectVideoWrapper>
    );
};
