/**
 * PLAY_LIST_OPTIONS
 */
export const playListOptions = [
    {
        id: "1",
        label: "Sports - Baseball",
        value: "Sports - Baseball",
        name: "Sports - Baseball"
    }, {
        id: "2",
        label: "NFL League",
        value: "NFL League",
        name: "NFL League"
    }
];

/**
 * GENRE_OPTIONS
 */
export const genreOptions = [
    {
        id: "1",
        value: "action",
        label: "Action"
    }, {
        id: "2",
        value: "adventure",
        label: "Adventure"
    }, {
        id: "3",
        value: "animation",
        label: "Animation"
    }, {
        id: "30",
        value: "anime",
        label: "Anime"
    }, {
        id: "4",
        value: "biography",
        label: "Biography"
    }, {
        id: "29",
        value: "children",
        label: "Children"
    }, {
        id: "5",
        value: "comedy",
        label: "Comedy"
    }, {
        id: "6",
        value: "crime",
        label: "Crime"
    }, {
        id: "7",
        value: "documentary",
        label: "Documentary"
    }, {
        id: "8",
        value: "drama",
        label: "Drama"
    }, {
        id: "9",
        value: "family",
        label: "Family"
    }, {
        id: "10",
        value: "fantasy",
        label: "Fantasy"
    }, {
        id: "11",
        value: "film-noir",
        label: "Film-Noir"
    }, {
        id: "12",
        value: "history",
        label: "History"
    }, {
        id: "13",
        value: "horror",
        label: "Horror"
    }, {
        id: "26",
        value: "indie",
        label: "Indie"
    }, {
        id: "28",
        value: "martial_arts",
        label: "Martial Arts"
    }, {
        id: "14",
        value: "music",
        label: "Music"
    }, {
        id: "15",
        value: "musical",
        label: "Musical"
    }, {
        id: "16",
        value: "mystery",
        label: "Mystery"
    }, {
        id: "17",
        value: "news",
        label: "News"
    }, {
        id: "18",
        value: "romance",
        label: "Romance"
    }, {
        id: "19",
        value: "science_fiction",
        label: "Science Fiction"
    }, {
        id: "20",
        value: "short",
        label: "Short"
    }, {
        id: "21",
        value: "sport",
        label: "Sport"
    }, {
        id: "22",
        value: "thriller",
        label: "Thriller"
    }, {
        id: "27",
        value: "tv_movie",
        label: "TV Movie"
    }, {
        id: "23",
        value: "war",
        label: "War"
    }, {
        id: "24",
        value: "western",
        label: "Western"
    }, {
        id: "25",
        value: "other",
        label: "Other"
    }
];

/**
 * YEAR_OPTIONS
 */
export const yearOptions = [
    {
        id: "1",
        value: "2001",
        label: "2001"
    }, {
        id: "2",
        value: "2002",
        label: "2002"
    }, {
        id: "3",
        value: "2003",
        label: "2003"
    }, {
        id: "4",
        value: "2004",
        label: "2004"
    }, {
        id: "5",
        value: "2005",
        label: "2005"
    }, {
        id: "6",
        value: "2006",
        label: "2006"
    }, {
        id: "7",
        value: "2007",
        label: "2007"
    }, {
        id: "8",
        value: "2008",
        label: "2008"
    }, {
        id: "9",
        value: "2009",
        label: "2009"
    }, {
        id: "10",
        value: "2010",
        label: "2010"
    }, {
        id: "11",
        value: "2011",
        label: "2011"
    }, {
        id: "12",
        value: "2012",
        label: "2012"
    }, {
        id: "13",
        value: "2013",
        label: "2013"
    }, {
        id: "14",
        value: "2014",
        label: "2014"
    }, {
        id: "15",
        value: "2015",
        label: "2015"
    }, {
        id: "16",
        value: "2016",
        label: "2016"
    }, {
        id: "17",
        value: "2017",
        label: "2017"
    }, {
        id: "18",
        value: "2018",
        label: "2018"
    }, {
        id: "19",
        value: "2019",
        label: "2019"
    }, {
        id: "20",
        value: "2020",
        label: "2020"
    }, {
        id: "21",
        value: "2021",
        label: "2021"
    }, {
        id: "22",
        value: "2022",
        label: "2022"
    }, {
        id: "23",
        value: "2023",
        label: "2023"
    }
];

/**
 * LANGUAGE_OPTIONS
 * TM1 Language Codes ??
 */
export const languageOptions = [
    {
        id: "1",
        value: "ENG",
        label: "English"
    }, {
        id: "3",
        value: "BRA",
        label: "Brazilian Portuguese"
    }, {
        id: "4",
        value: "HRV",
        label: "Croatian"
    }, {
        id: "5",
        value: "CSY",
        label: "Czech"
    }, {
        id: "6",
        value: "SCH",
        label: "Chinese (Simplified)"
    }, {
        id: "2",
        value: "TCH",
        label: "Chinese (Traditional)"
    }, {
        id: "7",
        value: "DAN",
        label: "Danish"
    }, {
        id: "8",
        value: "NLD",
        label: "Dutch"
    }, {
        id: "9",
        value: "DEU",
        label: "German"
    }, {
        id: "10",
        value: "FIN",
        label: "Finnish"
    }, {
        id: "11",
        value: "FRA",
        label: "French"
    }, {
        id: "12",
        value: "HUN",
        label: "Hungarian"
    }, {
        id: "13",
        value: "ITA",
        label: "Italian"
    }, {
        id: "14",
        value: "JPN",
        label: "Japan"
    }, {
        id: "15",
        value: "KAZ",
        label: "Kazakh"
    }, {
        id: "16",
        value: "KOR",
        label: "Korean"
    }, {
        id: "17",
        value: "NOR",
        label: "Norwegian"
    }, {
        id: "18",
        value: "POL",
        label: "Polish"
    }, {
        id: "19",
        value: "ROM",
        label: "Romanian"
    }, {
        id: "20",
        value: "RUS",
        label: "Russian"
    }, {
        id: "21",
        value: "ESP",
        label: "Spanish"
    }, {
        id: "22",
        value: "SLV",
        label: "Slovenian"
    }, {
        id: "23",
        value: "SVE",
        label: "Swedish"
    }, {
        id: "24",
        value: "THA",
        label: "Thai"
    }, {
        id: "25",
        value: "TRK",
        label: "Turkish"
    }
];
