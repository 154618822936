import { Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { colors, types } from "../../../libs";

export const RegistrationWrapper = styled.div`
  text-align: center;
  padding: 10px 0;
`;

export const RegistrationFormWrapper = styled.div`
  margin-top: 20px;
  padding: 10px 0;
`;

export const RegistrationFormHeader = styled.h1`
  ${types.headers.section};
  color: ${colors.text.white};
`;

export const LoginText = styled.p`
  ${types.body.subtitle};
  color: ${colors.text.mediumWhite};
`;

export const ConfirmCodeText = styled.p`
  ${types.body.normal};
  width: 50%;
  margin: 0 auto;
  color: ${colors.text.mediumWhite};
`;

export const LoginLink = styled.a`
  color: ${colors.text.green};
`;

export const RegistrationForm = styled(Form)`
  margin-top: 20px;
`;

export const RegisterFormRow = styled(Row)`
  justify-content: center;
  margin: 10px 0;
`;

export const TermsText = styled.p`
  margin: 10px 0;
  color: ${colors.text.disabled};
  ${types.body.normal};
`;

export const TermsTextLink = styled.span`
  color: ${colors.text.white};
  ${types.body.normal};
`;

export const CountryCodeSelect = styled(Form.Select)`
  /* styling */
  background-color: #1e2025;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;

  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  border: none;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  height: 59px;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1.5em + 2px),
    calc(100% - 15px) calc(1.5em + 2px), calc(100% - 2.5em) 1em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;

  &:focus {
    border-color: #1e2025;
    box-shadow: none;
    outline: 0;
  }
`;

export const PasswordMutedText = styled(Form.Text)`
  text-align: left;
`;

export const PromoCodeSpacer = styled.div`
  margin-top: 12px;
`;

export const PromoCodeInputWrapper = styled.div`
  z-index: 0;
`;

export const FinalAmountRow = styled(Row)`
display: flex;
justify-content: center;
`

export const FinalAmountContainer = styled(Col)`
  padding: 0 20px;
  margin-top: 10px;
`;

export const FinalAmountWrapper = styled.div`
  border: 1px solid ${colors.text.lightWhite};
  background-color: ${colors.background.formField};
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 5px;
`;

export const FinalAmountSummary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FinalAmountText = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  color: ${colors.text.white};
`;

export const FinalAmountPrice = styled.div`
  font-size: 1.25rem;
  font-weight: bolder;
  color: ${colors.text.green};
`;

export const FinalAmountPriceWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const AmountDetails = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  max-height: ${props => (props.isOpen ? "200px" : "0")};
  overflow: hidden;
`;

export const AmountDetailsText = styled.div`
  font-size: 1rem;
  color: ${colors.text.mediumWhite};
  display: flex;
  justify-content: space-between;
`;

export const ToggleIcon = styled.img<{ isOpen: boolean }>`
  transform: rotate(${props => (props.isOpen ? "-180deg" : "0deg")});
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
`;

export const BreakLine = styled.hr`
  border: 1px solid ${colors.text.white};
  margin: 10px 0;
`;