import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { types, colors } from "../../../libs";

export const ShowPlaylistsWrapper = styled(Row)``;

export const ShowPlaylistsHeader = styled(Col)`
    display: flex;
    justify-content: space-between;
`;

export const ShowPlaylistsTitle = styled.div`
    ${types.headers.item};
`;

export const ShowPlaylistsButtons = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const ShowPlaylistAddButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ShowPlaylistRow = styled(Row)`
    margin: 1rem 0;
`;

export const ShowPlaylistCol = styled(Col)`
    padding: 0.3rem;
`;

export const ShowPlaylistColInner = styled.div`
    border: ${colors.background.lightWhite} 2px solid;
    border-radius: 0.5rem;
    background-color: ${colors.background.lightBlack};
`;

export const ShowPlaylistThumbnail = styled.img`
    height: 13rem;
    margin: 0 auto;
    border-radius: 0.5rem;
    object-fit: cover;
    width: 100%;
`;

export const ShowPlaylistWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const ShowPlaylistTitle = styled.div`
    ${types.headers.small};
    margin: 0.5rem;
`;

export const ShowPlaylistDescription = styled.div`
    ${types.body.normal};
    margin: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ShowPlaylistOptions = styled.img`
    height: 20px;
    cursor: pointer;
    position: absolute;
    z-index: 100;
    right: 10px;
    top: 10px;
`;

export const ShowPlaylistHorizontalCol = styled(Col)``;

export const ShowPlaylistHorizontalRow = styled(Row)`
    background-color: ${colors.background.lightBlack};
    margin-bottom: 0.5rem;
`;

export const ShowPlaylistHorizontalThumbnail = styled.img`
    border: ${colors.background.lightWhite} 2px solid;
    margin: 0.5rem auto;
    height: 4rem;
    width: 6.5rem;
`;

export const ShowPlaylistHorizontalContent = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100%;
`;

export const ShowPlaylistHorizontalText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const ShowPlaylistHorizontalTitle = styled.div`
    ${types.headers.small};
    margin: 0 0.5rem 0.2rem 0.5rem;
`;

export const ShowPlaylistHorizontalDescription = styled.div`
    ${types.body.small};
    color: ${colors.text.disabled};
    margin: 0 0.5rem;
`;

export const ShowPlaylistHorizontalOptionsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ShowPlaylistHorizontalOptions = styled.img`
    height: 1rem;
`;

export const SwitchViewButton = styled.div`
    height: 2.5rem;
    width: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: ${colors.background.lightBlack};
`;

export const SwitchViewIcon = styled.img``;
