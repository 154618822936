import * as React from "react";
import { Container } from "react-bootstrap";

import { IPackageRequest } from "../../../../libs";
import { adminService } from "../../../../services";
import { PackageForm } from "../package-form";

export const AddPackage: React.FC<{
  onCancel: () => void;
  onCreateComplete: () => void;
}> = ({ onCancel, onCreateComplete }) => {
  const [createPackageError, setCreatePackageError] = React.useState("");

  const initialValues: IPackageRequest = {
    name: "",
    description: "",
    monthlyPrice: 0,
    features: [],
  };

  const handleSubmit = React.useCallback(
    async (data: IPackageRequest, setSubmitting: (value: boolean) => void) => {
      try {
        const packageId = await adminService.createPackage(data);
        if (packageId) {
          setSubmitting(false);
          onCreateComplete();
        }
      } catch (err) {
        console.log("There is an error while creating a package", err);
        setCreatePackageError(
          "There is an error while creating the package. Please try again."
        );
        setSubmitting(false);
      }
    },
    [onCreateComplete]
  );

  return (
    <Container fluid>
      <h3>Add Package</h3>
      <PackageForm
        initialValues={initialValues}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        errorMessage={createPackageError}
      />
    </Container>
  );
};
