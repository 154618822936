import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { colors } from "../../../../../libs";

export const TitleCellWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 700;
    img {
        border-radius: 0.5rem;
        border: 1px solid ${colors.background.darkGreen};
        height: 3.5rem;
        margin-right: 0.5rem;
    }
`

export const OrderSelectionWrapper = styled.div`
    padding: 1rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
`

export const OrderSelectionRow = styled(Row)`
    .MuiTableHead-root {
        .MuiTableCell-root {
            border: none;
        }
    }
    .MuiTableBody-root{
        .MuiTableRow-root{
            &:nth-child(1){
                .MuiTableCell-root {
                    border-top: 1px solid ${colors.background.darkGreen};
                }
                .MuiTableCell-root:nth-child(1) {
                    border-top-left-radius: 0.5rem;
                }
                .MuiTableCell-root:nth-last-child(1) {
                    border-top-right-radius: 0.5rem;
                }
            }
            
            &:last-child {
                .MuiTableCell-root:nth-child(1) {
                    border-bottom-left-radius: 0.5rem;
                }
                .MuiTableCell-root:nth-last-child(1) {
                    border-bottom-right-radius: 0.5rem;
                }
            }
            .MuiTableCell-root {
                border-color: ${colors.background.darkGreen};
                
                &:nth-child(1){
                    border-left: 1px solid ${colors.background.darkGreen};
                }
                &:nth-last-child(1){
                    border-right: 1px solid ${colors.background.darkGreen};
                }
            }
        }
    }
`

export const OrderSelectionRowSticky = styled(Row)`
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    background-color: ${colors.background.black};
`

export const OrderSelectionCol = styled(Col)``

export const OrderSelectionHeader = styled.div`
    display: flex;
    justify-content: space-between;
`

export const OrderSelectionHeaderText = styled.h3`
    font-size: 1.5rem;
    font-weight: 700;
    color: ${colors.text.white};
    margin: 0.5rem 0;
`

export const OrderSelectionHeaderSubText = styled.p`
    font-size: 1rem;
    color: ${colors.text.disabled};
`

export const OrderSelectionHeaderSide = styled.div`
    display: flex;
    flex-direction: column;
`

export const OrderSelectionHeaderActions = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
`

export const OrderSelectionHeaderBackButton = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 2rem;
    cursor: pointer;
`

export const OrderSelectionHeaderBackIcon = styled.img``

export const OrderSelectionHeaderMain = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 65vw;
    justify-content: space-between;
`

export const OrderSelectionActionButtons = styled.div`
    display: flex;
    gap: 1rem;
    height: 3rem;
    width: 20rem;
`

export const OrderSelectionActionButtonInside = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
`

export const OrderSelectionActionButtonIcon = styled.img`
    height: 0.9rem;
`