import styled from "styled-components";

export const MenuListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DropdownContainer = styled.div`
  padding: 0px;
`;

export const DropdownItem = styled.div`
  width: 100%;
`;

export const DropdownHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  & > img {
    position: absolute;
    right: 35px;
  }
`;

export const DropdownBody = styled.div<{
  show: boolean;
}>`
  padding: ${(props) => (props.show ? "10px 30px" : "0")};
  max-height: ${(props) => (props.show ? "1000px" : "0")};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
`;

export const DropdownToggleIcon = styled.img<{
  isOpen: boolean;
}>`
  transform: rotate(${(props) => (props.isOpen ? "180deg" : "0deg")});
  transition: transform 0.3s ease-in-out;
`;