import styled from "styled-components";
import { types, colors, OuterContainer } from "../../../../libs";
import { Row, Stack, Spinner } from "react-bootstrap";

export const RequiredText = styled.p`
    ${types.body.small};
    color: ${colors.text.green};
    margin: 5px 0;
`;

export const FormFieldRow = styled(Row)`
    margin-bottom: 15px;
`;

export const ThumbnailSectionContainer = styled(OuterContainer)`
    margin: 15px 0;
    padding: 0;
`;

export const ThumbnailText = styled.p`
    font-size: 14px;
    color: rgba(159, 159, 159, 1);
`;

export const ThumbnailHeader = styled.h3`
    ${types.headers.small};
`;

export const VisibilityOptionsWrapper = styled(Stack)`
    width: 100%;
`;

export const LoadingSpinner = styled(Spinner)`
    margin-right: 10px;
`;

export const ActionButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 6px;
`;