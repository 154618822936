import * as React from "react";
import {
  Alert,
  Button as BsButton,
  Col,
  Container,
  Row,
  Spinner,
  Image,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

import { BaseButton, ILiveChannelDetails, ConfirmModal, ROUTEPATHS } from "../../../libs";
import { liveChannelService } from "../../../services";

import { IVSPlayer } from "../../ivs-player";
import { EditChannel } from "../edit-channel";
import * as Styled from "./ChannelDetails.styled";

export const ChannelDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showEditChannelForm, setShowEditChannelForm] = React.useState(false);
  const [saveEditChannelForm, setSaveEditChannelForm] = React.useState(0);
  const [deleteErrorMessage, setDeleteErrorMessage] = React.useState("");
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [channelDetails, setChannelDetails] =
    React.useState<ILiveChannelDetails>();

  const getChannelDetails = React.useCallback(async () => {
    if (id) {
      const response = await liveChannelService.getLiveChannelById(id);
      setChannelDetails(response);
    }
  }, [id]);

  React.useEffect(() => {
    getChannelDetails();
  }, [getChannelDetails]);

  const handleBackClick = React.useCallback(() => {
    if (showEditChannelForm) {
      setSaveEditChannelForm(0);
      setShowEditChannelForm(false);
    } else {
      navigate(`${ROUTEPATHS.LIVE_CHANNELS}`);
    }
  }, [navigate, showEditChannelForm]);

  const handleOnEditClick = React.useCallback(() => {
    if (channelDetails?.stream.id) {
      alert("Stream is in progress and channel can't be edited");
      return;
    }

    if (showEditChannelForm) {
      setSaveEditChannelForm(saveEditChannelForm + 1);
      return;
    }

    setShowEditChannelForm(true);
  }, [channelDetails, showEditChannelForm, saveEditChannelForm]);

  const handleDeleteClick = React.useCallback(async () => {
    if (channelDetails?.planDetails && channelDetails.planDetails.length > 0) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmModal
              title="Attention!"
              description={[
                {
                  text: `This channel is associated with ${channelDetails.planDetails?.length} payment plan(s).`,
                  bold: true,
                },
                {
                  text: "Please unassociate the payment plan(s) before deleting the channel.",
                  bold: false,
                },
              ]}
              noText="Cancel"
              yesText="Go To Edit Plans"
              onNo={() => onClose()}
              onYes={() => { handleOnEditClick(); onClose() }}
            ></ConfirmModal>
          );
        },
      });
    } else if (channelDetails) {
      setShowSpinner(true);
      try {
        await liveChannelService.deleteLiveChannel(channelDetails.id);
        navigate(`${ROUTEPATHS.LIVE_CHANNELS}`, { replace: true });
      } catch (err) {
        console.log("There is an error while deleting the channel", err);
        setDeleteErrorMessage("There is an error while deleting the channel");
        setShowSpinner(false);
      }
    }
  }, [channelDetails, navigate, handleOnEditClick]);

  const handleEditComplete = React.useCallback(() => {
    getChannelDetails();
    setSaveEditChannelForm(0);
    setShowEditChannelForm(false);
  }, [getChannelDetails]);

  const handleOnStreamReady = React.useCallback(() => {
    getChannelDetails();
  }, [getChannelDetails]);

  const handleOnStreamEnd = React.useCallback(() => {
    getChannelDetails();
  }, [getChannelDetails]);

  if (!channelDetails) {
    return (
      <Styled.SpinnerWrapper>
        <Spinner animation="border">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <p>Fetching channel details...</p>
      </Styled.SpinnerWrapper>
    );
  }

  return (
    <Styled.LiveChannelsDetailsContainer fluid>
      <Styled.HeaderRow>
        <Col md={8}>
          <Styled.Title>Channel Details</Styled.Title>
        </Col>

        <Col md={4}>
          <Row>
            <Col md={4}>
              <BaseButton onClick={handleBackClick} variant="secondary">
                {showEditChannelForm ? "Cancel" : "Back"}
              </BaseButton>
            </Col>
            <Col md={4}>
              <BaseButton
                onClick={handleOnEditClick}
                variant="primary"
              // disabled={!!channelDetails.stream.id}
              >
                {showEditChannelForm ? "Save" : "Edit"}
              </BaseButton>
            </Col>
            {!showEditChannelForm && (
              <Col md={4}>
                <BsButton variant="danger" onClick={handleDeleteClick}>
                  {showSpinner && <Spinner size="sm" />} Delete
                </BsButton>
              </Col>
            )}
          </Row>
        </Col>
      </Styled.HeaderRow>
      {showEditChannelForm ? (
        <EditChannel
          channelData={{ ...channelDetails, id: id! }}
          onCancel={() => {
            setShowEditChannelForm(false);
            setSaveEditChannelForm(0);
          }}
          onEditComplete={handleEditComplete}
          saveEditChannel={saveEditChannelForm}
        />
      ) : (
        <>
          <Container fluid>
            {deleteErrorMessage && (
              <Row>
                <Col>
                  <Alert
                    variant="danger"
                    onClose={() => setDeleteErrorMessage("")}
                    dismissible
                  >
                    {deleteErrorMessage}{" "}
                  </Alert>
                </Col>
              </Row>
            )}
            <Styled.DetailRow>
              <Col md={3}>
                <Styled.DetailLabel>ID</Styled.DetailLabel>
                <Styled.DetailText>{channelDetails?.id}</Styled.DetailText>
              </Col>
              <Col md={3}>
                <Styled.DetailLabel>Name</Styled.DetailLabel>
                <Styled.DetailText>
                  {channelDetails?.channelName}
                </Styled.DetailText>
              </Col>
              <Col md={3}>
                <Styled.DetailLabel>Type</Styled.DetailLabel>
                <Styled.DetailText>
                  Standard (broadcast and deliver live video up to Full HD, with
                  transcoding)
                </Styled.DetailText>
              </Col>
            </Styled.DetailRow>
            <Styled.DetailRow>
              <Col md={3}>
                <Styled.DetailLabel>Latency</Styled.DetailLabel>
                <Styled.DetailText>
                  Ultra-low (best for near real-time interactions with viewers)
                </Styled.DetailText>
              </Col>
              <Col md={3}>
                <Styled.DetailLabel>Authorization</Styled.DetailLabel>
                <Styled.DetailText>
                  {channelDetails?.isPrivate ? "Private" : "Public"}
                </Styled.DetailText>
              </Col>
              <Col md={3}>
                <Styled.DetailLabel>Auto-Recording Enabled</Styled.DetailLabel>
                <Styled.DetailText>
                  {channelDetails?.recordingEnabled ? "Yes" : "No"}
                </Styled.DetailText>
              </Col>
            </Styled.DetailRow>
          </Container>
          <Container fluid>
            <Styled.StreamDetailRow>
              <Col>
                <Styled.StreamTitle>Stream Information</Styled.StreamTitle>
              </Col>
            </Styled.StreamDetailRow>
            <Row>
              <Col md={6}>
                <Styled.DetailLabel>RTMPS Ingest Endpoint</Styled.DetailLabel>

                <Styled.DetailText>
                  {`rtmps://${channelDetails?.ingestEndpoint}:443/app/`}
                </Styled.DetailText>
                <br />

                <Styled.DetailLabel>RTMP Ingest Endpoint</Styled.DetailLabel>

                <Styled.DetailText>
                  {`rtmp://${channelDetails?.ingestEndpoint}/app/`}
                </Styled.DetailText>
                <br />
                <Styled.DetailText>
                  We support both RTMPS and RTMP streams, please use the format
                  supported by your encoder.
                </Styled.DetailText>
              </Col>
              <Col md={6}>
                <Styled.DetailLabel>Stream Key</Styled.DetailLabel>
                <Styled.DetailText>
                  {channelDetails?.streamKey}
                </Styled.DetailText>
              </Col>
            </Row>
            <Styled.PlayBackUrlRow>
              <Col>
                <Styled.DetailLabel>Playback URL</Styled.DetailLabel>
                <Styled.DetailText>
                  {channelDetails?.playbackUrl}
                </Styled.DetailText>
              </Col>
            </Styled.PlayBackUrlRow>
            {channelDetails?.sponsorLogo && (
              <Styled.PlayBackUrlRow>
                <Col>
                  <Styled.DetailLabel>Sponsor Logo</Styled.DetailLabel>
                  <Image
                    src={channelDetails?.sponsorLogo}
                    height={100}
                    width={100}
                  />
                </Col>
              </Styled.PlayBackUrlRow>
            )}
          </Container>

          <Container fluid>
            <Row>
              <Styled.DetailLabelHeader>Live Preview</Styled.DetailLabelHeader>
              <IVSPlayer
                channelId={channelDetails.id}
                playbackUrl={channelDetails.playbackUrl}
                playbackToken={channelDetails.playbackToken}
                isPrivate={channelDetails.isPrivate}
                onReady={handleOnStreamReady}
                onEnd={handleOnStreamEnd}
              />
            </Row>

            <Styled.PlayBackUrlRow>
              <Col>
                <Styled.DetailLabel>State</Styled.DetailLabel>
                <Styled.DetailText>
                  {channelDetails?.stream.state
                    ? channelDetails?.stream.state
                    : "-"}
                </Styled.DetailText>
              </Col>
              <Col>
                <Styled.DetailLabel>Health</Styled.DetailLabel>
                <Styled.DetailText>
                  {channelDetails?.stream.state
                    ? channelDetails?.stream.health
                    : "-"}
                </Styled.DetailText>
              </Col>
              <Col>
                <Styled.DetailLabel>Duration</Styled.DetailLabel>
                <Styled.DetailText>
                  {channelDetails?.stream.duration
                    ? channelDetails?.stream.duration
                    : "-"}
                </Styled.DetailText>
              </Col>
              <Col>
                <Styled.DetailLabel>Viewers</Styled.DetailLabel>
                <Styled.DetailText>
                  {channelDetails?.stream.viewerCount
                    ? channelDetails?.stream.viewerCount
                    : "-"}
                </Styled.DetailText>
              </Col>
            </Styled.PlayBackUrlRow>
          </Container>
          {channelDetails?.planDetails && (
            <Container fluid>
              <Styled.DetailLabelHeader>Payment Plans</Styled.DetailLabelHeader>
              <Row>
                <Col md={3}>
                  <Styled.DetailLabel>Plan Id</Styled.DetailLabel>
                </Col>
                <Col md={3}>
                  <Styled.DetailLabel>Plan Name</Styled.DetailLabel>
                </Col>

                <Col md={3}>
                  <Styled.DetailLabel>Price</Styled.DetailLabel>
                </Col>
                <Col md={3}>
                  <Styled.DetailLabel>Expiry Date</Styled.DetailLabel>
                </Col>
              </Row>
              {channelDetails?.planDetails.map((plan, index) => {
                return (
                  <Row key={index}>
                    <Col md={3}>
                      <Styled.DetailText>{plan?.id}</Styled.DetailText>
                    </Col>
                    <Col md={3}>
                      <Styled.DetailText>{plan?.name}</Styled.DetailText>
                    </Col>

                    <Col md={3}>
                      <Styled.DetailText>
                        {"$" +
                          plan.amount
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                      </Styled.DetailText>
                    </Col>
                    <Col md={3}>
                      <Styled.DetailText>
                        {plan?.expiration
                          ? !isNaN(Date.parse(plan?.expiration))
                            ? new Date(plan?.expiration).toLocaleDateString()
                            : plan?.expiration
                          : "NONE"}
                      </Styled.DetailText>
                    </Col>
                  </Row>
                );
              })}
            </Container>
          )}
        </>
      )}
    </Styled.LiveChannelsDetailsContainer>
  );
};
