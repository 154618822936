import React from "react";
import { Col, Spinner } from "react-bootstrap";
import * as Yup from "yup";

import { useAuth } from "../../../behavioral";
import {
    BaseButton,
    Error,
    ROUTEPATHS,
    RegistrationStatus,
    FloatingTextInput as TextInput,
} from "../../../libs";
import { RegistrationSteps } from "../registration-steps";

import { AddressElement, Elements, useElements } from "@stripe/react-stripe-js";
import { useFormik } from "formik";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";
import { CountryCodesList } from "../../../data";
import { userService } from "../../../services";
import { VerifyAccountModal } from "../../verify-account-modal";
import * as Styled from "./Registration.styled";
import { loadStripe, StripeError } from "@stripe/stripe-js";
import { PaymentElementLocal } from "./payment-element-local";
import IconCaratDown from "../../../assets/images/icons/icon-carat-down.svg";

const stripeNew = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? "");

export const Registration: React.FC<{
    priceId: string;
    packageId: string;
    userId?: string;
    email?: string;
    name?: string;
    status?: RegistrationStatus;
}> = ({ priceId, packageId, userId, email, name, status }) => {
    const elements = useElements();
    const navigate = useNavigate();
    const {
        signUp,
        confirmAccount,
        signIn,
        unverifiedAccount,
        resendConfirmationCode,
    } = useAuth();

    const [currentStatus, setCurrentStatus] = React.useState(
        status ?? RegistrationStatus.ACCOUNT_INFO,
    );
    const [errorMessage, setErrorMessage] = React.useState();
    const [signUpFailureMessage, setSignUpFailureMessage] = React.useState("");
    const [clientSecret, setClientSecret] = React.useState("");
    const [initialAmountPayable, setInitialAmountPayable] = React.useState(0);
    const [finalAmountPayable, setFinalAmountPayable] = React.useState(0);
    const [showPaymentDetails, setShowPaymentDetails] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            name: name ?? "",
            email: email ?? "",
            password: "",
            countryCode: "US",
            phoneNumber: "",
            promoCode: "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required(
                "Please enter a name or an organization name",
            ),
            email: Yup.string()
                .email("Please provide a valid email address")
                .required("Please enter an email address"),
            password: Yup.string()
                .min(8, "Password should contain minmum of 8 characters.")
                .required("Please enter a password"),
            countryCode: Yup.string().required("Please select a country code"),
            phoneNumber: Yup.string().required("Please enter a phone number"),
        }),
        onSubmit: async (values) => {
            await handleRegisterUser();
        },
    });

    const handleError = (error: any) => {
        setErrorMessage(error.message);
    };

    const onAddressSubmit = React.useCallback(async () => {
        if (!elements) {
            return null;
        }
        const addressElement = elements.getElement("address");

        if (!addressElement) {
            return;
        }

        let promoCodeId = "";
        if (formik.values.promoCode) {
            promoCodeId = await userService.getPromoCodeId(
                formik.values.promoCode,
            );
            if (promoCodeId.includes("No matching")) {
                formik.setFieldError("promoCode", "Invalid Promo Code");
                return;
            }
        }

        const { complete, value } = await addressElement.getValue();
        if (complete) {
            if (!clientSecret) {
                const response = await userService.createUserSubscription({
                    ...value,
                    email: formik.values.email, // TODO: how to get user email and name easily instead of passing it query params
                    name: formik.values.name,
                    priceId: priceId,
                    userId: userId ?? unverifiedAccount.userId,
                    packageId,
                    promoCode: promoCodeId || undefined,
                });
                // in case of any error on payment confirm don't trigger api call & use same client secret
                setClientSecret(response.clientSecret);
                setFinalAmountPayable(response.amount_paid);
                setInitialAmountPayable(response.plan_amount);
            }

            setCurrentStatus(RegistrationStatus.PAYMENT_INFO);
        }
    }, [
        elements,
        priceId,
        userId,
        packageId,
        clientSecret,
        unverifiedAccount.userId,
        formik,
    ]);

    const handleConfirmSuccess = React.useCallback((onClose: () => void) => {
        setCurrentStatus(RegistrationStatus.BILLING_INFO);
        onClose();
    }, []);

    const handleVerifyAccountShowModal = React.useCallback(() => {
        confirmAlert({
            childrenElement: () => <div />,
            customUI: ({ onClose }) => {
                return (
                    <VerifyAccountModal
                        onClose={onClose}
                        unverifiedEmail={formik.values.email}
                        onConfirmAccount={confirmAccount}
                        onConfirmSuccessContinue={() =>
                            handleConfirmSuccess(onClose)
                        }
                        onResendCode={resendConfirmationCode}
                    />
                );
            },
            closeOnClickOutside: false,
        });
    }, [
        formik.values.email,
        confirmAccount,
        handleConfirmSuccess,
        resendConfirmationCode,
    ]);

    const handleRegisterUser = React.useCallback(async () => {
        const { name, email, password, countryCode, phoneNumber } =
            formik.values;
        const code = CountryCodesList.find(
            (item) => item.code === countryCode,
        )?.dial_code;
        try {
            await signUp({
                name: name,
                phoneNumber: `${code}${phoneNumber}`,
                email: email,
                password: password,
            });

            handleVerifyAccountShowModal();
        } catch (err: any) {
            switch (err.code) {
                case "InvalidPasswordException":
                    setSignUpFailureMessage(
                        "Password did not conform with policy, please refer to the policy.",
                    );
                    break;
                case "UsernameExistsException":
                    setSignUpFailureMessage(
                        "An account with the given email already exists.",
                    );
                    break;
                default:
                    setSignUpFailureMessage(
                        "There is an error while creating the account, please try again after sometime",
                    );
            }
        }
    }, [formik.values, handleVerifyAccountShowModal, signUp]);

    const isDisabled = React.useMemo(() => {
        // disable when submitting is true
        if (formik.isSubmitting) {
            return true;
        }

        if (
            !formik.values.email ||
            !formik.values.password ||
            !formik.values.name ||
            !formik.values.phoneNumber
        ) {
            return true;
        }

        if (!formik.isValid) {
            return true;
        }
    }, [formik]);

    const handlePaymentProcessingFinish = React.useCallback(
        async (error: StripeError | undefined) => {
            try {
                if (error) {
                    // This point is only reached if there's an immediate error when confirming the Intent.
                    // Show the error to your customer (for example, "payment details incomplete").
                    handleError(error);
                } else {
                    // Your customer is redirected to your `return_url`. For some payment
                    // methods like iDEAL, your customer is redirected to an intermediate
                    // site first to authorize the payment, then redirected to the `return_url`.
                    await userService.confirmSubscription({
                        userId: userId ?? unverifiedAccount.userId,
                    });
                    if (!userId) {
                        await signIn({
                            email: unverifiedAccount.email,
                            password: unverifiedAccount.password,
                        });
                    }
                    navigate(
                        { pathname: `${ROUTEPATHS.DASHBOARD}` },
                        { replace: true },
                    );
                }
            } catch (err) {
                console.log(err);
                handleError(
                    "There is an error while processing your request. Please try again,",
                );
            }
        },
        [
            userId,
            unverifiedAccount.userId,
            unverifiedAccount.email,
            unverifiedAccount.password,
            signIn,
            navigate,
        ],
    );

    const REGISTRATION_CONTENT: {
        [key in RegistrationStatus]: React.ReactNode;
    } = {
        [RegistrationStatus.ACCOUNT_INFO]: (
            <>
                <Styled.RegistrationForm
                    autoComplete="off"
                    onSubmit={formik.handleSubmit}
                >
                    <Styled.RegisterFormRow>
                        <Col md={7}>
                            {signUpFailureMessage && (
                                <Error message={signUpFailureMessage} />
                            )}
                        </Col>
                    </Styled.RegisterFormRow>
                    <Styled.RegisterFormRow>
                        <Col md={7}>
                            <TextInput
                                label="Name/Organization"
                                placeholder="Name/Organization"
                                controlId="name"
                                value={formik.values.name}
                                onChangeHandler={formik.handleChange}
                                onBlurHandler={formik.handleBlur}
                                hasError={
                                    formik.touched.name && !!formik.errors.name
                                }
                            />
                            {formik.touched.name && formik.errors.name && (
                                <Error message={formik.errors.name} />
                            )}
                        </Col>
                    </Styled.RegisterFormRow>
                    <Styled.RegisterFormRow>
                        <Col md={7}>
                            <TextInput
                                label="Email"
                                type="email"
                                placeholder="Email"
                                controlId="email"
                                value={formik.values.email}
                                onChangeHandler={formik.handleChange}
                                onBlurHandler={formik.handleBlur}
                                hasError={
                                    formik.touched.email &&
                                    !!formik.errors.email
                                }
                            />
                            {formik.touched.email && formik.errors.email && (
                                <Error message={formik.errors.email} />
                            )}
                        </Col>
                    </Styled.RegisterFormRow>

                    <Styled.RegisterFormRow>
                        <Col md={7}>
                            <TextInput
                                label="Password"
                                type="password"
                                placeholder="Password"
                                controlId="password"
                                value={formik.values.password}
                                onChangeHandler={formik.handleChange}
                                onBlurHandler={formik.handleBlur}
                                hasError={
                                    formik.touched.password &&
                                    !!formik.errors.password
                                }
                            />
                            {formik.touched.password &&
                                formik.errors.password && (
                                    <Error message={formik.errors.password} />
                                )}
                            <Styled.PasswordMutedText
                                id="passwordHelpBlock"
                                muted
                            >
                                Your password must be 6-20 characters long,
                                contain a lower case letter, an upper case
                                letter, a number, a special character and must
                                not contain a leading or trailing space
                            </Styled.PasswordMutedText>
                        </Col>
                    </Styled.RegisterFormRow>

                    <Styled.RegisterFormRow>
                        <Col md={2}>
                            <Styled.CountryCodeSelect
                                aria-label="Default select example"
                                size="sm"
                                value={formik.values.countryCode}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="countryCode"
                                id="countryCode"
                            >
                                <option>Select Code</option>
                                {CountryCodesList.map((el) => (
                                    <option key={el.name} value={el.code}>
                                        {el.code} ({el.dial_code})
                                    </option>
                                ))}
                            </Styled.CountryCodeSelect>
                        </Col>
                        <Col md={5}>
                            <TextInput
                                label="Phone Number"
                                type="text"
                                placeholder="Phone Number"
                                controlId="phoneNumber"
                                value={formik.values.phoneNumber}
                                onChangeHandler={formik.handleChange}
                                onBlurHandler={formik.handleBlur}
                                hasError={
                                    formik.touched.phoneNumber &&
                                    !!formik.errors.phoneNumber
                                }
                            />
                            {formik.touched.phoneNumber &&
                                formik.errors.phoneNumber && (
                                    <Error
                                        message={formik.errors.phoneNumber}
                                    />
                                )}
                        </Col>
                    </Styled.RegisterFormRow>

                    <Styled.RegisterFormRow>
                        <Col md={7}>
                            <Styled.TermsText>
                                By joining Cemboo, you agree to our{" "}
                                <Styled.TermsTextLink>
                                    Terms of Service{" "}
                                </Styled.TermsTextLink>
                                ,{" "}
                                <Styled.TermsTextLink>
                                    Privacy Policy{" "}
                                </Styled.TermsTextLink>
                                and{" "}
                                <Styled.TermsTextLink>
                                    Cookie Policy
                                </Styled.TermsTextLink>
                                .
                            </Styled.TermsText>
                        </Col>
                    </Styled.RegisterFormRow>

                    <Styled.RegisterFormRow>
                        <Col md={7}>
                            <BaseButton
                                type="submit"
                                variant={isDisabled ? "secondary" : "primary"}
                                disabled={isDisabled}
                            >
                                {formik.isSubmitting && <Spinner size="sm" />}
                                Continue
                            </BaseButton>
                        </Col>
                    </Styled.RegisterFormRow>
                </Styled.RegistrationForm>
            </>
        ),
        [RegistrationStatus.BILLING_INFO]: (
            <Styled.RegistrationForm>
                <Styled.RegisterFormRow>
                    <Col md={6}>
                        <AddressElement
                            options={{
                                mode: "billing",
                                defaultValues: {
                                    name: name ?? formik.values.name,
                                },
                            }}
                        />
                        <Styled.PromoCodeSpacer />
                        {!!formik.errors.promoCode && (
                            <Error message={formik.errors.promoCode} />
                        )}
                        <Styled.PromoCodeInputWrapper>
                            <TextInput
                                label="Promo Code (Optional)"
                                placeholder="Promo Code"
                                controlId="promoCode"
                                value={formik.values.promoCode}
                                onChangeHandler={formik.handleChange}
                                hasError={!!formik.errors.promoCode}
                            />
                        </Styled.PromoCodeInputWrapper>
                    </Col>
                </Styled.RegisterFormRow>
                <Styled.RegisterFormRow>
                    <Col md={6}>
                        <BaseButton
                            type="button"
                            variant={"primary"}
                            onClick={onAddressSubmit}
                        >
                            Continue
                        </BaseButton>
                    </Col>
                </Styled.RegisterFormRow>
            </Styled.RegistrationForm>
        ),
        [RegistrationStatus.PAYMENT_INFO]: (
            <Elements
                stripe={stripeNew}
                options={{
                    clientSecret: clientSecret,
                    appearance: {
                        theme: "night",
                        variables: {
                            fontFamily: "Open Sans, system-ui, sans-serif",
                            borderRadius: "0.375rem",
                            colorBackground: "#1E2025",
                            colorText: "#FFFFFF",
                        },
                        labels: "floating",
                        rules: {
                            ".Input": {
                                boxShadow: "none",
                                border: "none",
                                height: "75px",
                            },
                            ".Input:focus": {
                                outline: "none",
                                boxShadow: "none",
                            },
                        },
                    },
                }}
            >
                <Styled.FinalAmountRow>
                    <Styled.FinalAmountContainer md={6}>
                        <Styled.FinalAmountWrapper>
                            <Styled.FinalAmountSummary>
                                <Styled.FinalAmountText>
                                    Final Amount Payable
                                </Styled.FinalAmountText>
                                <Styled.FinalAmountPriceWrapper>
                                    <Styled.FinalAmountPrice>
                                        ${finalAmountPayable}
                                    </Styled.FinalAmountPrice>
                                    <Styled.ToggleIcon
                                        src={IconCaratDown}
                                        isOpen={showPaymentDetails}
                                        onClick={() =>
                                            setShowPaymentDetails(
                                                !showPaymentDetails,
                                            )
                                        }
                                    />
                                </Styled.FinalAmountPriceWrapper>
                            </Styled.FinalAmountSummary>
                            <Styled.AmountDetails isOpen={showPaymentDetails}>
                                <Styled.AmountDetailsText className="mt-2">
                                    <div>Subscription Fee</div>
                                    <div>${initialAmountPayable}</div>
                                </Styled.AmountDetailsText>
                                {formik.values.promoCode && (
                                    <Styled.AmountDetailsText>
                                        <div>Promo Code Discount</div>
                                        <div>
                                            -$
                                            {initialAmountPayable -
                                                finalAmountPayable}
                                        </div>
                                    </Styled.AmountDetailsText>
                                )}
                                <Styled.AmountDetailsText>
                                    <div>Tax</div>
                                    <div>$0</div>
                                </Styled.AmountDetailsText>
                                <Styled.BreakLine />
                                <Styled.AmountDetailsText>
                                    <div>Total</div>
                                    <div>${finalAmountPayable}</div>
                                </Styled.AmountDetailsText>
                            </Styled.AmountDetails>
                        </Styled.FinalAmountWrapper>
                    </Styled.FinalAmountContainer>
                </Styled.FinalAmountRow>
                <PaymentElementLocal
                    finishProcessing={handlePaymentProcessingFinish}
                    clientSecret={clientSecret}
                />
            </Elements>
        ),
    };

    return (
        <Styled.RegistrationWrapper>
            <RegistrationSteps currentStatus={currentStatus} />
            <Styled.RegistrationFormWrapper>
                <Styled.RegistrationFormHeader>
                    {currentStatus === RegistrationStatus.ACCOUNT_INFO
                        ? "Join Cemboo"
                        : currentStatus === RegistrationStatus.BILLING_INFO
                          ? "Billing Information"
                          : "Payment Details"}
                </Styled.RegistrationFormHeader>
                {currentStatus === RegistrationStatus.ACCOUNT_INFO && (
                    <Styled.LoginText>
                        Already have an account?{" "}
                        <Styled.LoginLink href="/login">
                            Log In
                        </Styled.LoginLink>
                    </Styled.LoginText>
                )}

                {errorMessage && <Error message={errorMessage} />}
                {REGISTRATION_CONTENT[currentStatus]}
            </Styled.RegistrationFormWrapper>
        </Styled.RegistrationWrapper>
    );
};
