import { Card, CardGroup } from "react-bootstrap";
import styled from "styled-components";
import { colors, types } from "../../../../libs";

export const PackagesCardGroup = styled(CardGroup)`
  gap: 1.25rem;
  flex-wrap: wrap;
`;

export const PackageCard = styled(Card)`
  min-height: 200px;
  max-width: 20rem;
  border: 1px solid ${colors.background.darkGreen} !important;
  border-radius: var(--bs-border-radius) !important;
  cursor: pointer;
`;

export const AddPackageCard = styled(PackageCard)`
  border: 1px solid ${colors.background.darkGreen} !important;
  border-radius: var(--bs-border-radius) !important;
  background: ${colors.background.linearGradient};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.text.mediumWhite};
`;

export const CardTitle = styled(Card.Title)`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  font-size: clamp(1.5rem, 1.3333rem + 0.8889vw, 2rem);
`;

export const PriceLabel = styled.h3`
  margin-bottom: 5px;
  &.price {
    font-size: clamp(1.5rem, 1.3333rem + 0.8889vw, 2rem);
    font-weight: 700;
    color: var(--color-primary);

    & span {
      font-size: clamp(1rem, 0.8333rem + 0.8889vw, 1.5rem);
      font-weight: 400;
      opacity: 0.7;
    }
  }
`;

export const FeaturesList = styled.ul`
  padding: 5px 10px;
`;

export const FeatureListItem = styled.li`
  list-style-type: initial;
  margin: 5px 10px;
`;

export const AddIconWrapper = styled.div`
  height: 50px;
  width: 50px;
  background: ${colors.background.black};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
`;

export const AddIconText = styled.div`
  color: ${colors.text.mediumWhite};
  ${types.body.title};
`;
