import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { colors, types } from "../../libs";

export const DashboardPageWrapper = styled.div`
    background: rgba(0, 0, 0);
    min-height: 100vh;
`;

export const DashboardContainer = styled(Container)`
    height: calc(100vh -72px);
    margin-top: 70px;
`;

export const DashboardSectionWrapper = styled(Row)`
    color: #fff;
    z-index: 1;
    padding: 0 10px;
`;

export const WelcomeHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Title = styled.h2`
    ${types.headers.secondary};
    background: ${colors.text.textGradient};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 10px;
`;

export const DashboardSectionSideMenuWrapper = styled(Col)`
    padding: 10px;
    background: ${colors.background.lightBlack};
    position: fixed;
    z-index: 1;
    height: calc(100% - 70px);
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media screen and (max-width: 1460px) {
        overflow-y: auto;
        overflow-x: hidden;
    }
`;

export const DashboardSectionMainWrapper = styled(Col)`
    padding: 25px;
`;
