import * as React from "react";

import { liveChannelService } from "../../services";
import * as Styled from "./IVSPlayer.styled";

interface IProps {
  playbackUrl: string;
  channelId: string;
  isPrivate?: boolean;
  onReady: () => void;
  onEnd: () => void;
  playbackToken?: string;
}

export const IVSPlayer: React.FC<IProps> = ({
  isPrivate,
  channelId,
  playbackUrl,
  onReady,
  onEnd,
  playbackToken,
}) => {
  const videoPlayerRef = React.useRef<HTMLVideoElement>(null);
  const { IVSPlayer: DefaultIVSPlayer } = window as any;

  const PlayerState = DefaultIVSPlayer.PlayerState;
  const PlayerEventType = DefaultIVSPlayer.PlayerEventType;

  const [isPlaying, setIsPlaying] = React.useState(false);

  const loadStream = React.useCallback(
    (playbackUrl: string, token?: string) => {
      const streamUrl = token ? `${playbackUrl}?token=${token}` : playbackUrl;
      const ivsPlayer = DefaultIVSPlayer.create();
      ivsPlayer.attachHTMLVideoElement(videoPlayerRef.current);
      ivsPlayer.load(streamUrl);
      ivsPlayer.play();
      ivsPlayer.addEventListener(PlayerState.PLAYING, function () {
        console.log("Player State - PLAYING");
        setIsPlaying(true);
        onReady();
      });
      ivsPlayer.addEventListener(PlayerState.ENDED, function () {
        console.log("Player State - ENDED");
        setIsPlaying(false);
        ivsPlayer.load("");
        onEnd();
      });
      ivsPlayer.addEventListener(PlayerState.READY, function () {
        console.log("Player State - READY");
      });

      ivsPlayer.addEventListener(PlayerState.PAUSED, function () {
        console.log("Player State - PAUSED");
      });
      ivsPlayer.addEventListener(PlayerEventType.ERROR, (error: any) => {
        console.log(error);
      });
    },
    [
      DefaultIVSPlayer,
      PlayerEventType.ERROR,
      PlayerState.ENDED,
      PlayerState.PLAYING,
      PlayerState.READY,
      PlayerState.PAUSED,
      onEnd,
      onReady,
    ]
  );

  const handlePlayStream = React.useCallback(async () => {
    if (isPlaying) {
      return;
    }

    if (isPrivate) {
      // send request to get playback token
      try {
        const { token } = await liveChannelService.getPlaybackToken(channelId);
        loadStream(playbackUrl, token);
      } catch (err) {
        console.log(err);
      }
    } else {
      loadStream(playbackUrl, playbackToken);
    }
  }, [channelId, isPrivate, loadStream, playbackToken, playbackUrl, isPlaying]);

  return (
    <Styled.PlayerWrapper>
      <Styled.Player ref={videoPlayerRef} crossOrigin="anonymous" controls />

      {!isPlaying && (
        <Styled.IconWrapper onClick={handlePlayStream}>
          <Styled.PlayIcon className="fas fa-play"></Styled.PlayIcon>
        </Styled.IconWrapper>
      )}
    </Styled.PlayerWrapper>
  );
};
