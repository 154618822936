import axios from "axios";
import {
  API_BASE_PATHS,
  API_ROUTES,
  ILiveChannel,
  ILiveChannelDetails,
  ILiveChannelRequest,
  IRecording,
} from "../libs";
import { buildRequestConfig } from "./buildRequestConfig";

class LiveChannelsService {
  async getAllLiveChannels(): Promise<ILiveChannel[]> {
    try {
      const config = await buildRequestConfig();
      const response = await axios.get(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.STREAM_API}/${API_ROUTES.CHANNELS}`,
        { ...config }
      );

      return response.data.data;
    } catch (err: any) {
      console.log("There is an error while fetching all live channels", err);
      throw new Error(err);
    }
  }

  async getLiveChannelById(channelId: string): Promise<ILiveChannelDetails> {
    try {
      const config = await buildRequestConfig();
      const response = await axios.get(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.STREAM_API}/${API_ROUTES.CHANNEL}/${channelId}`,
        { ...config }
      );

      return response.data.data;
    } catch (err: any) {
      console.log(
        "There is an error while fetching the channel information",
        err
      );
      throw new Error(err);
    }
  }

  async createLiveChannel(channelRequest: ILiveChannelRequest): Promise<any> {
    try {
      const config = await buildRequestConfig();
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.STREAM_API}/${API_ROUTES.CHANNEL}`,
        channelRequest,
        { ...config }
      );

      return response.data.data;
    } catch (err: any) {
      console.log("There is an error while creating a channel", err);
      throw new Error(err);
    }
  }

  async updateLiveChannel(
    channelId: string,
    channelRequest: ILiveChannelRequest
  ): Promise<any> {
    try {
      const config = await buildRequestConfig();
      const response = await axios.put(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.STREAM_API}/${API_ROUTES.CHANNEL}/${channelId}`,
        channelRequest,
        { ...config }
      );

      return response.data.data;
    } catch (err: any) {
      console.log("There is an error while updating the channel", err);
      throw new Error(err);
    }
  }

  async deleteLiveChannel(channelId: string): Promise<any> {
    try {
      const config = await buildRequestConfig();
      const response = await axios.delete(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.STREAM_API}/${API_ROUTES.CHANNEL}/${channelId}`,

        { ...config }
      );

      return response.data.data;
    } catch (err: any) {
      console.log("There is an error while deleting the channel", err);
      throw new Error(err);
    }
  }

  async getPlaybackToken(channelId: string): Promise<{ token: string }> {
    try {
      const config = await buildRequestConfig();
      const response = await axios.get(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.STREAM_API}/${API_ROUTES.CHANNEL}/${channelId}/token`,
        { ...config }
      );

      return response.data.data;
    } catch (err: any) {
      console.log("There is an error while fetching playback token", err);
      throw new Error(err);
    }
  }

  async getRecordings(): Promise<{ recordings: IRecording[] }> {
    try {
      const config = await buildRequestConfig();
      const response = await axios.get(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.STREAM_API}/recordings`,
        { ...config }
      );

      return response.data.data;
    } catch (err: any) {
      console.log("There is an error while fetching playback token", err);
      throw new Error(err);
    }
  }

  async getDownloadLinkForRecording(
    recordingId: string,
    channelId: string
  ): Promise<{ downloadUrl: string }> {
    try {
      const config = await buildRequestConfig();
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.STREAM_API}/recordings/${recordingId}/download`,
        { channelId },
        { ...config }
      );

      return response.data.data;
    } catch (err: any) {
      console.log(
        "There is an error while fetching download url for the recording",
        err
      );
      throw new Error(err);
    }
  }

  async getUpcomingEvents(): Promise<any[]> {
    try {
      const config = await buildRequestConfig();
      const response = await axios.get(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.STREAM_API}/${API_ROUTES.GET_EVENTS}`,
        { ...config }
      );

      return response.data.data;
    } catch (err: any) {
      console.log("There is an error while fetching upcoming channels", err);
      throw new Error(err);
    }
  }

  async deleteRecording(recordingId: string, channelId: string): Promise<any> {
    try {
      const config = await buildRequestConfig();
      const response = await axios.delete(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.STREAM_API}/channel/${channelId}/recording?recordingId=${recordingId}`,
        { ...config }
      );

      return response.data.data;
    } catch (err: any) {
      console.log("There is an error while deleting the recording", err);
      throw new Error(err);
    }

  }
}

export const liveChannelService = new LiveChannelsService();
