import { Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { colors, types } from "../../../libs";

export const ChangePasswordPageHeader = styled.h1`
  ${types.headers.section};
  color: ${colors.text.white};
`;

export const ConfirmCodeText = styled.p`
  ${types.body.normal};

  margin: 0 auto;
  color: ${colors.text.mediumWhite};
`;

export const ChangePasswordFormWrapper = styled.div`
  margin-top: 20px;
  padding: 10px 0;
  flex: 1;
`;

export const ChangePasswordFormRow = styled(Row)`
  margin: 10px 0;
`;

export const ChangePasswordForm = styled(Form)`
  margin-top: 20px;
`;

export const ChangePasswordFormCol = styled(Col)`
  padding: 0;
  text-align: right;
`;

export const ForgotPasswordText = styled.span`
  ${types.body.normal};
  color: ${colors.text.mediumWhite};
  cursor: pointer;
`;
