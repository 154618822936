import { Row, Card } from "react-bootstrap";
import styled from "styled-components";
import { types } from "../../../../libs";

export const AdServerItemWrapper = styled(Card)`
  background: #0b0b0b;
  padding: 20px;
  margin: 20px 0 50px 0;
`;

export const BannerTitle = styled.h4`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.04em;
  color: #ffffff;
`;

export const BannerIndex = styled.div`
  width: 21px;
  height: 20px;
  color: #000;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #b7f84d;
`;

export const BannerTextRow = styled(Row)`
  margin: 20px 0;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
  font-size: 16px;
`;

export const CastItemWrapper = styled.div`
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.24);
  border-radius: 4px;
  margin-bottom: 10px;
  align-items: center;
`;

export const CastName = styled.h3`
  ${types.body.normal};
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
  margin-bottom: 0;
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7) !important;
  transition: all 0.3s ease-in-out;
`;