import { Col, Form, Row, Image, Stack } from "react-bootstrap";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import { colors, types } from "../../../libs";

import SwitchOffImage from "./../../..//assets/images/switch_off.svg";
import SwitchOnImage from "./../../../assets/images/switch_on.svg";

export const LiveChannelForm = styled(Form)`
  @media screen and (min-width: 1200px) {
    width: 50%;
  }
`;
export const LiveChannelFormFieldRow = styled(Row)`
  margin: 20px 0;
  padding: 0;
`;

export const LiveChannelFormFieldCol = styled(Col)`
  // ${types.body.normal};
`;

export const LiveChannelFormSwitch = styled(Form.Check)`
  display: inline-block;
  margin-left: 10px;
  .form-check-input {
    cursor: pointer;
    width: 40px;
    height: 20px;
    color: ${colors.text.white};
    background-color: #1e2025;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-image: url(${SwitchOffImage});
    &:focus {
      background-image: url(${SwitchOffImage});
      border-color: transparent;
      box-shadow: none;
      border: 1px solid rgba(255, 255, 255, 0.5);
    }
    &:checked {
      background-color: #b7f84d;
      border-color: #b7f84d;
      background-image: url(${SwitchOnImage});
    }
  }
`;

export const CustomDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 15px;
  border-radius: 0.25rem;
  background: #1e2025;
  outline: none;
  border: 1px solid transparent;
  color: #fff;

  &.error {
    border: 1px solid red;
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80vh;
  color: ${colors.background.green};
  & > .spinner-border {
    width: 5rem;
    height: 5rem;
  }
`;

export const AddEventHeader = styled.h3``;

export const ConnectAccountMessage = styled(Form.Text)`
  color: ${colors.text.mediumWhite};
`;

export const IconImage = styled(Image)`
  cursor: pointer;
  margin: 0 10px;
`;

export const SponsorLogoContainer = styled(Stack)`
  justify-content: space-between;
  background-color: #0b0b0b;
  padding: 10px;
`