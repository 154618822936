import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { types, colors } from "../../../libs";

export const StorageInsightsWrapper = styled(Row)``;

export const StorageInsightsCol = styled(Col)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StorageInsightsCard = styled.div<{
    highlight?: string;
}>`
    border: 2px solid
        ${(props) => props.highlight || colors.background.lightBlack};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    background-color: ${colors.background.lightBlack};
    padding: 1rem;
    max-height: 10rem;
`;

export const StorageInsightsCardRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StorageInsightsCardUsage = styled.div<{
    highlight?: string;
}>`
    ${types.body.title}
    color: ${(props) => props.highlight || colors.text.black};
`;

export const StorageInsightsCardCount = styled.div`
    ${types.body.secondaryTitle}
`;

export const StorageInsightsCardCategory = styled.div`
    font-size: 22px;
    font-weight: bold;
`;

export const StorageInsightsCardIcon = styled.img`
    width: 3rem;
    height: 3rem;
`;
