import styled from "styled-components";
import { Image } from "react-bootstrap";
import { types } from "../../../../libs";

export const FileName = styled.h3`
  ${types.body.normal};
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
  margin-bottom: 10px;
  word-wrap: break-word;
`;

export const IconImage = styled(Image)`
  cursor: pointer;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  height: 100%;
`;