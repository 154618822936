import styled from "styled-components";
import { colors } from "../../../libs";

export const NewPlaylistAlertContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${colors.background.lightBlack};
    min-width: 35rem;
    padding: 2rem;
`;

export const NewPlaylistAlertTitle = styled.h1`
    font-size: 1.5rem;
    font-weight: 600;
    width: 100%;
    margin-bottom: 1.25rem;
    color: ${colors.text.white};
`;

export const ButtonsContainer = styled.div`
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    width: 100%;
    margin-top: 1.25rem;
`;

export const ErrorText = styled.p`
    font-size: 0.75rem;
    width: 100%;
    margin: 10px 0 0 0;
    color: ${colors.text.error};
`;