import React from "react";
import * as Styled from "./ShowPlaylistsModalHeader.styled";
import {
    IPlaylistResponseDto,
    DropdownButton,
} from "../../../../../libs";
import IconArrowRight from "../../../../../assets/images/icons/icon-arrow-right.svg";
import ListViewIcon from "../../../../../assets/images/icons/icon-list-view.svg";
import { CreateModal } from "../../../create-modal";
import { CreateModalSteps } from "../../../create-modal/types";
import { playlistsService } from "../../../../../services";

interface IProps {
    playlist: IPlaylistResponseDto;
    onHide: () => void;
    refreshPlaylist: (deleted?: boolean) => void;
}

export const ShowPlaylistsModalHeader: React.FC<IProps> = ({
    playlist,
    onHide,
    refreshPlaylist,
}) => {
    const [show, setShow] = React.useState(false);
    const [editStep, setEditStep] = React.useState<CreateModalSteps>(
        CreateModalSteps.ORDER_SELECTION,
    );

    const thumbnailUrl = React.useMemo(() => {
        if (playlist.thumbnails.userUploaded)
            return `${playlist.thumbnails.userUploaded.cdnPath}/${playlist.thumbnails.userUploaded.s3Key}`;
        if (playlist.thumbnails.existing)
            return playlist.thumbnails.existing.url;
    }, [playlist.thumbnails]);

    const hideCallWrapper = React.useCallback(
        (deleted: boolean = false) => {
            setShow(false);
            refreshPlaylist(deleted);
        },
        [setShow, refreshPlaylist],
    );

    const confirmDelete = React.useCallback(async () => {
        await playlistsService.deletePlaylist(playlist.playlistId);
        hideCallWrapper(true);
    }, [playlist.playlistId, hideCallWrapper]);

    return (
        <Styled.ShowPlaylistModalHeaderRow>
            <Styled.ShowPlaylistModalHeaderCol md={1}>
                <Styled.ShowPlaylistModalHeaderBack onClick={onHide}>
                    <Styled.ShowPlaylistModalHeaderBackIcon
                        src={IconArrowRight}
                        alt="Back to playlists"
                    />
                    Back
                </Styled.ShowPlaylistModalHeaderBack>
            </Styled.ShowPlaylistModalHeaderCol>
            <Styled.ShowPlaylistModalHeaderMiddle md={10}>
                <Styled.ShowPlaylistModalHeaderContent>
                    <Styled.ShowPlaylistModalHeaderThumbnail
                        src={thumbnailUrl}
                    />
                    <Styled.ShowPlaylistModalHeaderText>
                        <Styled.ShowPlaylistModalHeaderTitle>
                            {playlist.title}
                        </Styled.ShowPlaylistModalHeaderTitle>
                        <Styled.ShowPlaylistModalHeaderSubtitle>
                            Playlist ID: {playlist.playlistId}
                        </Styled.ShowPlaylistModalHeaderSubtitle>
                    </Styled.ShowPlaylistModalHeaderText>
                </Styled.ShowPlaylistModalHeaderContent>
                <Styled.ShowPlaylistModalHeaderActions>
                    <Styled.DeleteButton
                        onClick={confirmDelete}
                        variant="error"
                    >
                        Delete Playlist
                    </Styled.DeleteButton>
                    <DropdownButton dropdownTitle="Edit Playlist">
                        <Styled.DropdownOptions
                            onClick={() => {
                                setEditStep(CreateModalSteps.VIDEO_SELECTION);
                                setShow(true);
                            }}
                        >
                            Add or Remove video(s)
                        </Styled.DropdownOptions>
                        <Styled.DropdownOptions
                            onClick={() => {
                                setEditStep(CreateModalSteps.ORDER_SELECTION);
                                setShow(true);
                            }}
                        >
                            Edit queue order
                        </Styled.DropdownOptions>
                        <Styled.DropdownOptions
                            onClick={() => {
                                setEditStep(CreateModalSteps.DETAILS);
                                setShow(true);
                            }}
                        >
                            Edit settings
                        </Styled.DropdownOptions>
                    </DropdownButton>
                    <Styled.ChangeViewContiner>
                        <Styled.ChangeViewIcon src={ListViewIcon} alt="" />
                    </Styled.ChangeViewContiner>
                </Styled.ShowPlaylistModalHeaderActions>
            </Styled.ShowPlaylistModalHeaderMiddle>
            <CreateModal
                show={show}
                onHide={hideCallWrapper}
                playlistName={playlist.title}
                playlist={playlist}
                editStep={editStep}
            />
        </Styled.ShowPlaylistModalHeaderRow>
    );
};
