import axios from "axios";
import { API_BASE_PATHS, API_ROUTES, IPlaylistRequestDto, IPlaylistResponseDto } from "../libs";
import { buildRequestConfig } from "./buildRequestConfig";

class PlaylistsService {
    async createPlaylist(playlist: Partial<IPlaylistRequestDto>) {
        try {
            const config = await buildRequestConfig();
            const response = await axios.post(
                `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.PLAYLISTS_API}/${API_ROUTES.PLAYLIST}`,
                playlist,
                { ...config }
            );

            return response.data.data;
        } catch (err: any) {
            console.log(
                "There is an error while saving playlist",
                err
            );
            throw new Error(err);
        }
    }

    async getAllPlaylists(): Promise<IPlaylistResponseDto[]> {
        try {
            const config = await buildRequestConfig();
            const response = await axios.get(
                `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.PLAYLISTS_API}/${API_ROUTES.PLAYLIST}`,
                { ...config }
            );

            return response.data.data;
        } catch (err: any) {
            console.log(
                "There is an error while fetching playlists",
                err
            );
            throw new Error(err);
        }
    }

    async getPlaylistById(playlistId: string): Promise<IPlaylistResponseDto> {
        try {
            const config = await buildRequestConfig();
            const response = await axios.get(
                `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.PLAYLISTS_API}/${API_ROUTES.PLAYLIST}/${playlistId}`,
                { ...config }
            );

            return response.data.data;
        } catch (err: any) {
            console.log(
                "There is an error while fetching playlist",
                err
            );
            throw new Error(err);
        }
    }

    async updatePlaylist(playlist: IPlaylistRequestDto, playlistId: string) {
        try {
            const config = await buildRequestConfig();
            const response = await axios.put(
                `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.PLAYLISTS_API}/${API_ROUTES.PLAYLIST}/${playlistId}`,
                playlist,
                { ...config }
            );

            return response.data.data;
        } catch (err: any) {
            console.log(
                "There is an error while updating playlist",
                err
            );
            throw new Error(err);
        }
    }

    async deletePlaylist(playlistId: string) {
        try {
            const config = await buildRequestConfig();
            const response = await axios.delete(
                `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.PLAYLISTS_API}/${API_ROUTES.PLAYLIST}/${playlistId}`,
                { ...config }
            );

            return response.data.data;
        } catch (err: any) {
            console.log(
                "There is an error while deleting playlist",
                err
            );
            throw new Error(err);
        }
    }
}

export const playlistsService = new PlaylistsService();