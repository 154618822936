import { LoginForm } from "../../components";
import { HeaderLogo } from "../../libs";

import CembooLogo from "./../../assets/images/logos/cemboo_logo.png";

import * as Styled from "./login-page.styled";

import { useNavigate } from "react-router-dom";

export const LoginPage = () => {
  const navigate = useNavigate();

  return (
    <Styled.LoginPageContainer>
      <Styled.LoginPageRow>
        <Styled.LoginFormContainer md={8}>
          <Styled.LoginFormHeader>
            <HeaderLogo />
            <Styled.LoginPageRightNavWrapper>
              <Styled.RegistrationLinkText>
                Don't have an account?{" "}
                <Styled.RegistrationLinkButton
                  variant="outline-secondary"
                  size="sm"
                  onClick={() => navigate("/register", { replace: true })}
                >
                  Sign Up
                </Styled.RegistrationLinkButton>
              </Styled.RegistrationLinkText>
            </Styled.LoginPageRightNavWrapper>
          </Styled.LoginFormHeader>
          <Styled.LoginFormWrapper>
            <LoginForm />
          </Styled.LoginFormWrapper>
        </Styled.LoginFormContainer>
        <Styled.LoginPageImageContainer md={4}>
          <Styled.LoginPageImageTextWrapper>
            <Styled.LoginPageImageTextHeader>
              "<em>Cemboo</em> has set a new standard in the 21st century and&nbsp;beyond."
            </Styled.LoginPageImageTextHeader>
            <Styled.LoginPageImageTextCitation>
              Hayden Craddolph, CEO of <em>Cemboo</em>
            </Styled.LoginPageImageTextCitation>
            <Styled.LoginPageImageTextLogo src={CembooLogo} />
          </Styled.LoginPageImageTextWrapper>
        </Styled.LoginPageImageContainer>
      </Styled.LoginPageRow>
    </Styled.LoginPageContainer>
  );
};
