import * as React from "react";

import { Spinner } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../themes";

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  color: ${colors.background.green};
  & > .spinner-border {
    width: 2rem;
    height: 2rem;
  }
`;

export const Loader: React.FC<{ message?: string }> = ({ message }) => {
  return (
    <SpinnerWrapper>
      <Spinner animation="border">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <p>{message ? message : "Loading data..."}</p>
    </SpinnerWrapper>
  );
};
