import styled from "styled-components";

export const ErrorWrapper = styled.div`
  margin: 3px;
  text-align: left;
  padding-left: 10px;
  margin-top: 0.25rem;
  font-size: 0.975em;
  color: coral;
`;
