import React from "react";
import DataTable, { createTheme } from "react-data-table-component";
import {
    ActionMenu,
    BaseButton,
    IUser,
    bytesToHumanReadableSize,
    colors,
} from "../../../../libs";

import * as Styled from "./UsersList.styled";

import { Alert, Modal } from "react-bootstrap";
import { useAuth } from "../../../../behavioral";
import { GeneratePaymentLinkModal } from "../generate-payment-link-modal";
import { UpdateManualPaymentModal } from "../update-manual-payment-modal";
import { XLg } from "react-bootstrap-icons";

interface IProps {
    users: IUser[];
}

const customStyles = {
    headRow: {
        style: {
            border: "none",
        },
    },
    headCells: {
        style: {
            color: colors.text.white,
            fontSize: "18px",
            fontWeight: "bold",
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: "rgb(230, 244, 244)",
            borderBottomColor: "#FFFFFF",
            borderRadius: "25px",
            outline: "1px solid #FFFFFF",
        },
    },
    pagination: {
        style: {
            border: "none",
        },
    },
};

export const UsersList: React.FC<IProps> = ({ users }) => {
    const { resendConfirmationCode } = useAuth();
    const [
        resendConfirmationSuccessMessage,
        setResendConfirmationSuccessMessage,
    ] = React.useState("");
    const [resendConfirmCodeError, setResendConfirmCodeError] =
        React.useState("");

    const [showGeneratePaymentLinkModal, setShowGeneratePaymentLinkModal] =
        React.useState(false);
    const [showUpdateManualPaymentModal, setShowUpdateManualPaymentModal] =
        React.useState(false);
    const [showManualPaymentModal, setShowManualPaymentModal] =
        React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState<IUser | null>(null);

    const handleResendConfirmCode = React.useCallback(
        async (user: IUser) => {
            setResendConfirmCodeError("");
            setResendConfirmationSuccessMessage("");
            try {
                await resendConfirmationCode(user.email);
                setResendConfirmationSuccessMessage(
                    "Email confirmation sent successfully!",
                );
            } catch (err) {
                console.log(err);
                setResendConfirmCodeError(
                    "There is an error while sending the confirmation code to email: " +
                        user.email,
                );
            }
        },
        [resendConfirmationCode],
    );

    const handleGeneratePaymentLink = React.useCallback((user: IUser) => {
        setSelectedUser(user);
        setShowGeneratePaymentLinkModal(true);
    }, []);

    const handleUpdateManualPayment = React.useCallback((user: IUser) => {
        setSelectedUser(user);
        setShowUpdateManualPaymentModal(true);
    }, []);

    const columns = [
        {
            name: "Name",
            selector: (row: IUser) => row.name,
            sortable: true,
        },
        {
            name: "Email",
            selector: (row: IUser) => row.email,
            sortable: true,
            width: "350px",
        },
        {
            name: "Phone",
            selector: (row: IUser) => row.phone,
            sortable: false,
            width: "150px",
        },
        {
            name: "Storage",
            selector: (row: IUser) =>
                bytesToHumanReadableSize(row.storage?.used || 0),
            sortable: true,
            width: "120px",
        },
        {
            name: "Payment",
            cell: (row: IUser) =>
                row.subscriptionInfo &&
                row.subscriptionInfo.status === "PAYMENT_COMPLETED" ? (
                    <BaseButton
                        onClick={() => {
                            setSelectedUser(row);
                            setShowManualPaymentModal(true);
                        }}
                    >
                        {row.manualpayInfo === undefined ? "Yes" : "Yes (M)"}
                    </BaseButton>
                ) : (
                    <BaseButton variant="secondary">No</BaseButton>
                ),
            width: "120px",
        },
        {
            cell: (row: IUser) => (
                <ActionMenu
                    menu={{
                        resend: {
                            label: "Resend Confirm code",
                            onClickHandler: () => handleResendConfirmCode(row),
                        },
                        payment: {
                            label: "Generate Payment Link",
                            onClickHandler: () =>
                                handleGeneratePaymentLink(row),
                        },
                        manualPayment: {
                            label: "Manual Payment",
                            onClickHandler: () =>
                                handleUpdateManualPayment(row),
                        },
                    }}
                />
            ),
            allowOverflow: true,
            button: true,
            width: "56px",
        },
    ];

    createTheme("dark", {
        background: {
            default: "transparent",
        },
    });

    return (
        <Styled.ManageStorageWrapper fluid>
            <Styled.Header>Manage Users</Styled.Header>
            {resendConfirmCodeError && (
                <Alert variant="danger" dismissible>
                    {resendConfirmCodeError}
                </Alert>
            )}

            {resendConfirmationSuccessMessage && (
                <Alert dismissible>{resendConfirmationSuccessMessage}</Alert>
            )}

            <Styled.StyledTable>
                <DataTable
                    columns={columns}
                    data={users}
                    pagination={true}
                    paginationPerPage={10}
                    customStyles={customStyles}
                    theme="dark"
                    pointerOnHover
                />
            </Styled.StyledTable>
            {showGeneratePaymentLinkModal && selectedUser && (
                <GeneratePaymentLinkModal
                    user={selectedUser}
                    show={showGeneratePaymentLinkModal}
                    onHide={() => setShowGeneratePaymentLinkModal(false)}
                    centered
                />
            )}
            {showUpdateManualPaymentModal && selectedUser && (
                <UpdateManualPaymentModal
                    user={selectedUser}
                    show={showUpdateManualPaymentModal}
                    onHide={() => setShowUpdateManualPaymentModal(false)}
                    centered
                />
            )}
            {
                <Modal
                    show={showManualPaymentModal}
                    onHide={() => setShowManualPaymentModal(false)}
                    centered
                >
                    <Styled.ModalWrapper>
                        <Styled.ModalTitleWrapper>
                            <Styled.ModalTitle>Payment Info</Styled.ModalTitle>
                            <XLg
                                style={{ cursor: "pointer" }}
                                onClick={() => setShowManualPaymentModal(false)}
                            />
                        </Styled.ModalTitleWrapper>
                        <Modal.Body>
                            <Styled.CodeOutput>
                                {selectedUser?.manualpayInfo &&
                                    JSON.stringify(
                                        selectedUser.manualpayInfo,
                                        null,
                                        2,
                                    )}
                            </Styled.CodeOutput>
                            <Styled.CodeOutput>
                                {selectedUser?.subscriptionInfo &&
                                    JSON.stringify(
                                        selectedUser.subscriptionInfo,
                                        null,
                                        2,
                                    )}
                            </Styled.CodeOutput>
                        </Modal.Body>
                    </Styled.ModalWrapper>
                </Modal>
            }
        </Styled.ManageStorageWrapper>
    );
};
