import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { BaseButton, colors } from "../../../../../libs";
import { Dropdown } from "react-bootstrap";

export const ShowPlaylistModalHeaderRow = styled(Row)`
    background-color: ${colors.background.lightBlack};
    padding: 1rem 0;
`;

export const ShowPlaylistModalHeaderCol = styled(Col)`
    padding: 1rem;
`;
export const ShowPlaylistModalHeaderMiddle = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
`;

export const ShowPlaylistModalHeaderContent = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const ShowPlaylistModalHeaderActions = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const ShowPlaylistModalHeaderText = styled.div`
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    padding: 0.5rem;
    gap: 0.2rem;
`;
export const ShowPlaylistModalHeaderTitle = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    color: ${colors.text.white};
`;
export const ShowPlaylistModalHeaderSubtitle = styled.div``;

export const ShowPlaylistModalHeaderThumbnail = styled.img`
    height: 100px;
    border: 1px solid ${colors.text.lightWhite};
`;

export const ShowPlaylistModalHeaderBack = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 100%;
    cursor: pointer;
`;

export const ShowPlaylistModalHeaderBackIcon = styled.img``;

export const ShowPlaylistModalHeaderEditButton = styled.div`
    display: flex;
    align-items: center;
    gap: 0.2rem;
`;

export const ChangeViewContiner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 2rem;
    height: 3rem;
    min-width: 3rem;
`;
export const ChangeViewIcon = styled.img`
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
`;

export const DropdownOptions = styled(Dropdown.Item)`
    &:hover {
        color: ${colors.text.green};
        background-color: ${colors.background.darkGreen};
    }
`;

export const DeleteButton = styled(BaseButton)`
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 600;
`;
