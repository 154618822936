import React from "react";
import { Col, Form } from "react-bootstrap";

import { BaseButton, PublishStatus, RadioInput } from "../../../../../libs";

import * as Styled from "./VisibilityDetails.styled";

export const VisibilityDetails: React.FC<{
    visibilityStatus: PublishStatus;
    onVisibilityDetailSubmit: (status: PublishStatus) => void;
    percentage: number;
}> = ({
    visibilityStatus: visibilityStatusI,
    onVisibilityDetailSubmit,
    percentage,
}) => {
    const [isDisabled, setIsDisabled] = React.useState(false);
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [visibilityStatus, setVisibilityStatus] =
        React.useState<PublishStatus>(visibilityStatusI);

    const handleInputChange = React.useCallback((event) => {
        setVisibilityStatus(event.target.value);
        if (event.target.value) {
            setIsDisabled(false);
        }
    }, []);

    const handleVisibilityStatusSubmit = React.useCallback(
        (event) => {
            event.preventDefault();
            if (visibilityStatus) {
                onVisibilityDetailSubmit(visibilityStatus);
                setShowSpinner(true);
                setIsDisabled(true);
            }
        },
        [visibilityStatus, onVisibilityDetailSubmit],
    );

    React.useEffect(() => {
        if (percentage === 100) {
            setShowSpinner(false);
        }
    }, [percentage]);

    return (
        <>
            <Styled.VisibilityDetailsHeader>
                Visibility *
            </Styled.VisibilityDetailsHeader>
            <Styled.HelpText>
                Choose when to publish and who can see your video. Don’t worry
                you’ll be able to change this afterwords.
            </Styled.HelpText>
            <Form onSubmit={handleVisibilityStatusSubmit}>
                <Styled.RequiredText>* indicates required</Styled.RequiredText>
                <Styled.FormFieldRow>
                    <Col>
                        <Styled.VisibilityOptionsWrapper
                            direction="vertical"
                            gap={3}
                        >
                            <RadioInput
                                fieldLabel="Draft (Save it for later)"
                                fieldName="status"
                                fieldId="DRAFT"
                                fieldValue={PublishStatus.DRAFT}
                                checked={visibilityStatus === PublishStatus.DRAFT}
                                onChangeHandler={handleInputChange}
                            />
                            <RadioInput
                                fieldValue={PublishStatus.PUBLISHED}
                                fieldLabel="Public (Anyone with the video link can watch you video)"
                                fieldName="status"
                                fieldId="PUBLIC"
                                checked={visibilityStatus === PublishStatus.PUBLISHED}
                                onChangeHandler={handleInputChange}
                            />
                            <RadioInput
                                fieldValue={PublishStatus.SCHEDULED}
                                fieldLabel="Schedule (Select a date to make your video public)"
                                fieldName="status"
                                fieldId="SCHEDULED"
                                checked={visibilityStatus === PublishStatus.SCHEDULED}
                                onChangeHandler={handleInputChange}
                            />
                        </Styled.VisibilityOptionsWrapper>
                    </Col>
                </Styled.FormFieldRow>
                <Styled.FormFieldRow>
                    <Col>
                        <BaseButton
                            variant={isDisabled ? "secondary" : "primary"}
                            disabled={isDisabled}
                        >
                            Go Back
                        </BaseButton>
                    </Col>
                    <Col>
                        <BaseButton
                            type="submit"
                            variant={isDisabled ? "secondary" : "primary"}
                            disabled={isDisabled}
                        >
                            {showSpinner && (
                                <Styled.LoadingSpinner
                                    animation="border"
                                    size="sm"
                                    variant="primary"
                                />
                            )}
                            {showSpinner
                                ? `${percentage}% Uploaded`
                                : percentage === 100
                                  ? "Processing"
                                  : "Submit"}
                        </BaseButton>
                    </Col>
                </Styled.FormFieldRow>
            </Form>
        </>
    );
};
