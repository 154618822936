import React from "react";
import * as d3 from "d3";

interface Point {
    x: number;
    y: number;
}

interface IProps {
    percentFilled: number;
    text: string;
    labelText: string;
}

export const StorageGuage: React.FC<IProps> = ({
    percentFilled,
    text,
    labelText,
}) => {
    const mapValue = (oldValue) => {
        const oldMin = 0,
            oldMax = 1,
            newMin = -0.485,
            newMax = 0.52;
        return (
            ((oldValue - oldMin) * (newMax - newMin)) / (oldMax - oldMin) +
            newMin
        );
    };
    const guageRef = React.useRef(null);

    const renderGuage = React.useCallback(() => {
        const width = 350;
        const height = 190;
        const labelHeight = 60;
        const scale = d3.scaleLinear().range([0, Math.PI * 1]);

        const currentValue = scale.domain()[1] * mapValue(percentFilled);
        const currentAngle = scale(currentValue) - Math.PI / 2;

        const radius = 150;
        const offset = 30;
        const totalRadius = radius + offset;
        const iconX = totalRadius * Math.cos(currentAngle);
        const iconY = totalRadius * Math.sin(currentAngle);

        const rotation = (currentAngle + Math.PI) * (180 / Math.PI);

        const svg = d3
            .select(guageRef.current)
            .append("svg")
            .attr("width", width)
            .attr("height", height + labelHeight);

        d3.arc()
            .innerRadius(180)
            .outerRadius(190)
            .startAngle(1 * Math.PI)
            .endAngle(0 * Math.PI);

        d3.line<Point>()
            .x((d) => d.x)
            .y((d) => d.y);

        var ticks = scale.ticks(40);

        svg.selectAll(".tick")
            .data(ticks)
            .enter()
            .append("path")
            .attr("d", function (d) {
                const angle = scale(d);
                const innerRadius = 80;
                const outerRadius = 150;
                const thicknessInner = 0.5;
                const thicknessOuter = 1;

                const xInner1 =
                    Math.cos(angle - thicknessInner / innerRadius) *
                    innerRadius;
                const yInner1 =
                    Math.sin(angle - thicknessInner / innerRadius) *
                    innerRadius;
                const xOuter1 =
                    Math.cos(angle - thicknessOuter / outerRadius) *
                    outerRadius;
                const yOuter1 =
                    Math.sin(angle - thicknessOuter / outerRadius) *
                    outerRadius;
                const xInner2 =
                    Math.cos(angle + thicknessInner / innerRadius) *
                    innerRadius;
                const yInner2 =
                    Math.sin(angle + thicknessInner / innerRadius) *
                    innerRadius;
                const xOuter2 =
                    Math.cos(angle + thicknessOuter / outerRadius) *
                    outerRadius;
                const yOuter2 =
                    Math.sin(angle + thicknessOuter / outerRadius) *
                    outerRadius;

                return `M ${xInner1} ${yInner1} L ${xOuter1} ${yOuter1} L ${xOuter2} ${yOuter2} L ${xInner2} ${yInner2} Z`;
            })
            .style("fill", "#999CA9")
            .attr(
                "transform",
                `translate(${width / 2}, ${height}) rotate(180)`,
            );

        const filledArc = d3
            .arc()
            .innerRadius(80)
            .outerRadius(150)
            .startAngle(-0.5 * Math.PI)
            .endAngle(-0.5 * Math.PI + 1 * Math.PI * percentFilled);
        const pathData = filledArc({} as any);
        svg.append("path")
            .style("fill", "#999CA9")
            .attr("d", pathData)
            .attr("transform", `translate(${width / 2}, ${height})`);

        svg.append("text")
            .attr("x", width / 2)
            .attr("y", height - 20)
            .attr("text-anchor", "middle")
            .style("font-size", "22px")
            .style("fill", "#FFFFFF")
            .text(text);

        svg.append("path")
            .attr(
                "d",
                "M9.43044 3.64869C10.6585 4.35773 10.6585 6.13034 9.43044 6.83938L3.58962 10.2116C2.36153 10.9206 0.826406 10.0343 0.826406 8.61624L0.826406 1.87183C0.826406 0.453753 2.36152 -0.432549 3.58962 0.276492L9.43044 3.64869Z",
            )
            .attr("fill", "#999CA9")
            .attr(
                "transform",
                `translate(${iconX + width / 2}, ${iconY + height}) rotate(${rotation}) scale(2)`,
            );

        svg.append("rect")
            .attr("x", 7)
            .attr("y", height + 1)
            .attr("width", 335)
            .attr("height", 40)
            .attr("rx", 20)
            .attr("ry", 30)
            .style("fill", "#27272A");

        svg.append("text")
            .attr("x", width / 2)
            .attr("y", height + 28)
            .attr("text-anchor", "middle")
            .style("font-size", "18px")
            .style("fill", "#FFFFFF")
            .text(labelText);
    }, [percentFilled, text, labelText]);

    React.useEffect(() => {
        const guage = guageRef.current;
        renderGuage();
        return () => {
            d3.select(guage).selectAll("*").remove();
        };
    }, [percentFilled, text, renderGuage]);
    return (
        <div
            style={{
                width: "fit-content",
                height: "fit-content",
            }}
            ref={guageRef}
        ></div>
    );
};
