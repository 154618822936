import * as Styled from "./CheckBox.styled";
import { FormCheckProps } from "react-bootstrap";

export const Switch: React.FC<FormCheckProps> = ({ ...props }) => {
    return (
        <Styled.Switch
            type="switch"
            {...props}
        />
    );
};