import React from "react";
import { SelectVideo } from "./select-video";
import { SelectedVideos } from "./selected-videos";
import * as Styled from "./VideoSelection.styled";
import { IMediaResponseDto } from "../../../../../libs";
import { CreateModalSteps } from "../../types";

interface IProps {
    mediaItems: IMediaResponseDto[];
    playlistName: string;
    selectedVideos: string[];
    setSelectedVideos: (videos: string[]) => void;
    handleNextStep: (step: CreateModalSteps) => void;
    editMode: boolean;
}

export const VideoSelection: React.FC<IProps> = ({
    mediaItems,
    playlistName,
    selectedVideos,
    setSelectedVideos,
    handleNextStep,
    editMode,
}) => {
    return (
        <Styled.VideoSelectionWrapper>
            <Styled.VideoSelectionCol sm={12} md={7}>
                <SelectVideo
                    mediaItems={mediaItems}
                    selectedVideos={selectedVideos}
                    setSelectedVideos={setSelectedVideos}
                />
            </Styled.VideoSelectionCol>
            <Styled.VideoSelectionCol sm={12} md={5}>
                <SelectedVideos
                    playlistName={playlistName}
                    mediaItems={mediaItems}
                    selectedVideos={selectedVideos}
                    setSelectedVideos={setSelectedVideos}
                    handleNextStep={handleNextStep}
                    editMode={editMode}
                />
            </Styled.VideoSelectionCol>
        </Styled.VideoSelectionWrapper>
    );
};
