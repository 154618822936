import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

import { colors, types } from "./../../libs";

export const AdServerContainer = styled(Container)``;

export const WelcomeHeader = styled.h2`
  ${types.headers.secondary};
  line-height: 1;
  background: ${colors.text.textGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 40px;
`;

export const PreviewHeader = styled.h5`
  margin-top: 7px;
  ${types.headers.section};
  background: ${colors.text.textGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const PreviewWrapper = styled(Col)`
  background: ${colors.text.black};
  height: calc(100vh - 70px);
`;

export const WelcomeHeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

export const AdServerRow = styled(Row)``;

export const AdDetailsCol = styled(Col)`
  padding-top: 25px;
`;

export const AdPreviewCol = styled(Col)``;

export const AdServerTitle = styled.h1``;

export const AdServerTabsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AdServerTabTitle = styled.span`
  margin: 0 20px;
  font-size: 18px;
  cursor: pointer;
  opacity: 0.7;

  &.active {
    opacity: 1;
    background: ${colors.text.textGradient};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const AdServerItemRow = styled(Row)`
  margin: 35px 0;
`;
