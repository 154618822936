import { useLocation } from "react-router-dom";
import { IPackage } from "../../../libs";
import * as Styled from "./PackageInfo.styled";

export const PackageInfo: React.FC<{ selectedPackage: IPackage }> = ({
  selectedPackage,
}) => {
  const { search } = useLocation();
  return (
    <Styled.PackageInfoWrapper>
      <div className="card package-card popular">
        <div className="popular-choice text-center text-uppercase">
          <span>Selected Package</span>
        </div>
        <div className="card-body">
          <h2 className="card-title">{selectedPackage.name}</h2>
          <p className="card-text mb-4 mb-md-5">
            {selectedPackage.description}
          </p>
          <h3 className="price mb-4 mb-md-5">
            ${selectedPackage.monthlyPrice}
            <span>/month</span>
          </h3>
          <ul className="notes">
            {selectedPackage.features.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
        <div className="card-footer d-grid">
          <a
            href={search ? `/pricing${search}` : "/pricing"}
            className="btn btn-primary"
          >
            Back to Pricings
          </a>
        </div>
      </div>
    </Styled.PackageInfoWrapper>
  );
};
