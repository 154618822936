import React from "react";
import {
    useMaterialReactTable,
    type MRT_ColumnDef,
    MRT_Table as Table,
} from "material-react-table";
import {
    ClickAwayListener,
    createTheme,
    Fade,
    Popper,
    ThemeProvider,
} from "@mui/material";
import {
    IPlaylistResponseDto,
    IPlaylistMediaItem,
    BaseButton,
    IPlaylistRequestDto,
} from "../../../../../libs";
import { playlistsService } from "../../../../../services";
import { menuItemClasses } from "@mui/base/MenuItem";

import DefaultThumbnailImage from "../../../../../assets/images/default_thumbnail.jpeg";
import OptionsIcon from "../../../../../assets/images/icons/icon-options.svg";
import UploadIcon from "../../../../../assets/images/upload_icon_danger.svg";
import * as Styled from "./ShowPlaylistsModalBody.styled";
import moment from "moment";

interface IProps {
    playlist: IPlaylistResponseDto;
    refreshPlaylist: () => void;
}

export const ShowPlaylistsModalBody: React.FC<IProps> = ({
    playlist,
    refreshPlaylist,
}) => {
    const [data, setData] = React.useState<IPlaylistMediaItem[]>([]);

    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? "transition-popper" : undefined;

    const [selectedMediaItem, setSelectedMediaItem] =
        React.useState<IPlaylistMediaItem | null>(null);

    const handleOptionsClick = React.useCallback(
        (event: React.MouseEvent<HTMLElement>, row: IPlaylistMediaItem) => {
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
            setOpen(true);
            setSelectedMediaItem(row);
        },
        [],
    );

    React.useEffect(() => {
        setData(playlist.mediaItems || []);
    }, [playlist]);

    const columns = React.useMemo<MRT_ColumnDef<IPlaylistMediaItem>[]>(() => {
        return [
            {
                header: "#",
                Cell: ({ row }) => row.index + 1,
                enableSorting: false,
                size: 20,
            },
            {
                header: "Title",
                accessorKey: "title",
                Cell: ({ renderedCellValue, row }) => (
                    <Styled.TitleCellWrapper>
                        <img
                            src={
                                row.original.thumbnail
                                    ? row.original.thumbnail
                                    : DefaultThumbnailImage
                            }
                            alt="Title"
                        />
                        <div>{renderedCellValue}</div>
                    </Styled.TitleCellWrapper>
                ),
            },
            {
                header: "Description",
                accessorKey: "description",
                enableSorting: false,
            },
            {
                header: "Published Date",
                accessorKey: "publishedDate",
                size: 20,
                Cell: ({ row }) =>
                    moment(row.original.publishedDate).format("ll"),
            },
            {
                header: "Actions",
                accessorKey: "actions",
                enableSorting: false,
                size: 20,
                Cell: ({ row }) => (
                    <Styled.OptionsIcon
                        src={OptionsIcon}
                        onClick={(e) => handleOptionsClick(e, row.original)}
                    />
                ),
            },
        ];
    }, [handleOptionsClick]);

    const table = useMaterialReactTable({
        autoResetPageIndex: false,
        columns,
        data,
        enableColumnActions: false,
        mrtTheme: {
            baseBackgroundColor: "#0F1013",
            draggingBorderColor: "#b6f84d",
        },
    });

    const theme = createTheme({
        palette: {
            mode: "dark",
        },
    });

    const [showBottomBar, setShowBottomBar] = React.useState(false);
    const handleClickAway = React.useCallback(() => {
        setOpen(false);
        setSelectedMediaItem(null);
    }, []);

    const handleDeleteClick = React.useCallback(() => {
        if (selectedMediaItem) {
            setData((prevData) =>
                prevData.filter((item) => item.id !== selectedMediaItem.id),
            );
            setOpen(false);
            setShowBottomBar(true);
        }
    }, [selectedMediaItem, setData, setShowBottomBar]);

    const handleCancelEdit = React.useCallback(() => {
        setData(playlist.mediaItems || []);
        setShowBottomBar(false);
    }, [setData, playlist, setShowBottomBar]);

    const handleSaveEdit = React.useCallback(async () => {
        if (playlist.playlistId) {
            const updatedPlaylist: IPlaylistRequestDto = {
                title: playlist.title,
                description: playlist.description,
                thumbnails: playlist.thumbnails,
                audience: playlist.audience,
                visibility: playlist.visibility,
                playliststatus: playlist.playliststatus,
                steps: playlist.steps,
                category: "",
                mediaItems: data.map((item) => item.mediaId),
            };

            await playlistsService.updatePlaylist(
                updatedPlaylist,
                playlist.playlistId,
            );
            refreshPlaylist();
            setShowBottomBar(false);
        }
    }, [playlist, data, refreshPlaylist, setShowBottomBar]);

    return (
        <ThemeProvider theme={theme}>
            <Styled.ShowPlaylistsModalWrapper>
                <Styled.ShowPlaylistsModalCol md={8} className="mx-auto">
                    <Styled.TableWrapper>
                        <Table table={table} />
                    </Styled.TableWrapper>
                    {showBottomBar && (
                        <Styled.BottomBar>
                            <Styled.BottomActionWrapper>
                                <BaseButton
                                    variant="secondary"
                                    onClick={handleCancelEdit}
                                >
                                    Cancel
                                </BaseButton>
                            </Styled.BottomActionWrapper>
                            <Styled.BottomActionWrapper>
                                <BaseButton onClick={handleSaveEdit}>
                                    Save
                                </BaseButton>
                            </Styled.BottomActionWrapper>
                        </Styled.BottomBar>
                    )}
                </Styled.ShowPlaylistsModalCol>
                <Popper
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    style={{ zIndex: "2000" }}
                    transition
                    modifiers={[
                        {
                            name: "offset",
                            options: {
                                offset: [0, 10],
                            },
                        },
                    ]}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={150}>
                            <Styled.DropdownPaper>
                                <ClickAwayListener
                                    onClickAway={handleClickAway}
                                >
                                    <Styled.DropdownMenuList>
                                        <Styled.DropdownMenuItem
                                            className={menuItemClasses.root}
                                            danger={true}
                                            onClick={handleDeleteClick}
                                        >
                                            <Styled.DropdownMenuIcon
                                                src={UploadIcon}
                                            />{" "}
                                            Remove
                                        </Styled.DropdownMenuItem>
                                    </Styled.DropdownMenuList>
                                </ClickAwayListener>
                            </Styled.DropdownPaper>
                        </Fade>
                    )}
                </Popper>
            </Styled.ShowPlaylistsModalWrapper>
        </ThemeProvider>
    );
};
