import styled from "styled-components";
import { colors, types } from "../../../../libs";
import { Modal } from "react-bootstrap";

export const RecordingsModal = styled(Modal)`
    .modal-width {
        max-width: 35%;
    }
    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
`;

export const RecordingsModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    background-color: ${colors.background.lightBlack};
    overflow-y: auto;
`;

export const ModalTitle = styled.div`
    ${types.body.secondaryTitle}
    color: ${colors.text.white};
    font-weight: bold;
`;

export const ModalSubtitle = styled.div`
    ${types.body.normal}
`;

export const Spacer = styled.div`
    height: 20px;
`;

export const ThumbnailWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ThumbnailWrapperTitle = styled.div`
    ${types.body.normal}
    color: ${colors.text.white};
    font-weight: bold;
`;

export const ThumbnailWrapperChoose = styled.div`
    ${types.body.normal}
    color: ${colors.text.green};
    cursor: pointer;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
`;

export const ThumbnailBannerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const ThumbnailBanner = styled.img`
    width: 100%;
    height: 100%;
`;

export const ThumbnailUploadText = styled.div`
    ${types.body.normal}
    color: ${colors.text.white};
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ThumbnailUploadIcon = styled.img`
    width: 3rem;
`;

export const Bold = styled.span`
    font-weight: bold;
`;

export const Green = styled.span`
    color: ${colors.text.green};
    margin-left: 3px;
`;

export const SelectedThumbnailWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: 1px solid #b7f84d52;
    background-color: #b7f84d0a;
    border-radius: 10px;
`;

export const SelectedThumbnailLeft = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SelectedThumbnail = styled.img`
    height: 7rem;
    border-radius: 10px;
`;

export const SelectedThumbnailText = styled.div`
    ${types.body.normal}
    color: ${colors.text.white};
    margin-left: 20px;
`;
