import React from "react";
import { Col, Form, Stack } from "react-bootstrap";

import {
    BaseButton,
    Error,
    IFilmFestivalCastDetails,
} from "../../../../../libs";

import PlusIcon from "./../../../../../assets/images/plus.svg";

import { CastItem } from "./cast-item";
import { CastUploadForm } from "./cast-upload-form";

import { FileWithPreview } from "../../../../../libs/models/uploads";
import * as Styled from "./CastDetails.styled";

export const CastDetails: React.FC<{
    onCastDetailsSubmit: (castDetails: IFilmFestivalCastDetails[]) => void;
    castDetails?: IFilmFestivalCastDetails[];
}> = ({ onCastDetailsSubmit, castDetails }) => {
    const [errorMessage, setErrorMessage] = React.useState("");

    const [castList, setCastList] = React.useState<IFilmFestivalCastDetails[]>(
        castDetails || [],
    );

    const [showAddIcon, setShowAddIcon] = React.useState(true);
    const [showCastUploadForm, setShowCastUploadForm] = React.useState(false);
    const [isImportedFromIMDB, setIsImportedFromIMDB] = React.useState(false);

    const handleUseImdbLinkToggle = React.useCallback((event) => {
        setShowAddIcon(!event.target.checked);
        if (event.target.checked) {
            const imdbCastInfo: IFilmFestivalCastDetails[] = [
                {
                    id: 1,
                    name: "Ryan Gosling",
                    role: "Officer KD6-3.7",
                },
                { id: 2, name: "Ana de Armas", role: "Joi" },
            ];
            setCastList(imdbCastInfo);
            setIsImportedFromIMDB(true);
            setShowCastUploadForm(false);
        } else {
            setCastList([]);
            setIsImportedFromIMDB(false);
        }
    }, []);

    const handleAddManualIconClick = React.useCallback(() => {
        setShowCastUploadForm(true);
    }, []);

    const handleCastEdit = React.useCallback(() => {}, []);

    const handleCastDelete = React.useCallback(
        (castId) => {
            const updatedCastList = [...castList];
            setCastList([...updatedCastList.splice(castId)]);
        },
        [castList],
    );

    const castListDetail = castList.map((cast, index) => (
        <CastItem
            roleName={cast?.role}
            castName={cast?.name}
            castImageSrc={cast.file ? cast.file.previewUrl : ""}
            showActions={!isImportedFromIMDB}
            castId={(index + 1).toString()}
            onDelete={handleCastDelete}
            onEdit={handleCastEdit}
        />
    ));

    const handleCastSave = React.useCallback(
        (castName: string, roleName: string, castImage?: FileWithPreview) => {
            if (!roleName || !castName) {
                setErrorMessage("Please enter valid details");
                return;
            }
            const newCastObj: IFilmFestivalCastDetails = {
                id: castList.length + 1,
                name: castName,
                role: roleName,
                file: castImage,
            };
            setCastList([...castList, newCastObj]);
            setShowCastUploadForm(false);
        },
        [castList],
    );

    const handleCastDiscard = React.useCallback(() => {
        setShowCastUploadForm(false);
    }, []);

    const handleClearAllCast = React.useCallback(() => {
        setCastList([]);
    }, []);

    const handleCastDetailsSubmit = React.useCallback(
        (event) => {
            event.preventDefault();
            onCastDetailsSubmit(castList);
        },
        [onCastDetailsSubmit, castList],
    );

    return (
        <>
            <Styled.CastDetailsHeader>Cast *</Styled.CastDetailsHeader>
            <Form onSubmit={handleCastDetailsSubmit}>
                {errorMessage && <Error message={errorMessage} />}
                <Styled.RequiredText>* indicates required</Styled.RequiredText>
                <Styled.FormFieldRow>
                    <Col>
                        <Styled.CastField>
                            <Styled.FormSwitch
                                type="switch"
                                id="custom-switch"
                                label="Use same as IMDB"
                                onChange={handleUseImdbLinkToggle}
                            />
                        </Styled.CastField>
                    </Col>
                </Styled.FormFieldRow>
                <Styled.FormFieldRow>
                    <Col>
                        <Stack
                            direction="horizontal"
                            gap={5}
                            className="space-between"
                        >
                            <Styled.CastFieldManualText>
                                {isImportedFromIMDB
                                    ? "Imported from IMDB"
                                    : "or add it manually"}
                            </Styled.CastFieldManualText>
                            {!isImportedFromIMDB && castList.length > 0 && (
                                <Styled.CastFieldClearAllText
                                    onClick={handleClearAllCast}
                                >
                                    Clear all
                                </Styled.CastFieldClearAllText>
                            )}
                        </Stack>
                        {castList && castListDetail}
                    </Col>
                </Styled.FormFieldRow>
                {showCastUploadForm && (
                    <Styled.FormFieldRow>
                        <Col>
                            <CastUploadForm
                                onSave={handleCastSave}
                                onDiscard={handleCastDiscard}
                            />
                        </Col>
                    </Styled.FormFieldRow>
                )}
                {showAddIcon && (
                    <Styled.FormFieldRow>
                        <Col>
                            <Styled.CastAddIcon
                                src={PlusIcon}
                                onClick={handleAddManualIconClick}
                            />
                        </Col>
                    </Styled.FormFieldRow>
                )}

                <Styled.FormFieldRow>
                    <Col>
                        <BaseButton variant="secondary">Go Back</BaseButton>
                    </Col>
                    <Col>
                        <BaseButton type="submit" variant="primary">
                            Continue
                        </BaseButton>
                    </Col>
                </Styled.FormFieldRow>
            </Form>
        </>
    );
};
