import { InnerContainer, InnerSection } from "../ui";
//import * as Styled from "./team.styled";

import BioPicHaydenCraddolph from './../../assets/images/team/hayden-craddolph.png';
import BioPicChrisSaraceno from './../../assets/images/team/chris-saraceno.png';
import BioPicPranjaliKhadse from './../../assets/images/team/pranjali-khadse.png';
import BioPicProsenjitDas from './../../assets/images/team/prosenjit-das.png';
import BioPicVaishaliJain from './../../assets/images/team/vaishali-jain.png';
import BioPicTamaraLedbetter from './../../assets/images/team/tamara-ledbetter.png';
import BioPicYashKhadse from './../../assets/images/team/yash-khadse.png';
import BioPicKatieHestor from './../../assets/images/team/katie-hestor.png';
import StatsImage from './../../assets/images/team/team-stats.svg';

import './team.scss';

export const TeamContainer = () => {
    return (

        <InnerSection id="team" className="team section-bg" style={{ marginTop: '120px', overflow: 'hidden' }}>

            <InnerContainer className="page-hero">

                <div className="meta mx-4 my-4">
                    <h1
                        className="gradient-text"
                        data-aos="fade-up"
                        data-aos-delay="50"
                        data-aos-duration="800">Hi, We’re Cemboo</h1>
                    <h3
                        className="px-4 px-md-0"
                        data-aos="zoom-in"
                        data-aos-delay="100"
                        data-aos-duration="750">Our mission is to empower content owners with the flexibility to control, monetize and distribute their own digital media content through values of quality, community and innovation.</h3>
                </div>

                <div className="row w-100 mt-5 mb-5 mb-md-0">
                    <div className="col-12 col-md-6 offset-md-6 text-center text-md-start">
                        <img src={StatsImage} alt="" className="stats-image" />
                    </div>
                </div>

            </InnerContainer>

            <InnerContainer className="container mt-3 mt-md-5 team-container">
                <InnerContainer className="row">
                    <InnerContainer className="col-12">

                        <div className="intro mb-3 mb-md-5">
                            <h1 id="our-story" className="d-inline-block gradient-text mb-4">Our Story</h1>

                            <div className="intro-copy">
                                <p>Cemboo evolved from the pioneering digital initiatives of Hayden Craddolph, who founded the world's first online film festival through Haydenfilms. In 2022, this expertise was channeled into creating Cemboo, a B2B SaaS platform based in Reading, PA. Our platform revolutionizes how digital content is controlled, monetized, and distributed, focusing on digital library owners across various sectors, including education, film festivals, entertainment, and sports, among many others.</p>
                                <p>Our technology suite ensures complete control for content owners and guarantees 100% advertising revenue. Features include customizable video players, a robust cloud network for seamless streaming, and an exclusive ad server—all designed to enhance user engagement and optimize monetization. Today, Cemboo targets over 24,313 higher education institutions and 10,172 film festivals worldwide.</p>
                                <p>As Cemboo expands, we leverage a scalable business model prioritizing customer satisfaction and technological innovation. We are committed to empowering video publishers worldwide to maximize the value of their digital assets, promising new growth and success in the evolving digital landscape.</p>
                            </div>
                        </div>

                        <div className="team mb-3 mb-md-5">
                            <h1 id="our-team" className="d-inline-block gradient-text mb-4">Our Team</h1>

                            <div className="team-grid">

                                <div className="member-card card">
                                    <img src={BioPicHaydenCraddolph} alt="Hayden Craddolph" className="card-img-top" />
                                    <div className="card-body">
                                        <div className="meta">
                                            <h5 className="name card-title mb-4">Hayden Craddolph</h5>
                                            <h6 className="title card-subtitle mb-1">Founder & CEO</h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="member-card card">
                                    <img src={BioPicPranjaliKhadse} alt="Pranjali Khadse" className="card-img-top" />
                                    <div className="card-body">
                                        <div className="meta">
                                            <h5 className="name card-title mb-4">Pranjali Khadse</h5>
                                            <h6 className="title card-subtitle mb-1">Data Analyst</h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="member-card card">
                                    <img src={BioPicYashKhadse} alt="Yash Khadse" className="card-img-top" />
                                    <div className="card-body">
                                        <div className="meta">
                                            <h5 className="name card-title mb-4">Yash Khadse</h5>
                                            <h6 className="title card-subtitle mb-1">Software Developer</h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="member-card card">
                                    <img src={BioPicProsenjitDas} alt="Prosenjit Das" className="card-img-top" />
                                    <div className="card-body">
                                        <div className="meta">
                                            <h5 className="name card-title mb-4">Prosenjit Das</h5>
                                            <h6 className="title card-subtitle mb-1">AWS Architect</h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="member-card card">
                                    <img src={BioPicVaishaliJain} alt="Vaishali Jain" className="card-img-top" />
                                    <div className="card-body">
                                        <div className="meta">
                                            <h5 className="name card-title mb-4">Vaishali Jain</h5>
                                            <h6 className="title card-subtitle mb-1">Product Manager</h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="member-card card">
                                    <img src={BioPicTamaraLedbetter} alt="Tamara Skye" className="card-img-top" />
                                    <div className="card-body">
                                        <div className="meta">
                                            <h5 className="name card-title mb-4">Tamara Skye</h5>
                                            <h6 className="title card-subtitle mb-1">Strategy Advisor</h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="member-card card">
                                    <img src={BioPicChrisSaraceno} alt="Chris Saraceno" className="card-img-top" />
                                    <div className="card-body">
                                        <div className="meta">
                                            <h5 className="name card-title mb-4">Chris Saraceno</h5>
                                            <h6 className="title card-subtitle mb-1">Business Advisor</h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="member-card card">
                                    <img src={BioPicKatieHestor} alt="Katie Hestor" className="card-img-top" />
                                    <div className="card-body">
                                        <div className="meta">
                                            <h5 className="name card-title mb-4">Katie Hestor</h5>
                                            <h6 className="title card-subtitle mb-1">Growth Advisor</h6>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </InnerContainer>
                </InnerContainer>
            </InnerContainer>



        </InnerSection>

    );
};
