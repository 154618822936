import { Auth, Hub } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { IUser } from "../libs";
import { userService } from "../services";

export interface IAuthContextType {
  user: any;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  unverifiedAccount: {
    email: string;
    password: string;
    name: string;
    userId: string;
    isConfirmed: boolean;
  };
  signIn: (p: { email: string; password: string }) => Promise<any>;
  signOut: () => Promise<any>;
  signUp: (p: {
    name: string;
    phoneNumber: string;
    email: string;
    password: string;
  }) => Promise<any>;
  changePassword: (
    currentUser: any,
    temporaryPassword: string,
    newPassword: string
  ) => Promise<any>;
  confirmAccount: (email: string, code: string) => Promise<any>;
  verifyIfUserExists: (email: string) => Promise<any>;
  resendConfirmationCode: (email: string) => Promise<any>;
  resetPassword: (email: string) => Promise<any>;
  confirmForgotPassword: (
    email: string,
    code: string,
    password: string
  ) => Promise<any>;
}

// Create a context object
export const AuthContext = React.createContext<IAuthContextType>({
  user: null,
  isAuthenticated: false,
  isAuthenticating: true,
  unverifiedAccount: {
    email: "",
    password: "",
    name: "",
    userId: "",
    isConfirmed: false,
  },
  signIn: async () => {},
  signOut: async () => {},
  signUp: async () => {},
  changePassword: async () => {},
  confirmAccount: async () => {},
  resendConfirmationCode: async () => {},
  resetPassword: async () => {},
  confirmForgotPassword: async () => {},
  verifyIfUserExists: async () => {},
});

interface IAuthProviderProps {
  children: React.ReactNode;
}

// Create a provider for components to consume and subscribe to changes
export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [user, setUser] = useState<IUser | undefined>();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [unverifiedAccount, setUnverifiedAccount] = useState({
    email: "",
    password: "",
    name: "",
    userId: "",
    isConfirmed: false,
  });

  /**
   * fetch currently logged-in user using AWS Auth library
   * @returns {Promise<void>}
   */
  const fetchAuthUser = async () => {
    try {
      const fetchedUser = await Auth.currentAuthenticatedUser();
      setIsAuthenticating(false);
      setUser(fetchedUser);
    } catch (err) {
      setIsAuthenticating(false);
      setUser(undefined);
    }
  };

  useEffect(() => {
    fetchAuthUser();

    // listening for auth change events
    const authListener = Hub.listen(
      "auth",
      async ({ payload: { event, data, message } }) => {
        console.log(event);
        switch (event) {
          case "signIn":
            await fetchAuthUser();
            break;
          case "signOut":
            setUser(undefined);
            localStorage.removeItem("connectionId");
            break;
          case "signIn_failure":
          case "signUp_failure":
            console.log(message);
            if (user) {
              setUser(undefined);
            }
            break;
          case "signUp":
          case "forgotPassword":
          case "forgotPasswordSubmit":
          case "forgotPasswordSubmit_failure":
          case "forgotPassword_failure":
            console.log(message);
            break;
          default:
            await fetchAuthUser();
        }
      }
    );

    // cleanup
    return () => {
      authListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * log user in
   * @param email
   * @param password
   */
  const signIn = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    const response = await Auth.signIn({ username: email, password });
    return response;
  };

  /**
   * Check if user exists
   *
   */
  const verifyIfUserExists = async (email: string) => {
    try {
      await Auth.confirmSignUp(email, "000000", { forceAliasCreation: false });
    } catch (err: any) {
      const code = err.code;
      switch (code) {
        case "UserNotFoundException":
          return false;
        case "NotAuthorizedException":
          return true;
        case "PasswordResetRequiredException":
          return true;
        case "UserNotConfirmedException":
          return true;
        default:
          return false;
      }
    }
  };

  /**
   * create new user account
   * @param email
   * @param password
   * @param name
   * @param phoneNumber
   */
  const signUp = async ({
    email,
    password,
    name,
    phoneNumber,
  }: {
    email: string;
    password: string;
    name: string;
    phoneNumber: string;
  }) => {
    const user = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        name: name,
        phone_number: phoneNumber,
      },
    });

    setUnverifiedAccount({
      email,
      password,
      name,
      userId: user.userSub,
      isConfirmed: user.userConfirmed,
    });

    await userService.registerUser({
      userId: user.userSub,
      name,
      email,
      phoneNumber,
      isConfirmed: user.userConfirmed,
    });
  };

  /**
   * confirm account using code
   * @param confirmCode
   * @returns {Promise<any>}
   */
  const confirmAccount = React.useCallback(
    async (email: string, code: string) => {
      await Auth.confirmSignUp(email, code);
      // await signIn({
      //   email: unverifiedAccount?.email,
      //   password: unverifiedAccount?.password,
      // });
    },
    []
  );

  /**
   * Change Password for NEW_PASSWORD_REQUIRED challenge
   */
  /**
   * log user in
   * @param temporaryPassword
   * @param newPassword
   */
  const changePassword = async (
    currentUser: any,
    temporaryPassword: string,
    newPassword: string
  ) => {
    const response = await Auth.completeNewPassword(currentUser, newPassword);
    return response;
  };

  /**
   * logout user
   */
  const signOut = async () => {
    Auth.signOut();
    localStorage.removeItem("connectionId");
  };

  /**
   * Resend confirmation code
   */

  const resendConfirmationCode = async (email: string) => {
    await Auth.resendSignUp(email);
  };

  /**
   * Forgot password send verification code to reset
   */

  const resetPassword = async (email: string) => {
    await Auth.forgotPassword(email);
  };

  /**
   *
   * Confrim password code
   */

  const confirmForgotPassword = async (
    email: string,
    code: string,
    newPassword: string
  ) => {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
  };

  const value = {
    user,
    isAuthenticated: !!user,
    isAuthenticating,
    unverifiedAccount,
    signIn,
    signOut,
    signUp,
    confirmAccount,
    changePassword,
    resendConfirmationCode,
    resetPassword,
    verifyIfUserExists,
    confirmForgotPassword,
  };

  if (isAuthenticating) {
    return <>Loading... </>;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
