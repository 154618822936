import React from "react";
import { Col, Form } from "react-bootstrap";
import * as Yup from "yup";

import {
    BaseButton,
    FileWithPreview,
    SelectInput,
    UploadedFilwWithPreview,
    IFilmFestivalLanguageDetails,
} from "../../../../../libs";
import { DragAndDropZone } from "../../../../drag-drop-zone";

import { SubtitleFile, SubtitleList } from "./subtitle-file";

import { useFormik } from "formik";
import { languageOptions } from "../../../../mocks";
import * as Styled from "./LanguageDetails.styled";

export const LanguageDetails: React.FC<{
    onLanguageDetailsSubmit: (
        languageDetails: IFilmFestivalLanguageDetails,
    ) => void;
    languageDetails?: IFilmFestivalLanguageDetails;
}> = ({ onLanguageDetailsSubmit, languageDetails }) => {
    const [uploadedFiles, setUploadedFiles] = React.useState<FileWithPreview[]>(
        [],
    );

    const [subtitles, setSubtitles] = React.useState<
        {
            id: number;
            language: string;
            file?: FileWithPreview;
            uploadedFile?: UploadedFilwWithPreview;
        }[]
    >(
        languageDetails && languageDetails.subtitles
            ? languageDetails.subtitles
            : [],
    );

    const [showDragAndDropZone, setShowDragAndDropZone] = React.useState(
        languageDetails?.subtitles && languageDetails?.subtitles?.length > 0
            ? true
            : false,
    );
    const [
        showErrorForEmptySubtitleLanguage,
        setShowErrorForEmptySubtitleLanguage,
    ] = React.useState(false);

    const handleOnDrop = React.useCallback(
        (acceptedFiles) => {
            setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
        },
        [uploadedFiles],
    );

    const handleSubtitleToggle = React.useCallback((event) => {
        setShowDragAndDropZone(event.target.checked);
        if (!event.target.checked) {
            setUploadedFiles([]);
            setSubtitles([]);
        }
    }, []);

    const handleFileDiscard = React.useCallback(
        (index) => {
            if (index === 0) {
                setUploadedFiles([]);
            } else {
                setUploadedFiles([...uploadedFiles.splice(index, 1)]);
            }
        },
        [uploadedFiles],
    );

    const handleFileSave = React.useCallback(
        (file: FileWithPreview, language: string, index: number) => {
            if (!language) {
                setShowErrorForEmptySubtitleLanguage(true);
                return;
            }
            const newSubtitle = {
                id: index + 1,
                language,
                file,
            };
            setSubtitles([...subtitles, newSubtitle]);

            handleFileDiscard(index);
        },
        [subtitles, handleFileDiscard],
    );

    const uploadedFilesList = uploadedFiles.map((file, index) => (
        <SubtitleFile
            fileName={file.file.name}
            fileNumber={index + 1}
            onSave={(language) => handleFileSave(file, language, index)}
            onDelete={() => handleFileDiscard(index)}
        />
    ));

    const subtitleFileList = subtitles.map((subtitleFile, index) => {
        console.log(subtitleFile, "subtitleFile", !!subtitleFile.uploadedFile);
        return (
            <SubtitleList
                key={index}
                fileName={
                    subtitleFile.file?.file.name ||
                    subtitleFile.uploadedFile?.fileName ||
                    ""
                }
                fileLanguage={subtitleFile.language}
                uploaded={!!subtitleFile.uploadedFile}
            />
        );
    });

    const formik = useFormik({
        initialValues: {
            language: languageDetails?.language || "",
        },

        validationSchema: Yup.object({
            language: Yup.string().required(
                "Please select a language for the media item",
            ),
        }),

        onSubmit(values) {
            const languageAndSubtitleDetails: IFilmFestivalLanguageDetails = {
                language: values.language,
                subtitles: subtitles.map((item) => {
                    return {
                        id: item.id,
                        language: item.language,
                        ...(item.file && { file: item.file }),
                        ...(item.uploadedFile && {
                            uploadedFile: item.uploadedFile,
                        }),
                    };
                }),
            };

            onLanguageDetailsSubmit(languageAndSubtitleDetails);
        },
    });

    return (
        <>
            <Styled.LanguageDetailsHeader>
                Language and Subtitles
            </Styled.LanguageDetailsHeader>
            <Styled.HelpText>
                Use the the field below to select your video's language and, if
                you want to use captions.
            </Styled.HelpText>
            <Form onSubmit={formik.handleSubmit}>
                <Styled.RequiredText>* indicates required</Styled.RequiredText>
                <Styled.FormFieldRow>
                    <Col>
                        <SelectInput
                            fieldName="language"
                            fieldLabel="Language *"
                            options={languageOptions}
                            placeholder="Audio Language"
                            fieldValue={formik.values.language}
                            onChangeHandler={formik.handleChange}
                        />
                    </Col>
                </Styled.FormFieldRow>

                <Styled.FormFieldRow>
                    <Col>
                        <Styled.SubtitleField>
                            <Styled.FormSwitch
                                type="switch"
                                id="custom-switch"
                                checked={showDragAndDropZone}
                                label={
                                    showDragAndDropZone
                                        ? "Subtitiles: ON"
                                        : "Subtitiles: OFF"
                                }
                                onChange={handleSubtitleToggle}
                            />
                        </Styled.SubtitleField>
                    </Col>
                </Styled.FormFieldRow>
                <Styled.FormFieldRow>
                    <Col>{subtitles && subtitleFileList}</Col>
                </Styled.FormFieldRow>
                {showErrorForEmptySubtitleLanguage && (
                    <Styled.FormFieldRow>
                        <Col>
                            Please choose a language for the subtitle uploaded.
                        </Col>
                    </Styled.FormFieldRow>
                )}
                <Styled.FormFieldRow>
                    <Col>{uploadedFiles.length > 0 && uploadedFilesList}</Col>
                </Styled.FormFieldRow>
                {showDragAndDropZone && (
                    <Styled.FormFieldRow>
                        <Col>
                            <DragAndDropZone
                                showSmallImage
                                textalign="left"
                                direction="horizontal"
                                acceptedFileType=".srt"
                                onDropHandler={handleOnDrop}
                            />
                        </Col>
                    </Styled.FormFieldRow>
                )}
                <Styled.FormFieldRow>
                    <Col>
                        <BaseButton variant="secondary">Go Back</BaseButton>
                    </Col>
                    <Col>
                        <BaseButton
                            type="submit"
                            variant={!formik.isValid ? "secondary" : "primary"}
                            disabled={!formik.isValid}
                        >
                            Continue
                        </BaseButton>
                    </Col>
                </Styled.FormFieldRow>
            </Form>
        </>
    );
};
