import { Card, Col, Dropdown, Modal, Row, Tabs } from "react-bootstrap";
import styled from "styled-components";
import { colors, types } from "../../../libs";

export const UploadContentWrapper = styled.div``;

export const UploadContentTitle = styled.h3`
  ${types.headers.small};
`;

export const UploadsRow = styled(Row)`
  margin-top: 10px;
  padding: 0;
`;

export const UploadCol = styled(Col)`
  margin: 10px 0;
`;

export const UploadCard = styled(Card)`
  cursor: pointer;
  background: #121419;
  position: relative;
  border: 1px solid ${colors.text.lightWhite};
  height: 215px;
  // &.disabled {
  //   opacity: 0.7;
  // }
`;

export const ImageContainer = styled.div`
  height: 75%;
`;

export const UploadCardImage = styled(Card.Img)`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const UploadCardBody = styled(Card.Body)`
  padding: 5px 8px;
  height: 25%;
`;

export const UploadCardTitle = styled(Card.Title)`
  ${types.body.subtitle};
  color: ${colors.text.mediumWhite};
  margin-bottom: 2px;
  overflow: hidden;
  white-space: nowrap;
`;

export const UploadCardText = styled(Card.Text)`
  ${types.body.small};
`;

export const TrashIcon = styled.i`
  position: absolute;
  right: 10px;
  bottom: 5px;
  font-size: 18px;
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

export const PlayerModalHeader = styled(Modal.Header)`
  background: ${colors.background.black};
  color: ${colors.text.mediumWhite};
`;

export const PlayerModalBody = styled(Modal.Body)`
  background: ${colors.background.black};
  padding: 30px 10px;
`;

export const UploadsCategoryWrapper = styled.div`
  border-bottom: 2px solid rgba(183, 248, 77, 0.6);
  padding-bottom: 20px;
  margin-bottom: 20px;
  &:last-child {
    border: none;
  }
`;

export const UploadsTabs = styled(Tabs)`
  border: none;
  .nav-item {
    margin: 0px 4px;
  }
  .nav-link {
    color: ${colors.text.white};
    border: 1px solid ${colors.background.lightWhite} !important;
    border-radius: 5px;

    &.active {
      border: none;
      color: ${colors.text.green};
      background: ${colors.background.formField}; 
    }

    &:hover {
      border: none;
      background: ${colors.background.lightBlack}; 
    }
  }
`

export const ShowVodOptions = styled.img`
  height: 20px;
  cursor: pointer;
  z-index: 100;
`;

export const VodCardDropdown = styled(Dropdown)`
  position: absolute;
  z-index: 100;
  right: 10px;
  top: 10px;
`;

export const VodCardDropdownMenu = styled(Dropdown.Menu)`
  background: ${colors.background.formField};
  color: ${colors.text.mediumWhite};
`;

export const VodCardDropdownItem = styled(Dropdown.Item) <{ danger?: boolean }>`
  color: ${({ danger }) => (danger ? colors.text.error : colors.text.mediumWhite)};
  display: flex;
  align-items: center;
`;

export const VodCardDropdownIcon = styled.img`
  height: 1rem;
  margin-right: 0.5rem;
`;