import { IUser } from "../models";

export const shouldAllowAccess = (userInfo: IUser) => {
  return (
    (userInfo &&
      userInfo.subscriptionInfo &&
      userInfo.subscriptionInfo.packageId &&
      userInfo.subscriptionInfo.status === "PAYMENT_COMPLETED") ||
    userInfo.isAdmin
  );
};
