import React from "react";
import { Modal } from "react-bootstrap";

interface IModalProps {
    show?: boolean;
    onHide?: (params?: any) => void;
    header?: React.ReactNode;
    body: React.ReactNode;
    children: React.ReactNode;
    zIndex?: number;
}

interface Props {
    children: React.ReactNode;
}

export const DefaultModalHeader: React.FC<Props> = ({ children }) => {
    return (
        <Modal.Header closeButton>
            <Modal.Title>Modal Title</Modal.Title>
        </Modal.Header>
    );
};

export const ModalHeader: React.FC<Props> = ({ children }) => {
    return (
        <Modal.Header closeButton>
            <Modal.Title>{children}</Modal.Title>
        </Modal.Header>
    );
};

export const FullScreenModal: React.FC<IModalProps> = ({
    show,
    onHide,
    header,
    body,
    children,
    zIndex,
}) => {
    const onHideHandler = React.useCallback(() => {
        if (onHide) {
            onHide();
        }
    }, [onHide]);
    return (
        <Modal
            show={show}
            fullscreen={true}
            onHide={onHideHandler}
            style={zIndex ? { zIndex: zIndex } : {}}
        >
            {header ? header : <DefaultModalHeader children={undefined} />}
            {body}
        </Modal>
    );
};
