import { MultiPartUploader } from "../utils";
import { IFilmFestivalFormValues } from "./film-festival-upload";
import { IGeneralFormValues, IStaticFilePaths } from "./general-upload";
import { IUploadCategory } from "./uploads";
import { IUser } from "./user";

export interface IOngoingUploads {
    type: IUploadCategory;
    progress: number;
    uploadId: string;
    mediaId: string;
    formData?: IGeneralFormValues; // Add the FilmFestivalFormValues type here
    formDataFilmFestival?: IFilmFestivalFormValues;
    done: boolean;
    abort: boolean;
    filesToUpload?: {
        category: string;
        files: File[];
    }[];
    uploadedFiles?: IStaticFilePaths[]; // Will be the S3 Keys
    uploaders?: MultiPartUploader[];
}

export interface IStateContextType {
    storage: {
        used: number;
        limit: number;
        category: {
            liveStream: number;
            vod: {
                general: number;
                filmFestival: number;
            };
            other: number;
        };
    };
    isAdmin: boolean;
    allowAccess: boolean;
    currentUser?: IUser;
    ongoingUploads: IOngoingUploads[];
    refreshOnUpload: boolean;
}

export enum IStateActionType {
    SET_STORAGE = "SET_STORAGE",
    SET_IS_ADMIN = "SET_IS_ADMIN",
    SET_ALLOW_ACCESS = "SET_ALLOW_ACCESS",
    SET_USER = "SET_USER",
    SET_ONGOING_UPLOADS = "SET_ONGOING_UPLOADS",
    UPDATE_ONGOING_UPLOADS = "UPDATE_ONGOING_UPLOADS",
    SET_REFRESH_ON_UPLOAD = "SET_REFRESH_ON_UPLOAD",
}