import React from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth, useGlobalState } from "../../behavioral";
import { ManageStorageView } from "../../components";
import { IMediaResponseDto, IRecording } from "../../libs";
import { liveChannelService, userService, vodService } from "../../services";

import * as Styled from "./manage-storage-page.styled";

export const ManageStoragePage = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { dispatch, actions } = useGlobalState();

  const [showLoader, setShowLoader] = React.useState(true);
  const [showLoaderTable, setShowLoaderTable] = React.useState(false);
  const [mediaItems, setMediaItems] = React.useState<IMediaResponseDto[]>([]);
  const [recordings, setRecordings] = React.useState<IRecording[]>([]);

  const getUserUploads = React.useCallback(async () => {
    const results = await vodService.getAllMediaItems();
    if (results) {
      setMediaItems(results);

      setShowLoader(false);
    }
  }, []);

  const getRecordings = React.useCallback(async () => {
    try {
      const response = await liveChannelService.getRecordings();
      setRecordings(response.recordings);
    } catch (err) {
      console.log(err);
    } finally {
      setShowLoader(false);
    }
  }, []);

  React.useEffect(() => {
    getUserUploads();
    getRecordings();
  }, [getRecordings, getUserUploads]);

  const fetchStorage = React.useCallback(async () => {
    const email = auth.user?.attributes?.email;
    if (email) {
      const userInfo = await userService.getUserByEmail(email);
      dispatch({ type: actions.SET_STORAGE, payload: userInfo.storage });
    }
  }, [auth.user, dispatch, actions]);

  React.useEffect(() => {
    if (!auth.isAuthenticated) {
      navigate("/login", { replace: true });
    }
  }, [navigate, auth.isAuthenticated, auth.user]);

  const handleDeleteUpload = React.useCallback(
    async (row) => {
      setShowLoaderTable(true);
      if (row.category === "LIVE_STREAM_RECORDING") {
        console.log("Delete Recording Not Implemented");
      } else {
        const response = await vodService.deleteMediaItem(row.id);

        if (!response.error) {
          getUserUploads();
        }
        fetchStorage();
      }
      setShowLoaderTable(false);
    },
    [getUserUploads, fetchStorage]
  );

  return (
    <>
      {showLoader ? (
        <Styled.SpinnerWrapper>
          <Spinner animation="border">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p>Fetching the uploads...</p>
        </Styled.SpinnerWrapper>
      ) : (
        <ManageStorageView
          mediaItems={mediaItems}
          recordings={recordings}
          onDeleteUpload={handleDeleteUpload}
          showLoader={showLoaderTable}
        />
      )}
    </>
  );
};
