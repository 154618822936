import styled from "styled-components";
import { Col, Row } from "react-bootstrap";

export const VideoSelectionWrapper = styled(Row)`
    height: 100%;
    width: 100%;
`

export const VideoSelectionCol = styled(Col)`
    padding-right: 0;
`