import { InnerContainer, InnerSection } from "../ui";
import ContactForm from "./contact-form";
//import * as Styled from "./contact.styled";

import './contact.scss';

export const ContactContainer = () => {

    return (

        <InnerSection id="contact" className="contact" style={{ marginTop: '120px', overflow: 'hidden' }}>
            <InnerContainer className="container">
                <InnerContainer className="row">
                    <InnerContainer className="col-12 col-md-10 col-lg-7 mx-auto">

                        <div className="contact-form">

                            <div>
                                <h3 className="mb-3">Get in touch with us!</h3>
                                <p className="mb-5">Our team is dedicated to answering your questions about our services and subscription tiers. Inform us of your needs, and we will contact your indicated point-of-contact.</p>
                            </div>

                            <ContactForm />

                        </div >

                    </InnerContainer>
                </InnerContainer>
            </InnerContainer >
        </InnerSection >

    );
};
