export function bytesToHumanReadableSize(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (bytes === 0) return '0 Byte';

    const absBytes = Math.abs(bytes);
    const i: number = parseInt(Math.floor(Math.log(absBytes) / Math.log(1024)).toString(), 10);
    
    const readableSize = (absBytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
    return (bytes < 0 ? '-' : '') + readableSize;
}
