import React from "react";
import { useNavigate } from "react-router-dom";

import { HeaderLogo } from "../../../libs";

import SearchIcon from "./../../../assets/images/icons/search.svg";

import { Dropdown } from "react-bootstrap";

import { useAuth } from "../../../behavioral";

import * as Styled from "./DashboardHeader.styled";

import './dashboard-header.css';

export const DashboardHeader: React.FC = () => {
    const navigate = useNavigate();
    const auth = useAuth();

    const handleSignout = React.useCallback(async () => {
        try {
            await auth.signOut();
            navigate("/login", { replace: true });
        } catch (err) {
            console.log(err);
        }
    }, [auth, navigate]);

    return (
        <Styled.DashboardHeader>
            <HeaderLogo />
            <Styled.DashboardSearchWrapper>
                <Styled.SearchBarWrapper>
                    <Styled.SearchInput
                        name="search"
                        type="text"
                        placeholder="Search your library"
                    />
                    <Styled.SearchIcon src={SearchIcon} />
                </Styled.SearchBarWrapper>
            </Styled.DashboardSearchWrapper>
            <Styled.DashboardRightNavWrapper>
                <Styled.NotificationsIconWrapper>
                    <Styled.NotificationsIcon className="fas fa-bell"></Styled.NotificationsIcon>
                </Styled.NotificationsIconWrapper>

                <Styled.AvatarIconWrapper>
                    <Dropdown>
                        <Styled.AvatarIcon id="dropdown-basic">
                            <i className="fas fa-user"></i>
                        </Styled.AvatarIcon>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => console.log("Profile")}>
                                Profile
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => console.log("Settings")}>
                                Settings
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handleSignout}>Sign Out</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Styled.AvatarIconWrapper>
            </Styled.DashboardRightNavWrapper>
        </Styled.DashboardHeader>
    );
};
