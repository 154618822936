import React from "react";
import * as Styled from "./RecordingsModal.styled";
import { ModalProps } from "react-bootstrap";
import {
    BaseButton,
    Error,
    FileWithPreview,
    IRecording,
    MoveRecordingToVodRequestDto,
    MultiPartUploader,
    PublishStatus,
    TextAreaInput,
    TextInput,
} from "../../../../libs";
import { ArrowRight, XLg } from "react-bootstrap-icons";
import IconUploadThumbnail from "../../../../assets/images/icons/icon-upload-thumbnail.svg";
import UploadThumbnailBanner from "../../../../assets/images/icons/upload-thumbnail-banner.svg";
import { animated, useSpring } from "@react-spring/web";
import { CembooPlayer } from "@haydenfilms-institute/cemboo-player";
import { useDropzone } from "react-dropzone";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "../../../../behavioral";
import { v4 as uuid } from "uuid";
import { liveChannelService, vodService } from "../../../../services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface IProps extends ModalProps {
    moveToVodMedia?: IRecording;
}

export const RecordingsModal: React.FC<IProps> = ({
    moveToVodMedia,
    ...props
}) => {
    const [mainSprings, mainApi] = useSpring(() => ({
        from: { maxHeight: "100vh" },
    }));

    const [selectThumbnailSprings, selectThumbnailApi] = useSpring(() => ({
        from: { maxHeight: "0vh" },
    }));

    const [showSelectThumbnail, setShowSelectThumbnail] = React.useState(false);
    const [customSelectedThumbnail, setCustomSelectedThumbnail] =
        React.useState<FileWithPreview>();

    const { getRootProps } = useDropzone({
        accept: "image/png, image/jpeg",
        onDrop: (acceptedFiles) => {
            setCustomSelectedThumbnail({
                file: acceptedFiles[0],
                previewUrl: URL.createObjectURL(acceptedFiles[0]),
            });
        },
    });

    const handleChooseThumbnail = React.useCallback(() => {
        if (showSelectThumbnail) {
            selectThumbnailApi.start({ maxHeight: "0vh" });
            mainApi.start({ maxHeight: "100vh" });
            setShowSelectThumbnail(false);
        } else {
            mainApi.start({ maxHeight: "0vh" });
            selectThumbnailApi.start({ maxHeight: "100vh" });
            setShowSelectThumbnail(true);
        }
    }, [mainApi, selectThumbnailApi, showSelectThumbnail]);

    const handleConfirmThumbnail = React.useCallback(() => {
        const video = document.querySelector(
            "#recordings-thumbnail-player-wrapper video",
        ) as HTMLVideoElement;
        if (!video) {
            console.error("Video element not found");
            return;
        }

        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        const ctx = canvas.getContext("2d");
        if (!ctx) {
            console.error("Canvas context not found");
            return;
        }
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((blob) => {
            if (!blob) {
                console.error("Blob not found");
                return;
            }
            const file = new File([blob], "thumbnail.png", {
                type: "image/png",
            });

            const url = URL.createObjectURL(file);
            setCustomSelectedThumbnail({
                file,
                previewUrl: url,
            });
        }, "image/png");
        handleChooseThumbnail();
    }, [setCustomSelectedThumbnail, handleChooseThumbnail]);

    const clearSelectedThumbnail = React.useCallback(() => {
        setCustomSelectedThumbnail(undefined);
    }, []);

    const formik = useFormik({
        initialValues: {
            recordingTitle: "",
            recordingDescription: "",
        },
        validationSchema: Yup.object({
            recordingTitle: Yup.string()
                .required("Title is required")
                .max(100, "Max 100 characters allowed"),
            recordingDescription: Yup.string()
                .required("Description is required")
                .max(200, "Max 200 characters allowed"),
        }),
        onSubmit: (values) => {
            uploadThumbnailToS3();
        },
    });

    const resetState = React.useCallback(() => {
        setCustomSelectedThumbnail(undefined);
        formik.resetForm();
    }, [formik, setCustomSelectedThumbnail]);

    React.useEffect(() => {
        if (props.show) {
            resetState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    const isAllValid = React.useMemo(() => {
        return formik.isValid && !!customSelectedThumbnail;
    }, [formik.isValid, customSelectedThumbnail]);

    const { user } = useAuth();
    const [percentage, setPercentage] = React.useState(0);
    const navigate = useNavigate();

    const moveToVod = React.useCallback(
        async (s3Key, mediaId) => {
            if (!moveToVodMedia) {
                return;
            }
            formik.setSubmitting(true);
            const parts = moveToVodMedia.video.split("/");
            const cdn = parts.slice(0, parts.length - 1).join("/");
            const videoS3Prefix = parts.slice(3, parts.length - 1).join("/");
            const videoS3Key = parts[parts.length - 1];

            const moveToVodRequest: MoveRecordingToVodRequestDto = {
                title: formik.values.recordingTitle,
                description: formik.values.recordingDescription,
                category: "GENERAL",
                mediaId: mediaId,
                status: PublishStatus.PUBLISHED,
                objectSize: moveToVodMedia.objectSize,
                planId: [],
                video: {
                    destination: {
                        cdnPath: cdn,
                        s3Key: videoS3Key,
                        s3Prefix: videoS3Prefix,
                    },
                },
                thumbnails: {
                    userUploaded: {
                        fileName: customSelectedThumbnail?.file.name || "",
                        s3Key,
                    },
                },
            };

            await vodService.moveRecordingToVod(moveToVodRequest);
            await liveChannelService.deleteRecording(
                moveToVodMedia.id,
                moveToVodMedia.channelId,
            );
            formik.setSubmitting(false);
            props.onHide && props.onHide();
            toast.success(
                "Recording moved to VOD successfully. Click to Visit VOD Page.",
                {
                    onClick: () => {
                        navigate("/dashboard");
                    },
                },
            );
        },
        [formik, customSelectedThumbnail, props, navigate, moveToVodMedia],
    );

    const uploadThumbnailToS3 = React.useCallback(async () => {
        if (customSelectedThumbnail) {
            formik.setSubmitting(true);

            const mediaId = uuid();
            const uploader = new MultiPartUploader({
                fileName: customSelectedThumbnail.file.name,
                file: customSelectedThumbnail.file,
                clientId: user.username,
                mediaId: mediaId,
                category: "thumbnails",
            });

            uploader
                .getOnProgress((progress) => {
                    setPercentage(progress.percentage);
                })
                .getOnComplete(async (response) => {
                    await moveToVod(response.s3Key, mediaId);
                })
                .getOnError((error) => {
                    console.error(error);
                    formik.setSubmitting(false);
                });

            await uploader.start();
        }
    }, [
        customSelectedThumbnail,
        user.username,
        setPercentage,
        moveToVod,
        formik,
    ]);

    return (
        <Styled.RecordingsModal
            {...props}
            size="lg"
            dialogClassName="modal-width"
            keyboard={false}
        >
            <animated.div
                style={{
                    overflow: "hidden",
                    ...mainSprings,
                }}
            >
                <Styled.RecordingsModalWrapper>
                    <Styled.ModalTitle>
                        Move to Video On Demand
                    </Styled.ModalTitle>
                    <Styled.ModalSubtitle>
                        * To move this video to VOD you need to add a title.{" "}
                    </Styled.ModalSubtitle>
                    <Styled.Spacer />
                    <TextInput
                        placeholder="Enter title here"
                        fieldName="recordingTitle"
                        fieldLabel="Title"
                        fieldValue={formik.values.recordingTitle}
                        onChangeHandler={formik.handleChange}
                        inputLimit={100}
                        hasError={!!formik.errors.recordingTitle}
                    />
                    {formik.errors.recordingTitle && (
                        <Error message={formik.errors.recordingTitle} />
                    )}
                    <Styled.Spacer />
                    <TextAreaInput
                        placeholder="Enter description here"
                        fieldName="recordingDescription"
                        fieldLabel="Description"
                        fieldValue={formik.values.recordingDescription}
                        onChangeHandler={formik.handleChange}
                        hasError={!!formik.errors.recordingDescription}
                    />
                    {formik.errors.recordingDescription && (
                        <Error message={formik.errors.recordingDescription} />
                    )}
                    <Styled.Spacer />
                    <Styled.ThumbnailWrapper>
                        <Styled.ThumbnailWrapperTitle>
                            Thumbnail
                        </Styled.ThumbnailWrapperTitle>
                        <Styled.ThumbnailWrapperChoose
                            onClick={handleChooseThumbnail}
                        >
                            Choose frame
                            <ArrowRight className="ms-1" />
                        </Styled.ThumbnailWrapperChoose>
                    </Styled.ThumbnailWrapper>
                    <Styled.Spacer />
                    {!customSelectedThumbnail ? (
                        <Styled.ThumbnailBannerWrapper
                            {...getRootProps({ className: "dropzone" })}
                        >
                            <Styled.ThumbnailBanner
                                src={UploadThumbnailBanner}
                            />
                            <Styled.ThumbnailUploadText>
                                <Styled.ThumbnailUploadIcon
                                    src={IconUploadThumbnail}
                                />
                                Thumbnail 1920 px x 1080 px. Drag and drop here
                                or choose file.
                            </Styled.ThumbnailUploadText>
                        </Styled.ThumbnailBannerWrapper>
                    ) : (
                        <Styled.SelectedThumbnailWrapper>
                            <Styled.SelectedThumbnailLeft>
                                <Styled.SelectedThumbnail
                                    src={customSelectedThumbnail?.previewUrl}
                                />
                                <Styled.SelectedThumbnailText>
                                    {customSelectedThumbnail?.file.name}
                                </Styled.SelectedThumbnailText>
                            </Styled.SelectedThumbnailLeft>

                            <div
                                onClick={clearSelectedThumbnail}
                                style={{ cursor: "pointer" }}
                            >
                                <XLg className="mx-4" />
                            </div>
                        </Styled.SelectedThumbnailWrapper>
                    )}
                    <Styled.Spacer />
                    <Styled.ButtonWrapper>
                        <BaseButton
                            variant="secondary"
                            size="lg"
                            onClick={props.onHide}
                        >
                            Cancel
                        </BaseButton>
                        <BaseButton
                            variant={
                                !isAllValid || formik.isSubmitting
                                    ? "secondary"
                                    : "primary"
                            }
                            size="lg"
                            disabled={!isAllValid || formik.isSubmitting}
                            onClick={formik.handleSubmit}
                        >
                            {formik.isSubmitting
                                ? `Uploading ${percentage}%`
                                : `Move to VOD`}
                        </BaseButton>
                    </Styled.ButtonWrapper>
                    <Styled.Spacer />
                </Styled.RecordingsModalWrapper>
            </animated.div>
            <animated.div
                style={{
                    overflow: "hidden",
                    ...selectThumbnailSprings,
                }}
            >
                <Styled.RecordingsModalWrapper>
                    <Styled.ModalTitle>Select Thumbnail</Styled.ModalTitle>
                    <Styled.Spacer />
                    <Styled.Spacer />
                    <div id="recordings-thumbnail-player-wrapper">
                        {showSelectThumbnail && (
                            <CembooPlayer
                                url={moveToVodMedia?.video || ""}
                                showThumbnail={true}
                            />
                        )}
                    </div>
                    <Styled.Spacer />
                    <Styled.ButtonWrapper>
                        <BaseButton
                            variant="secondary"
                            size="lg"
                            onClick={handleChooseThumbnail}
                        >
                            Back
                        </BaseButton>
                        <BaseButton
                            variant="primary"
                            size="lg"
                            onClick={handleConfirmThumbnail}
                        >
                            Confirm
                        </BaseButton>
                    </Styled.ButtonWrapper>
                </Styled.RecordingsModalWrapper>
            </animated.div>
        </Styled.RecordingsModal>
    );
};
