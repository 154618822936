import React from "react";
import { Stack } from "react-bootstrap";

import { RegistrationStatus } from "../../../libs";
import * as Styled from "./RegistrationSteps.styled";

export const RegistrationSteps: React.FC<{
  currentStatus: RegistrationStatus;
}> = ({ currentStatus }) => {
  const [status, setStatus] = React.useState(RegistrationStatus.ACCOUNT_INFO);

  React.useEffect(() => {
    setStatus(currentStatus);
  }, [currentStatus]);

  return (
    <Styled.RegistrationStepsWrapper>
      <Stack direction="horizontal" gap={5} className="justify-content-center">
        <Styled.RegistrationStepIndicator
          stepName="Account Info"
          stepNumber={1}
          isActive={status === RegistrationStatus.ACCOUNT_INFO}
        >
          <Styled.NavbarLine />
        </Styled.RegistrationStepIndicator>

        <Styled.RegistrationStepIndicator
          stepName="Billing Info"
          stepNumber={2}
          isActive={status === RegistrationStatus.BILLING_INFO}
        >
          <Styled.NavbarLine />
        </Styled.RegistrationStepIndicator>
        <Styled.RegistrationStepIndicator
          stepName="Payment"
          stepNumber={3}
          isActive={status === RegistrationStatus.PAYMENT_INFO}
        ></Styled.RegistrationStepIndicator>
      </Stack>
    </Styled.RegistrationStepsWrapper>
  );
};
