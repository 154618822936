import * as React from "react";
import { UsersList } from "../../../components";
import { IUser } from "../../../libs";

import { Spinner } from "react-bootstrap";
import { adminService } from "../../../services";
import * as Styled from "./ManageUsersContainer.styled";

export const ManageUsersContainer: React.FC = () => {
  const [users, setUsers] = React.useState<IUser[]>([]);
  const [showLoader, setShowLoader] = React.useState(true);

  const getAllUsers = React.useCallback(async () => {
    const response = await adminService.getAllUsers();
    setUsers(response);
    setShowLoader(false);
  }, []);

  React.useEffect(() => {
    getAllUsers();
  }, [getAllUsers]);

  if (showLoader) {
    return (
      <Styled.SpinnerWrapper>
        <Spinner animation="border">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <p>Fetching the users...</p>
      </Styled.SpinnerWrapper>
    );
  }

  return <UsersList users={users} />;
};
