import { useEffect } from "react";
import { InnerContainer, InnerSection } from "../ui";
import { RoadmapData } from "../../data/roadmap";

import TimelineLogo from './../../assets/images/roadmap/timeline-logo.png';
import IconArrowRight from './../../assets/images/icons/arrow-right.svg';
import IconCheckmarkCircle from './../../assets/images/icons/dot-check.svg';
//import RoadmapImage from './../../assets/images/roadmap.png';

import { RoadmapItem } from "./roadmap-item";
//import * as Styled from "./roadmap.styled";

import './roadmap.scss';

export const RoadmapContainer = () => {

    useEffect(() => {

        let lines: any[] = [];
        let timelineLogo = document.getElementById('timeline-logo');
        let firstMarker = document.querySelector('.marker .marker--icon');

        const startingLine = new window.LeaderLine(
            window.LeaderLine.pointAnchor(timelineLogo, { x: '50%', y: ((timelineLogo ? timelineLogo.offsetHeight : 0) - 104) }),
            window.LeaderLine.pointAnchor(firstMarker, { x: '50%', y: -120 }),
            {
                color: 'rgba(255,255,255, 0.9)',
                size: 2,
                path: 'fluid',
                startSocket: 'bottom',
                endSocket: 'top',
                endPlug: 'behind',
                dash: {
                    len: 2,
                    gap: 16,
                    animation: false
                }
            }
        );

        let dots = document.querySelectorAll('.marker .marker--icon');

        dots.forEach((dot, idx) => {

            if (dots[idx + 1] !== undefined) {
                let line = new window.LeaderLine(
                    window.LeaderLine.pointAnchor(dot, { x: '50%', y: -120 }),
                    window.LeaderLine.pointAnchor(dots[idx + 1], { x: 'calc(50% - 1px)', y: -120 }),
                    {
                        color: 'rgba(255,255,255, 0.9)',
                        size: 2,
                        path: 'fluid',
                        startSocket: 'bottom',
                        endSocket: 'top',
                        endPlug: 'behind',
                        dash: {
                            len: 2,
                            gap: 16,
                            animation: false
                        }
                    }
                );
                lines.push(line);
            }

        });

        return () => {
            startingLine.remove();
            lines.forEach((ln) => {
                ln.remove();
            });
        };

    }, []);

    // eslint-disable-next-line
    const roadmapData = RoadmapData.map((roadmap) => (
        <RoadmapItem key={roadmap.id} {...roadmap} />
    ));

    return (
        <InnerSection id="roadmap" className="roadmap section-bg" style={{ marginTop: '120px', overflow: 'hidden' }}>

            <InnerContainer className="container">
                <InnerContainer className="row">
                    <InnerContainer className="col-12">

                        <div className="cemboo-roadmap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">

                                        <div className="row">
                                            <div className="col-12 col-md-7">
                                                <div className="intro">
                                                    <h1 className="d-inline-block gradient-text text-start mx-0 mb-5" data-aos="fade-up"
                                                        data-aos-delay="50" data-aos-duration="800">The Company: <br />About Cemboo
                                                    </h1>

                                                    <p className="mb-5" data-aos="zoom-in" data-aos-delay="100" data-aos-duration="750">Cemboo was
                                                        the direct brainchild of a decades-long endeavor in digital creativity called the
                                                        Haydenfilms Film Festival. The Festival itself was born out of Haydenfilms, an independent film and
                                                        distribution company originally founded in Kutztown, PA. Haydenfilms itself is dedicated to the
                                                        support and development of emerging independent filmmakers and student&nbsp;filmmakers.
                                                    </p>

                                                    <ul className="roadmap-list mb-5">
                                                        <li data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-back" data-aos-delay="50 * idx">
                                                            <span className="icon">
                                                                <img src={IconCheckmarkCircle} alt="" />
                                                            </span>
                                                            <span className="label">Global platform</span>
                                                        </li>
                                                        <li data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-back" data-aos-delay="50 * idx">
                                                            <span className="icon">
                                                                <img src={IconCheckmarkCircle} alt="" />
                                                            </span>
                                                            <span className="label">Distribute</span>
                                                        </li>
                                                        <li data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-back" data-aos-delay="50 * idx">
                                                            <span className="icon">
                                                                <img src={IconCheckmarkCircle} alt="" />
                                                            </span>
                                                            <span className="label">Share</span>
                                                        </li>
                                                        <li data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-back" data-aos-delay="50 * idx">
                                                            <span className="icon">
                                                                <img src={IconCheckmarkCircle} alt="" />
                                                            </span>
                                                            <span className="label">Own</span>
                                                        </li>
                                                        <li data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-back" data-aos-delay="50 * idx">
                                                            <span className="icon">
                                                                <img src={IconCheckmarkCircle} alt="" />
                                                            </span>
                                                            <span className="label">Share</span>
                                                        </li>
                                                    </ul>

                                                    <a 
                                                        className="hot-link d-flex gap-2" 
                                                        href="#roadmap-timeline" 
                                                        data-aos="fade" 
                                                        data-aos-duration="800" 
                                                        data-aos-easing="ease-out-back">
                                                        <span className="label">Learn more</span> 
                                                        <span className="icon"><img src={IconArrowRight} alt="" /></span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-5">
                                                <div className="d-flex justify-content-center">
                                                    <img
                                                        src={TimelineLogo}
                                                        alt="Haydenfilms Institute"
                                                        id="timeline-logo"
                                                        width="379"
                                                        height="587"
                                                        data-aos="fade-down"
                                                        data-aos-delay="200"
                                                        data-aos-duration="750" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row" id="roadmap-timeline">
                                            <div className="timeline-intro text-center">
                                                <h2 className="gradient-text d-inline-block mx-auto">Cemboo Roadmap</h2>
                                            </div>
                                            <div className="timeline-list mx-auto">

                                                <div className="timeline-list--item past-item d-flex flex-row flex-nowrap align-items-top" data-aos="fade-up" data-aos-delay="100" data-aos-duration="750">
                                                    <h3 className="date">2023</h3>
                                                    <div className="marker" id="marker-0"><span className="marker--icon"></span></div>
                                                    <div className="description">Secured trademark, launched Cemboo beta, onboarded 3 beta clients, and accelerated platform&nbsp;adoption.</div>
                                                </div>

                                                <div className="timeline-list--item past-item d-flex flex-row flex-nowrap align-items-top" data-aos="fade-up" data-aos-delay="100" data-aos-duration="750">
                                                    <h3 className="date">Q1 2024</h3>
                                                    <div className="marker" id="marker-1"><span className="marker--icon"></span></div>
                                                    <div className="description">Enhance live streaming scalability, optimize VOD library management, implement a user-friendly payment management system, launch Ad Server Phase I, and develop comprehensive technical support and training&nbsp;materials.</div>
                                                </div>

                                                <div className="timeline-list--item past-item d-flex flex-row flex-nowrap align-items-top" data-aos="fade-up" data-aos-delay="100" data-aos-duration="750">
                                                    <h3 className="date">Q2 2024</h3>
                                                    <div className="marker" id="marker-2"><span className="marker--icon"></span></div>
                                                    <div className="description">Empower users with storage management, unlock advanced advertising with Ad Server Phase II launch, enhance product offering with playlist creation, streamline subscriptions with integrated payment gateway, and elevate user support with in-platform video&nbsp;tutorials.</div>
                                                </div>

                                                <div className="timeline-list--item current-item d-flex flex-row flex-nowrap align-items-top" data-aos="fade-up" data-aos-delay="100" data-aos-duration="750">
                                                    <h3 className="date gradient-text">Q3 2024</h3>
                                                    <div className="marker" id="marker-3"><span className="marker--icon"></span></div>
                                                    <div className="description">Unleash advanced playlist functionalities with Phase II launch, empower creators with personalized design tools (Phase I), equip Premium users with standard analytics, and boost client marketing with downloadable customer&nbsp;data.</div>
                                                </div>

                                                <div className="timeline-list--item d-flex flex-row flex-nowrap align-items-top" data-aos="fade-up" data-aos-delay="100" data-aos-duration="750">
                                                    <h3 className="date">Q4 2024</h3>
                                                    <div className="marker" id="marker-4"><span className="marker--icon"></span></div>
                                                    <div className="description">Unleash advanced functionalities with enhanced design tools (Phase II), unlock deeper insights with advanced analytics for Professional and Enterprise clients, empower customization with custom video player features, and streamline support with integrated ticketing&nbsp;system.</div>
                                                </div>

                                                <div className="timeline-list--item d-flex flex-row flex-nowrap align-items-top" data-aos="fade-up" data-aos-delay="100" data-aos-duration="750">
                                                    <h3 className="date">Q1 2025</h3>
                                                    <div className="marker" id="marker-5"><span className="marker--icon"></span></div>
                                                    <div className="description">Empower clients with powerful marketing & engagement strategies to help maximize growth and advertisement&nbsp;revenue.</div>
                                                </div>

                                                <div className="timeline-list--item d-flex flex-row flex-nowrap align-items-top" data-aos="fade-up" data-aos-delay="100" data-aos-duration="750">
                                                    <h3 className="date">Q2 2025</h3>
                                                    <div className="marker" id="marker-6"><span className="marker--icon"></span></div>
                                                    <div className="description">Help clients create captivating video content with in-built video editing tools and release FAQs and in-depth tutorials under the Cemboo University&nbsp;brand.</div>
                                                </div>

                                                <div className="timeline-list--item d-flex flex-row flex-nowrap align-items-top" data-aos="fade-up" data-aos-delay="100" data-aos-duration="750">
                                                    <h3 className="date">Q3 2025</h3>
                                                    <div className="marker" id="marker-7"><span className="marker--icon"></span></div>
                                                    <div className="description">Help our Enterprise clients engage with their customers better with customizable video players, and enable educational clients to leverage the .edu network with academic social engagement&nbsp;tools.</div>
                                                </div>

                                                <div className="timeline-list--item d-flex flex-row flex-nowrap align-items-top" data-aos="fade-up" data-aos-delay="100" data-aos-duration="750">
                                                    <h3 className="date">Q4 2025</h3>
                                                    <div className="marker" id="marker-8"><span className="marker--icon"></span></div>
                                                    <div className="description">Launch Phase I of Cemboo Ads and Cemboo Marketplace, expand our client portfolio and partnerships through email marketing and&nbsp;advertising.</div>
                                                </div>

                                                <div className="timeline-list--item d-flex flex-row flex-nowrap align-items-top" data-aos="fade-up" data-aos-delay="100" data-aos-duration="750">
                                                    <h3 className="date">2026</h3>
                                                    <div className="marker" id="marker-8"><span className="marker--icon"></span></div>
                                                    <div className="description">Unlock new possibilities: begin research on integrating Cemboo with Web 3.0 and&nbsp;Blockchain.</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </InnerContainer>
                </InnerContainer>
            </InnerContainer>

        </InnerSection>
    );

};
