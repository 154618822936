import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";

import { Error } from "../../../libs";
import { paymentService } from "../../../services";

import * as Styled from "./ConnectWithStripe.styled";

export const ConnectWithStripe: React.FC = () => {
  const [showSpinner, setShowSpinner] = React.useState(false);

  const [error, setError] = React.useState(false);

  const handleConnectWithStripeClick = React.useCallback(async () => {
    setShowSpinner(true);
    setError(false);
    try {
      const response = await paymentService.createStripeCustomerAccount();

      window.location.href = response.data.redirect_url;
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setShowSpinner(false);
    }
  }, []);

  return (
    <>
      {error && (
        <Error message="There is an error while processing the request." />
      )}
      <Row>
        <Col md={6}>
          To ensure a seamless payment experience, we kindly request you to link
          your account with Stripe, a secure and reliable payment processing
          platform.
        </Col>
      </Row>
      <Row>
        <Col>
          <Styled.StripeButton
            onClick={handleConnectWithStripeClick}
            className="stripe-connect white"
          >
            <span>Connect with</span>{" "}
            {showSpinner && <Styled.Spinner variant="success" />}
          </Styled.StripeButton>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Text muted style={{ color: "#ccc" }}>
            On clicking the button you will redirected to Stripe website to
            process the account onboarding.
          </Form.Text>
        </Col>
      </Row>
    </>
  );
};
