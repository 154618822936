import React from "react";
import {
    IUploadCategory,
    IGeneralVideoType,
    IGeneralThumbnailType,
    IGeneralMediaRequestDto,
    IOngoingUploads,
    IGeneralFormValues,
    IStaticFilePaths,
    IStateActionType,
    MultiPartUploader,
} from "../../../libs";
import { vodService } from "../../../services";

export const buildGeneralUploadRequestDto = (
    values: IGeneralFormValues,
    staticFilePaths: IStaticFilePaths[],
    mediaId: string,
) => {
    let generalRequestDto: IGeneralMediaRequestDto | undefined;
    // get static assets uploaded paths
    let thumbnails: IGeneralThumbnailType | undefined;
    const thumbnailStaticPaths = staticFilePaths.find(
        (item) => item.category === "thumbnails",
    )?.paths[0];

    if (thumbnailStaticPaths) {
        thumbnails = thumbnailStaticPaths;
    }

    let video: IGeneralVideoType | undefined;
    const videoStaticPaths = staticFilePaths.find(
        (item) => item.category === "video",
    )?.paths[0];

    if (videoStaticPaths) {
        video = videoStaticPaths;
    }

    // create request dto
    if (values && video) {
        generalRequestDto = {
            title: values.title,
            description: values.description,
            status: values.status,
            category: IUploadCategory.GENERAL,
            mediaId: mediaId,
            thumbnails: {
                userUploaded: thumbnails,
            },
            processed: false,
            video: { source: video },
            categoryType: values.categories,
        };
    }

    return generalRequestDto;
};

export const buildGeneralEditRequestDto = (
    values: IGeneralFormValues,
    staticFilePaths: IStaticFilePaths[],
    mediaId: string,
) => {
    let generalRequestDto: Partial<IGeneralMediaRequestDto> | undefined;
    // get static assets uploaded paths
    let thumbnails: IGeneralThumbnailType | undefined;
    const thumbnailStaticPaths = staticFilePaths.find(
        (item) => item.category === "thumbnails",
    )?.paths[0];

    if (thumbnailStaticPaths) {
        thumbnails = thumbnailStaticPaths;
    }

    // create request dto
    generalRequestDto = {
        title: values.title,
        description: values.description,
        status: values.status,
        categoryType: values.categories,
        mediaId: mediaId,
    };

    if (thumbnails) {
        generalRequestDto.thumbnails = {
            userUploaded: thumbnails,
        };
    }

    return generalRequestDto;
};

export const checkGeneralUploadFiles = (upload: IOngoingUploads) => {
    if (
        upload.type === IUploadCategory.GENERAL ||
        upload.type === IUploadCategory.FILM_FESTIVAL
    ) {
        if (upload.filesToUpload && upload.uploadedFiles) {
            const uploadedCount = upload.uploadedFiles.reduce((count, obj) => {
                count += obj.paths.length;
                return count;
            }, 0);

            const actualCountToUpload = upload.filesToUpload.reduce(
                (count, obj) => {
                    count += obj.files.length;
                    return count;
                },
                0,
            );

            if (
                actualCountToUpload > 0 &&
                uploadedCount === actualCountToUpload
            ) {
                return true;
            }
        }
    }
    return false;
};

export const executeGeneralUpload = async (upload: IOngoingUploads) => {
    if (upload.type === IUploadCategory.GENERAL) {
        if (upload.filesToUpload && upload.uploadedFiles) {
            const uploadedCount = upload.uploadedFiles.reduce((count, obj) => {
                count += obj.paths.length;
                return count;
            }, 0);

            const actualCountToUpload = upload.filesToUpload.reduce(
                (count, obj) => {
                    count += obj.files.length;
                    return count;
                },
                0,
            );

            if (
                actualCountToUpload > 0 &&
                uploadedCount === actualCountToUpload &&
                upload.formData
            ) {
                const requestDto = buildGeneralUploadRequestDto(
                    upload.formData,
                    upload.uploadedFiles,
                    upload.mediaId,
                );
                if (requestDto) {
                    await vodService.createGeneralMediaItem(requestDto);
                    return true;
                }
            }
        }
    }

    return false;
};

export const uploadFilesToS3 = (
    dispatch: React.Dispatch<any>,
    upload: IOngoingUploads,
    clientId: string,
) => {
    let filePaths: {
        category: string;
        paths: { fileName: string; s3Key: string }[];
    }[] = [];

    const filesToUpload = upload.filesToUpload || [];
    const uploaders: MultiPartUploader[] = [];

    for (let index = 0; index < filesToUpload.length; index++) {
        const { files, category } = filesToUpload[index];
        console.log(`Uploading ${category} files now`);

        for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
            const file = files[fileIndex];
            const uploader = new MultiPartUploader({
                fileName: file.name,
                file: file,
                clientId: clientId,
                mediaId: upload.mediaId,
                category: category,
            });

            uploader
                .getOnProgress(function (params: any) {
                    if (category === "video") {
                        dispatch({
                            type: IStateActionType.UPDATE_ONGOING_UPLOADS,
                            payload: {
                                uploadId: upload.uploadId,
                                progress: params.percentage,
                            },
                        });
                    }
                })
                .getOnComplete(function (params: any) {
                    console.log(`Uploaded ${category} files`);
                    const groupIndex = filePaths.findIndex(
                        (elem) => elem.category === category,
                    );
                    const elem = filePaths[groupIndex];
                    if (elem) {
                        elem.paths.push({
                            s3Key: params.s3Key,
                            fileName: params.fileName,
                        });
                        filePaths[groupIndex] = elem;
                    } else {
                        filePaths.push({
                            category,
                            paths: [
                                {
                                    s3Key: params.s3Key,
                                    fileName: params.fileName,
                                },
                            ],
                        });
                    }

                    dispatch({
                        type: IStateActionType.UPDATE_ONGOING_UPLOADS,
                        payload: {
                            uploadId: upload.uploadId,
                            mediaId: upload.mediaId,
                            uploadedFiles: [...filePaths],
                        },
                    });
                })
                .getOnError((error: any) => {
                    console.log(error);
                });

            uploader.start();
            uploaders.push(uploader);
        }
    }

    dispatch({
        type: IStateActionType.UPDATE_ONGOING_UPLOADS,
        payload: {
            uploadId: upload.uploadId,
            uploaders: uploaders,
        },
    });
};
