import React from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import DataTable, { createTheme } from "react-data-table-component";
import { useGlobalState } from "../../behavioral";
import {
    BaseButton,
    ConfirmModal,
    IMediaResponseDto,
    IRecording,
    bytesToHumanReadableSize,
} from "../../libs";
import IconCaratDown from "../../assets/images/icons/icon-carat-down.svg";
import * as Styled from "./ManageStorageView.styled";
import { StorageInsights } from "./storage-insights";

interface IProps {
    mediaItems: IMediaResponseDto[];
    recordings: IRecording[];
    onDeleteUpload: (row: ManageStorageTable) => void;
    showLoader: boolean;
}

type ManageStorageTable = {
    category: string;
    name: string;
    percentage: string;
    used: string;
    created: string;
    id: string;
};

export const ManageStorageView: React.FC<IProps> = ({
    mediaItems,
    recordings,
    onDeleteUpload,
    showLoader,
}) => {
    const [storageTableRows, setStorageTableRows] = React.useState<
        ManageStorageTable[]
    >([]);
    const { state } = useGlobalState();
    const [showDropdown, setShowDropdown] = React.useState(false);

    const toggleRef = React.useRef<any>(null);
    const [filters, setFilters] = React.useState({
        libraryVOD: true,
        playlists: true,
        liveChannel: true,
        recordings: true,
    });

    const filtersRef = React.useRef(filters);

    const handleDeleteCallback = React.useCallback(
        (row) => {
            if (row.category === "LIVE_STREAM_RECORDING") {
                alert("Delete Recording Not Supported");
                return;
            }

            // confirm delete action
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <ConfirmModal
                            title="Confirm Item Removal"
                            description={[
                                {
                                    text: "Are you sure, you want to remove this item?",
                                    bold: false,
                                },
                                {
                                    text: "This operation is permanent and cannot be reverted.",
                                    bold: true,
                                },
                            ]}
                            noText="No"
                            yesText="Yes, Delete it!"
                            onNo={onClose}
                            onYes={() => {
                                // setDeletingItemId(mediaId);
                                onDeleteUpload(row);
                                onClose();
                            }}
                            danger
                        ></ConfirmModal>
                    );
                },
                closeOnEscape: true,
                closeOnClickOutside: false,
                keyCodeForClose: [8, 32],
            });
        },
        [onDeleteUpload],
    );

    const columns = [
        {
            name: "Category",
            selector: (row) => row.category,
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "Percentage",
            selector: (row) => row.percentage,
            sortable: true,
        },
        {
            name: "Used",
            selector: (row) => row.used,
            sortable: true,
        },
        {
            name: "Created",
            selector: (row) => row.created,
            sortable: true,
        },
        {
            name: "Action",
            cell: (row) => (
                <Styled.TrashIcon
                    className="fas fa-trash-alt"
                    title="Delete Item"
                    style={{ fontWeight: "900", color: "" }}
                    onClick={() => handleDeleteCallback(row)}
                ></Styled.TrashIcon>
            ),
        },
    ];

    const populateRows = React.useCallback(() => {
        const rows: ManageStorageTable[] = [];
        mediaItems.forEach((item) => {
            if (!filtersRef.current.libraryVOD) {
                return;
            }
            rows.push({
                category: item.category,
                name: item.title,
                percentage:
                    ((item.objectSize / state.storage.used) * 100).toFixed(3) +
                    "%",
                used: bytesToHumanReadableSize(item.objectSize),
                created: new Date(item.createdAt).toLocaleString("en-US", {
                    timeZone: "UTC",
                }),
                id: item.mediaId,
            });
        });

        recordings.forEach((recording) => {
            if (!filtersRef.current.recordings) {
                return;
            }
            rows.push({
                category: "LIVE_STREAM_RECORDING",
                name: recording.event.name,
                percentage:
                    ((recording.objectSize / state.storage.used) * 100).toFixed(
                        3,
                    ) + "%",
                used: bytesToHumanReadableSize(recording.objectSize),
                created: new Date(recording.event.date).toLocaleString(
                    "en-US",
                    {
                        timeZone: "UTC",
                    },
                ),
                id: recording.id,
            });
        });

        setStorageTableRows(rows);
    }, [recordings, mediaItems, state.storage, filtersRef]);

    React.useEffect(() => {
        populateRows();
    }, [mediaItems, state.storage, recordings, populateRows]);

    createTheme("dark", {
        background: {
            default: "transparent",
        },
    });

    const handleApply = React.useCallback(() => {
        if (toggleRef.current) {
            toggleRef.current.click();
        }
        filtersRef.current = filters;
        populateRows();
        setShowDropdown(false);
    }, [populateRows, setShowDropdown, toggleRef, filters]);

    return (
        <Styled.ManageStorageWrapper fluid>
            <Styled.Header>Manage Storage</Styled.Header>
            <StorageInsights
                mediaItems={mediaItems}
                liveStreamRecordings={recordings}
            />
            <Styled.ManageStorageVideoRow>
                <Styled.ManageStorageVideoRowContentWrapper>
                    <Styled.ManageStorageVideoRowTitle>
                        Video
                    </Styled.ManageStorageVideoRowTitle>
                    <Styled.ManageStorageVideoRowContent>
                        ({mediaItems.length + recordings.length})
                    </Styled.ManageStorageVideoRowContent>
                </Styled.ManageStorageVideoRowContentWrapper>
                <Dropdown
                    show={showDropdown}
                    onToggle={(nextShow) => setShowDropdown(nextShow)}
                >
                    <Styled.LocationDropdownToggle>
                        <Styled.LocationDropdownToggleWrapper>
                            Location
                            <Styled.LocationDropdownToggleIcon
                                src={IconCaratDown}
                                alt=""
                                isOpen={showDropdown}
                            />
                        </Styled.LocationDropdownToggleWrapper>
                    </Styled.LocationDropdownToggle>

                    <Styled.LocationDropdownMenu>
                        <Styled.LocationDropdownItem
                            onClick={(e) => {
                                setFilters((filts) => ({
                                    ...filts,
                                    libraryVOD: !filts.libraryVOD,
                                }));
                            }}
                        >
                            <Styled.LocationDropdownItemWrapper>
                                <Styled.LocationDropdownItemCheckbox
                                    checked={filters.libraryVOD}
                                    onChange={(e) => e.stopPropagation()}
                                    readOnly
                                />
                                <Styled.LocationDropdownItemLabel>
                                    VOD Library
                                </Styled.LocationDropdownItemLabel>
                            </Styled.LocationDropdownItemWrapper>
                        </Styled.LocationDropdownItem>
                        <Styled.LocationDropdownItem>
                            <Styled.LocationDropdownItemWrapper
                                onClick={(e) => {
                                    setFilters((filts) => ({
                                        ...filts,
                                        recordings: !filts.recordings,
                                    }));
                                }}
                            >
                                <Styled.LocationDropdownItemCheckbox
                                    checked={filters.recordings}
                                    readOnly
                                />
                                <Styled.LocationDropdownItemLabel>
                                    Recordings
                                </Styled.LocationDropdownItemLabel>
                            </Styled.LocationDropdownItemWrapper>
                        </Styled.LocationDropdownItem>
                        <Styled.LocationDropdownItem>
                            <Styled.LocationDropdownAction>
                                <BaseButton
                                    variant="secondary"
                                    onClick={() => setShowDropdown(false)}
                                >
                                    Cancel
                                </BaseButton>
                                <BaseButton
                                    variant="primary"
                                    onClick={handleApply}
                                >
                                    Apply
                                </BaseButton>
                            </Styled.LocationDropdownAction>
                        </Styled.LocationDropdownItem>
                    </Styled.LocationDropdownMenu>
                </Dropdown>
            </Styled.ManageStorageVideoRow>
            <Styled.StyledTable>
                {showLoader && (
                    <Styled.StyledTableOverlay>
                        <Spinner animation="border" />
                    </Styled.StyledTableOverlay>
                )}
                <DataTable
                    columns={columns}
                    data={storageTableRows}
                    pagination={true}
                    paginationPerPage={10}
                    theme="dark"
                />
            </Styled.StyledTable>
        </Styled.ManageStorageWrapper>
    );
};
