import { Stack, FormControl, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../../../../../libs";
import SearchIcon from "../../../../../../assets/images/icons/search.svg";


export const SelectVideoWrapper = styled(Stack)`
    width: 100%;
    height: 100%;
    padding: 2rem;
`;

export const SelectVideoHeader = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
    color: ${colors.text.white};
`;

export const SelectVideoSearchBar = styled.div`
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
`;

export const SelectVideoSearchBarInput = styled(FormControl)`
    background: url(${SearchIcon});
    background-repeat: no-repeat;
    background-position: 0.75rem center;
    padding-left: 2.5rem;
    border: 3px solid rgba(255, 255, 255, 0.20);
    border-radius: 2rem;
    height: 3rem;
`

export const SideIconsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
`

export const SearchVideoFilter = styled.div`
    display: flex;
    align-items: center;
    height: 3rem;
    border: 2px solid rgba(255, 255, 255, 0.20);
    padding: 0 1rem;
    border-radius: 2rem;
    font-size: 1rem;
    width: fit-content;
    cursor: pointer;
    overflow: hidden;
`;

export const SearchVideoFilterIcon = styled.img`
    margin-right: 0.75rem;
    min-width: 1.25rem;
    height: 1.25rem;
`

export const SearchVideoFilterText = styled.div`
    color: ${colors.text.white};
    font-weight: 600;
`

export const ChangeViewContiner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(255, 255, 255, 0.20);
    border-radius: 2rem;
    height: 3rem;
    min-width: 3rem;
`
export const ChangeViewIcon = styled.img`
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
`

export const SelectVideoBody = styled(Row)`
    margin-top: 1rem;
    height: 74vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    } 
`;

export const SelectVideoBodyCol = styled(Col)``;

export const SelectVideoCardContainer = styled.div``

export const SelectVideoCardThumbnailContainer = styled.div``

export const SelectVideoCardThumbnail = styled.img`
    width: 100%;
    cursor: pointer; 
`

export const SelectVideoCardSelect = styled.img`
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
`

export const SelectVideoCardRuntime = styled.div`
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    background: rgba(0, 0, 0, 0.50);
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    font-weight: 600;
    color: ${colors.text.white};
`

export const SelectVideoCardTitle = styled.div`
    color: ${colors.text.white};
    font-weight: 600;
    font-size: 1rem;
    margin: 0.75rem 0;
`

export const SelectedVideoRow = styled(Row)`
    height: 5rem;
    cursor: pointer;
    padding: 0 .5rem;
    &:hover {
        background: rgba(255, 255, 255, 0.10);
    }
`;

export const SelectedVideoCol = styled(Col)`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

export const SelectedVideoImg = styled.img`
    width: 100%;
    padding: 0 1.5rem;
`;

export const SelectedVideoTitle = styled.div`
    color: ${colors.text.white};
    font-size: 1.25rem;
`;

export const SelectedVideoDescription = styled.div`
    color: ${colors.text.disabled};
    font-size: 1rem;
`;

export const SelectedVideoCross = styled.img`
    width: 1.5rem;
    cursor: pointer;
`;

export const SelectVideoLoading = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.text.lightGreen};
`;