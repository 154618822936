import { LandingContainer } from "../../components";
import * as Styled from "./landing-page.styled";

export const LandingPage = () => {
  return (
    <Styled.LandingPageWrapper>
      <LandingContainer />
    </Styled.LandingPageWrapper>
  );
};
