import React from "react";
import * as Styled from "./UploadProgressModal.styled";
import { ModalProps } from "react-bootstrap";

import { useGlobalState } from "../../../behavioral";
import { XLg } from "react-bootstrap-icons";

export const UploadProgressModal: React.FC<ModalProps> = (props) => {
    const { state, dispatch, actions } = useGlobalState();

    const handleAbortUpload = React.useCallback(
        (uploadId: string) => {
            const currentUpload = state.ongoingUploads.find(
                (upload) => upload.uploadId === uploadId,
            );
            currentUpload?.uploaders?.forEach((uploader) => {
                uploader.abort();
            });
            dispatch({
                type: actions.UPDATE_ONGOING_UPLOADS,
                payload: {
                    uploadId,
                    abort: true,
                },
            });
        },
        [actions, dispatch, state.ongoingUploads],
    );

    return (
        <Styled.UploadProgressModal
            {...props}
            size="lg"
            dialogClassName="modal-width"
            // keyboard={false}
        >
            <Styled.UploadProgressModalWrapper>
                <Styled.ModalTitle>
                    Ongoing uploads
                    <XLg style={{ cursor: "pointer" }} onClick={props.onHide} />
                </Styled.ModalTitle>
                {state.ongoingUploads.map((upload, index) => {
                    return (
                        <Styled.ItemWrapper key={`ongoing_uploads_${index}`}>
                            <Styled.CameraReelsIcon />
                            <Styled.ItemBodyWrapper>
                                <Styled.ItemTitleWrapper>
                                    <Styled.ItemTitle>
                                        {upload.formData?.title ||
                                            upload.formDataFilmFestival?.details
                                                .title ||
                                            "Untitled"}
                                    </Styled.ItemTitle>
                                    <Styled.ItemProgress>
                                        {upload.abort
                                            ? "Aborted"
                                            : upload.progress === 100
                                              ? "Uploaded"
                                              : upload.progress === 0
                                                ? "Queued"
                                                : `${upload.progress}%`}
                                    </Styled.ItemProgress>
                                </Styled.ItemTitleWrapper>
                                <Styled.UploadProgressBar
                                    now={upload.progress}
                                    variant={
                                        upload.abort ? "danger" : "primary"
                                    }
                                />
                            </Styled.ItemBodyWrapper>
                            {upload.abort ? (
                                <Styled.AbortIcon />
                            ) : upload.progress === 100 ? (
                                <Styled.CheckIcon />
                            ) : (
                                <Styled.CloseIcon
                                    disabled={upload.abort}
                                    onClick={() =>
                                        handleAbortUpload(upload.uploadId)
                                    }
                                />
                            )}
                        </Styled.ItemWrapper>
                    );
                })}
            </Styled.UploadProgressModalWrapper>
        </Styled.UploadProgressModal>
    );
};
