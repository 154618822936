import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { colors } from "../../../../../libs";
import { MenuItem, MenuList, Paper } from "@mui/material";

export const ShowPlaylistsModalWrapper = styled(Row)``;

export const ShowPlaylistsModalCol = styled(Col)`
    .MuiTableHead-root {
        .MuiTableCell-root {
            border: none;
        }
    }
    .MuiTableBody-root {
        .MuiTableRow-root {
            .MuiTableCell-root {
                border: none;
                overflow: visible;
            }
        }
    }
`;

export const TitleCellWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 700;
    img {
        border-radius: 0.5rem;
        border: 1px solid ${colors.background.darkGreen};
        height: 3.5rem;
        margin-right: 0.5rem;
    }
`;

export const OptionsIcon = styled.img`
    cursor: pointer;
    height: 1rem;
`;

export const DropdownPaper = styled(Paper)`
    border-radius: 0.5rem;
    padding: 0;
    z-index: 200;
`;

export const DropdownMenuList = styled(MenuList)`
    background-color: ${colors.background.formField};
    border: 1px solid ${colors.background.lightWhite};
    border-radius: 0.5rem;
    &.MuiList-root {
        padding: 0;
    }
`;

export const DropdownMenuItem = styled(MenuItem)<{ danger?: boolean }>`
    color: ${({ danger }) =>
        danger ? colors.text.error : colors.text.white} !important;
    &.MuiMenuItem-root {
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const DropdownMenuIcon = styled.img`
    height: 1.5rem;
    margin-right: 0.5rem;
`;

export const BottomBar = styled(Row)`
    background-color: ${colors.background.lightBlack};
    padding: 1rem;
    border-radius: 0.5rem;
    margin-top: 1rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
`;
export const TableWrapper = styled.div`
    overflow-x: auto;
    max-height: 75vh;
    width: 100%;
    border-radius: 0.5rem;
    padding-bottom: 3.5rem;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const BottomActionWrapper = styled.div`
    width: 50%;
`;
