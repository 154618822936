import styled from "styled-components";
import { colors } from "../../../../../../libs";
import { Stack, Spinner, Row, Col } from "react-bootstrap";

export const SelectedVideosWrapper = styled(Stack)`
    height: 100%;
    width: 100%;
    background-color: ${colors.background.lightBlack};
    padding: 3rem;
`;

export const SelectedVideosHeader = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
    color: ${colors.text.white};
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
`;

export const SelectedVideosHeaderText = styled.div`
    color: ${colors.text.white};
    font-size: 1.5rem;
    max-width: 85%;
`

export const SelectedVideoHeaderMutedText = styled.div<{ fontSize?: string, shouldSpace?: boolean }>`
    color: ${colors.text.disabled};
    font-size: ${props => props.fontSize || "1rem"};
    margin: ${props => props.shouldSpace ? "0 5px" : "0"};
    font-weight: 700;
    display: inline-block;
`;

export const SelectedVideosHeaderSpinner = styled(Spinner)`
    margin: 0 10px;
    color: ${colors.text.green};
`;

export const SelectedVideosHeaderSpinnerWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: ${colors.text.disabled};
`

export const SelectedVideosColumnTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
`;

export const SelectedVideosColumnTitleMutedText = styled.div`
    color: ${colors.text.disabled};
    font-size: 1rem;
`;

export const SelectedVideosColumnClearAllText = styled.div`
    color: ${colors.text.green};
    font-size: 1rem;
    cursor: pointer;
`;

export const SelectedVideosRowsWrapper = styled.div`
    background-color: ${colors.background.black};
    height: 65vh;
    margin-bottom: 1rem;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const SelectedVideoRow = styled(Row)`
    height: 5rem;
    cursor: pointer;
    padding: 0 .5rem;
`;

export const SelectedVideoCol = styled(Col)`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

export const SelectedVideoImg = styled.img`
    width: 100%;
`;

export const SelectedVideoTitle = styled.div`
    color: ${colors.text.white};
    font-size: 1.25rem;
`;

export const SelectedVideoDescription = styled.div`
    color: ${colors.text.disabled};
    font-size: 1rem;
`;

export const SelectedVideoCross = styled.img`
    width: 1.5rem;
    cursor: pointer;
`;


export const SelectedVideosButtonWrapper = styled(Row)``;
export const SelectedVideosButtonCol = styled(Col)``;