import * as React from "react";
import { Container } from "react-bootstrap";

import { ILiveChannelDetails, ILiveChannelRequest } from "../../../libs";
import { liveChannelService } from "../../../services";
import { LiveChannelForm } from "../live-channel-form/LiveChannelForm";
import * as Styled from "./EditChannel.styled";

export const EditChannel: React.FC<{
  channelData: ILiveChannelDetails;
  saveEditChannel?: number;
  onCancel: () => void;
  onEditComplete: () => void;
}> = ({ onCancel, onEditComplete, channelData, saveEditChannel }) => {
  const [editChannelError, setEditChannelError] = React.useState("");

  // const getRecentEvent = React.useMemo(() => {
  //   return channelData.events.reduce((item1, item2) => {
  //     return new Date(item1.date) > new Date(item2.date) ? item1 : item2;
  //   });
  // }, [channelData]);

  const initialValues = {
    name: channelData.channelName,
    isPrivate: channelData.isPrivate,
    eventName: channelData.event?.name,
    eventStartDate: channelData.event?.startDate,
    eventEndDate: channelData.event?.endDate,
    autoRecordEnabled: channelData.recordingEnabled,
    domainName: channelData.website,
    planId: channelData.planDetails?.map(plan => plan.id),
    sponsorLogo: channelData?.sponsorLogo,
  };

  const handleSubmit = React.useCallback(
    async (data: ILiveChannelRequest, setSubmitting) => {
      try {
        const channelId = await liveChannelService.updateLiveChannel(
          channelData.id,
          data
        );
        if (channelId) {
          setSubmitting(false);
          onEditComplete();
        }
      } catch (err) {
        console.log("There is an error while updating the channel", err);
        setEditChannelError(
          "There is an error while updating the channel. Please try again."
        );
        setSubmitting(false);
      }
    },
    [channelData.id, onEditComplete]
  );

  return (
    <Container fluid>
      <Styled.CreateChannelHeader>Edit Channel</Styled.CreateChannelHeader>
      <LiveChannelForm
        initialValues={initialValues}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        errorMessage={editChannelError}
        saveChannel={saveEditChannel}
      />
    </Container>
  );
};
