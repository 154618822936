import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { AdServerItems } from "./adserver-items";

import AdPreviewImage from "../../assets/images/adPreview1.png";

import * as Styled from "./AdServerComponent.styled";

export const AdServerComponent: React.FC = () => {
  const [activeTabName, setActiveTabName] = React.useState("banners");
  return (
    <Styled.AdServerContainer fluid className="pt-10">
      <Styled.AdServerRow>
        <Styled.AdDetailsCol md={8}>
          <Row>
            <Styled.WelcomeHeaderWrapper>
              <Styled.WelcomeHeader>AdServer</Styled.WelcomeHeader>
              <Styled.AdServerTabsContainer>
                <Styled.AdServerTabTitle
                  className={activeTabName === "banners" ? "active" : ""}
                  onClick={() => setActiveTabName("banners")}
                >
                  Banners
                </Styled.AdServerTabTitle>
                <Styled.AdServerTabTitle
                  className={activeTabName === "rollads" ? "active" : ""}
                  onClick={() => setActiveTabName("rollads")}
                >
                  Roll Ads
                </Styled.AdServerTabTitle>
              </Styled.AdServerTabsContainer>
            </Styled.WelcomeHeaderWrapper>
          </Row>
          <Styled.AdServerItemRow>
            <Col>
              <AdServerItems />
            </Col>
          </Styled.AdServerItemRow>
        </Styled.AdDetailsCol>
        <Styled.PreviewWrapper md={4}>
          <Styled.PreviewHeader>Preview</Styled.PreviewHeader>
          <Image src={AdPreviewImage} width={250} />
        </Styled.PreviewWrapper>
      </Styled.AdServerRow>
    </Styled.AdServerContainer>
  );
};
