import styled from "styled-components";

export const StyledButton = styled.button`
    padding: 10px 24px;
    box-shadow: none;
    color: rgba(183, 248, 77, 1);
    background: linear-gradient(
        103.07deg,
        rgba(183, 248, 77, 0.2) -38.19%,
        rgba(141, 198, 63, 0.2) 102.21%
    );
    border: 1px solid rgba(183, 248, 77, 0.2);
    font-weight: 600;
    font-size: 14px;
    border-radius: 1px;
    letter-spacing: 0.005em;
    line-height: 20px;
    cursor: pointer;
`;
