import { Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../../../libs";

import SwitchOffImage from "./../../../../assets/images/switch_off.svg";
import SwitchOnImage from "./../../../../assets/images/switch_on.svg";

export const PackageForm = styled(Form)`
  @media screen and (min-width: 1200px) {
    width: 50%;
  }
`;
export const PackageFormFieldRow = styled(Row)`
  margin: 20px 0;
  padding: 0;
`;

export const PackageFormSwitch = styled(Form.Check)`
  display: inline-block;
  margin-left: 10px;
  .form-check-input {
    cursor: pointer;
    width: 40px;
    height: 20px;
    color: ${colors.text.white};
    background-color: #1e2025;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-image: url(${SwitchOffImage});
    &:focus {
      background-image: url(${SwitchOffImage});
      border-color: transparent;
      box-shadow: none;
      border: 1px solid rgba(255, 255, 255, 0.5);
    }
    &:checked {
      background-color: #b7f84d;
      border-color: #b7f84d;
      background-image: url(${SwitchOnImage});
    }
  }
`;
