import React from "react";
import { NoPlaylists } from "./no-playlists";
import * as Styled from "./PlaylistsView.styled";
import { CreateModal } from "./create-modal";
import { IPlaylistResponseDto } from "../../libs";
import { playlistsService } from "../../services";
import { ShowPlaylists } from "./show-playlists";
import { Spinner } from "react-bootstrap";

export const PlaylistsView: React.FC = () => {
    const [playlistName, setPlaylistName] = React.useState("");
    const [show, setShow] = React.useState(false);
    const [playlists, setPlaylists] = React.useState<IPlaylistResponseDto[]>(
        [],
    );
    const [showSpinner, setShowSpinner] = React.useState(true);

    const startCreatePlaylist = React.useCallback(
        (name: string) => {
            setShow(true);
            setPlaylistName(name);
        },
        [setPlaylistName],
    );

    const fetchPlaylists = React.useCallback(
        async (force: boolean = false) => {
            if (playlists.length !== 0 && !force) return;
            setShowSpinner(true);
            const response = await playlistsService.getAllPlaylists();
            setPlaylists(response);
            setShowSpinner(false);
        },
        [playlists, setPlaylists, setShowSpinner],
    );

    const refreshPlaylists = React.useCallback(() => {
        fetchPlaylists(true);
    }, [fetchPlaylists]);

    React.useEffect(() => {
        fetchPlaylists();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {showSpinner ? (
                <Styled.SpinnerWrapper>
                    <Spinner variant="primary" animation="border" />
                    Loading
                </Styled.SpinnerWrapper>
            ) : playlists.length > 0 ? (
                <ShowPlaylists
                    playlists={playlists}
                    createPlaylist={startCreatePlaylist}
                    refreshPlaylists={refreshPlaylists}
                />
            ) : (
                <>
                    <Styled.WelcomeHeaderWrapper>
                        <Styled.WelcomeHeader>
                            Your Playlists
                        </Styled.WelcomeHeader>
                    </Styled.WelcomeHeaderWrapper>
                    <NoPlaylists createPlaylist={startCreatePlaylist} />
                </>
            )}

            <CreateModal
                show={show}
                onHide={() => {
                    setShow(false);
                    refreshPlaylists();
                }}
                playlistName={playlistName}
            />
        </>
    );
};
