import styled from "styled-components";
import SwitchOffImage from "./../../../../assets/images/switch_off.svg";
import SwitchOnImage from "./../../../../assets/images/switch_on.svg";
import { colors } from "../../../themes";
import { Form } from "react-bootstrap";

export const Switch = styled(Form.Check)`
  padding-left: 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  .form-check-input {
    width: 40px;
    height: 20px;
    color: ${colors.text.white};
    background-color: #1e2025;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-image: url(${SwitchOffImage});
    &:focus {
      background-image: url(${SwitchOffImage});
      border-color: transparent;
      box-shadow: none;
      border: 1px solid rgba(255, 255, 255, 0.5);
    }
    &:checked {
      background-color: #b7f84d;
      border-color: #b7f84d;
      background-image: url(${SwitchOnImage});
    }
  }
`;