export interface IStorage {
  category: {
    liveStream: number;
    other: number;
    vod: {
      filmFestival: number;
      general: number;
    };
  };
  limit: number;
  used: number;
}
export interface IUser {
  userId: string;
  email: string;
  name: string;
  phone: string;
  storage: IStorage;
  isAdmin: boolean;
  isConfirmed?: boolean;
  subscriptionInfo?: {
    customerId: string;
    status: string;
    subscriptionId: string;
    packageId: string;
  };
  manualpayInfo?: {
    description: string;
    paydate: string;
    validitydate: string;
    amount: number;
    accno: string;
  }
}

export interface IUserRequest {
  userId: string;
  email: string;
  name: string;
  phoneNumber: string;
  isConfirmed?: boolean;
}

export enum RegistrationStatus {
  ACCOUNT_INFO = "ACCOUNT_INFO",
  BILLING_INFO = "BILLING_INFO",
  PAYMENT_INFO = "PAYMENT_INFO",
}
