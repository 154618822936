import { useLocation } from "react-router-dom";
import { PricingContainer } from "../../components";
import * as Styled from "./pricing-page.styled";

export const PricingPage = () => {
  const { search } = useLocation();

  const ref = new URLSearchParams(search).get("ref");
  let decoded: any;
  if (ref) {
    try {
      decoded = JSON.parse(window.atob(ref));
    } catch (err) {
      console.log("Unable to parse the search string", err);
    }
  }

  return (
    <Styled.PricingPageWrapper>
      <PricingContainer
        userId={decoded?.userId}
        name={decoded?.name}
        email={decoded?.email}
      />
    </Styled.PricingPageWrapper>
  );
};
