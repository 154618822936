import * as React from "react";
import { Col, Container } from "react-bootstrap";

import { BaseButton, IPaymentAccount } from "../../../libs";

import { paymentService } from "../../../services";
import * as Styled from "./PaymentAccountDetails.styled";

interface IProps {
  accountDetails: IPaymentAccount;
  onUpdateAccountClick: () => void;
}

export const PaymentAccountDetails: React.FC<IProps> = ({
  accountDetails,
  onUpdateAccountClick,
}) => {
  const handleUpdateAccountClick = React.useCallback(async () => {
    if (accountDetails.requirements.length > 0) {
      // Create endpint to get account update link
      const result = await paymentService.getUpdateAccountLink(
        accountDetails.accountId
      );
      window.location.href = result.data?.redirect_url;
    } else {
      onUpdateAccountClick();
    }
  }, [accountDetails, onUpdateAccountClick]);

  return (
    <Container fluid>
      <Styled.DetailRow>
        <Col md={12} xl={6}>
          <Styled.Subtitle>Account Details</Styled.Subtitle>
        </Col>
      </Styled.DetailRow>

      <Styled.DetailRow>
        <Col md={4} xl={3}>
          <Styled.DetailLabel>ID</Styled.DetailLabel>
          <Styled.DetailText>{accountDetails.accountId}</Styled.DetailText>
        </Col>
        <Col md={4} xl={3}>
          <Styled.DetailLabel>Identification & Validation</Styled.DetailLabel>
          <Styled.DetailText>
            {accountDetails.enabled ? "Completed" : "Pending"}
          </Styled.DetailText>
        </Col>
        <Col md={4} xl={3}>
          <Styled.DetailLabel>Currency</Styled.DetailLabel>
          <Styled.DetailText>
            {accountDetails.currency?.toUpperCase()}
          </Styled.DetailText>
        </Col>
      </Styled.DetailRow>
      {accountDetails.bankAccount.map((item: any) => (
        <div key={item.bankName}>
          <Styled.DetailRow>
            <Col md={4} xl={3}>
              <Styled.DetailLabel>Bank Name</Styled.DetailLabel>
              <Styled.DetailText>{item.bankName}</Styled.DetailText>
            </Col>
            <Col md={4} xl={3}>
              <Styled.DetailLabel>Account Holder Name</Styled.DetailLabel>
              <Styled.DetailText>
                {item.accountHolderName ?? "Not Available"}
              </Styled.DetailText>
            </Col>
          </Styled.DetailRow>
          <Styled.DetailRow>
            <Col md={4} xl={3}>
              <Styled.DetailLabel>Account Number</Styled.DetailLabel>
              <Styled.DetailText>XXXX-{item.accountNumber}</Styled.DetailText>
            </Col>
            <Col md={4} xl={3}>
              <Styled.DetailLabel>Routing Number</Styled.DetailLabel>
              <Styled.DetailText>{item.routingNumber}</Styled.DetailText>
            </Col>
          </Styled.DetailRow>
        </div>
      ))}

      {!accountDetails.enabled && accountDetails.requirements.length === 0 && (
        <Styled.DetailRow>
          <Col md={6}>
            Thank you for providing all the required details, Please be patient
            while we onboard you account Cemboo. We will notify once the process
            is completed and your account is ready to use.
          </Col>
        </Styled.DetailRow>
      )}
      {accountDetails.requirements.length > 0 && (
        <>
          <Styled.DetailRow>
            <Col>
              There are few required details missing on your account, please
              complete the setup to enable payouts.
            </Col>
          </Styled.DetailRow>
          <Styled.DetailRow>
            <Col xl={2} md={4}>
              <BaseButton onClick={handleUpdateAccountClick}>
                Complete Setup
              </BaseButton>
            </Col>
          </Styled.DetailRow>
        </>
      )}

      {accountDetails.enabled && (
        <Styled.DetailRow>
          <Col xl={3} md={6}>
            <BaseButton onClick={handleUpdateAccountClick}>
              Update Account
            </BaseButton>
          </Col>
        </Styled.DetailRow>
      )}
    </Container>
  );
};
