export const ROUTEPATHS = {
  HOME: "/",
  ABOUT: "/about",
  PRICING: "/pricing",
  ROADMAP: "/roadmap",
  BLOCKCHAIN: "/blockchain",
  CONFIRM_USER: "/confirm_user",
  TEAM: "/team",
  CONTACT: "/contact",
  LOGIN: "/login",
  REGISTER: "/register",
  DASHBOARD: "/dashboard",
  VOD: "/vod-uploads",
  LIVE_CHANNELS: "/live-channels",
  AD_SERVER: "/ad-server",
  RECORDINGS: "/recordings",
  MANAGE_PAYMENTS: "/manage-payments",
  MANAGE_STORAGE: "/manage-storage",
  MANAGE_LIBRARIES: "/manage-libraries",
  ADMIN: "/admin",
  MANAGE_USERS: "/manage-users",
  MANAGE_PACKAGES: "/manage-packages",
  PLAYLISTS: "/playlists",
};

export const isComingSoon = false;

export const API_BASE_PATHS = {
  USERS_API: "v1/user",
  VOD_API: "v1/vod",
  STREAM_API: "v1/live-stream",
  PAYMENTS_API: "v1/payments",
  AD_SERVER_API: "v1/ad-server",
  ADMIN_API: "v1/admin",
  PLAYLISTS_API: "v1/playlist-service",
  ANALYTICS_API: "v1/analytics",
};

export const API_ROUTES = {
  // Users service API Paths
  GET_CURRENT_USER: "me",
  GET_USER_BY_EMAIL: "search",
  GET_LIBRARY_CONFIG: "library-config",
  REGISTER_USER: "register",
  CREATE_SUBSCRIPTION: "create-subscription",
  COMPLETE_SUBSCRIPTION: "complete-subscription",
  GET_PROMO_CODE_ID: "promocode",

  // VOD API Paths
  MEDIA: "list",
  INITIALIZE_MULTIPART_UPLOAD: "initialize-multipart-upload",
  GET_MULTIPART_PRESIGNED_URLS: "create-multipart-presigned-urls",
  FINALIZE_MULTIPART_UPLOAD: "finalize-multipart-upload",
  MOVE_TO_VOD: "movetovod",
  CATEGORY: "category",

  // Live Channels paths
  CHANNELS: "channels",
  CHANNEL: "channel",
  GET_EVENTS: "upcoming-events",

  // Payment service paths
  CREATE_ACCOUNT: "create-account",
  SAVE_ACCOUNT: "save-account",
  GET_ACCOUNT_DETAILS: "get-account-details",
  MEMBER_PLANS: "member-plans",
  UPDATE_ACCOUNT: "update-account",
  GET_UPDATE_ACCOUNT_LINK: "update-account-link",

  // Ad Server paths
  STATIC_AD: "staticAd",

  // Admin Paths
  USERS: "users",
  GENERATE_PAYMENT_LINK: "users/sendemail",
  PACKAGES: "packages",
  UPDATE_MANUAL_PAYMENT: "payment/users",

  // Playlist service paths
  PLAYLIST: "playList",

  // Analytics paths
  METRICES: "videometrics",
  METRICES_SUMMARY: "videometrics/summary",
};
