import axios from "axios";
import {
  API_BASE_PATHS,
  API_ROUTES,
  IGeneratePaymentLinkRequest,
  IPackage,
  IPackageRequest,
  IUpdatePaymentDetails,
  IUser,
} from "../libs";
import { buildRequestConfig } from "./buildRequestConfig";

class AdminService {
  async getAllUsers(): Promise<IUser[]> {
    try {
      const config = await buildRequestConfig();
      const { data: userData } = await axios.get(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.ADMIN_API}/${API_ROUTES.USERS}`,
        { ...config }
      );

      return userData.data;
    } catch (err: any) {
      console.log("There is an error while fetching all the users", err);
      throw new Error(err);
    }
  }

  async updateUser(
    userId: string,
    packageId: string,
    packageName: string
  ): Promise<IUser> {
    try {
      const config = await buildRequestConfig();
      const { data: userData } = await axios.put(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.ADMIN_API}/${API_ROUTES.USERS}/${userId}`,
        { packageId, packageName },
        { ...config }
      );

      return userData.data;
    } catch (err: any) {
      console.log("There is an error while updating the user", err);
      throw new Error(err);
    }
  }

  async createPackage(packageRequest: IPackageRequest): Promise<IPackage> {
    try {
      const config = await buildRequestConfig();
      const { data: packageData } = await axios.post(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.ADMIN_API}/${API_ROUTES.PACKAGES}`,
        { ...packageRequest },
        { ...config }
      );

      return packageData.data;
    } catch (err: any) {
      console.log("There is an error while creating a package", err);
      throw new Error(err);
    }
  }

  async updatePackage(
    packageId: string,
    packageRequest: IPackageRequest
  ): Promise<IPackage> {
    try {
      const config = await buildRequestConfig();
      const { data: packageData } = await axios.put(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.ADMIN_API}/${API_ROUTES.PACKAGES}/${packageId}`,
        { ...packageRequest },
        { ...config }
      );

      return packageData.data;
    } catch (err: any) {
      console.log("There is an error while updating a package", err);
      throw new Error(err);
    }
  }

  async deletePackage(packageId: string): Promise<IPackage> {
    try {
      const config = await buildRequestConfig();
      const { data: packageData } = await axios.delete(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.ADMIN_API}/${API_ROUTES.PACKAGES}/${packageId}`,
        { ...config }
      );

      return packageData.data;
    } catch (err: any) {
      console.log("There is an error while deleting a package", err);
      throw new Error(err);
    }
  }

  async getAllPackages(): Promise<IPackage[]> {
    try {
      const { data: packageData } = await axios.get(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.ADMIN_API}/${API_ROUTES.PACKAGES}`
      );

      return packageData.data;
    } catch (err: any) {
      console.log("There is an error while fetching all packages", err);
      throw new Error(err);
    }
  }

  async getPackageById(packageId: string): Promise<IPackage> {
    try {
      const { data: packageData } = await axios.get(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.ADMIN_API}/${API_ROUTES.PACKAGES}/${packageId}`
      );

      return packageData.data;
    } catch (err: any) {
      console.log("There is an error while fetching a package", err);
      throw new Error(err);
    }
  }

  async generatePaymentLink(
    generatePaymentLinkRequest: IGeneratePaymentLinkRequest
  ): Promise<void> {
    try {
      const config = await buildRequestConfig();
      await axios.post(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.ADMIN_API}/${API_ROUTES.GENERATE_PAYMENT_LINK}`,
        { ...generatePaymentLinkRequest },
        { ...config }
      );
    } catch (err: any) {
      console.log("There is an error while generating payment link", err);
      throw new Error(err);
    }
  }

  async updateManualPayment(
    userId: string,
    manualPaymentDetails: IUpdatePaymentDetails
  ): Promise<void> {
    try {
      const config = await buildRequestConfig();
      await axios.put(
        `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.ADMIN_API}/${API_ROUTES.UPDATE_MANUAL_PAYMENT}/${userId}`,
        { ...manualPaymentDetails },
        { ...config }
      );
    } catch (err: any) {
      console.log("There is an error while updating manual payment", err);
      throw new Error(err);
    }
  }

}

export const adminService = new AdminService();
