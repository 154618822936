import * as React from "react";
import { ConfirmModal, Error } from "../../libs";

import VerificationInput from "react-verification-input";

import * as Styled from "./VerifyAccountModal.styled";

interface IProps {
  onClose: () => void;
  unverifiedEmail: string;
  onConfirmAccount: (code: string, email: string) => Promise<void>;
  onConfirmSuccessContinue: () => void;
  onResendCode: (email: string) => Promise<void>;
}

export const VerifyAccountModal: React.FC<IProps> = ({
  onClose,
  onConfirmAccount,
  unverifiedEmail,
  onConfirmSuccessContinue,
  onResendCode,
}) => {
  const [confirmCode, setConfirmCode] = React.useState("");
  const [isCodeResendRequested, setIsCodeResendRequested] =
    React.useState(false);
  const [confirmCodeErrorMessage, setConfirmCodeErrorMessage] =
    React.useState("");

  const [showConfirmationSuccess, setShowConfirmationSuccess] =
    React.useState(false);
  const handleInputComplete = React.useCallback((value: string) => {
    setConfirmCode(value);
  }, []);

  const handleResendCode = React.useCallback(async () => {
    await onResendCode(unverifiedEmail);
    setIsCodeResendRequested(true);
  }, [onResendCode, unverifiedEmail]);

  const handleConfirmAccount = React.useCallback(async () => {
    try {
      await onConfirmAccount(unverifiedEmail, confirmCode);
      setShowConfirmationSuccess(true);
    } catch (err: any) {
      switch (err.code) {
        case "CodeMismatchException":
          setConfirmCodeErrorMessage(
            "Invalid verification code provided, please try again."
          );
          break;
        case "InvalidPasswordException":
          setConfirmCodeErrorMessage(
            "Password did not conform with policy, please refer to the policy."
          );
          break;
        case "UsernameExistsException":
          setConfirmCodeErrorMessage(
            "An account with the given email already exists."
          );
          break;
        default:
          setConfirmCodeErrorMessage(
            "There is an error while creating the account, please try again after sometime"
          );
      }
    }
  }, [onConfirmAccount, unverifiedEmail, confirmCode]);

  return (
    <ConfirmModal
      title={showConfirmationSuccess ? "Account Verified" : "Verify your email"}
      description={
        showConfirmationSuccess
          ? [
              {
                text: `Congratulations, your account has been successfully verified.`,
                bold: true,
              },
            ]
          : [
              {
                text: isCodeResendRequested
                  ? `We have resent the confirmation code to your email: `
                  : `Please enter the 6-digit code sent to: `,
                bold: false,
              },
              {
                text: `${unverifiedEmail}`,
                bold: true,
              },
            ]
      }
      customContent={
        showConfirmationSuccess ? undefined : (
          <>
            {confirmCodeErrorMessage && (
              <Error message={confirmCodeErrorMessage} />
            )}
            <VerificationInput
              length={6}
              placeholder=""
              classNames={{
                container: "verify-account-modal-container",
                character: "verify-account-modal-container character",
                characterInactive:
                  "verify-account-modal-container character--inactive",
                characterSelected:
                  "verify-account-modal-container character--selected",
              }}
              onComplete={handleInputComplete}
            />
            <Styled.ResendCodeText>
              Didn't Receive Code?{" "}
              <span onClick={handleResendCode}>Resend Code</span>
            </Styled.ResendCodeText>
          </>
        )
      }
      noText={showConfirmationSuccess ? undefined : "Cancel"}
      yesText={showConfirmationSuccess ? "Continue" : "Confirm"}
      onYes={
        showConfirmationSuccess
          ? onConfirmSuccessContinue
          : handleConfirmAccount
      }
      onNo={showConfirmationSuccess ? undefined : onClose}
    ></ConfirmModal>
  );
};
