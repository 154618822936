import * as React from "react";
import { Container } from "react-bootstrap";

import { IPackage, IPackageRequest } from "../../../../libs";
import { adminService } from "../../../../services";
import { PackageForm } from "../package-form";

export const EditPackage: React.FC<{
  packageItem: IPackage;
  onCancel: () => void;
  onEditComplete: () => void;
}> = ({ onCancel, onEditComplete, packageItem }) => {
  const [updatePackageError, setUpdatePackageError] = React.useState("");

  const initialValues: IPackageRequest = {
    name: packageItem.name,
    description: packageItem.description,
    monthlyPrice: +packageItem.monthlyPrice,
    features: packageItem.features,
    annualPrice: +packageItem.annualPrice,
  };

  const handleSubmit = React.useCallback(
    async (data: IPackageRequest, setSubmitting: (value: boolean) => void) => {
      try {
        const packageId = await adminService.updatePackage(
          packageItem.id,
          data
        );
        if (packageId) {
          setSubmitting(false);
          onEditComplete();
        }
      } catch (err) {
        console.log("There is an error while updating a package", err);
        setUpdatePackageError(
          "There is an error while updating the package. Please try again."
        );
        setSubmitting(false);
      }
    },
    [onEditComplete, packageItem.id]
  );

  return (
    <Container fluid>
      <h3>Add Package</h3>
      <PackageForm
        initialValues={initialValues}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        errorMessage={updatePackageError}
      />
    </Container>
  );
};
