import styled from "styled-components";
import { Container, Dropdown } from "react-bootstrap";
import { colors, types } from "../../libs";

export const ManageStorageWrapper = styled(Container)`
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const Header = styled.h2`
    ${types.headers.secondary};
    background: ${colors.text.textGradient};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 10px;
`;

export const StyledTable = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .rdt_TableCell,
    .rdt_TableCol_Sortable {
        font-size: 16px;
    }
`;

export const StyledTableOverlay = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    color: ${colors.background.green};
    & > .spinner-border {
        width: 5rem;
        height: 5rem;
    }
`;

export const TrashIcon = styled.i`
    font-size: 18px;
    cursor: pointer;
`;

export const ManageStorageVideoRow = styled.div`
    display: flex;
    gap: 6px;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: space-between;
`;

export const ManageStorageVideoRowTitle = styled.div`
    font-size: 20px;
    font-weight: bold;
    color: ${colors.text.white};
`;

export const ManageStorageVideoRowContent = styled.div`
    font-size: 20px;
    font-weight: bold;
    color: ${colors.text.disabled};
`;

export const ManageStorageVideoRowContentWrapper = styled.div`
    display: flex;
    gap: 10px;
`;

export const LocationDropdownToggle = styled(Dropdown.Toggle)`
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
    border-radius: 20px;
    &:focus,
    &:active,
    &:hover {
        border-radius: 20px;
        border: none !important;
    }
`;

export const LocationDropdownToggleWrapper = styled.div`
    border: 2px solid ${colors.background.lightWhite};
    color: ${colors.text.white};
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 5px;
    background: ${colors.background.black};
`;

export const LocationDropdownMenu = styled(Dropdown.Menu)`
    background: ${colors.background.formField};
    border: 1px solid ${colors.background.lightWhite};
    border-radius: 10px;
    padding: 1rem;
    margin-top: 0.5rem;
    width: 250px;

    display: block;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.5s ease;
    pointer-events: none;

    &.show {
        transform: translateY(0);
        opacity: 1;
        pointer-events: all;
    }
`;

export const LocationDropdownItem = styled.div``;

export const LocationDropdownToggleIcon = styled.img<{
    isOpen: boolean;
}>`
    transform: rotate(${(props) => (props.isOpen ? "180deg" : "0deg")});
    transition: transform 0.3s ease-in-out;
`;

export const LocationDropdownItemCheckbox = styled.input.attrs({
    type: "checkbox",
})`
    appearance: none;
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 1px;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;

    &:after {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f00c";
        color: #111;
        display: none;
    }

    &:hover {
        background: #a5a5a5;
    }

    &:checked {
        background: rgba(183, 248, 77, 1);
        &:after {
            display: block;
        }
    }
`;

export const LocationDropdownItemLabel = styled.label`
    color: #fff;
    margin-left: 16px;
    font-size: 14px;
    cursor: pointer;
`;

export const LocationDropdownItemWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`;

export const LocationDropdownAction = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 1rem;
`;
