import React from "react";
import { NavLink } from "react-router-dom";
import { useGlobalState } from "../../../behavioral";
import * as Styled from "./MenuList.styled";
import { MenuItem } from "./menu-item";
import IconCaratDown from "../../../assets/images/icons/icon-carat-down.svg";

type Menu = {
    iconPath?: string;
    itemText: string;
    linkPath?: string;
    children?: {
        itemText: string;
        linkPath: string;
    }[];
};

interface IProps {
    menuList: Menu[];
    disableNavigationItems?: boolean;
}

const DropdownMenuContainer: React.FC<{
    mainMenuLabel?: string;
    submenuList?: { itemText: string; linkPath: string }[];
    disableNavigationItems?: boolean;
}> = ({ mainMenuLabel, submenuList, disableNavigationItems }) => {
    const [showChildMenu, setShowChildMenu] = React.useState(false);
    const toggleDropdownMenu = React.useCallback(() => {
        setShowChildMenu(!showChildMenu);
    }, [showChildMenu]);
    return (
        <Styled.DropdownContainer>
            <Styled.DropdownItem>
                <Styled.DropdownHeader onClick={toggleDropdownMenu}>
                    <MenuItem menuItemText={mainMenuLabel || ""} />

                    <Styled.DropdownToggleIcon
                        src={IconCaratDown}
                        isOpen={showChildMenu}
                    />
                </Styled.DropdownHeader>
                <Styled.DropdownBody show={showChildMenu}>
                    {submenuList?.map((el) => (
                        <NavLink
                            to={el.linkPath}
                            key={el.itemText}
                            style={{
                                pointerEvents: disableNavigationItems
                                    ? "auto"
                                    : "none",
                            }}
                        >
                            {({ isActive }) => (
                                <MenuItem
                                    menuItemText={el.itemText}
                                    isActive={isActive}
                                />
                            )}
                        </NavLink>
                    ))}
                </Styled.DropdownBody>
            </Styled.DropdownItem>
        </Styled.DropdownContainer>
    );
};

export const MenuList: React.FC<IProps> = ({ menuList }) => {
    const { state } = useGlobalState();

    return (
        <Styled.MenuListWrapper>
            {menuList.map((el) =>
                el.linkPath ? (
                    <NavLink
                        to={el.linkPath}
                        key={el.itemText}
                        style={{
                            pointerEvents:
                                state.allowAccess || el.itemText === "Dashboard"
                                    ? "auto"
                                    : "none",
                        }}
                    >
                        {({ isActive }) => (
                            <MenuItem
                                menuItemText={el.itemText}
                                isActive={isActive}
                            />
                        )}
                    </NavLink>
                ) : (
                    <DropdownMenuContainer
                        key={el.itemText}
                        disableNavigationItems={state.allowAccess}
                        mainMenuLabel={el.itemText}
                        submenuList={el.children}
                    />
                ),
            )}
        </Styled.MenuListWrapper>
    );
};
