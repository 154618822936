import React from "react";
import { Form, Col, Stack } from "react-bootstrap";
import { IAdBanner } from "../../types";
import {
    FileWithPreview,
    BaseButton,
    Error,
    MultiPartUploader,
    TextInput,
    IAdServerBannerRequestDto,
    IAdBannerType,
    IAdBannerStatus,
} from "../../../../libs";
import * as Styled from "./BannerFile.styled";
import { useFormik } from "formik";
import * as Yup from "yup";
import { adServerService } from "../../../../services";
import { useDropzone } from "react-dropzone";

interface IBannerFileProps {
    bannerType?: IAdBannerType;
    file?: FileWithPreview;
    onSave: () => void;
    onCancel: () => void;
    bannerWidth: Number;
    bannerHeight: Number;
    clientId?: string;
    banner?: IAdBanner;
}

export const BannerFile: React.FC<IBannerFileProps> = ({
    bannerType,
    file,
    onSave,
    onCancel,
    bannerWidth,
    bannerHeight,
    clientId,
    banner,
}) => {
    const [percentage, setPercentage] = React.useState(0);
    const { open, acceptedFiles } = useDropzone({
        noClick: true,
        noKeyboard: true,
    });
    const [newUpdatedFile, setNewUpdatedFile] =
        React.useState<FileWithPreview>();
    const [validImageDims, setValidImageDims] = React.useState(false);

    const [imageBannerWidth, setImgBannerWidth] = React.useState(0);
    const [imageBannerHeight, setImgBannerHeight] = React.useState(0);

    const formik = useFormik({
        initialValues: {
            adImageUrl: "",
            adImageAltText: "",
            adStatus: true,
        },

        validationSchema: Yup.object({
            adImageUrl: Yup.string()
                .matches(
                    /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/,
                    "Enter correct url!"
                )
                .required("Please enter website")
                .test(
                    "is-new-url",
                    "Please enter new data",
                    (value, context) => {
                        if (newUpdatedFile) return true;
                        if (banner) {
                            if (
                                banner.adImageUrl === value &&
                                banner.adImageAlttext ===
                                    context.parent.adImageAltText
                            ) {
                                return false;
                            }
                        }
                        return true;
                    }
                ),
            adImageAltText: Yup.string()
                .required("Please enter alt text")
                .test(
                    "is-new-alt-text",
                    "Please enter new data",
                    (value, context) => {
                        if (newUpdatedFile) return true;
                        if (banner) {
                            if (
                                banner.adImageAlttext === value &&
                                banner.adImageUrl === context.parent.adImageUrl
                            ) {
                                return false;
                            }
                        }
                        return true;
                    }
                ),
        }),

        onSubmit() {
            // If we want to update the image then we delete the existing ad banner and upload the new one
            // If we want to update the image url and alt text then we update the existing ad banner
            // If we want to create a new ad banner then we upload the new one
            if (!validImageDims) return;
            if (banner && newUpdatedFile) {
                deleteBanner(banner).then(() => uploadFilesToS3());
            } else if (banner && !newUpdatedFile) {
                const requestDtol: IAdServerBannerRequestDto = {
                    ...banner,
                    adImageAlttext: formik.values.adImageAltText,
                    adImageUrl: formik.values.adImageUrl,
                };
                updateBanner(requestDtol);
            } else {
                uploadFilesToS3();
            }
        },
    });

    const formikRef = React.useRef(formik);

    const getImageDimensions = React.useCallback((url) => {
        var img = new Image();
        img.onload = function () {
            setImgBannerHeight(img.height);
            setImgBannerWidth(img.width);
        };
        img.src = url;
    }, []);

    const calculateBannerDims = React.useCallback(() => {
        if (newUpdatedFile) {
            getImageDimensions(newUpdatedFile?.previewUrl);
        } else if (banner) {
            getImageDimensions(banner.adImageSource);
        } else {
            getImageDimensions(file?.previewUrl);
        }
    }, [banner, file, newUpdatedFile, getImageDimensions]);

    React.useEffect(() => {
        if (acceptedFiles.length > 0) {
            const filesWithPreviewUrl = acceptedFiles.map((file: File) =>
                Object.assign(
                    {},
                    {
                        file: file,
                        previewUrl: URL.createObjectURL(file),
                    }
                )
            );
            setNewUpdatedFile(filesWithPreviewUrl[0]);
        }
    }, [acceptedFiles]);

    React.useEffect(() => {
        formikRef.current.validateForm();
        calculateBannerDims();
    }, [newUpdatedFile, calculateBannerDims, formikRef]);

    const createBanner = React.useCallback(
        async (requestDto: IAdServerBannerRequestDto) => {
            await adServerService.createBanner(requestDto);
            onSave();
        },
        [onSave]
    );

    const updateBanner = React.useCallback(
        async (requestDto: IAdServerBannerRequestDto) => {
            await adServerService.updateBanner(requestDto);
            onSave();
        },
        [onSave]
    );

    const deleteBanner = React.useCallback(async (banner: IAdBanner) => {
        if (banner?.adId) {
            await adServerService.deleteBanner(banner.adId, banner.s3Key);
        }
    }, []);

    const uploadFilesToS3 = React.useCallback(() => {
        if (file || (banner && newUpdatedFile)) {
            const uploader = new MultiPartUploader({
                fileName: newUpdatedFile
                    ? newUpdatedFile.file.name
                    : file?.file.name,
                file: newUpdatedFile ? newUpdatedFile.file : file?.file,
                clientId: clientId,
                mediaId: "ad-server-banners",
                category: "image",
            });

            uploader
                .getOnProgress(function (params: any) {
                    setPercentage(params.percentage);
                })
                .getOnComplete(function (params: any) {
                    const requestDtol: IAdServerBannerRequestDto = {
                        adFileName: params.fileName,
                        s3Key: params.s3Key,
                        adPosition: bannerType
                            ? bannerType
                            : IAdBannerType.AD_HORIZONTAL_BANNER,
                        adImageAlttext: formik.values.adImageAltText,
                        adImageUrl: formik.values.adImageUrl,
                        adStatus: IAdBannerStatus.AD_ACTIVE,
                    };
                    createBanner(requestDtol);
                })
                .getOnError((error: any) => {
                    console.log(error);
                });

            uploader.start();
        }
    }, [file, clientId, formik, createBanner, bannerType, newUpdatedFile, banner]);

    React.useEffect(() => {
        if (banner) {
            formikRef.current.setFieldValue("adImageUrl", banner.adImageUrl);
            formikRef.current.setFieldValue("adImageAltText", banner.adImageAlttext);
        }
    }, [banner, formikRef]);

    React.useEffect(() => {
        if (
            bannerWidth === imageBannerWidth &&
            bannerHeight === imageBannerHeight
        ) {
            setValidImageDims(true);
        } else {
            setValidImageDims(false);
        }
    }, [imageBannerWidth, imageBannerHeight, bannerWidth, bannerHeight]);

    React.useEffect(() => {
        calculateBannerDims();
    }, [calculateBannerDims]);

    return (
        <Styled.AdServerComponentWrapper>
            <Styled.ImagePreview
                src={
                    newUpdatedFile
                        ? newUpdatedFile?.previewUrl
                        : banner
                        ? banner.adImageSource
                        : file?.previewUrl
                }
            ></Styled.ImagePreview>
            {!validImageDims && (
                <Styled.ErrorText>
                    {`Image dimensions are invalid. Please use an image of ${bannerWidth}x${bannerHeight}px dimensions.`}
                </Styled.ErrorText>
            )}
            <Styled.UpdateButton type="button" variant="primary" onClick={open}>
                Update Image
            </Styled.UpdateButton>
            <Form onSubmit={formik.handleSubmit}>
                <Styled.RequiredText>* indicates required</Styled.RequiredText>
                <Styled.FormFieldRow>
                    <Col>
                        <TextInput
                            fieldName="adImageUrl"
                            fieldLabel="Advert Image Link *"
                            placeholder="URL to which the Ad will redirect"
                            fieldValue={formik.values.adImageUrl}
                            onChangeHandler={formik.handleChange}
                            onBlurHandler={formik.handleBlur}
                            hasError={
                                formik.touched.adImageUrl &&
                                !!formik.errors.adImageUrl
                            }
                        />
                        {formik.touched.adImageUrl &&
                            formik.errors.adImageUrl && (
                                <Error message={formik.errors.adImageUrl} />
                            )}
                    </Col>
                </Styled.FormFieldRow>
                <Styled.FormFieldRow>
                    <Col>
                        <TextInput
                            fieldName="adImageAltText"
                            fieldLabel="Advert Alt Text *"
                            placeholder="Alt Text for the Advert Image"
                            fieldValue={formik.values.adImageAltText}
                            onChangeHandler={formik.handleChange}
                            onBlurHandler={formik.handleBlur}
                            hasError={
                                formik.touched.adImageAltText &&
                                !!formik.errors.adImageAltText
                            }
                        />
                        {formik.touched.adImageAltText &&
                            formik.errors.adImageAltText && (
                                <Error message={formik.errors.adImageAltText} />
                            )}
                    </Col>
                </Styled.FormFieldRow>
                <Stack direction="horizontal" gap={2}>
                    <BaseButton
                        type="button"
                        variant="secondary"
                        onClick={onCancel}
                        disabled={formik.isSubmitting}
                    >
                        Cancel
                    </BaseButton>
                    <BaseButton
                        type="submit"
                        variant={
                            !formik.isValid || !validImageDims
                                ? "secondary"
                                : "primary"
                        }
                        disabled={!formik.isValid || !validImageDims}
                    >
                        {formik.isSubmitting && (
                            <>
                                <Styled.LoadingSpinner
                                    animation="border"
                                    size="sm"
                                    variant="primary"
                                />
                            </>
                        )}
                        {formik.isSubmitting
                            ? `${percentage}% Uploaded`
                            : percentage === 100
                            ? "Processing"
                            : "Submit"}
                    </BaseButton>
                </Stack>
            </Form>
        </Styled.AdServerComponentWrapper>
    );
};
