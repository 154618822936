import axios from "axios";
import { API_BASE_PATHS, API_ROUTES, IAdServerBannerRequestDto } from "../libs";
import { buildRequestConfig } from "./buildRequestConfig";

class AdServerService {
    async getAllBanners() {
        try {
            const config = await buildRequestConfig();
            const response = await axios.get(
                `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.AD_SERVER_API}/${API_ROUTES.STATIC_AD}`,
                { ...config }
            );

            return response.data.data;
        } catch (err: any) {
            console.log(
                "There is an error while fetching ad server banners",
                err
            );
            throw new Error(err);
        }
    }

    async createBanner(adServerBannerRequest: IAdServerBannerRequestDto) {
        try {
            const config = await buildRequestConfig();
            const response = await axios.post(
                `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.AD_SERVER_API}/${API_ROUTES.STATIC_AD}`,
                adServerBannerRequest,
                { ...config }
            );

            return response.data;
        } catch (err: any) {
            console.log(
                "There is an error while creating a ad server banner",
                err
            );
            throw new Error(err);
        }
    }

    async deleteBanner(ad_id: string, s3_key: string) {
        try {
            const config = await buildRequestConfig();
            const response = await axios.delete(
                `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.AD_SERVER_API}/${API_ROUTES.STATIC_AD}/${ad_id}/${s3_key}`,
                { ...config }
            );

            return response.data;
        } catch (err: any) {
            console.log(
                "There is an error while deleting a ad server banner",
                err
            );
            throw new Error(err);
        }
    }

    async updateBanner(adServerBannerRequest: IAdServerBannerRequestDto) {
        try {
            const config = await buildRequestConfig();
            const updateRequest = {
                adImageUrl: adServerBannerRequest.adImageUrl,
                adImageAlttext: adServerBannerRequest.adImageAlttext,
                adPosition: adServerBannerRequest.adPosition,
                adStatus: adServerBannerRequest.adStatus,
            };

            const response = await axios.put(
                `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.AD_SERVER_API}/${API_ROUTES.STATIC_AD}/${adServerBannerRequest.adId}`,
                updateRequest,
                { ...config }
            );

            return response.data;
        } catch (err: any) {
            console.log(
                "There is an error while updating a ad server banner",
                err
            );
            throw new Error(err);
        }
    }
}

export const adServerService = new AdServerService();