import React from "react";
import { ICategoryType, IMediaResponseDto } from "../../../libs";
import { vodService } from "../../../services";
import { UploadsContent } from "../../dashboard-components/uploads-content";
import * as Styled from "./VodContent.styled";

export const VodContent: React.FC<{
    mediaItems: IMediaResponseDto[];
    categories: ICategoryType[];
    clientId: string;
    onUpdateUploads?: () => void;
    progressItems?: {
        [key in string]: { percentage: number; currentPhase: string };
    };
}> = ({ mediaItems, categories, onUpdateUploads, clientId, progressItems }) => {
    const handleOnDeleteUpload = React.useCallback(
        async (mediaId) => {
            const response = await vodService.deleteMediaItem(mediaId);

            if (!response.error && onUpdateUploads) {
                onUpdateUploads();
            }
        },
        [onUpdateUploads],
    );

    const handleOnUpdateUploads = React.useCallback(() => {
        onUpdateUploads && onUpdateUploads();
    }, [onUpdateUploads]);

    return (
        <>
            <Styled.WelcomeHeaderWrapper>
                <Styled.WelcomeHeader>Video-On-Demand</Styled.WelcomeHeader>
            </Styled.WelcomeHeaderWrapper>
            {mediaItems.length === 0 ? (
                <>
                    There are no uploads available in your account at this time.
                    Start uploading!!
                </>
            ) : (
                <UploadsContent
                    mediaItems={mediaItems}
                    categories={categories}
                    progressItems={progressItems}
                    onDeleteUpload={handleOnDeleteUpload}
                    onUpdateUploads={handleOnUpdateUploads}
                />
            )}
        </>
    );
};
