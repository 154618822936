import React from "react";
import BackImage from "../../../assets/images/upload-image.svg";
import * as Styled from "./NoPlaylists.styled";
import { Col } from "react-bootstrap";
import { BaseButton } from "../../../libs";
import { confirmAlert } from "react-confirm-alert";
import { NewPlaylistAlert } from "../new-playlist-alert";

export const NoPlaylists: React.FC<{
    createPlaylist: (name: string) => void;
}> = ({ createPlaylist }) => {
    const handleCreatePlaylist = React.useCallback(
        (name: string) => {
            createPlaylist(name);
        },
        [createPlaylist],
    );

    const showModal = React.useCallback(() => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <NewPlaylistAlert
                        onClose={onClose}
                        handleCreatePlaylist={handleCreatePlaylist}
                    />
                );
            },
            closeOnEscape: true,
            closeOnClickOutside: false,
        });
    }, [handleCreatePlaylist]);

    return (
        <div>
            <Styled.NoPlaylistsContainer>
                <Col
                    md={7}
                    className="mx-auto"
                    style={{ position: "relative" }}
                >
                    <Styled.NoPlaylistsImage src={BackImage} />
                    <Styled.NoPlaylistsText>
                        You haven't created any playlists yet.
                        <Styled.NoPlaylistsTextSmall>
                            You'll create, organize, and share your playlists
                            here.
                        </Styled.NoPlaylistsTextSmall>
                        <Styled.NoPlaylistsButtonWrap>
                            <BaseButton size="sm" onClick={showModal}>
                                Create Playlist
                            </BaseButton>
                        </Styled.NoPlaylistsButtonWrap>
                    </Styled.NoPlaylistsText>
                </Col>
            </Styled.NoPlaylistsContainer>
        </div>
    );
};
