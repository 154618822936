import React from "react";
import { Form } from "react-bootstrap";

import * as Styled from "./NormalInput.styled";

interface ITextInputProps {
    fieldName: string;
    placeholder: string;
    fieldLabel?: string;
    fieldType?: string;
    fieldValue?: string;
    inputLimit?: number;
    onBlurHandler?: (e: Event) => void;
    hasError?: boolean;
    onChangeHandler?: (event: React.ChangeEvent) => void;
    rows?: number;
}

interface IRadioProps {
    fieldName: string;
    fieldLabel: string;
    fieldValue: string | number;
    fieldId: string;
    checked?: boolean;
    description?: string;
    onChangeHandler: (event: React.ChangeEvent) => void;
}

export interface ISelectOption {
    id: string;
    value: string;
    label: string;
    name?: string;
}

interface ISelectProps extends Omit<ITextInputProps, "onBlurHandler" | "inputLimit"> {
    options: ISelectOption[];
    onChangeHandler?: (event: React.ChangeEvent) => void;
    onBlurHandler?: (event: React.ChangeEvent) => void;
    menuHeight?: number;
}

export const TextInput: React.FC<ITextInputProps> = ({
    fieldName,
    placeholder,
    fieldLabel,
    fieldType = "text",
    fieldValue,
    inputLimit,
    onChangeHandler,
    onBlurHandler,
    hasError,
}) => {
    return (
        <Styled.FormFieldGroup controlId={fieldName}>
            {fieldLabel && <Styled.FormGroupLabel>{fieldLabel}</Styled.FormGroupLabel>}
            <Styled.FormGroupInput
                value={fieldValue}
                type={fieldType}
                name={fieldName}
                placeholder={placeholder}
                onChange={onChangeHandler}
                onBlur={onBlurHandler}
                className={hasError ? "is-invalid" : ""}
            />
            {inputLimit && <Styled.FormGroupLimit>{fieldValue?.length ? fieldValue.length : 0}/{inputLimit}</Styled.FormGroupLimit>}
        </Styled.FormFieldGroup>
    );
};

export const TextAreaInput: React.FC<ITextInputProps> = ({
    fieldName,
    placeholder,
    fieldLabel,
    onChangeHandler,
    fieldValue,
    rows = 3,
}) => {
    return (
        <Styled.FormFieldGroup controlId={fieldName}>
            <Styled.FormGroupLabel>{fieldLabel}</Styled.FormGroupLabel>
            <Styled.TextAreaInput
                as="textarea"
                onChange={onChangeHandler}
                rows={rows}
                value={fieldValue}
                placeholder={placeholder}
                name={fieldName}
            />
        </Styled.FormFieldGroup>
    );
};

export const SelectInput: React.FC<ISelectProps> = ({
    fieldName,
    placeholder,
    options,
    fieldValue,
    fieldLabel = "",
    onChangeHandler,
    onBlurHandler,
    menuHeight,
}) => {
    const optionValues = options.map((option) => (
        <option key={option.id} value={option.value}>
            {option.label}
        </option>
    ));
    return (
        <Styled.FormFieldGroup controlId={fieldName}>
            {fieldLabel && (
                <Styled.FormGroupLabel>{fieldLabel}</Styled.FormGroupLabel>
            )}
            <Styled.SelectInput
                aria-label="Default select example"
                onChange={onChangeHandler}
                value={fieldValue}
                name={fieldName}
                onBlur={onBlurHandler}
                maxMenuHeight={menuHeight || 200}
            >
                <option>{placeholder}</option>
                {optionValues}
            </Styled.SelectInput>
        </Styled.FormFieldGroup>
    );
};

export const RadioInput: React.FC<IRadioProps> = ({
    fieldLabel,
    fieldName,
    fieldValue,
    fieldId,
    checked,
    description,
    onChangeHandler,
}) => {
    return (
        <Styled.RadioInputWrapper>
            <Styled.RadioInput
                type="radio"
                name={fieldName}
                value={fieldValue}
                id={fieldId}
                defaultChecked={checked}
                onChange={(event) => onChangeHandler(event)}
            />
            <Styled.RadioInputLabel htmlFor={fieldId}>
                {fieldLabel}
                {description && <Styled.RadioInputDescription>{description}</Styled.RadioInputDescription>}
            </Styled.RadioInputLabel>
        </Styled.RadioInputWrapper>
    );
};

export const InputSwtich = () => {
    return <Form.Check type="switch" id="custom-switch" />;
};
