import styled from "styled-components";
import { colors, types } from "../../libs";

export const ResendCodeText = styled.p`
  color: ${colors.text.mediumWhite};
  ${types.body.small};
  & span {
    color: ${colors.text.green};
    cursor: pointer;
  }
`;
