import { Route, Routes } from "react-router-dom";
import { ROUTEPATHS } from "../../libs";
import { ManagePackagesContainer } from "./manage-packages";
import { ManageUsersContainer } from "./manage-users";

export const RouteAdminContainer: React.FC = () => {
  return (
    <Routes>
      <Route
        path={`/${ROUTEPATHS.MANAGE_USERS}`}
        element={<ManageUsersContainer />}
      />
      <Route
        path={`/${ROUTEPATHS.MANAGE_PACKAGES}`}
        element={<ManagePackagesContainer />}
      />
    </Routes>
  );
};
