import styled from "styled-components";
import { Card } from "react-bootstrap";

const StyledCard = styled(Card)`
    background-color: transparent;
    border-bottom: 2px solid #525252;
    border-radius: 0;
`;

const StyledCardBody = styled(Card.Body)`
    padding: 0.5rem;
`;

const StyledCardTitle = styled(Card.Title)`
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
`;

const StyledCardSubtitle = styled(Card.Subtitle)`
    font-size: 12px;
    font-weight: 600;
    color: #fff;
`;

const StyledCardText = styled(Card.Text)``;

export const ACLCard = ({ children, ...props }) => (
    <StyledCard {...props}>{children}</StyledCard>
);

ACLCard.Body = StyledCardBody;
ACLCard.Title = StyledCardTitle;
ACLCard.Subtitle = StyledCardSubtitle;
ACLCard.Text = StyledCardText;

export const ALCCardContainerStats = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`;
export const ALCCardContainerStatsEnd = styled.div`
    display: flex;
    flex-direction: row;
    align-items: end;
`;

export const ALCCardContainerGraph = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    margin-bottom: 5px;
`;

export const ALCCardContainerGraphText = styled.div`
    font-size: 12px;
    font-weight: 600;
    color: #b7f84d;
`;

export const ALCCardMetricText = styled.div`
    color: #fff;
    text-align: right;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    opacity: 0.7;
`;

export const ALCCardMetricTextSmall = styled.div`
    color: #6a6a6a;
    text-align: right;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
    padding-left: 5px;
`;
