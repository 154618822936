import * as React from "react";
import { AddPackage, EditPackage, PackagesList } from "../../../components";
import { IPackage } from "../../../libs";

import { Spinner } from "react-bootstrap";
import { adminService } from "../../../services";
import * as Styled from "./ManagePackagesContainer.styled";

export const ManagePackagesContainer: React.FC = () => {
  const [showLoader, setShowLoader] = React.useState(true);
  const [packages, setPackages] = React.useState<IPackage[]>([]);
  const [showAddPackageForm, setShowAddPackageForm] = React.useState(false);
  const [packageToEdit, setPackageToEdit] = React.useState<
    IPackage | undefined
  >();

  const onAddPackageClick = React.useCallback(() => {
    setShowAddPackageForm(true);
  }, []);

  const onEditPackageClick = React.useCallback((packageItem: IPackage) => {
    setPackageToEdit(packageItem);
  }, []);

  const getAllPackages = React.useCallback(async () => {
    const result = await adminService.getAllPackages();
    setPackages(result);
    setShowLoader(false);
  }, []);

  const handlePackageCreate = React.useCallback(() => {
    // Refetch the packages and set show add package flag to false
    setShowAddPackageForm(false);
    getAllPackages();
  }, [getAllPackages]);

  const handlePackageEdit = React.useCallback(() => {
    setPackageToEdit(undefined);
    getAllPackages();
  }, [getAllPackages]);

  const onDeletePackageClick = React.useCallback(async (packageId: string) => {
    try {
      await adminService.deletePackage(packageId);
    } catch (err) {
      console.log(
        "There is an error while deleting the package: " + packageId,
        err
      );
    }
  }, []);

  React.useEffect(() => {
    getAllPackages();
  }, [getAllPackages]);

  if (showLoader) {
    <Styled.SpinnerWrapper>
      <Spinner animation="border">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <p>Fetching the users...</p>
    </Styled.SpinnerWrapper>;
  }

  return (
    <>
      <Styled.Title>Packages</Styled.Title>
      {showAddPackageForm ? (
        <AddPackage
          onCancel={() => setShowAddPackageForm(false)}
          onCreateComplete={handlePackageCreate}
        />
      ) : packageToEdit ? (
        <EditPackage
          packageItem={packageToEdit}
          onCancel={() => setPackageToEdit(undefined)}
          onEditComplete={handlePackageEdit}
        />
      ) : (
        <PackagesList
          packages={packages}
          onCreateNewPackageClick={onAddPackageClick}
          onEditPackageClick={onEditPackageClick}
          onDeletePackageClick={onDeletePackageClick}
        />
      )}
    </>
  );
};
